import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { SendStore } from '../../store/send.store';
import { IDetailedTransaction, IDetailedTransactionResponseModel, ITransactionIdParams } from './send.models';
import {
  RemittanceCountriesQuery
} from '../../../../../../data-modules/remittance-countries/remittance-countries.store';
import {
  RemittanceCountriesService
} from '../../../../../../data-modules/remittance-countries/remittance-countries.service';
import { CalculatorService } from '../../../../../../data-modules/calculator/calculator.service';
import { CalculatorStore } from '../../../../../../data-modules/calculator/calculator.store';
import { WorkflowsService } from '../../../../../../data-modules/workflows/workflows.service';
import { GatewaysService } from '../../../../../../data-modules/gateways/gateways.service';
import { GatewaysQuery } from '../../../../../../data-modules/gateways/gateways.store';
import { CurrenciesSourceQuery } from '../../../../../../data-modules/currencies-source/currencies-source.store';
import { CurrenciesSourceService } from '../../../../../../data-modules/currencies-source/currencies-source.service';
import {
  CurrenciesDestinationQuery
} from '../../../../../../data-modules/currencies-destination/currencies-destination.store';
import {
  CurrenciesDestinationService
} from '../../../../../../data-modules/currencies-destination/currencies-destination.service';
import { MyReceiversQuery } from '../../../../../../data-modules/my-receivers/my-receivers.store';
import { RequiredFieldsService } from '../../../../../../data-modules/required-fields/required-fields.service';
import { MyBeneficiariesService } from '../beneficiary/my-beneficiaries/my-beneficiaries.service';
import { MyReceiversService } from '../../../../../../data-modules/my-receivers/my-receivers.service';
import { WorkflowsQuery } from '../../../../../../data-modules/workflows/workflows.store';
import { BeneficiaryGeneralFormStore } from '../beneficiary/beneficiary-general/beneficiary-general.store';
import {
  DocTypesSourceIncomeQuery
} from '../../../../../../data-modules/doctypes-source-income/doctypes-source-income.store';
import { IGateway } from '../../../../../../data-modules/gateways/gateways.model';
import { ILookupInt } from '../../../../private-models/lookup-str';
import { IWorkflow } from '../../../../../../data-modules/workflows/workflows.models';
import { IRemittanceCountry } from '../../../../../../data-modules/remittance-countries/remittance-countries.model';
import { IMyReceiver } from '../../../../../../data-modules/my-receivers/my-receivers.models';
import { IDocTypeValue } from '../../../../../../data-modules/doctypes-identity/doctypes-identity.model';
import {
  DocTypesSourceIncomeService
} from '../../../../../../data-modules/doctypes-source-income/doctypes-source-income.service';

@Injectable()
export class SendAutofillService {

  constructor(
    private _remittanceCountriesQuery: RemittanceCountriesQuery,
    private _remittanceCountriesService: RemittanceCountriesService,
    private _calculatorService: CalculatorService,
    private _calculatorStore: CalculatorStore,
    private _workflowsService: WorkflowsService,
    private _gatewaysService: GatewaysService,
    private _gatewaysQuery: GatewaysQuery,
    private _currenciesSourceQuery: CurrenciesSourceQuery,
    private _currenciesSourceService: CurrenciesSourceService,
    private _http: HttpClient,
    private _currenciesDestinationQuery: CurrenciesDestinationQuery,
    private _currenciesDestinationService: CurrenciesDestinationService,
    private _sendStore: SendStore,
    private _myReceiversQuery: MyReceiversQuery,
    private _myBeneficiaryService: MyBeneficiariesService,
    private _myReceiversService: MyReceiversService,
    private _workflowsQuery: WorkflowsQuery,
    private _beneficiaryGeneralFormStore: BeneficiaryGeneralFormStore,
    private _doctypesSourceIncomeService: DocTypesSourceIncomeService,
    private _docTypesSourceIncomeQuery: DocTypesSourceIncomeQuery,
    private _requiredFieldsService: RequiredFieldsService
  ) { }

  fillWithSendAgain$(params: ITransactionIdParams): Observable<void> {
    let theResponse: IDetailedTransaction;
    return of(void 0)
      .pipe(
        tap(() => this._sendStore.setLoading(true)),
        switchMap(() => this._http.get<IDetailedTransactionResponseModel>(`remittance/send-again/${params.transactionId}`)),
        catchError(() => {
          this._sendStore.setLoading(false);
          return EMPTY;
        }),
        tap(response => {
          theResponse = response;
        }),
        switchMap(() => this._remittanceCountriesService.getRemittanceCountries$()),
        switchMap(() => this._remittanceCountriesQuery.selectEntity(theResponse.direction.country.id)),
        take(1),
        tap((destinationCountry: IRemittanceCountry | undefined) => {
          if (destinationCountry == null) {
            console.warn(destinationCountry + 'not found');
            return;
          }
          this._calculatorStore.update({ destinationCountry });
        }),
        switchMap(() => this._workflowsService.downloadAndStoreWorkflows$()),
        switchMap(() => this._workflowsQuery.selectAll()),
        take(1),
        map((wfs: IWorkflow[]) => wfs.find(wf => wf.title === theResponse.direction.workflow)),
        switchMap((wf: IWorkflow | undefined) => {
          if (wf == null) {
            console.warn('workflow not found', theResponse.direction.workflow, this._gatewaysQuery.getValue());
            return of(void 0);
          }
          return this._workflowsService.activateWorkflow$(wf);
        }),
      )
      .pipe(
        switchMap(() => this._gatewaysQuery.selectEntity(theResponse.direction.id)),
        take(1),
        switchMap((gateway: IGateway | undefined) => {
          if (gateway == null) {
            console.warn('gateway not found', theResponse.direction.id, this._gatewaysQuery.getValue());
            return of(void 0);
          }
          return this._gatewaysService.activateGateway$$(gateway.directionId);
        }),
        switchMap(() => this._currenciesSourceService.getCurrenciesSource()),
        switchMap(() => this._currenciesSourceQuery.selectEntity(theResponse.funds.sourceCurrency.id.toString(10))),
        take(1),
        tap((sourceCurrency: ILookupInt | undefined) => {
          if (sourceCurrency == null) {
            console.warn(JSON.stringify(theResponse.funds.sourceCurrency) + ' not found in ' + JSON.stringify(this._currenciesSourceQuery.getValue().entities));
            return;
          }
          this._calculatorStore.update({ sourceCurrency });
        }),
        switchMap(() => combineLatest([
          this._gatewaysQuery.selectEntity(theResponse.direction.id),
          this._currenciesSourceQuery.selectEntity(theResponse.funds.sourceCurrency.id.toString())
        ])),
        take(1),
        switchMap(([gateway, sourceCurrency]) => {
          if (gateway == null || sourceCurrency == null) {
            console.warn('cant load destionationc currencies');
            return of(void 0);
          }
          return this._currenciesDestinationService.getCurrenciesDestination$(sourceCurrency.id, gateway.directionId);
        }),
      )
      .pipe(
        switchMap(() => this._currenciesDestinationQuery.selectEntity(theResponse.funds.destinationCurrency.id.toString())),
        take(1),
        tap((destinationCurrency: ILookupInt | undefined) => {
          if (destinationCurrency == null) {
            console.warn('destinationCurrency not found');
            return;
          }
          this._calculatorStore.update({ destinationCurrency: destinationCurrency ?? null });
        }),
        switchMap(() => this._setExternalAmount$(theResponse.funds.amount.toString(), theResponse.funds.amountKey.toString(10))),
        tap(() => this._sendStore.setLoading(false)),
        switchMap(() => this._calculatorService.executeCalculationAndUpdateStore$()),
        switchMap(() => this._gatewaysQuery.selectEntity(theResponse.direction.id)),
        take(1),
        switchMap((gateway: IGateway | undefined) => {
          if (gateway == null) {
            console.warn('gateway not found');
            return of(void 0);
          }
          return this._requiredFieldsService.getRequiredFields$(gateway.directionId);
        }),
        switchMap(() => this._myReceiversService.getMyReceivers$()),
        switchMap(() => this._myReceiversQuery.selectEntity(theResponse.receiver.id.toString(10))),
        take(1),
        switchMap((receiver: IMyReceiver | undefined) => {
          if (receiver == null) {
            console.warn('receiver not found');
            return of(void 0);
          }
          return this._myBeneficiaryService.setBeneficiary$$(receiver);
        }),
      )
      .pipe(
        switchMap(() => this._doctypesSourceIncomeService.getDocTypesSourceIncome$()),
        switchMap(() => {
          if (theResponse.sourceOfIncome == null) {return of(void 0); }
          return this._docTypesSourceIncomeQuery.selectEntity(theResponse.sourceOfIncome)
            .pipe(
              take(1),
              switchMap((soiType: IDocTypeValue | undefined) => {
                if (soiType == null) {
                  console.warn('soi not found');
                  return of(void 0);
                }
                return this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({ senderSourceOfIncomeType: soiType });
              }),
            );
        }),
        tap(() => this._sendStore.setLoading(false)),
        take(1),
        mapTo(void 0)
      );
  }

  private _setExternalAmount$(amount: string, amountKey: string): Observable<void> {
    return of(void 0).pipe(
      switchMap(_ => {
        if (amountKey === '0') {
          return this._calculatorService.setSourceAmount$(Number(amount));
        } else {
          return this._calculatorService.setDestinationAmount$(Number(amount));
        }
      }),
    );
  }


}
