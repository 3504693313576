import { Query, Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ICountry } from '../../../../../../../data-modules/countries/countries.model';
import { Observable } from 'rxjs';

export interface IBeneficiaryAddressForm {
  country: ICountry | null,
  city: string,
  street: string,
  building: string,
  apartment: string,
  postCode: string
}

export function createInitialState(): IBeneficiaryAddressForm {
  return {
    country: null,
    city: '',
    street: '',
    building: '',
    apartment: '',
    postCode: ''
  };
}

@StoreConfig({ name: 'beneficiary-address-form' })
export class BeneficiaryAddressStore extends Store<IBeneficiaryAddressForm> {
  constructor() {
    super(createInitialState());
  }
}

@Injectable()
export class BeneficiaryAddressQuery extends Query<IBeneficiaryAddressForm> {

  state$$: Observable<IBeneficiaryAddressForm> = this.select();

  constructor(protected override store: BeneficiaryAddressStore) {
    super(store);
  }
}
