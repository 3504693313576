import { Query, Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IMyReceiver } from '../../../../../../data-modules/my-receivers/my-receivers.models';
import { Observable } from 'rxjs';

interface IMyBeneficiaryState {
  myBeneficiary: null | IMyReceiver
}

export function createInitialState(): IMyBeneficiaryState {
  return {
    myBeneficiary: null,
  };
}

@StoreConfig({ name: 'my-beneficiary' })
export class MyBeneficiaryStore extends Store<IMyBeneficiaryState> {

  constructor() {
    super(createInitialState());
  }
}

@Injectable()
export class MyBeneficiaryQuery extends Query<IMyBeneficiaryState> {

  myBeneficiary$$: Observable<null | IMyReceiver> = this.select(x => (x.myBeneficiary));

  constructor(protected override store: MyBeneficiaryStore) {
    super(store);
  }
}
