import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LanguageCode, LanguagesQuery } from '@ff/localization';
import { catchError, concatMap, take, tap } from 'rxjs/operators';

@Injectable()
export class StaticResourceDialogService {
  private _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isLoading$$: Observable<boolean> = this._isLoading$.asObservable();

  constructor(
    private _http: HttpClient,
    private _languagesQuery: LanguagesQuery
  ) {}

  getTemplate$(companyName: string, template: string): Observable<string | null> {
    return this._languagesQuery.selectActive().pipe(
      take(1),
      tap(() => this._isLoading$.next(true)),
      concatMap(language => {
        const apiUrl = `reference/static-resource?companyName=${companyName}&template=${template}TXT&stamp=${Date.now()}`;
        const languageCode: LanguageCode | null = language?.id ?? null;

        if (languageCode != null && languageCode.trim() !== '') {
          return this._http.get(apiUrl, { responseType: 'text' });
        }

        return throwError('There is no active language');
      }),
      tap(() => this._isLoading$.next(false)),
      catchError((error) => {
        console.warn(error);
        this._isLoading$.next(false);
        return throwError(error);
      })
    );
  }
}
