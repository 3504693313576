import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BeneficiaryAddressQuery, BeneficiaryAddressStore, IBeneficiaryAddressForm } from './beneficiary-address.store';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FormStoreMapper } from '../../../../../../../utils/helpers/form-store-mapper';
import { CountriesQuery } from '../../../../../../../data-modules/countries/countries.store';
import { CalculatorQuery } from '../../../../../../../data-modules/calculator/calculator.store';
import { CountriesService } from '../../../../../../../data-modules/countries/countries.service';
import { ICountry } from '../../../../../../../data-modules/countries/countries.model';

@Injectable()
export class BeneficiaryAddressService {
  beneficiaryAddressForm: FormGroup = new FormGroup({
    country: new FormControl(null, [Validators.required]),
    city: new FormControl(null, [Validators.required]),
    street: new FormControl(null, [Validators.required]),
    building: new FormControl(null), // FFO-194 validators removed
    apartment: new FormControl(null),
    postCode: new FormControl(null, [Validators.minLength(1), Validators.maxLength(128)]),
  });

  formMapper: FormStoreMapper = new FormStoreMapper(this.beneficiaryAddressForm);

  constructor(
    public countriesQuery: CountriesQuery,
    public calculatorQuery: CalculatorQuery,
    public countriesService: CountriesService,
    private beneficiaryAddressStore: BeneficiaryAddressStore,
    private beneficiaryAddressQuery: BeneficiaryAddressQuery
  ) {

    this.formMapper.formChange<ICountry | null>('country', value =>
      this.updateAddressForm({ country: value }));
    this.formMapper.formChange<string>('city', value =>
      this.updateAddressForm({ city: value }));
    this.formMapper.formChange<string>('street', value =>
      this.updateAddressForm({ street: value }));
    this.formMapper.formChange<string>('building', value =>
      this.updateAddressForm({ building: value }));
    this.formMapper.formChange<string>('apartment', value =>
      this.updateAddressForm({ apartment: value }));
    this.formMapper.formChange<string>('postCode', value =>
      this.updateAddressForm({ postCode: value }));

    this.beneficiaryAddressQuery.select(x => ({
      country: x.country,
      city: x.city,
      street: x.street,
      building: x.building,
      apartment: x.apartment,
      postCode: x.postCode,
    })).subscribe(data => {
      this.formMapper.patchValue(data);
    });
  }

  updateAddressForm(partial: Partial<IBeneficiaryAddressForm>): Observable<void> {
    return of(void 0)
      .pipe(
        tap(() => {
          this.beneficiaryAddressStore.update(x => ({ ...x, ...partial }));
        }));
  }

}
