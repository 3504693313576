import { Injectable } from '@angular/core';
import { IDocumentBodyUI } from '../../../private-models/document.model';
import { IdentityDocumentsQuery, IdentityDocumentsStore, IIdDocsUIState } from './store/identity-documents.store';
import { Observable, of } from 'rxjs';
import { concatMap, mapTo, take, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { RequiredDocumentsQuery } from '../../../../../data-modules/required-documents/required-documents.store';
import { ProfileQuery } from '../../../private-store/profile.store';
import { LocalizationQuery } from '@ff/localization';
import { SnackbarService } from '../../../../../functionality-modules/snackbars/snackbar.service';

@Injectable()
export class IdentityDocumentsService {

  constructor(
    private _identityDocumentsStore: IdentityDocumentsStore,
    private _identityDocumentsQuery: IdentityDocumentsQuery,
    private _requiredDocumentsQuery: RequiredDocumentsQuery,
    private _profileQuery: ProfileQuery,
    private _localizationQ: LocalizationQuery,
    private _snackbarService: SnackbarService
  ) { }

  upsertDocument(docId: string, newState: Partial<IDocumentBodyUI>): void {
    this._identityDocumentsStore.ui.upsert(docId, x => ({ ...x, ...newState }));
  }

  updateUIDocumentsStore(newState: IIdDocsUIState): void {
    this._identityDocumentsStore.ui.update(x => ({ ...x, ...newState }));
  }

  openOnlyFirstIdentityDocument$(): Observable<void> {
    return this._identityDocumentsQuery.selectAll().pipe(
      take(1),
      withLatestFrom(
        this._requiredDocumentsQuery.isIDVerificationRequired$$,
        this._profileQuery.isWizardMode$$,
        this._requiredDocumentsQuery.isIdentityDocumentExpired$$,
        this._requiredDocumentsQuery.isReadonlyAccess$$
      ),
      concatMap(([identityDocuments, isIdentityDataRequired, isWizardMode, isIdentityDocumentExpired, isReadonlyAccess]) => {
        if (identityDocuments.length > 0) {
          this._identityDocumentsStore.ui.update(x => ({ ...x, newDocFormIsOpen: isIdentityDocumentExpired }));
          const id = identityDocuments.map(x => x.id);
          this._identityDocumentsStore.ui.upsert(id, { isExpanded: isIdentityDocumentExpired === false });

          if (isIdentityDataRequired === true && isWizardMode === false && isIdentityDocumentExpired === false) {
            this._identityDocumentsStore.ui.upsert(id, { isEdit: true });
            if (isReadonlyAccess !== true) {
              const msg = this._localizationQ.transform('%[flags.snackbar.message.id-required-no-attach]%');
              const title = this._localizationQ.transform('%[flags.snackbar.title.id-required-no-attach]%');
              return this._snackbarService.openWarning(msg, { title, duration: environment.appSettings.snackbar.duration });
            }
          } else {
            this._identityDocumentsStore.ui.upsert(id, { isEdit: false });
          }
        }
        return of(void 0);
      }),
      mapTo(void 0)
    );
  }
}
