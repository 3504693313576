import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ThemeService } from '../../data-modules/theme/theme.service';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';

export function tokenGetterFn(): string | null {
  return localStorage.getItem('access_token');
}

export function initApp(themeService: ThemeService): () => void {
  return () => themeService.setTheme(environment.vendor.theme.name);
}

@NgModule({
  imports: [
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetterFn
      }
    }),
  ],
  exports: [],
  declarations: [],
  providers: [
    ThemeService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [ThemeService],
      multi: true
    },
  ],
})
export class AppInitializerModule {}
