import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, mapTo, switchMap, tap } from 'rxjs/operators';
import { TransferPurposesStore } from './transfer-purposes.store';
import { ITransferPurposesResponseModel } from './transfer-purposes.model';

@Injectable()
export class TransferPurposesService {

  constructor(
    private _http: HttpClient,
    private _transferPurposesStore: TransferPurposesStore
  ) { }

  getTransferPurposes$(): Observable<void> {
    return of(void 0).pipe(
      tap(() => this._transferPurposesStore.setLoading(true)),
      switchMap(() => this._http.get<ITransferPurposesResponseModel[]>('reference/transfer-purposes')),
      catchError(() => {
        this._transferPurposesStore.setLoading(false);
        return EMPTY;
      }),
      tap(remittanceCountries => {
        this._transferPurposesStore.setLoading(false);
        this._transferPurposesStore.upsertMany(remittanceCountries);
      }),
      mapTo(void 0)
    );
  }
}
