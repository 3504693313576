<mat-dialog-content class="dialog-content">
  <div class="row">
    <div class="col-12">
      <p mat-dialog-title>
        {{ '%[send.error.message.rate-has-changed]%' | tr }}
      </p>
      <p class="dialog-content__text" *ngIf="showDestinationCase | async">
        {{ '%[send.error.message.rate-has-changed-you-pay]%' | tr }}:
      </p>
      <p class="dialog-content__text" *ngIf="showSourceCase | async">
        {{ '%[send.error.message.rate-has-changed-receiver-gets]%' | tr }}:
      </p>
    </div>
  </div>
  <div class="row dialog-content__amount-wrapper">
    <div class="col-6">
      <div class="row">
        <div class="col-11 col-sm-10">
          <div class="dialog-content__amount-wrapper-end">
            <p class="label">{{ '%[send.error.message.rate-has-changed-old-amount]%' | tr }}</p>
            <p class="amount">{{ oldAmount$ | async }} {{ oldCurrency$ | async }}</p>
          </div>
        </div>
        <div class="col-1 col-sm-2"></div>
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-1 col-sm-2"></div>
        <div class="col-11 col-sm-10">
          <div class="dialog-content__amount-wrapper-start">
            <p class="label">{{ '%[send.error.message.rate-has-changed-new-amount]%' | tr }}</p>
            <p class="amount fw-bold">{{ newAmount$ | async }} {{ newCurrency$ | async }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="row flex-grow-1 gy-5 justify-content-center">
    <div class="col-12 col-sm-5 order-2 order-sm-1">
      <button mat-stroked-button class="btn-block" (click)="onNoClick()">
        {{ '%[send.error.message.profile-in-approval-no]%' | tr }}
      </button>
    </div>
    <div class="col-12 col-sm-5 order-1 order-sm-2">
      <button mat-flat-button color="primary" class="btn-block" (click)="onYesClick()" cdkFocusInitial>
        {{ '%[send.error.message.profile-in-approval-yes]%' | tr }}
      </button>
    </div>
  </div>
</mat-dialog-actions>
