export enum EVerificationFlags {
  None = 'none',
  NoAttach = 'noattach',
  Wait = 'wait',
  BadAttach = 'badattach',
  Success = 'success',
  OnVerification = 'onverification'
}

export enum EIdentityDocumentVerificationFlags {
  None = 'none',
  Required = 'required',
  Wait = 'wait',
  Success = 'success',
  OnVerification = 'onverification'
}

export type IVerificationFlagsType = keyof typeof EVerificationFlags;

export type IIdentityDocumentsVerificationFlagsType = keyof typeof EIdentityDocumentVerificationFlags;

enum ERequiredFlag {
  Required = 'required',
}

enum ERequiredBasicFlag {
  RequiredBasic = 'requiredbasic'
}

enum ERequiredExtendedFlag {
  RequiredExtended = 'requiredextended'
}

enum EExpiredFlag {
  Expired = 'expired'
}

enum ESuccessFlag {
  Success = 'success'
}

enum ENoneFlag {
  None = 'none'
}

enum EEditFlags {
  AllowEditing = 'allowediting',
  DisallowEditing = 'disallowediting'
}

enum EWaitFlag {
  Wait = 'wait'
}

enum ERejectedFlag {
  Rejected = 'rejected'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AdditionalDocsFlags = { ...EVerificationFlags, ...ERequiredFlag, ...EExpiredFlag };
export type IAdditionalDocsFlagsType = keyof typeof AdditionalDocsFlags;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const KYCQuestionnaireFlags = { ...ERequiredBasicFlag, ...ERequiredExtendedFlag, ...EExpiredFlag, ...ESuccessFlag };
export type IKYCQuestionnaireFlagsType = keyof typeof KYCQuestionnaireFlags;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const EmailValidatePossessionFlags = { ...ENoneFlag, ...ERequiredFlag, ...ESuccessFlag };
export type IEmailValidatePossessionFlagsType = keyof typeof EmailValidatePossessionFlags;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const IdentityDocumentsFlags = { ...ERequiredFlag, ...EEditFlags, ...EExpiredFlag };
export type IdentityDocumentsFlagsType = keyof typeof IdentityDocumentsFlags;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PersonalDataFlags = { ...ERequiredFlag, ...EEditFlags };
export type IPersonalDataFlagsType = keyof typeof PersonalDataFlags;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const BankAccountOwnershipFlags = { ...ENoneFlag, ...ERequiredFlag, ...ESuccessFlag, ...EWaitFlag, ...ERejectedFlag };
export type IBankAccountOwnershipType = keyof typeof BankAccountOwnershipFlags;

export interface IRequiredDocumentsRequest {
  sourceCurrencyId?: string,
  sourceAmount?: string,
  directionId?: string
}

export interface IRequiredDocumentsResponse {
  refusedService: boolean,
  readonlyAccess: boolean,
  refusedToSend: boolean,
  bankAccountOwnership: IBankAccountOwnershipType,
  personalData: IPersonalDataFlagsType,
  identityDocument: IdentityDocumentsFlagsType,
  identityDocumentVerification: IIdentityDocumentsVerificationFlagsType,
  kycQuestionnaire: IKYCQuestionnaireFlagsType,
  proofOfAddress: IAdditionalDocsFlagsType,
  sourceOfIncome: IAdditionalDocsFlagsType,
  extraDocuments: IAdditionalDocsFlagsType,
  emailValidatePossession: IEmailValidatePossessionFlagsType,
  customerProfileId: number,
  memberId: number,
  getScoring: boolean
}

export interface IRequiredDocumentsState {
  isMockDataUsed: boolean,
  refusedService: boolean | null,
  readonlyAccess: boolean | null,
  refusedToSend: boolean | null,
  bankAccountOwnership: IBankAccountOwnershipType | null,
  personalData: IPersonalDataFlagsType | null,
  identityDocument: IdentityDocumentsFlagsType | null,
  identityDocumentVerification: IIdentityDocumentsVerificationFlagsType | null,
  kycQuestionnaire: IKYCQuestionnaireFlagsType | null,
  proofOfAddress: IAdditionalDocsFlagsType | null,
  sourceOfIncome: IAdditionalDocsFlagsType | null,
  extraDocuments: IAdditionalDocsFlagsType | null,
  emailValidatePossession: IEmailValidatePossessionFlagsType | null
}

