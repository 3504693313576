import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BaseUrlInterceptor } from './interceptors/base-url.interceptor';
import { JWTInterceptor } from './interceptors/jwt.interceptor';
import { UtmMarksInterceptor } from './interceptors/utm-marks.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { DeviceIdInterceptor } from './interceptors/device-id.interceptor';
import { LocaleInterceptor } from './interceptors/locale.interceptor';
import { RecaptchaInterceptor } from './interceptors/recaptcha.interceptor';
import { StoredProcedureErrorService } from '../../data-modules/stored-procedure-error/stored-procedure-error.service';
import { HttpErrorsHandlerInterceptor } from './interceptors/http-errors-handler.interceptor';
import { ErrorsSnackbarInterceptor } from './interceptors/errors-snackbar.interceptor';


@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [],
  declarations: [],
  providers: [
    StoredProcedureErrorService,
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UtmMarksInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DeviceIdInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LocaleInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RecaptchaInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorsSnackbarInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorsHandlerInterceptor, multi: true },

  ],
})
export class HttpModule {}
