<!-- component custom-alert begin -->
<div (dragend)="onNoClick()" class="snackbar">
  <div class="snackbar-title">{{ data.title }} <i class="{{ data.icon }}"></i></div>
  <p>{{ data.message }}</p>
  <button mat-icon-button class="btn-mat-close" (click)="onNoClick()" aria-label="Close">
    <i class="ff-icon ff-close icon-size-4"></i>
  </button>
  <button mat-flat-button color="primary" class="mt-4" (click)="onSubmitClick()">
    {{ data.buttonLabel }}
  </button>
</div>
<!-- component custom-alert end-->
