import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SendQuery } from './store/send.store';

@Injectable()
export class SecureTradingGuard implements CanActivate {
  constructor(private _router: Router, private _sendQuery: SendQuery) {}

  canActivate(): boolean {
    const orderreference: string | null = this._sendQuery.getValue().paymentGatewayParams.orderreference;
    if (orderreference == null || orderreference.length === 0) {
      this._router.navigate(['private/send']).then();
    }

    return true;
  }
}
