<ng-container *ngIf="isFullScreen === true; else defaultSpinner">
  <div class="mat-spinner-fullscreen">
    <mat-spinner [color]="color" [mode]="mode" [value]="value" [diameter]="diameter"></mat-spinner>
    <p class="mat-spinner__text">{{ text$$ | async }}</p>
  </div>
</ng-container>
<ng-template #defaultSpinner>
  <div>
    <mat-spinner
      class="mat-spinner-default"
      [color]="color"
      [mode]="mode"
      [value]="value"
      [diameter]="diameter"
    ></mat-spinner>
    <p class="mat-spinner__text">{{ text$$ | async }}</p>
  </div>
</ng-template>
