import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IRemittanceCountry } from './remittance-countries.model';

export interface IRemittanceCountriesState extends EntityState<IRemittanceCountry, string> {
  filter: string
}

@Injectable()
@StoreConfig({ name: 'remittance-countries' })
export class RemittanceCountriesStore extends EntityStore<IRemittanceCountriesState> {
  constructor() {
    super({ filter: 'ALL' });
  }
}

@Injectable()
export class RemittanceCountriesQuery extends QueryEntity<IRemittanceCountriesState> {
  remittanceCountries$$: Observable<IRemittanceCountry[]> = this.selectAll();

  constructor(protected override store: RemittanceCountriesStore) {
    super(store);
  }
}
