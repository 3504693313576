import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, mapTo, mergeMap, pairwise, switchMap, take, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { combineQueries, logAction } from '@datorama/akita';
import {
  BeneficiaryAdditionalFieldsFormQuery,
  BeneficiaryAdditionalFieldsFormStore
} from '../beneficiary/beneficiary-additional-fields/beneficiary-additional-fields.store';
import {
  BeneficiaryGeneralFormQuery,
  BeneficiaryGeneralFormStore
} from '../beneficiary/beneficiary-general/beneficiary-general.store';

import { MyBeneficiaryQuery } from '../beneficiary/beneficiary.store';
import { ILoadDraftResponseModel } from './draft.model';

import { MyBeneficiariesService } from '../beneficiary/my-beneficiaries/my-beneficiaries.service';

import { BeneficiaryAddressQuery } from '../beneficiary/beneficiary-address/beneficiary-address.store';
import { BeneficiaryAddressService } from '../beneficiary/beneficiary-address/beneficiary-address.service';
import { GatewaysQuery, GatewaysStore } from '../../../../../../data-modules/gateways/gateways.store';
import { GatewaysService } from '../../../../../../data-modules/gateways/gateways.service';
import { CalculatorQuery, CalculatorStore } from '../../../../../../data-modules/calculator/calculator.store';
import { CalculatorService } from '../../../../../../data-modules/calculator/calculator.service';
import { TransferPurposesQuery } from '../../../../../../data-modules/transfer-purposes/transfer-purposes.store';
import { TransferPurposesService } from '../../../../../../data-modules/transfer-purposes/transfer-purposes.service';
import { RequiredFieldsService } from '../../../../../../data-modules/required-fields/required-fields.service';
import {
  CurrenciesDestinationQuery
} from '../../../../../../data-modules/currencies-destination/currencies-destination.store';
import {
  CurrenciesDestinationService
} from '../../../../../../data-modules/currencies-destination/currencies-destination.service';
import { CurrenciesSourceService } from '../../../../../../data-modules/currencies-source/currencies-source.service';
import { CurrenciesSourceQuery } from '../../../../../../data-modules/currencies-source/currencies-source.store';
import {
  RemittanceCountriesQuery
} from '../../../../../../data-modules/remittance-countries/remittance-countries.store';
import { MyReceiversQuery } from '../../../../../../data-modules/my-receivers/my-receivers.store';
import { MyReceiversService } from '../../../../../../data-modules/my-receivers/my-receivers.service';
import { BankBranchesService } from '../../../../../../data-modules/bank-branches/bank-branches.service';
import { BanksService } from '../../../../../../data-modules/banks/banks.service';
import { WorkflowsQuery, WorkflowsStore } from '../../../../../../data-modules/workflows/workflows.store';
import { WorkflowsService } from '../../../../../../data-modules/workflows/workflows.service';
import { IRemittanceCountry } from '../../../../../../data-modules/remittance-countries/remittance-countries.model';
import { IWorkflow } from '../../../../../../data-modules/workflows/workflows.models';
import { IGateway } from '../../../../../../data-modules/gateways/gateways.model';
import { ILookupStr } from '../../../../private-models/lookup-str';
import { IMyReceiver } from '../../../../../../data-modules/my-receivers/my-receivers.models';
import { RequiredDocumentsService } from '../../../../../../data-modules/required-documents/required-documents.service';
import { deepEquals, isNullOrEmpty } from '@ff/utils';
import { DraftQuery, DraftStore, IDraftState } from './draft.store';
import { PersistenceQuery } from '../../../../../../data-modules/persist/persist.store';

@Injectable()
export class DraftService {

  private _draftSaveTriggers$: Observable<void> = of(void 0)
    .pipe(
      switchMap(() => combineLatest([
        this._calculatorQ.select(),
        this._beneficiaryGeneralFormQuery.select(),
        this._beneficiaryAddressQuery.select(),
        this._beneficiaryAdditionalFieldsFormQuery.select()
      ])),
      pairwise(),
      filter((prev, next) => !deepEquals(prev, next)),
      debounceTime(3 * 1000),
      mergeMap(() => this._draftQuery.isMonitoring$$),
      filter(e => e === true),
      switchMap(() => this.saveDraft$())
    );

  private _isDraftPasting$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _isDraftLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isDraftPasting$$: Observable<boolean> = this._isDraftPasting$.asObservable();
  isDraftLoading$$: Observable<boolean> = this._isDraftLoading$.asObservable();

  constructor(
    private _draftStore: DraftStore,
    private _draftQuery: DraftQuery,
    private _http: HttpClient,
    private _gatewaysStore: GatewaysStore,
    private _gatewaysQ: GatewaysQuery,
    private _gatewaysService: GatewaysService,
    private _calculatorService: CalculatorService,
    private _calculatorStore: CalculatorStore,
    private _calculatorQ: CalculatorQuery,
    private _workflowsService: WorkflowsService,
    private _workflowsStore: WorkflowsStore,
    private _workflowsQ: WorkflowsQuery,
    private _banksService: BanksService,
    private _banksBranchesService: BankBranchesService,
    private _beneficiaryAdditionalFieldsFormStore: BeneficiaryAdditionalFieldsFormStore,
    private _beneficiaryAdditionalFieldsFormQuery: BeneficiaryAdditionalFieldsFormQuery,
    private _beneficiaryGeneralFormStore: BeneficiaryGeneralFormStore,
    private _myBeneficiaryQuery: MyBeneficiaryQuery,
    private _myBeneficiaryService: MyBeneficiariesService,
    private _myReceiversService: MyReceiversService,
    private _myReceiversQuery: MyReceiversQuery,
    private _remittanceCountriesQuery: RemittanceCountriesQuery,
    private _currenciesSourceQuery: CurrenciesSourceQuery,
    private _currenciesSourceService: CurrenciesSourceService,
    private _currenciesDestinationService: CurrenciesDestinationService,
    private _currenciesDestinationQ: CurrenciesDestinationQuery,
    private _requiredFieldsService: RequiredFieldsService,
    private _beneficiaryGeneralFormQuery: BeneficiaryGeneralFormQuery,
    private _transferPurposesService: TransferPurposesService,
    private _transferPurposesQuery: TransferPurposesQuery,
    private _beneficiaryAddressService: BeneficiaryAddressService,
    private _beneficiaryAddressQuery: BeneficiaryAddressQuery,
    private _requiredDocumentsService: RequiredDocumentsService,
    private _persistQuery: PersistenceQuery
  ) {
    this._draftSaveTriggers$.subscribe();
  }

  updateDraftStore(draftModel: Partial<IDraftState>, logActionText?: string): void {
    if (logActionText != null) {
      logAction(logActionText);
    }
    this._draftStore.update({ ...draftModel });
  }

  deleteDraft$(): Observable<void> {
    return this._deleteDraft$();
  }

  loadDraftAndApply$(): Observable<void> {
    return of(0)
      .pipe(
        tap(() => this._isDraftLoading$.next(true)),
        switchMap(() => this._http.get<ILoadDraftResponseModel>('remittance/get-draft')),
        catchError(() => {
          this._isDraftLoading$.next(false);
          return EMPTY;
        }),
        tap(() => {
          this._isDraftLoading$.next(false);
        }),
        switchMap(data => {
          if (data == null) {
            return of(void 0);
          }
          return this._applyDraft2$(data);
        }),
        mapTo(void 0)
      );
  }

  saveDraft$(): Observable<void> {
    return this._harvestData2$()
      .pipe(
        switchMap((body: ILoadDraftResponseModel) => this._http.post(`remittance/save-draft`, body)),
        catchError(() => EMPTY),
        mapTo(void 0)
      );
  }

  private _harvestData2$(): Observable<ILoadDraftResponseModel> {
    return combineQueries([
      this._calculatorQ.select(),
      this._workflowsQ.selectActive(),
      this._workflowsQ.selectAll(),
      this._gatewaysQ.selectActive(),
      this._gatewaysQ.selectAll(),
      this._myBeneficiaryQuery.select(x => x.myBeneficiary),
      this._beneficiaryGeneralFormQuery.select(),
      this._beneficiaryAddressQuery.select(),
      this._beneficiaryAdditionalFieldsFormQuery.select()
    ])
      .pipe(
        take(1),
        // eslint-disable-next-line complexity
        map(([
               calculator,
               workflow,
               workflows,
               gateway,
               gateways,
               myBeneficiary,
               beneficiaryForm,
               beneficiaryAddress,
               beneficiaryAdditionalFields
             ]): ILoadDraftResponseModel => {

          return {
            remittanceCountry: calculator.destinationCountry,
            workflows,
            gateways,
            directionCountryId: calculator.destinationCountry?.id ?? null,
            amountKey: calculator.amountKey ?? null,
            workflowId: workflow?.id ?? null,
            gatewayId: gateway?.directionId ?? null,
            sourceCurrencyId: calculator.sourceCurrency?.id ?? null,
            sourceAmount: calculator.sourceAmount ?? null,
            destinationCurrencyId: calculator.destinationCurrency?.id ?? null,
            destinationAmount: calculator.destinationAmount ?? null,
            beneficiaryId: myBeneficiary?.id ?? null,
            beneficiaryName: beneficiaryForm.name ?? null,
            beneficiaryMiddleName: beneficiaryForm.middleName ?? null,
            beneficiarySurname: beneficiaryForm.surname ?? null,
            beneficiaryDateOfBirth: beneficiaryForm.dateOfBirth ?? null,
            beneficiaryGender: beneficiaryForm.sex ?? null,
            beneficiaryPhoneNumber: beneficiaryForm.phoneNumber ?? null,
            beneficiaryTransferPurposeId: beneficiaryForm.remittancePurpose?.id ?? null,
            senderSourceOfIncomeType: beneficiaryForm.senderSourceOfIncomeType ?? null,

            beneficiaryCountry: beneficiaryAddress.country ?? null,
            beneficiaryCity: beneficiaryAddress.city ?? null,
            beneficiaryStreet: beneficiaryAddress.street ?? null,
            beneficiaryBuilding: beneficiaryAddress.building ?? null,
            beneficiaryApartment: beneficiaryAddress.apartment ?? null,
            beneficiaryPostCode: beneficiaryAddress.postCode ?? null,

            beneficiaryPan: beneficiaryAdditionalFields.beneficiaryPan ?? null,
            beneficiaryIDType: beneficiaryAdditionalFields.beneficiaryIDType ?? null,
            beneficiaryIDNumber: beneficiaryAdditionalFields.beneficiaryIDNumber ?? null,
            beneficiaryAccountNumber: beneficiaryAdditionalFields.beneficiaryAccountNumber ?? null,
            beneficiaryCitizenship: beneficiaryAdditionalFields.beneficiaryCitizenship ?? null,
            beneficiaryBankCode: beneficiaryAdditionalFields.beneficiaryBankCode ?? null,
            beneficiaryBankBranchCode: beneficiaryAdditionalFields.beneficiaryBankBranchCode ?? null,
            beneficiaryAccountType: beneficiaryAdditionalFields.beneficiaryAccountType ?? null,
            beneficiaryBankBic: beneficiaryAdditionalFields.beneficiaryBankBic ?? null,
            beneficiaryBankBranchBic: beneficiaryAdditionalFields.beneficiaryBankBranchBic ?? null,
          };
        })
      );
  }


  private _deleteDraft$(): Observable<void> {
    return this._persistQuery.accessToken$$.pipe(
      take(1),
      filter(token => !isNullOrEmpty(token)),
      switchMap(() => this._http.delete('remittance/delete-draft')),
      tap(() => {
        this.updateDraftStore({ isDraftLoading: false, isDraftPasting: false }, 'draft-service_delete-draft');
      }),
      catchError(() => {
        return EMPTY;
      }),
      mapTo(void 0)
    );
  }

  private _applyDraft2$(draft: ILoadDraftResponseModel): Observable<void> {
    return of(void 0).pipe(
      tap(() => this._isDraftPasting$.next(true)),
      switchMap(() => {
        if (draft.directionCountryId == null) { return of(void 0); }
        return of(void 0)
          .pipe(
            switchMap(() => this._remittanceCountriesQuery.selectAll()),
            filter((rcs: IRemittanceCountry[]) => rcs.length > 0),
            take(1),
            switchMap(() => this._remittanceCountriesQuery.selectEntity(draft.directionCountryId ?? '')),
            take(1),
            tap(destinationCountry => {
              if (destinationCountry == null) { return; }
              this._calculatorStore.update({ destinationCountry });
            }),
            switchMap(() => this._workflowsService.downloadAndStoreWorkflows$())
          );
      }),

      switchMap(() => {
        if (draft.workflowId == null) { return of(void 0); }
        return of(void 0)
          .pipe(
            tap(() => {
              this._workflowsStore.set(draft.workflows);
              this._gatewaysStore.set(draft.gateways);
            }),
            switchMap(() => this._workflowsQ.selectEntity(draft.workflowId ?? '')),
            take(1),
            switchMap((wf: IWorkflow | undefined) => {
              if (wf == null) {
                console.warn(draft.workflowId + ' workflow not found ' + JSON.stringify(this._workflowsQ.getValue().entities));
                return of(void 0);
              }
              return this._workflowsService.activateWorkflow$(wf);
            }),
          );
      }),
      switchMap(() => {
        if (draft.gatewayId == null) { return of(void 0); }
        return of(void 0)
          .pipe(
            switchMap(() => this._gatewaysQ.selectEntity(draft.gatewayId ?? '')),
            take(1),
            switchMap((gateway: IGateway | undefined) => {
              if (gateway == null) {
                console.warn('gateway not found', draft.gatewayId, this._gatewaysQ.getValue());
                return of(void 0);
              }
              return this._gatewaysService.activateGateway$$(gateway.directionId);
            }),
          );
      }),
      switchMap(() => {
        if (draft.sourceCurrencyId == null) { return of(void 0); }
        return of(void 0)
          .pipe(
            switchMap(() => this._currenciesSourceService.getCurrenciesSource()),
            switchMap(() => this._currenciesSourceQuery.selectEntity(draft.sourceCurrencyId?.toString(10) ?? '')),
            take(1),
            tap(sourceCurrency => {
              if (sourceCurrency == null) {
                console.warn(sourceCurrency + 'not found');
                return;
              }
              this._calculatorStore.update({ sourceCurrency });
            }),
          );
      }),
      switchMap(() => {
        if (draft.destinationCurrencyId == null) { return of(void 0); }
        return of(void 0)
          .pipe(
            switchMap(() => combineLatest([
              this._gatewaysQ.selectEntity(draft.gatewayId ?? ''),
              this._currenciesSourceQuery.selectEntity(draft.sourceCurrencyId?.toString(10) ?? '')
            ])),
            take(1),
            switchMap(([gateway, sourceCurrency]) => {
              if (gateway == null || sourceCurrency == null) { return of(void 0); }
              return this._currenciesDestinationService.getCurrenciesDestination$(sourceCurrency.id, gateway.directionId);
            }),
            switchMap(() => this._currenciesDestinationQ.selectEntity(draft.destinationCurrencyId?.toString(10) ?? '')),
            take(1),
            tap(destinationCurrency => {
              if (destinationCurrency == null) { return; }
              this._calculatorStore.update({ destinationCurrency });
            }),
          );
      }),
      switchMap(() => {
        if (draft.sourceAmount == null) { return of(void 0); }
        return of(void 0)
          .pipe(
            switchMap(() => this._setExternalAmount$(draft.sourceAmount ?? 0, draft.destinationAmount ?? 0, draft.amountKey)),
            switchMap(() => this._calculatorService.executeCalculationAndUpdateStore$()),
          );
      }),
      switchMap(() => {
        if (draft.gatewayId == null) { return of(void 0); }
        return of(void 0)
          .pipe(
            switchMap(() => this._gatewaysQ.selectEntity(draft.gatewayId ?? '')),
            take(1),
            switchMap((gateway: IGateway | undefined) => {
              if (gateway == null) {return of(void 0); }
              return this._requiredFieldsService.getRequiredFields$(gateway.directionId);
            }),
          );
      }),
      switchMap(() => {
        if (draft.beneficiaryId == null) { return of(void 0); }
        return of(void 0).pipe(
          switchMap(() => this._myReceiversService.getMyReceivers$()),
          switchMap(() => this._myReceiversQuery.selectEntity(draft.beneficiaryId?.toString(10) ?? '')),
          take(1),
          switchMap((receiver: IMyReceiver | undefined) => {
            if (receiver == null) { return of(void 0); }
            return this._myBeneficiaryService.setBeneficiary$$(receiver);
          }),
        );
      }),
    )
      .pipe(
        switchMap(() => {
          if (draft.beneficiaryName == null) { return of(void 0); }
          return this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({ name: draft.beneficiaryName });
        }),
        switchMap(() => {
          if (draft.beneficiaryMiddleName == null) { return of(void 0); }
          return this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({ middleName: draft.beneficiaryMiddleName });
        }),
        switchMap(() => {
          if (draft.beneficiarySurname == null) { return of(void 0); }
          return this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({ surname: draft.beneficiarySurname });
        }),
        switchMap(() => {
          if (draft.beneficiaryDateOfBirth == null) { return of(void 0); }
          return this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({ dateOfBirth: draft.beneficiaryDateOfBirth });
        }),
        switchMap(() => {
          if (draft.beneficiaryGender == null) { return of(void 0); }
          return this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({ sex: draft.beneficiaryGender });
        }),
        switchMap(() => {
          if (draft.beneficiaryPhoneNumber == null) { return of(void 0); }
          return this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({ phoneNumber: draft.beneficiaryPhoneNumber });
        }),
        switchMap(() => {
          if (draft.beneficiaryTransferPurposeId == null) { return of(void 0); }
          return this._transferPurposesService.getTransferPurposes$()
            .pipe(
              switchMap(() => this._transferPurposesQuery.selectEntity(draft.beneficiaryTransferPurposeId ?? '')),
              take(1),
              switchMap((transferPurpose: ILookupStr | undefined) => {
                if (transferPurpose == null) {
                  console.warn('transfer purpose not found');
                  return of(void 0);
                }
                return this._beneficiaryGeneralFormStore.setRemittancePurpose$(transferPurpose);
              })
            );
        }),
        switchMap(() => {

          if (draft.senderSourceOfIncomeType == null) { return of(void 0); }
          return this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({ senderSourceOfIncomeType: draft.senderSourceOfIncomeType });
        })
      )
      .pipe(
        switchMap(() => {
          if (draft.beneficiaryCountry == null) { return of(void 0); }
          return this._beneficiaryAddressService.updateAddressForm({ country: draft.beneficiaryCountry });
        }),
        switchMap(() => {
          if (draft.beneficiaryCity == null) {return of(void 0); }
          return this._beneficiaryAddressService.updateAddressForm({ city: draft.beneficiaryCity });
        }),
        switchMap(() => {
          if (draft.beneficiaryStreet == null) {return of(void 0); }
          return this._beneficiaryAddressService.updateAddressForm({ street: draft.beneficiaryStreet });
        }),
        switchMap(() => {
          if (draft.beneficiaryBuilding == null) {return of(void 0); }
          return this._beneficiaryAddressService.updateAddressForm({ building: draft.beneficiaryBuilding });
        }),
        switchMap(() => {
          if (draft.beneficiaryApartment == null) {return of(void 0); }
          return this._beneficiaryAddressService.updateAddressForm({ apartment: draft.beneficiaryApartment });
        }),
        switchMap(() => {
          if (draft.beneficiaryPostCode == null) {return of(void 0); }
          return this._beneficiaryAddressService.updateAddressForm({ postCode: draft.beneficiaryPostCode });
        }),
      )
      .pipe(
        switchMap(() => {
          if (draft.beneficiaryPan == null) {return of(void 0); }
          return this._beneficiaryAdditionalFieldsFormStore.setAdditionalField({ beneficiaryPan: draft.beneficiaryPan });
        }),
        switchMap(() => {
          if (draft.beneficiaryIDType == null) {return of(void 0); }
          return this._beneficiaryAdditionalFieldsFormStore.setAdditionalField({ beneficiaryIDType: draft.beneficiaryIDType });
        }),
        switchMap(() => {
          if (draft.beneficiaryIDNumber == null) {return of(void 0); }
          return this._beneficiaryAdditionalFieldsFormStore.setAdditionalField({ beneficiaryIDNumber: draft.beneficiaryIDNumber });
        }),
        switchMap(() => {
          if (draft.beneficiaryAccountNumber == null) {return of(void 0); }
          return this._beneficiaryAdditionalFieldsFormStore.setAdditionalField({ beneficiaryAccountNumber: draft.beneficiaryAccountNumber });
        }),
        switchMap(() => {
          if (draft.beneficiaryCitizenship == null) {return of(void 0); }
          return this._beneficiaryAdditionalFieldsFormStore.setAdditionalField({ beneficiaryCitizenship: draft.beneficiaryCitizenship });
        }),
      )
      .pipe(
        switchMap(() => {
          if (draft.beneficiaryBankCode == null) {return of(void 0); }
          return this._banksService.getBanks$(draft.gatewayId ?? '')
            .pipe(
              switchMap(() => this._beneficiaryAdditionalFieldsFormStore.setAdditionalField({ beneficiaryBankCode: draft.beneficiaryBankCode }))
            );
        }),
        switchMap(() => {
          if (draft.beneficiaryBankBranchCode == null) {return of(void 0); }
          return this._banksBranchesService.getBankBranches$(draft.gatewayId ?? '', draft.beneficiaryBankCode?.code ?? '')
            .pipe(
              switchMap(() => this._beneficiaryAdditionalFieldsFormStore.setAdditionalField({ beneficiaryBankBranchCode: draft.beneficiaryBankBranchCode }))
            );
        }),
        switchMap(() => {
          if (draft.beneficiaryAccountType == null) {return of(void 0); }
          return this._beneficiaryAdditionalFieldsFormStore.setAdditionalField({ beneficiaryAccountType: draft.beneficiaryAccountType });
        }),
        switchMap(() => {
          if (draft.beneficiaryBankBic == null) {return of(void 0); }
          return this._beneficiaryAdditionalFieldsFormStore.setAdditionalField({ beneficiaryBankBic: draft.beneficiaryBankBic });
        }),
        switchMap(() => {
          if (draft.beneficiaryBankBranchBic == null) {return of(void 0); }
          return this._beneficiaryAdditionalFieldsFormStore.setAdditionalField({ beneficiaryBankBranchBic: draft.beneficiaryBankBranchBic });
        }),
        switchMap(() => this._requiredDocumentsService.updateRequiredDocuments$()),
        tap(() => {
          this._isDraftPasting$.next(false);
        }),
        mapTo(void 0)
      );
  }

  private _setExternalAmount$(sourceAmount: number, destinationAmount: number, amountKey: 0 | 1): Observable<void> {
    if (amountKey === 0) {
      return this._calculatorService.setSourceAmount$(sourceAmount);
    } else {
      return this._calculatorService.setDestinationAmount$(destinationAmount);
    }
  }

}
