import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: 'snackbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: {message: string, title: string, icon: string }, public snackbarRef: MatSnackBarRef<SnackbarComponent>) { }

  onNoClick(): void {
    this.snackbarRef.dismiss();
  }

}
