import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withTransaction } from '@datorama/akita';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mapTo, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AmlQuestionnaireModalQuery, AmlQuestionnaireModalStore } from './store/aml-questionnaire-modal.store';
import {
  IAmlAddressModel,
  IAmlQStoreAnswersDto,
  IAmlQStoreModel, IAmlStoreQuestionsModel, IRemittanceGetAmlAnswerModel,
  IRemittanceGetAmlAnswersModel, IRemittanceGetAmlQuestionsModel,
  IRemittanceGetAmlResponseModel, IRemittanceSetAmlModel
} from './store/aml-questionnaire-modal.model';
import { isNullOrEmpty } from '@ff/utils';
import { EGoogleMapsValidationStatus } from '../../../../../../../../functionality-modules/google-maps/models/google-maps-validation-status.models';
import { BeneficiaryGeneralFormQuery } from '../../../beneficiary/beneficiary-general/beneficiary-general.store';
import { BeneficiaryAddressQuery } from '../../../beneficiary/beneficiary-address/beneficiary-address.store';

@Injectable()
export class AmlQuestionnaireModalService {
  constructor(
    private _http: HttpClient,
    private _amlCheckFormModalStore: AmlQuestionnaireModalStore,
    private _amlCheckFormModalQuery: AmlQuestionnaireModalQuery,
    private _beneficiaryGeneralFormQ: BeneficiaryGeneralFormQuery,
    private _beneficiaryAddressQuery: BeneficiaryAddressQuery,
  ) {}

  loadAMLQuestionnaire$(): Observable<void> {
    return of(void 0).pipe(
      tap(() => this._amlCheckFormModalStore.setLoading(true)),
      switchMap(() => forkJoin([this._http.get<IRemittanceGetAmlAnswersModel>('remittance/aml-questionnaire-answers'),
        this._http.get<IRemittanceGetAmlQuestionsModel[]>('remittance/aml-questionnaire-template')])),
      catchError(() => {
        this._amlCheckFormModalStore.setLoading(false);
        return EMPTY;
      }),
      withTransaction(([answers, questions]) => {
        this._updateStore({ ...this._remapServerResponse({ answers, questions }) });
        this._amlCheckFormModalStore.setLoading(false);
      }),
      withLatestFrom(
        this._beneficiaryGeneralFormQ.dateOfBirth$$,
        this._beneficiaryAddressQuery.state$$),
      tap(([_, dateOfBirth, address]) => {

        this._amlCheckFormModalStore.update((st) => ({
          ...st,
          answers: st.answers.map((answer) => {
            if (answer.questionId === 'RECEIVER_DATE_OF_BIRTH') {
              return { ...answer, selectedValue: dateOfBirth };
            }
            return answer;
          }),
        }));

      }),
      mapTo(void 0)
    );
  }

  saveQuestionnaire$(transactionId: string, isGoogleValidationSuccess: boolean): Observable<void> {
    return of(0).pipe(
      tap(() => this._amlCheckFormModalStore.setLoading(true)),
      withLatestFrom(this._amlCheckFormModalQuery.amlAnswers$$),
      map(([, answers]): IRemittanceSetAmlModel => this._mapStoreModelsToSetAmlRequestModel(transactionId, answers, isGoogleValidationSuccess)),
      switchMap((body: IRemittanceSetAmlModel) => this._http.post<IRemittanceGetAmlAnswersModel>('remittance/aml-questionnaire-answers', body)),
      catchError(() => {
          this._amlCheckFormModalStore.setLoading(false);
          return EMPTY;
      }),
      tap(() => this._amlCheckFormModalStore.setLoading(false)),
      mapTo(void 0)
    );
  }

  updateFormData(answers: IAmlQStoreAnswersDto[]): void {
    this._amlCheckFormModalStore.update((st) => ({ ...st, answers }));
  }

  collectAddress(address: IAmlAddressModel): string {
    return address.country.title + ', ' + address.city + ', ' + address.street + ', ' + address.building
      + (!isNullOrEmpty(address.apartment) ? ', ' + address.apartment : '') + (!isNullOrEmpty(address.postcode) ? ', ' + address.postcode : '');
  }

  private _remapServerResponse(resp: IRemittanceGetAmlResponseModel): IAmlQStoreModel {
    // @ts-ignore
    const remappedQuestions: IAmlStoreQuestionsModel[] = resp.questions.sort((a, b) => a.order - b.order).map<IAmlStoreQuestionsModel[]>(q => {
      // @ts-ignore
      const remappedQuestionAnswers: IAmlQStoreAnswersDto[] = q.answers.map<IAmlQStoreAnswersDto>(rQA => {
        return Object.fromEntries(
          Object.entries(rQA).map(([k, v]) =>
            [`${k.substr(0, 1).toLowerCase()}${k.substr(1)}`, v])
        );
      }) ?? [];

      return {
        id: q.id,
        type: q.type,
        order: q.order,
        description: q.description,
        field: q.field,
        annotation: q.annotation,
        tr: q.tr,
        level: q.level,
        validation: q.validation,
        annotationLink: q.annotationLink,
        answers: remappedQuestionAnswers as IAmlQStoreAnswersDto[]
      };
    });

    const currentAnswers: IRemittanceGetAmlAnswersModel | null = resp.answers ?? null;

    const remappedAnswers: IAmlQStoreAnswersDto[] = currentAnswers?.answers.map(
      (parsedAnswer: IRemittanceGetAmlAnswerModel): IAmlQStoreAnswersDto => {
        return {
          questionId: parsedAnswer.questionId,
          selectedValue: parsedAnswer.selectedValue === 'RECEIVER_ADDRESS' ? parsedAnswer.jsonValue : parsedAnswer.selectedValue,
          customText: parsedAnswer.customText
        };
      }
    ) ?? [];


    const remappedData: IAmlQStoreModel= {
      questions: remappedQuestions,
      answers: remappedAnswers,
    };

    return { ...remappedData };
  }

  private _mapStoreModelsToSetAmlRequestModel(transactionId: string, answers: IAmlQStoreAnswersDto[], isGoogleValidationSuccess: boolean): IRemittanceSetAmlModel {
    return {
      transactionId,
      answers: answers.map(answer => ({
        questionId: answer.questionId,
        selectedValue: answer.questionId === 'RECEIVER_ADDRESS' ? null : (answer.selectedValue as string),
        customText: answer.questionId === 'RECEIVER_ADDRESS'
          ? `${isGoogleValidationSuccess ? EGoogleMapsValidationStatus.Success : EGoogleMapsValidationStatus.Error}: ${this.collectAddress(answer.selectedValue as IAmlAddressModel)}`
          : answer.customText,
        jsonValue: answer.questionId === 'RECEIVER_ADDRESS' ? answer.selectedValue : null
      }))
    };
  }

  private _updateStore(state: Partial<IAmlQStoreModel>): void {
    this._amlCheckFormModalStore.update((st) => ({ ...st, ...state }));
  }
}
