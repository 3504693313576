import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-info-dialog',
  styleUrls: ['info-dialog.component.scss'],
  templateUrl: 'info-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class InfoDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {text: string},
    public dialogRef: MatDialogRef<InfoDialogComponent>) {
  }

}
