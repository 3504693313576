import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { AuthStore } from './auth.store';
import { PersistenceQuery, PersistenceStore } from '../persist/persist.store';
import { IRefreshToken } from '../../root/public/authentication/verifying-password/models/refresh-token';
import { removeNonDigits } from '../../utils/functions/remove-non-digits';
import { IAuthenticationCountry } from '../authentication-countries/auth-countries.models';
import { AuthenticationCountriesQuery } from '../authentication-countries/authentication-countries.store';

export interface IGetSmsResponse {
  _message: string
}

@Injectable()
export class AuthService {

  constructor(
    private _authStore: AuthStore,
    private _http: HttpClient,
    private _persistenceStore: PersistenceStore,
    private _authCountriesQuery: AuthenticationCountriesQuery,
    private _persistenceQuery: PersistenceQuery,
  ) { }

  setLoading(value: boolean): void {
    this._authStore.setLoading(value);
  }

  setError<T>(value: T): void {
    this._authStore.setError<T>(value);
  }

  resetStore(): void {
    this._authStore.reset();
  }

  setTimeAvailableSubmit(date: string | null): void {
    this._persistenceStore.update(st => ({ ...st, submitAvailableTime: date }));
  }

  setApprovalCodeAwaiting(value: boolean): void {
    this._authStore.update(st => ({ ...st, approvalCodeAwaiting: value }));
  }

  setChangePasswordApprovalCodeAwaiting(value: boolean): void {
    this._authStore.update(st => ({ ...st, changePassApprovalCodeAwaiting: value }));
  }

  public setCountry(country: IAuthenticationCountry): Observable<void> {
    return combineLatest([
      of(country),
      this._persistenceQuery.phone$$,
      this._authCountriesQuery.getAuthCountryByPhone$$(this._persistenceQuery.phone$$)
    ]).pipe(
      take(1),
      tap(([newCountry, oldPhone, oldCountry]) => {
        const oldCountryCodes: string[] = oldCountry == null ? [] : [...oldCountry.phoneCodes].sort((a, b) => b.length - a.length);
        const oldPhoneClean = removeNonDigits(oldPhone);
        const newCountryCodeClean: string = newCountry.phoneCodes[0];
        const oldCountryCodeToReplace = oldCountryCodes.find(code => oldPhoneClean.startsWith(code)) ?? '';

        const oldPhoneWithNoPrefix = oldPhoneClean.slice(oldCountryCodeToReplace.length);
        const newPhoneValue = newCountryCodeClean + oldPhoneWithNoPrefix;
        this._persistenceStore.update(state => ({
          ...state,
          phone: newPhoneValue
        }));
      }),
      mapTo(void 0)
    );
  }

  refreshCredentials(): Observable<string> {
    return combineLatest([
      this._persistenceQuery.select(x => x.phone),
      this._persistenceQuery.select(x => x.refreshToken),
    ])
      .pipe(
        take(1),
        map(([phone, refreshToken]): IRefreshToken.IRequest => ({ phone, refreshToken, isRememberDevice: true, riskRequestToken: '' })),
        switchMap((body: IRefreshToken.IRequest): Observable<IRefreshToken.IResponse> =>
          this._http.post<IRefreshToken.IResponse>('login/refresh-token', { ...body })),
        tap((response: IRefreshToken.IResponse) => {
          this._persistenceStore.update({ refreshToken: response.refreshToken, accessToken: response.token });
        }),
        map((response: IRefreshToken.IResponse) => response.token)
      );
  }
}
