import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineQueries, withTransaction } from '@datorama/akita';
import { combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import {
  delay,
  map,
  mapTo,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { IProfileServerResponse } from '../private-models/profile-server-response.model';
import { IDocumentBody } from '../private-models/document.model';
import { ProfileQuery, ProfileStore } from './profile.store';
import { SnackbarService } from '../../../functionality-modules/snackbars/snackbar.service';
import { DialogService } from '../../../functionality-modules/dialog-windows/dialog.service';
import { IdenfyResultsQuery, IdenfyResultsStore } from './idenfy-results.store';
import { LocalizationQuery } from '@ff/localization';
import { ProfileFlagsService } from './profile-flags.service';
import { ILookupInt } from '../private-models/lookup-str';
import { GatewaysQuery } from '../../../data-modules/gateways/gateways.store';
import { CalculatorQuery } from '../../../data-modules/calculator/calculator.store';
import { PersonalDataFormStore } from '../private-pages/profile/personal-data/personal-data.store';
import { IPersonalFormData } from '../private-pages/profile/personal-data/personal-data.model';
import {
  SourceOfIncomeStore,
  SourcesOfIncomeQuery
} from '../private-pages/profile/additional-documents/store/source-of-income.store';
import {
  ResidenceConfirmationsQuery,
  ResidenceConfirmationsStore
} from '../private-pages/profile/additional-documents/store/residence-confirmations.store';
import {
  IdentityDocumentsQuery,
  IdentityDocumentsStore
} from '../private-pages/profile/identity-documents/store/identity-documents.store';
import { RequiredDocumentsService } from '../../../data-modules/required-documents/required-documents.service';
import { environment } from '../../../../environments/environment';
import { RequiredDocumentsQuery } from '../../../data-modules/required-documents/required-documents.store';
import { randomString } from '@ff/utils';
import { EProfileTabs } from '../private-models/profile-tabs.model';
import { IdentityDocumentsService } from '../private-pages/profile/identity-documents/identity-documents.service';

@Injectable()
export class ProfileService {
  private _destroyProfileUpdate$$: Subject<void> = new Subject<void>();

  constructor(
    private _http: HttpClient,
    private _profileStore: ProfileStore,
    private _profileQuery: ProfileQuery,
    private _snackbarService: SnackbarService,
    private _personalDataFormStore: PersonalDataFormStore,
    private _identityDocumentsStore: IdentityDocumentsStore,
    private _identityDocumentsQuery: IdentityDocumentsQuery,
    private _residenceConfirmationsStore: ResidenceConfirmationsStore,
    private _residenceConfirmationsQ: ResidenceConfirmationsQuery,
    private _sourcesOfIncomeStore: SourceOfIncomeStore,
    private _sourcesOfIncomeQuery: SourcesOfIncomeQuery,
    private _dialogService: DialogService,
    private _calculatorQuery: CalculatorQuery,
    private _idenfyResultsStore: IdenfyResultsStore,
    private _idenfyQuery: IdenfyResultsQuery,
    private _localizationQ: LocalizationQuery,
    private _profileFlagsService: ProfileFlagsService,
    private _gatewaysQuery: GatewaysQuery,
    private _requiredDocumentsService: RequiredDocumentsService,
    private _requiredDocumentsQuery: RequiredDocumentsQuery,
    private _identityDocumentsService: IdentityDocumentsService
  ) { }

  updateProfile$(showLoaders: boolean = true): Observable<void | never> {
    return of(void 0)
      .pipe(
        withTransaction(() => {
          if (showLoaders) {
            this._profileStore.setLoading(true);
          }
        }),
        switchMap(() => combineLatest([
          // Cannot read property 'id' of null
          this._calculatorQuery.select(x => x.sourceCurrency),
          // .pipe(map((sourceCurrency: ILookupStr) => sourceCurrency.id)),
          this._calculatorQuery.select(x => x.sourceAmount)
        ])),
        take(1),
        switchMap(([sourceCurrency, sourceAmount]) => {
          const sourceCurr: ILookupInt | null = sourceCurrency;
          const sourceAm: number | null = sourceAmount;
          const params = sourceCurr != null && sourceAm != null ? {
            params: {
              currencyId: sourceCurr.id.toString(),
              sourceAmount: sourceAm.toString()
            }
          } : {};
          return this._http.get<IProfileServerResponse>('profile', params);
        }),
        withTransaction((response: IProfileServerResponse): void => {

          const personalData: IPersonalFormData = response.personalData;

          this._profileStore.update({
            customerProfileId: response.customerProfileId,
            customerProfileStatus: response.customerProfileStatus,
            memberId: response.memberId,
            memberStatus: response.memberStatus,
            riskRating: response.riskRating,
            stamp: response.stamp
          });

          this._personalDataFormStore.update(x => ({
            ...x,
            current: personalData,
            draft: personalData,
            isEdit: response.personalData.name == null
          }));

          const tr = (docs: IDocumentBody[]): IDocumentBody[] => docs.map(doc => ({
            ...doc,
            id: doc.document.id,
            currentDocument: doc.document
          }));

          this._identityDocumentsStore.set(tr(response.identityDocuments));
          this._residenceConfirmationsStore.set(tr(response.residenceConfirmations));
          this._sourcesOfIncomeStore.set(tr(response.sourceOfIncomes));

          this._idenfyResultsStore.update(x => ({
            ...x,
            idenfyStatusManualDocument: response.idenfy.idenfyStatusManualDocument,
            idenfyStatusManualFace: response.idenfy.idenfyStatusManualFace,
            onBoardingScreeningResponseLogId: response.idenfy.onBoardingScreeningResponseLogId
          }));

          if (showLoaders) {
            this._profileStore.setLoading(false);
          }
        }),
        mapTo(void 0),
      );
  }

  runFlowContinuationAfterIdDocsApprovalAttemptIfPoaLimitExceeded$(): Observable<void> {
    // if wizard, id docs and selfie good status and poa limit exceeded, require poa
    return combineLatest([
        this._profileQuery.select(x => x.isWizardMode),
        this._requiredDocumentsService.isPoaFlagIsState$$(['NoAttach'], true),
        this._identityDocumentsQuery.isPrimaryDocumentScansInStatus$$(
          ['AcceptBot', 'AcceptManualInner', 'AcceptManualOuter']
        ),
        // this._selfieQuery.isSelfieScanInStatus(
        //   ['AcceptBot', 'AcceptManualInner', 'AcceptManualOuter']
        // )
      ]
    ).pipe(
      take(1),
      switchMap(([
                   isWizardMode, isProofOfAddressLimitExceededOrAboutToBeExceeded, docsGood,
                   // selfieGood
                 ]) => {
        if (isWizardMode && isProofOfAddressLimitExceededOrAboutToBeExceeded && docsGood
          // && selfieGood
        ) {
          return this.runPoaCheck$();
        }
        return of(void 0);
      }),
      mapTo(void 0)
    );
  }

  updateAnimationDone(activeTabIndex: number): void {
    this._profileStore.update({ animationDoneStamp: activeTabIndex + '_' + randomString() });
  }

  public runSoiCheck$(): Observable<void> {
    return combineLatest([
      combineLatest([
        this._sourcesOfIncomeQuery.isHasCustomerSourceOfIncomeBody$,
        this._sourcesOfIncomeQuery.isCustomerSourceOfIncomeExpired$,
        this._sourcesOfIncomeQuery.isHasCustomerSourceOfIncomeCopy$,
        this._sourcesOfIncomeQuery.isCustomerSourceOfIncomeCopyHasStatuses(['New', 'OnBoardingConfirmation']),
        this._sourcesOfIncomeQuery.isCustomerSourceOfIncomeCopyHasStatuses(['DenyBot', 'DenyManualInner', 'DenyManualOuter'])
      ]),
      combineLatest([
        this._profileQuery.profileHasRiskRating$(['High']),
        this._profileQuery.hasMemberStatus$(['Registered', 'DualDiligence'])
      ]),
    ]).pipe(
      map(([soi, profile]): [boolean, boolean, boolean, boolean, boolean, boolean, boolean] => [...soi, ...profile]),
      take(1),
      switchMap(([
                   isHasCustomerSourceOfIncomeBody,
                   isSoiExpired,
                   isHasCustomerSourceOfIncomeCopy,
                   isCustomerSourceOfIncomeCopyStatusNew,
                   isCustomerSourceOfIncomeCopyStatusDenied,
                   isProfileHighRisk,
                   isProfileRegOrDued
                 ]) => {

        // FFO-1530
        const isSoiHighRisk: boolean = isProfileHighRisk && !isProfileRegOrDued;

        if (!isHasCustomerSourceOfIncomeBody || (!isHasCustomerSourceOfIncomeBody && isSoiHighRisk)) {
          return of(void 0)
            .pipe(
              switchMap(() => this._profileStore.setActiveTab$(3)),
              switchMap(() => this._snackbarService.openWarning(
                this._localizationQ.transform('%[flags.snackbar.message.source-of-income-empty]%'), {
                  title: this._localizationQ.transform('%[flags.snackbar.title.source-of-income-empty]%'),
                  duration: environment.appSettings.snackbar.longDuration
                })),
              switchMap(() => EMPTY)
            );
        }

        if (isSoiExpired || (isSoiExpired && isSoiHighRisk)) {
          return of(void 0)
            .pipe(
              switchMap(() => this._profileStore.setActiveTab$(3)),
              switchMap(() => this._snackbarService.openWarning(
                this._localizationQ.transform('%[flags.snackbar.message.source-of-income-expired]%'), {
                  title: this._localizationQ.transform('%[flags.snackbar.title.source-of-income-expired]%'),
                  duration: environment.appSettings.snackbar.longDuration
                })),
              switchMap(() => EMPTY)
            );
        }

        if (!isHasCustomerSourceOfIncomeCopy || (!isHasCustomerSourceOfIncomeCopy && isSoiHighRisk)) {
          return of(void 0)
            .pipe(
              switchMap(() => this._profileStore.setActiveTab$(3)),
              switchMap(() => this._snackbarService.openWarning(
                this._localizationQ.transform('%[flags.snackbar.message.source-of-income-copy-empty]%'), {
                  title: this._localizationQ.transform('%[flags.snackbar.title.source-of-income-copy-empty]%'),
                  duration: environment.appSettings.snackbar.longDuration
                })),
            )
            .pipe(
              switchMap(() => this._sourcesOfIncomeQuery.selectAll()),
              take(1),
              tap((rcs: IDocumentBody[]) => {
                this._sourcesOfIncomeStore.ui.upsertMany(rcs.map((e, i) => ({
                  id: e.id,
                  isExpanded: !e.document.isExpired,
                  isEdit: !e.document.isExpired
                })));
              }),
              switchMap(() => EMPTY)
            );
        }


        if (isCustomerSourceOfIncomeCopyStatusNew || (isCustomerSourceOfIncomeCopyStatusNew && isSoiHighRisk)) {
          return of(void 0)
            .pipe(
              switchMap(() => this._profileStore.setActiveTab$(3)),
              switchMap(() => this._snackbarService.openWarning(
                this._localizationQ.transform('%[flags.snackbar.message.source-of-income-copy-in-approval]%'), {
                  title: this._localizationQ.transform('%[flags.snackbar.title.source-of-income-copy-in-approval]%'),
                  duration: environment.appSettings.snackbar.longDuration
                })),
              switchMap(() => EMPTY)
            );
        }

        if (isCustomerSourceOfIncomeCopyStatusDenied || (isCustomerSourceOfIncomeCopyStatusDenied && isSoiHighRisk)) {
          return of(void 0)
            .pipe(
              switchMap(() => this._profileStore.setActiveTab$(3)),
              switchMap(() => this._snackbarService.openWarning(
                this._localizationQ.transform('%[flags.snackbar.message.source-of-income-copy-unreadable]%'), {
                  title: this._localizationQ.transform('%[flags.snackbar.title.source-of-income-copy-unreadable]%'),
                  duration: environment.appSettings.snackbar.longDuration
                })),
              switchMap(() => this._sourcesOfIncomeQuery.selectAll()), take(1),
              tap((rcs: IDocumentBody[]) => {
                this._sourcesOfIncomeStore.ui.upsertMany(rcs.map((e, i) => ({
                  id: e.id,
                  isExpanded: !e.document.isExpired,
                  isEdit: !e.document.isExpired
                })));
              }),
              switchMap(() => EMPTY)
            );
        }

        if (!isProfileRegOrDued || (!isProfileRegOrDued && isSoiHighRisk)) {
          return of(void 0)
            .pipe(
              switchMap(() => this._snackbarService.openWarning(
                this._localizationQ.transform('%[flags.snackbar.message.not-in-registered-status]%'), {
                  title: this._localizationQ.transform('%[flags.snackbar.title.not-in-registered-status]%'),
                  duration: environment.appSettings.snackbar.longDuration
                })),
              switchMap(() => EMPTY)
            );
        }

        return of(void 0);
      })
    );
  }

  public runPoaCheck$(): Observable<void> {
    return combineQueries([
      this._residenceConfirmationsQ.isHasCustomerProofOfAddressBody$$,
      this._residenceConfirmationsQ.isCustomerProofOfAddressExpired$$,
      this._residenceConfirmationsQ.isHasCustomerProofOfAddressCopy$$,
      this._residenceConfirmationsQ.isCustomerProofOfAddressCopyHasStatuses(
        ['New']
      ),
      this._residenceConfirmationsQ.isCustomerProofOfAddressCopyHasStatuses(
        ['DenyBot', 'DenyManualInner', 'DenyManualOuter']
      ),
      this._identityDocumentsQuery.isPrimaryDocumentExistsAndCountryHasRiskRating$$(
        ['Medium', 'High', 'Prohibited'],
      ),
      this._gatewaysQuery.isGatewayHighRisk$$,
      this._profileFlagsService.isCustomerResidenceCountryHighRisk$$])
      .pipe(
        take(1),
        switchMap(([
                     isHasCustomerProofOfAddressBody,
                     isPoaExpired,
                     isHasCustomerProofOfAddressCopy,
                     isCustomerProofOfAddressCopyStatusNew,
                     isCustomerProofOfAddressCopyStatusDenied,
                     isPrimaryDocumentExistsAndCountryHighRisk,
                     isGatewayHighRisk,
                     isCustomerResidenceCountryHighRisk
                   ]) => {

          const isHighRisk = isPrimaryDocumentExistsAndCountryHighRisk ||
            isGatewayHighRisk ||
            isCustomerResidenceCountryHighRisk;

          if (!isHasCustomerProofOfAddressBody || (!isHasCustomerProofOfAddressBody && isHighRisk)) {
            return of(void 0)
              .pipe(
                switchMap(() => this._profileStore.setActiveTab$(3)),
                switchMap(() => {
                  let msg = this._localizationQ.transform('%[flags.snackbar.message.proof-of-address-empty]%');
                  let title = this._localizationQ.transform('%[flags.snackbar.title.proof-of-address-empty]%');
                  if (isHighRisk) {
                    msg = this._localizationQ.transform('%[flags.snackbar.message.high-risk]%');
                    title = this._localizationQ.transform('%[flags.snackbar.title.high-risk]%');
                  }
                  return this._snackbarService.openWarning(msg, { title, duration: environment.appSettings.snackbar.longDuration });
                }),
                switchMap(() => this._residenceConfirmationsQ.selectAll()), take(1),
                tap((rcs: IDocumentBody[]) => {
                  rcs.forEach((rc: IDocumentBody) => {
                    this._residenceConfirmationsStore.ui.upsert(rc.id, { isExpanded: false, isEdit: false });
                  });
                  this._residenceConfirmationsStore.ui.update({ newDocFormIsOpen: true });
                }),
                switchMap(() => EMPTY)
              );
          }

          if (isPoaExpired || (isPoaExpired && isHighRisk)) {
            return of(void 0)
              .pipe(
                switchMap(() => this._profileStore.setActiveTab$(3)),
                switchMap(() => {
                  let msg = this._localizationQ.transform('%[flags.snackbar.message.proof-of-address-expired]%');
                  let title = this._localizationQ.transform('%[flags.snackbar.title.proof-of-address-expired]%');
                  if (isHighRisk) {
                    msg = this._localizationQ.transform('%[flags.snackbar.message.high-risk]%');
                    title = this._localizationQ.transform('%[flags.snackbar.title.high-risk]%');
                  }
                  return this._snackbarService.openWarning(msg, { title, duration: environment.appSettings.snackbar.longDuration });
                }),
                switchMap(() => this._residenceConfirmationsQ.selectAll()), take(1),
                tap((rcs: IDocumentBody[]) => {
                  rcs.forEach((rc: IDocumentBody) => {
                    this._residenceConfirmationsStore.ui.upsert(rc.id, { isExpanded: false, isEdit: false });
                  });
                  this._residenceConfirmationsStore.ui.update({ newDocFormIsOpen: true });
                }),
                switchMap(() => EMPTY)
              );
          }

          if (!isHasCustomerProofOfAddressCopy || (!isHasCustomerProofOfAddressCopy && isHighRisk)) {
            return of(void 0)
              .pipe(
                switchMap(() => this._profileStore.setActiveTab$(3)),
                switchMap(() => {
                  let msg = this._localizationQ.transform('%[flags.snackbar.message.proof-of-address-copy-empty]%');
                  let title = this._localizationQ.transform('%[flags.snackbar.title.proof-of-address-copy-empty]%');
                  if (isHighRisk) {
                    msg = this._localizationQ.transform('%[flags.snackbar.message.high-risk]%');
                    title = this._localizationQ.transform('%[flags.snackbar.title.high-risk]%');
                  }
                  return this._snackbarService.openWarning(msg, { title, duration: environment.appSettings.snackbar.longDuration });
                }),
              )
              .pipe(
                switchMap(() => this._residenceConfirmationsQ.selectAll()),
                take(1),
                tap((rcs: IDocumentBody[]) => {
                  this._residenceConfirmationsStore.ui.upsertMany(rcs.map((e, i) => ({
                    id: e.id,
                    isExpanded: !e.document.isExpired,
                    isEdit: !e.document.isExpired
                  })));
                }),
                switchMap(() => EMPTY)
              );
          }
          // FFO-1155
          if (isCustomerProofOfAddressCopyStatusNew && isHighRisk) {
            return of(void 0)
              .pipe(
                switchMap(() => this._profileStore.setActiveTab$(3)),
                switchMap(() => {
                  const msg = this._localizationQ.transform('%[flags.snackbar.message.proof-of-address-copy-in-approval]%');
                  const title = this._localizationQ.transform('%[flags.snackbar.title.proof-of-address-copy-in-approval]%');
                  // FFO-1497
                  // if (isHighRisk) {
                  //   msg =  this.localizationQ.transform('%[flags.snackbar.message.high-risk]%');
                  //   title =  this.localizationQ.transform('%[flags.snackbar.title.high-risk]%');
                  // }
                  return this._snackbarService.openWarning(msg, { title, duration: environment.appSettings.snackbar.longDuration });
                }),
                switchMap(() => EMPTY)
              );
          }

          if (isCustomerProofOfAddressCopyStatusDenied || (isCustomerProofOfAddressCopyStatusDenied && isHighRisk)) {
            return of(void 0)
              .pipe(
                switchMap(() => this._profileStore.setActiveTab$(3)),
                switchMap(() => {
                  let msg = this._localizationQ.transform('%[flags.snackbar.message.proof-of-address-copy-unreadable]%');
                  let title = this._localizationQ.transform('%[flags.snackbar.title.proof-of-address-copy-unreadable]%');
                  if (isHighRisk) {
                    msg = this._localizationQ.transform('%[flags.snackbar.message.high-risk]%');
                    title = this._localizationQ.transform('%[flags.snackbar.title.high-risk]%');
                  }
                  return this._snackbarService.openWarning(msg, { title, duration: 60000 });
                }),
                switchMap(() => this._residenceConfirmationsQ.selectAll()), take(1),
                tap((rcs: IDocumentBody[]) => {
                  this._residenceConfirmationsStore.ui.upsertMany(rcs.map((e, i) => ({
                    id: e.id,
                    isExpanded: !e.document.isExpired,
                    isEdit: !e.document.isExpired
                  })));
                }),
                switchMap(() => EMPTY)
              );
          }

          return of(void 0);

        }),
        mapTo(void 0)
      );
  }

  public runIdenfyScenario$(): Observable<void> {
    return combineLatest([
      this._profileQuery.select(x => x.activeTab),
      this._requiredDocumentsQuery.isIDVerificationRequired$$
    ])
      .pipe(
        take(1),
        switchMap(([
                     activeTab,
                     badAttach,
                   ]) => {

          const isOpenWindowOnDoc = activeTab === EProfileTabs.IdentityDocuments && badAttach;
          // const isOpenWindowOnSelfie = activeTab === 2 && badAttach;

          if (isOpenWindowOnDoc) {
            return this._warnIdenfyDocument$();
          }

          return of(void 0);
        })
      );
  }

  startProfileUpdate$$(): Observable<void> {
    return this._identityDocumentsService.openOnlyFirstIdentityDocument$().pipe(
      withLatestFrom(this._requiredDocumentsQuery.isIDOnVerification$$),
      switchMap(([_, hasDocumentOnWaitStatus]) => {
        return hasDocumentOnWaitStatus === true ? of(true).pipe(
          delay(20000),
          mergeMap(() => this.startProfileUpdate$$())) : of(hasDocumentOnWaitStatus);
      }),
      mapTo(void 0),
      takeUntil(this._destroyProfileUpdate$$)
    );
  }

  stopProfileUpdate(): void {
    this._destroyProfileUpdate$$.next();
    this._destroyProfileUpdate$$.complete();
  }


  // Логика выдачи сообщений:
  //
  // 1. если в ответе из Idenfy в полях IdenfyStatusManualFace, IdenfyStatusManualDocument стоят "плохие" ответы,
  // то при переключении по вкладкам показываем соответствующие сообщения.
  //
  // 2. если в ответе из Idenfy в одном из полей IdenfyStatusManualFace, IdenfyStatusManualDocument стоит Null или "хороший" ответ, а во втором "плохой",
  // то при переключении на вкладку с "плохим" ответом показываем соответствующее сообщение,
  // а при переключении на вкладку без "плохого" ответа показываем сообщение о необходимости перегрузить файлы
  //
  // irrelevant
  // 3. если в ответе из Idenfy в одном из полей IdenfyStatusManualFace, IdenfyStatusManualDocument стоит Null или "хорошие" ответы,
  // то никакие сообщения не показываем

  private _warnIdenfyDocument$(): Observable<void> {
    return combineLatest([
      this._idenfyQuery.enrichedIdenfyStatusManualDocument$$,
      this._idenfyQuery.enrichedIdenfyStatusManualFace$$
    ])
      .pipe(
        take(1),
        switchMap(([
                     enrichedIdenfyStatusManualDocument,
                     enrichedIdenfyStatusManualFace
                   ]) => {

          let message = '';

          if (enrichedIdenfyStatusManualDocument == null) {
            message = '%[idenfy-result.empty-doc]%';
          }

          if (enrichedIdenfyStatusManualDocument != null && !enrichedIdenfyStatusManualDocument.isGoodStatus) {
            message = enrichedIdenfyStatusManualDocument.localizationKey;
          }

          const arr = [enrichedIdenfyStatusManualDocument, enrichedIdenfyStatusManualFace];
          if (
            arr.some(e => e == null || e.isGoodStatus) &&
            arr.some(e => e != null && e.isGoodStatus)
          ) {
            if (enrichedIdenfyStatusManualDocument != null && enrichedIdenfyStatusManualDocument.isGoodStatus) {
              message = '%[idenfy-result.empty-doc]%';
            }
          }

          return this._dialogService.openIdenfyResults$(
            this._localizationQ.transform(message)
          );

        }),
        mapTo(void 0)
      );
  }

}
