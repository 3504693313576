import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import snsWebSdk from '@sumsub/websdk';
import { BehaviorSubject } from 'rxjs';
import { nextOnChange } from '@ff/utils';
import { filter, take, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FFWebSDKEvents } from '../sumsub.model';
import { combineQueries } from '@datorama/akita';
import { DocDefinitions } from '@sumsub/websdk/types/types';
import { ThemeService } from '../../../../data-modules/theme/theme.service';


@UntilDestroy()
@Component({
  selector: 'app-sumsum-websdk-container',
  templateUrl: './sumsum-websdk-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SumsumWebsdkContainerComponent implements OnInit, OnChanges {
  private _token$$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private _language$$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private _documentDefinitions$$: BehaviorSubject<DocDefinitions | null> = new BehaviorSubject<DocDefinitions | null>(null);

  @Input() token: string | null;
  @Input() language: string | null;
  @Input() documentDefinitions: DocDefinitions | null;
  @Output() isIdWasScanned: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isSumsubReady: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private _themeService: ThemeService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    nextOnChange(changes.token, this._token$$);
    nextOnChange(changes.language, this._language$$);
    nextOnChange(changes.documentDefinitions, this._documentDefinitions$$);
  }

  ngOnInit(): void {
    this._subscribeOnInit();
  }

  private _subscribeOnInit(): void {
    combineQueries([this._token$$, this._language$$, this._documentDefinitions$$]).pipe(
      filter(([token, language, documentDefinitions]) => token != null && language != null && documentDefinitions != null),
      take(1),
      tap(([token, language, documentDefinitions]) => {
        if (token != null && language != null && documentDefinitions != null) {
          this._launchSumSub(token, language, documentDefinitions);
        }
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  private _launchSumSub(token: string, lang: string, documentDefinitions: DocDefinitions): void {
    this._themeService.getSumsubCustomStylesLink$().pipe(
      tap((sumsubStylesLink) => {
        const snsWebSdkInstance = snsWebSdk.init(token, () => Promise.resolve(token))
          .withConf({
            lang,
            documentDefinitions,
            uiConf: {
              customCss: sumsubStylesLink
            }
          })
          .withOptions({ adaptIframeHeight: true, addViewportTag: true })
          .onMessage((type, payload) => {
            const stepCompleted: FFWebSDKEvents['idCheck.stepCompleted'] = payload as FFWebSDKEvents['idCheck.stepCompleted'];
            if (type === 'idCheck.onReady') {
              this.isSumsubReady.emit(true);
            }
            if (type === 'idCheck.stepCompleted' && stepCompleted.step != null && stepCompleted.step === 'IDENTITY') {
              this.isIdWasScanned.emit(true);
            }
          })
          .build();

        snsWebSdkInstance.launch('#sumsub');
      })
    ).subscribe();
  }
}
