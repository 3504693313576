import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import pkg from '../../../../package.json';
import { VersionStore } from './version.store';
import { catchError, mapTo, switchMap } from 'rxjs/operators';
import { applyTransaction, withTransaction } from '@datorama/akita';

@Injectable()
export class VersionService {
  constructor(
    private _http: HttpClient,
    private _store: VersionStore
  ) {}

  loadVersions(): Observable<void> {
    return of(0).pipe(
      withTransaction(() => {
        this._store.reset();
        this._store.setLoading(true);
      }),
      switchMap(() => this._http.get<string>('ping/version')),
      withTransaction(backendVersion => {
        this._store.setLoading(false);
        this._store.update({
          backend: backendVersion,
          frontend: pkg.version,
          materialUi: pkg.devDependencies['@ff/material-ui']
        });
      }),
      catchError(err => {
        applyTransaction(() => {
          this._store.setError(err);
          this._store.setLoading(false);
        });
        return throwError(err);
      }),
      mapTo(void 0)
    );
  }
}
