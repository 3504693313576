import { ActiveState, EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { ICard, IWorkflow } from './workflows.models';
import { IGateway } from '../gateways/gateways.model';
import { LocalizationQuery } from '@ff/localization';

export interface IWorkflowsState extends EntityState<IWorkflow, string>, ActiveState<string> {}

export type WorkflowType = 'ToCard' | 'ToCash' | 'ToAccount' | 'ToMWallet' | 'ToEWallet' | 'ToSwift';

const STATIC_PAYMENT_METHODS: { id: WorkflowType, title: string, systemCode: string, gateways: IGateway[] }[] = [
  { id: 'ToCard', title: 'ToCard', systemCode: '', gateways: [] },
  { id: 'ToCash', title: 'ToCash', systemCode: '', gateways: [] },
  // FFO-1041
  // { id: 'ToEWallet', title: 'ToEWallet', systemCode: '', gateways: [] },
  // { id: 'ToMWallet', title: 'ToMWallet', systemCode: '', gateways: [] },
  // { id: 'ToSwift', title: 'ToSwift', systemCode: '', gateways: [] },
  { id: 'ToAccount', title: 'ToAccount', systemCode: '', gateways: [] }
];

@Injectable()
@StoreConfig({ name: 'workflows' })
export class WorkflowsStore extends EntityStore<IWorkflowsState> {
  constructor() {
    super({ active: null, loading: false });
    this.upsertMany(STATIC_PAYMENT_METHODS);
  }

  setDefault(): void {
    this.reset();
    this.upsertMany(STATIC_PAYMENT_METHODS);
  }
}

@Injectable()
export class WorkflowsQuery extends QueryEntity<IWorkflowsState> {
  private _cardMap$: Observable<ICard[]> = of(0).pipe(
    map((): ICard[] => ([
      {
        id: 'ToCard',
        className: 'ff-icon ff-credit-card',
        name: this._localizationQ.transform('%[send.amount.workflow.label.card]%')
      },
      {
        id: 'ToCash',
        className: 'ff-icon ff-money',
        name: this._localizationQ.transform('%[send.amount.workflow.label.cash]%')
      },
      {
        id: 'ToAccount',
        className: 'ff-icon ff-museum',
        name: this._localizationQ.transform('%[send.amount.workflow.label.account]%')
      },
      {
        id: 'ToEWallet',
        className: 'ff-icon ff-mobile-android-alt',
        name: this._localizationQ.transform('%[send.amount.workflow.label.e-wallet]%')
      },
      {
        id: 'ToMWallet',
        className: 'ff-icon ff-mobile-android-alt',
        name: this._localizationQ.transform('%[send.amount.workflow.label.m-wallet]%')
      },
      {
        id: 'ToSwift',
        className: 'ff-icon ff-swift-seeklogo',
        name: this._localizationQ.transform('%[send.amount.workflow.label.swift]%')
      }
    ]))
  );

  loading$$: Observable<boolean> = this.selectLoading();
  activeWorkflow$$: Observable<IWorkflow | undefined> = this.selectActive();
  isToCashWorkflow$$: Observable<boolean> = this.activeWorkflow$$.pipe(
    map((a: IWorkflow | undefined) => a?.id.includes('ToCash') ?? false)
  );

  workflowsWithIcons$$: Observable<IWorkflow[]> = combineLatest([this.selectAll(), this._cardMap$]).pipe(
    delay(0),
    map(([workflows, cardMap]) => workflows.map(w => this._attachIcons(w, cardMap)))
  );

  constructor(
    protected override store: WorkflowsStore,
    private _localizationQ: LocalizationQuery
  ) {
    super(store);
  }

  private _attachIcons(workflow: IWorkflow, cardMap: ICard[]): IWorkflow {
    const wf = cardMap.find(card => card.id === workflow.id);
    const icon: ICard | null = wf == null ? null : ({ id: wf.id, name: wf.name, className: wf.className });
    return {
      ...workflow,
      card: icon
    };
  }
}
