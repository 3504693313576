import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { map, mapTo, takeWhile, tap } from 'rxjs/operators';
import { ThemeService } from '../../../../data-modules/theme/theme.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-refused-to-send',
  styleUrls: ['./prove-your-identity.component.scss'],
  templateUrl: 'prove-your-identity.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProveYourIdentityComponent implements OnInit {
  private readonly _countDown: number = 50;
  private _route$: Subject<void> = new Subject<void>();
  private _interval$: Observable<number> = interval(1000);
  secondsLeft$: BehaviorSubject<number> = new BehaviorSubject(this._countDown);

  constructor(public dialogRef: MatDialogRef<ProveYourIdentityComponent>, public themeService: ThemeService) {}

  ngOnInit(): void {
    this._subscribeOnInterval();
    this._subscribeOnRouteEvent();
  }

  onProvideDocumentClick(): void {
    this._route$.next();
  }

  private _subscribeOnRouteEvent(): void {
    this._route$
      .pipe(
        tap(() => {
          this.dialogRef.close(true);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private _subscribeOnInterval(): void {
    this._interval$
      .pipe(
        map((timeElapsed: number): number => this._countDown - timeElapsed),
        takeWhile((timeLeft: number): boolean => timeLeft >= 0),
        tap((timeLeft: number) => {
          this.secondsLeft$.next(timeLeft);

          if (timeLeft === 0) {
            this._route$.next();
          }
        }),
        mapTo(void 0),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
