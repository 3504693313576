import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDocTypeValue } from '../doctypes-identity/doctypes-identity.model';

export interface IDocTypesSourceIncomeState extends EntityState<IDocTypeValue, string> { }

@Injectable()
@StoreConfig({ name: 'doctypes-source-income' })
export class DocTypesSourceIncomeStore extends EntityStore<IDocTypesSourceIncomeState> {
  constructor() {
    super();
  }
}

@Injectable()
export class DocTypesSourceIncomeQuery extends QueryEntity<IDocTypesSourceIncomeState> {
  docTypesSourceIncome$$: Observable<IDocTypeValue[]> = this.selectAll();

  constructor(protected override store: DocTypesSourceIncomeStore) {
    super(store);
  }
}
