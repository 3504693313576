
// commented fields are filtered by the backend

export const FIELDS_WILL_DE_IGNORED = [
  // {
  //   additionalInfo: null,
  //   isRequired: 'Required',
  //   name: 'RemitterName',
  //   sourceMethodName: null,
  //   sourceType: 'Manual'
  // },
  {
    additionalInfo: null,
    isRequired: 'Optional',
    name: 'RemitterMiddlename',
    sourceMethodName: null,
    sourceType: 'Manual'
  },
  // {
  //   additionalInfo: null,
  //   isRequired: 'Required',
  //   name: 'RemitterSurname',
  //   sourceMethodName: null,
  //   sourceType: 'Manual'
  // },
  // {
  //   additionalInfo: null,
  //   isRequired: 'Required',
  //   name: 'RemitterResidence',
  //   sourceMethodName: null,
  //   sourceType: 'Manual'
  // },
  {
    additionalInfo: null,
    isRequired: 'Required',
    name: 'RemitterAddress',
    sourceMethodName: null,
    sourceType: 'Manual'
  },
  // {
  //   additionalInfo: null,
  //   isRequired: 'Required',
  //   name: 'RemitterIDType',
  //   sourceMethodName: null,
  //   sourceType: 'Manual'
  // },
  // {
  //   additionalInfo: null,
  //   isRequired: 'Required',
  //   name: 'RemitterIDNumber',
  //   sourceMethodName: null,
  //   sourceType: 'Manual'
  // },
  // {
  //   additionalInfo: null,
  //   isRequired: 'Required',
  //   name: 'RemitterIDIssuedDate',
  //   sourceMethodName: null,
  //   sourceType: 'Manual'
  // },
  // {
  //   additionalInfo: null,
  //   isRequired: 'Required',
  //   name: 'RemitterIDIssuedBy',
  //   sourceMethodName: null,
  //   sourceType: 'Manual'
  // },
  // {
  //   additionalInfo: null,
  //   isRequired: 'Required',
  //   name: 'RemitterDateOfBirth',
  //   sourceMethodName: null,
  //   sourceType: 'Manual'
  // },
  // {
  //   additionalInfo: null,
  //   isRequired: 'Required',
  //   name: 'RemitterPhone',
  //   sourceMethodName: null,
  //   sourceType: 'Manual'
  // },
];

export const EXISTING_FIELDS = [
  // {
  //   additionalInfo: null,
  //   isRequired: 'Required',
  //   name: 'BeneficiaryName',
  //   sourceMethodName: null,
  //   sourceType: 'Manual'
  // },
  {
    additionalInfo: null,
    isRequired: 'Optional',
    name: 'BeneficiaryMiddlename',
    sourceMethodName: null,
    sourceType: 'Manual'
  },
  // {
  //   additionalInfo: null,
  //   isRequired: 'Required',
  //   name: 'BeneficiarySurname',
  //   sourceMethodName: null,
  //   sourceType: 'Manual'
  // },
  // {
  //   additionalInfo: null,
  //   isRequired: 'Required',
  //   name: 'BeneficiaryPhone',
  //   sourceMethodName: null,
  //   sourceType: 'Manual'
  // },
];
