import { ActiveState, EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IGateway } from './gateways.model';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IDetailData } from '../my-receivers/my-receivers.models';

export interface IGatewaysState extends EntityState<IGateway, string>, ActiveState<string> {}

const idKey: keyof IGateway = 'directionId';

@Injectable()
@StoreConfig({ name: 'gateways', idKey })
export class GatewaysStore extends EntityStore<IGatewaysState> {
  constructor() {
    super({ active: null });
  }
}

@Injectable()
export class GatewaysQuery extends QueryEntity<IGatewaysState> {
  gateways$$: Observable<IGateway[]> = this.selectAll();
  activeGateway$$: Observable<IGateway | undefined> = this.selectActive();
  activeGatewayDirectionId$$: Observable<string | undefined> = this.selectActive(x => x.directionId);
  activeGatewayHintMessage$$: Observable<string | null> = this.activeGateway$$.pipe(map(s => s?.directionHintMessage ?? null));
  isGatewayHighRisk$$: Observable<boolean> = this.selectActive((x: IGateway) => x.isHighRisk).pipe(
    map((risk: boolean | undefined): boolean => risk ?? false)
  );

  constructor(protected override store: GatewaysStore) {
    super(store);
  }

  getDetailByDirection$$(details: IDetailData[]): Observable<IDetailData | undefined> {
    return this.activeGateway$$
      .pipe(map((activeGateway: IGateway | undefined): IDetailData | undefined => {
        return details.find(detail => detail.directionId === activeGateway?.directionId);
      }));
  }

  getDirectionByIdAndNotDirectionId$(id: string): Observable<IGateway | undefined> {
    return this.selectAll()
      .pipe(
        take(1),
        map((gs: IGateway[]) => gs.find(g => g.id === id))
      );
  }
}
