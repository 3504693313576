import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { akitaConfig, enableAkitaProdMode, persistState } from '@datorama/akita';
import { environment } from './environments/environment';
import { OnlineAppModule } from './online-app/online-app.module';


if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}
akitaConfig({
  resettable: true
});

persistState({
  include: ['persistence', 'languages', 'security', 'utm-marks', 'send'],
  storage: window.sessionStorage
});
persistState({ include: ['device-info'], storage: window.localStorage });


platformBrowserDynamic().bootstrapModule(OnlineAppModule)
  .catch(err => console.error(err));
