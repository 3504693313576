import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor() {

  }

  intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {
    return next.handle(req).pipe(
      catchError((errorMessage: HttpErrorResponse) => {

        // if (errorMessage.status === 401) {
        //   // this.authError$.next();
        //   // this._authService.forcedLogout().subscribe();
        //   return EMPTY;
        // }

        return throwError(errorMessage);
      })
    );
  }
}
