import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mapTo, switchMap, take, tap } from 'rxjs/operators';
import { AuthenticationCountriesStore } from './authentication-countries.store';
import { HttpClient } from '@angular/common/http';
import { IAuthenticationCountryResponseModel } from './auth-countries.models';

@Injectable()
export class AuthenticationCountriesService {

  constructor(
    private _authCountriesStore: AuthenticationCountriesStore,
    private _http: HttpClient,
  ) { }

  getAuthCountries2$(): Observable<void> {
    return of(void 0)
      .pipe(
        switchMap(() => this._http.get<IAuthenticationCountryResponseModel[]>('reference/auth-countries')),
        take(1),
        tap((countries: IAuthenticationCountryResponseModel[]) => {
          this._authCountriesStore.set(countries
              .sort((a, b) => a.title.localeCompare(b.title))
            // .map(c => ({ ...c, phoneCode: c.phoneCode.replace('+', '') }))
            // .map(c => ({...c, weight: c.id === 'RUS' ? 1 : 0}))
          );
        }),
        mapTo(void 0)
      );
  }
}
