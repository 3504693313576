import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ILookupInt } from '../../root/private/private-models/lookup-str';

export interface ICurrenciesDestinationState extends EntityState<ILookupInt, string> {
  loading: boolean
}

@Injectable()
@StoreConfig({ name: 'currencies-destination' })
export class CurrenciesDestinationStore extends EntityStore<ICurrenciesDestinationState> {
  constructor() {
    super({ loading: false });
  }
}

@Injectable()
export class CurrenciesDestinationQuery extends QueryEntity<ICurrenciesDestinationState> {
  loading$$: Observable<boolean> = this.selectLoading();
  currenciesDestination$$: Observable<ILookupInt[]> = this.selectAll();

  constructor(protected override store: CurrenciesDestinationStore) {
    super(store);
  }
}
