import { Injectable } from '@angular/core';
import { combineQueries } from '@datorama/akita';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { ResidenceCountriesQuery } from '../../../data-modules/residence-countries/residence-countries.store';
import { PersonalDataFormQuery } from '../private-pages/profile/personal-data/personal-data.store';
import { IdentityDocumentsQuery } from '../private-pages/profile/identity-documents/store/identity-documents.store';
import { IIdIssuedCountriesModel } from '../../../data-modules/id-issued-countries/id-issued-countries.model';
import { RequiredDocumentsService } from '../../../data-modules/required-documents/required-documents.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class ProfileFlagsService {

  isSomeDocumentInVerification$$: Observable<boolean> = this._requiredDocumentsService.isIdentityVerificationFlagInState$(['Wait'], false);
  isProfileAwaitingApproval$$: Observable<boolean> = combineQueries([
    this.isSomeDocumentInVerification$$
  ]).pipe(
    map(arr => arr.some(e => e)),
    distinctUntilChanged(),
    untilDestroyed(this)
  );

  isProfileAwaitingApprovalOnlyWhenFilesAreLoadedToClient$$: Observable<boolean> = combineQueries([
    this.isProfileAwaitingApproval$$,
    this._identityDocumentsQ.selectCount().pipe(map(cnt => cnt > 0))
  ]).pipe(
    filter(([, hasIdentityDocs ]) => hasIdentityDocs),
    map(([isWaiting, , ]) => isWaiting),
    distinctUntilChanged(),
    untilDestroyed(this)
  );

  isCustomerResidenceCountryHighRisk$$: Observable<boolean> = this._personalDataFormQuery.select(x => x.current.country)
    .pipe(
      switchMap((country: IIdIssuedCountriesModel | null): Observable<boolean> => {
        if (country == null) {
          return of(false);
        }
        return this._residenceCountriesQuery.selectEntity(country.id)
          .pipe(
            map((country2: IIdIssuedCountriesModel | undefined) => {
              if (country2 == null) {
                // eslint-disable-next-line no-alert
                alert(country.title + ' not found in residence countries list');
                throw new Error(country.title + ' not found');
              }
              const isLow = ['Low'].includes(country2.riskRating);
              return !isLow;
            })
          );
      })
    );

  constructor(
    private _identityDocumentsQ: IdentityDocumentsQuery,
    private _personalDataFormQuery: PersonalDataFormQuery,
    private _residenceCountriesQuery: ResidenceCountriesQuery,
    private _requiredDocumentsService: RequiredDocumentsService
  ) {}

}
