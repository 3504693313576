import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { IAttachment } from '../../root/private/private-models/attachment.model';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { SendCheckComponent } from './send-check/send-check.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { ReadonlyOnlineComponent } from './flag-related/readonly-online/readonly-online.component';
import { ServiceDeniedComponent } from './flag-related/service-denied/service-denied.component';
import { ProveYourIdentityComponent } from './flag-related/prove-your-identity/prove-your-identity.component';
import { DocumentViewerComponent } from './document-viewer/document-viewer.component';
import { PrepareForPaymentComponent } from './prepare-for-payment/prepare-for-payment.component';
import { LocationsComponent } from './locations/locations.component';
import { ChangeCountryComponent } from './change-country/change-country.component';
import { RateHasChangedComponent } from './rate-has-changed/rate-has-changed.component';
import { ServiceDeniedStrictComponent } from './flag-related/service-denied-strict/service-denied-strict.component';
import { LocalizationQuery } from '@ff/localization';
import { LogoutDialogComponent } from './logout-dialog/logout-dialog.component';
import { IFrameResponseMessage } from './identify-iframe/identify-iframe.model';
import {
  ImageModalComponent
} from '../../root/private/private-pages/profile/additional-documents/dialogs/image-modal/image-modal.component';
import { SnackbarService } from '../snackbars/snackbar.service';
import {
  ProvideKycQuestionnaireComponent
} from './flag-related/provide-kyc-questionnaire/provide-kyc-questionnaire.component';
import {
  IdentityOnVerificationComponent
} from './flag-related/identity-on-verification/identity-on-verification.component';
import { EmailVerificationComponent } from './flag-related/email-verification/email-verification.component';
import { WizardAwaitingComponent } from './flag-related/wizard-awaiting/wizard-awaiting.component';
import { IdentifyIframeService } from './identify-iframe/identify-iframe.service';

@Injectable()
export class DialogService {
  constructor(
    private _dialogController: MatDialog,
    private _localizationQuery: LocalizationQuery,
    private _identifyIframeService: IdentifyIframeService,
    private _snackbarService: SnackbarService) {}

  // it is better not to use filter on modular streams because you would want to do something if the user declined
  openConfirmDialog$(
    confirmText: string,
    yes: string = this._localizationQuery.transform('%[confirm-dialog.button.yes]%'),
    no: string = this._localizationQuery.transform('%[confirm-dialog.button.no]%')
  ): Observable<boolean> {
    return this._dialogController
      .open(ConfirmDialogComponent, {
        data: { confirmText, yes, no },
        autoFocus: false,
        disableClose: true,
        maxWidth: 420,
        width: '100%'
      })
      .afterClosed()
      .pipe(take(1));
  }

  openSendCheck$(): Observable<string | null> {
    return this._dialogController
      .open(SendCheckComponent, {
        data: {},
        panelClass: 'modal-dialog',
        autoFocus: false,
        disableClose: false,
        maxWidth: 420,
        width: '100%'
      })
      .afterClosed()
      .pipe(
        take(1),
        tap((x) => {
          if (x != null) {
            return this._snackbarService.openSuccess(this._localizationQuery.transform('%[payment-summary.send-to-email-dialog.success.label]%'));
          }
          return;
        })
      );
  }

  openCaptureAndEdit$(attachment?: IAttachment): Observable<IAttachment> {
    return this._dialogController.open(ImageModalComponent,
      {
        data: { attachment },
        panelClass: 'cdk-overlay-pane-overflow-x-hidden',
        autoFocus: false,
        disableClose: true,
        maxWidth: 640
      })
      .afterClosed()
      .pipe(
        take(1),
      );
  }

  openInfo$(infoText: string): Observable<unknown> {
    return this._dialogController
      .open(InfoDialogComponent, {
        data: { text: infoText },
        autoFocus: false,
        disableClose: true,
        maxWidth: 420,
        width: '100%'
      })
      .afterClosed()
      .pipe(take(1));
  }

  openReadonlyOnline$(): Observable<boolean> {
    return this._dialogController
      .open(ReadonlyOnlineComponent, {
        data: {},
        panelClass: 'modal-dialog',
        autoFocus: false,
        disableClose: true,
        maxWidth: 420,
        width: '100%'
      })
      .afterClosed()
      .pipe(take(1));
  }

  openServiceDenied$(): Observable<unknown> {
    return this._dialogController
      .open(ServiceDeniedComponent, {
        data: {},
        autoFocus: false,
        disableClose: true,
        maxWidth: 420,
        width: '100%'
      })
      .afterClosed()
      .pipe(take(1));
  }

  openServiceDeniedStrict$(): Observable<unknown> {
    return this._dialogController
      .open(ServiceDeniedStrictComponent, {
        data: {},
        autoFocus: false,
        hasBackdrop: true,
        disableClose: true,
        maxWidth: 420,
        width: '100%'
      })
      .afterClosed()
      .pipe(take(1));
  }

  openProveYourIdentity$(): Observable<unknown> {
    return this._dialogController
      .open(ProveYourIdentityComponent, {
        data: {},
        autoFocus: false,
        disableClose: true,
        maxWidth: 560,
        width: '100%'
      })
      .afterClosed()
      .pipe(take(1));
  }

  openIdentityOnVerification$(): Observable<unknown> {
    return this._dialogController
      .open(IdentityOnVerificationComponent, {
        data: {},
        autoFocus: false,
        disableClose: true,
        maxWidth: 560,
        width: '100%'
      })
      .afterClosed()
      .pipe(take(1));
  }

  openEmailVerification$(): Observable<unknown> {
    return this._dialogController
      .open(EmailVerificationComponent, {
        data: {},
        autoFocus: false,
        disableClose: true,
        maxWidth: 560,
        width: '100%'
      })
      .afterClosed()
      .pipe(take(1));
  }

  openProveKYCQuestionnaire$(): Observable<unknown> {
    return this._dialogController
      .open(ProvideKycQuestionnaireComponent, {
        data: {},
        autoFocus: false,
        disableClose: true,
        maxWidth: 560,
        width: '100%'
      })
      .afterClosed()
      .pipe(take(1));
  }

  openWizardAwaiting$(message: string): Observable<unknown> {
    return this._dialogController
      .open(WizardAwaitingComponent, {
        data: { message },
        autoFocus: false,
        disableClose: true,
        maxWidth: 420,
        width: '100%',
        restoreFocus: false
      })
      .afterClosed()
      .pipe(take(1));
  }

  openDocumentViewer$(attachment: IAttachment): Observable<unknown> {
    return this._dialogController
      .open(DocumentViewerComponent, {
        data: { msg: 'Errors have occured', attachment },
        autoFocus: false,
        disableClose: false,
        panelClass: 'cdk-overlay-pane-p-0',
        maxWidth: 640,
        width: '100%',
      })
      .afterClosed()
      .pipe();
  }

  openPrepareForPayment$(): Observable<unknown> {
    return this._dialogController
      .open(PrepareForPaymentComponent, {
        data: {},
        panelClass: 'modal-dialog',
        autoFocus: false,
        disableClose: true,
        maxWidth: 560,
        width: '100%'
      })
      .afterClosed()
      .pipe();
  }

  /**
   * open locations dialog
   * @param options mode='edit' -> default mode, used during creating remittance, mode='before-start' - dialog mode before sending
   */
  openLocations$(options?: { mode?: 'edit' | 'before-start' }): Observable<unknown> {
    const mode: 'edit' | 'before-start' = options?.mode ?? 'edit';
    const dialogData: { showSkipButton: boolean, showCloseConfirmation: boolean } = {
      showCloseConfirmation: true,
      showSkipButton: false,
    };
    if (mode === 'before-start') {
      dialogData.showCloseConfirmation = false;
      dialogData.showSkipButton = true;
    }
    return this._dialogController
      .open(LocationsComponent, {
        data: dialogData,
        autoFocus: false,
        disableClose: true,
        width: '100%',
        maxWidth: 1032,
        panelClass: 'mat-dialog-fullscreen-on-mobile'
      })
      .afterClosed()
      .pipe();
  }

  openIdenfyIframe$(url: string): Observable<IFrameResponseMessage | null> {
    return this._identifyIframeService.openIdenfyIframe$(url);
  }

  openIdenfyResults$(message: string): Observable<unknown> {
    return this._identifyIframeService.openIdenfyResults$(message);
  }

  openCountryChange$(): Observable<boolean> {
    return this._dialogController
      .open(ChangeCountryComponent, {
        data: {},
        autoFocus: false,
        disableClose: true,
        maxWidth: 560,
        width: '100%'
      })
      .afterClosed()
      .pipe();
  }

  openLogOut$(): Observable<boolean> {
    return this._dialogController
      .open(LogoutDialogComponent, {
        data: {},
        autoFocus: false,
        disableClose: true,
        maxWidth: '420px',
        width: '100%'
      })
      .afterClosed()
      .pipe(take(1));
  }

  openRateHasChanged$(): Observable<boolean> {
    return this._dialogController
      .open(RateHasChangedComponent, {
        maxWidth: 560,
        width: '100%'
      })
      .afterClosed().pipe();
  }
}
