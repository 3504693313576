import { Query, Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IBeneficiaryAdditionalFieldsForm } from './beneficiary-additional-fields.model';

export function createInitialState(): IBeneficiaryAdditionalFieldsForm {
  return {
    beneficiaryPan: '',
    beneficiaryIDType: null,
    beneficiaryIDNumber: '',
    beneficiaryAccountNumber: '',
    beneficiaryCitizenship: null,
    beneficiaryBankCode: null,
    beneficiaryBankBranchCode: null,
    beneficiaryAccountType: null,
    beneficiaryBankBic: '',
    beneficiaryBankBranchBic: '',
  };
}

@StoreConfig({ name: 'beneficiary-additional-fields-form' })
export class BeneficiaryAdditionalFieldsFormStore extends Store<IBeneficiaryAdditionalFieldsForm> {
  constructor() {
    super(createInitialState());
  }

  public setAdditionalField(val: Partial<IBeneficiaryAdditionalFieldsForm>): Observable<void> {
    return of(void 0)
      .pipe(
        tap(() => {
          this.update((x: IBeneficiaryAdditionalFieldsForm) => ({ ...x, ...val }));
        }));
  }
}

@Injectable({ providedIn: 'root' })
export class BeneficiaryAdditionalFieldsFormQuery extends Query<IBeneficiaryAdditionalFieldsForm> {

  state$$: Observable<IBeneficiaryAdditionalFieldsForm> = this.select(x => x);

  constructor(protected override store: BeneficiaryAdditionalFieldsFormStore) {
    super(store);
  }
}
