import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ILookupInt, ILookupStr } from '../../../root/private/private-models/lookup-str';

@Component({
  selector: 'app-button-toggle-group',
  templateUrl: 'ff-button-toggle-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FfButtonToggleGroupComponent {
  @Input() items: ILookupInt[] | ILookupStr[];
  @Input() activeDirectionId: string;
  @Input() disabled: boolean = false;
  @Input() multiple: boolean = false;
  @Input() vertical: boolean = false;
  @Input() selected: boolean;
  @Input() disableRipple: boolean = false;
  @Input() checked: boolean = false;

  @Output() clickEvent: EventEmitter<string> = new EventEmitter<string>();

  onClick(id: string): void {
    this.clickEvent.emit(id);
  }
}
