import { NgModule } from '@angular/core';
import { FfpgErrorHelper } from './root/private/private-pages/send/containers/payment-gateway/ff-pg/ffpg-error.helper';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { akitaDevtools } from '@datorama/akita';
import { NgxMaskModule } from 'ngx-mask';
import { environment } from '../environments/environment';
import { DeviceInfoModule } from './data-modules/device-info/device-info.module';
import { PersistModule } from './data-modules/persist/persist.module';
import { UserSessionsSignalRService } from './data-modules/user-sessions-signalr.service';
import { UtmMarksService } from './data-modules/utm-marks/utm-marks.service';
import { VersionCheckService } from './data-modules/version-check.service';
import { VersionModule } from './data-modules/version/version.module';
import { AppInitializerModule } from './functionality-modules/app-initializer/app-initializer.module';
import { GoogleAnalyticsModule } from './functionality-modules/google-analytics/google-analytics.module';
import { HttpModule } from './functionality-modules/http/http.module';
import { OnlineAppComponent } from './online-app.component';
import {
  ResidenceConfirmationsQuery,
  ResidenceConfirmationsStore
} from './root/private/private-pages/profile/additional-documents/store/residence-confirmations.store';
import {
  SourceOfIncomeStore,
  SourcesOfIncomeQuery,
} from './root/private/private-pages/profile/additional-documents/store/source-of-income.store';
import {
  IdentityDocumentsQuery,
  IdentityDocumentsStore,
} from './root/private/private-pages/profile/identity-documents/store/identity-documents.store';
import { PersonalDataFormService } from './root/private/private-pages/profile/personal-data/personal-data-form.service';
import {
  PersonalDataFormQuery,
  PersonalDataFormStore,
} from './root/private/private-pages/profile/personal-data/personal-data.store';
import { PasswordService } from './root/private/private-pages/security/containers/authentication/password/password.service';
import {
  PasswordQuery,
  PasswordStore,
} from './root/private/private-pages/security/containers/authentication/password/password.store';
import { TwoFactorService } from './root/private/private-pages/security/containers/authentication/two-factor/two-factor.service';
import {
  TwoFactorQuery,
  TwoFactorStore,
} from './root/private/private-pages/security/containers/authentication/two-factor/two-factor.store';
import { SecuritySettingsService } from './root/private/private-pages/security/services/security-settings.service';
import {
  SecuritySettingsQuery,
  SecuritySettingsStore,
} from './root/private/private-pages/security/store/security.store';
import { SendService } from './root/private/private-pages/send/containers/send/send.service';
import { SendQuery, SendStore } from './root/private/private-pages/send/store/send.store';
import { ProfileFlagsService } from './root/private/private-store/profile-flags.service';
import { ProfileService } from './root/private/private-store/profile.service';
import { ProfileQuery, ProfileStore } from './root/private/private-store/profile.store';

import { LocationsService } from './functionality-modules/dialog-windows/locations/locations.service';
import { LocationsQuery, LocationsStore } from './functionality-modules/dialog-windows/locations/locations.store';
import { IdenfyResultsQuery, IdenfyResultsStore } from './root/private/private-store/idenfy-results.store';

import {
  BeneficiaryAdditionalFieldsFormQuery,
  BeneficiaryAdditionalFieldsFormStore,
} from './root/private/private-pages/send/containers/beneficiary/beneficiary-additional-fields/beneficiary-additional-fields.store';
import { BeneficiaryAddressService } from './root/private/private-pages/send/containers/beneficiary/beneficiary-address/beneficiary-address.service';
import {
  BeneficiaryAddressQuery,
  BeneficiaryAddressStore,
} from './root/private/private-pages/send/containers/beneficiary/beneficiary-address/beneficiary-address.store';
import { BeneficiaryGeneralService } from './root/private/private-pages/send/containers/beneficiary/beneficiary-general/beneficiary-general.service';
import {
  BeneficiaryGeneralFormQuery,
  BeneficiaryGeneralFormStore,
} from './root/private/private-pages/send/containers/beneficiary/beneficiary-general/beneficiary-general.store';
import { DraftService } from './root/private/private-pages/send/containers/draft/draft.service';

import { BeneficiaryAdditionalFieldsService } from './root/private/private-pages/send/containers/beneficiary/beneficiary-additional-fields/beneficiary-additional-fields.service';
import { BeneficiaryService } from './root/private/private-pages/send/containers/beneficiary/beneficiary.service';
import {
  MyBeneficiaryQuery,
  MyBeneficiaryStore,
} from './root/private/private-pages/send/containers/beneficiary/beneficiary.store';
import { MyBeneficiariesService } from './root/private/private-pages/send/containers/beneficiary/my-beneficiaries/my-beneficiaries.service';
import { SaveTemplatePanelService } from './root/private/private-pages/send/containers/save-template-panel/save-template-panel.service';
import {
  SaveTemplatePanelQuery,
  SaveTemplatePanelStore,
} from './root/private/private-pages/send/containers/save-template-panel/save-template-panel.store';
import { ProfileAdditionalAttributesService } from './root/private/private-store/profile-additional-attributes.service';
import {
  ProfileAdditionalAttributesQuery,
  ProfileAdditionalAttributesStore,
} from './root/private/private-store/profile-additional-attributes.store';

import { EFileType, LocalizationModule, LocalizationModuleConfig } from '@ff/localization';
import packageInfo from '../../package.json';
import { AuthModule } from './data-modules/auth/auth.module';
import { AuthenticationCountriesModule } from './data-modules/authentication-countries/authentication-countries.module';
import { BankAccountModule } from './data-modules/bank-account-types/bank-account-types.module';
import { BankBranchesModule } from './data-modules/bank-branches/bank-branches.module';
import { BanksModule } from './data-modules/banks/banks.module';
import { CalculatorModule } from './data-modules/calculator/calculator.module';
import { CitizenshipCountriesModule } from './data-modules/citizenship-countries/citizenship-countries.module';
import { CountriesModule } from './data-modules/countries/countries.module';
import { CurrenciesDestinationModule } from './data-modules/currencies-destination/currencies-destination.module';
import { CurrenciesSourceModule } from './data-modules/currencies-source/currencies-source.module';
import { DirectionCitiesModule } from './data-modules/direction-cities/direction-cities.modules';
import { DocTypesIdentityModule } from './data-modules/doctypes-identity/doctypes-identity.module';
import { DocTypesResidenceConfirmationModule } from './data-modules/doctypes-residence-confirmation/doctypes-residence-confirmation.module';
import { DocTypesSourceOfIncomeModule } from './data-modules/doctypes-source-income/doctypes-source-income.module';
import { GatewaysModule } from './data-modules/gateways/gateways.module';
import { IdIssuedCountriesModule } from './data-modules/id-issued-countries/id-issued-countries.module';
import { LogoutService } from './data-modules/logout.service';
import { MyReceiversModule } from './data-modules/my-receivers/my-receiver.module';
import { PointsModule } from './data-modules/points/points.module';
import { RemittanceCountriesModule } from './data-modules/remittance-countries/remittance-countries.module';
import { RequiredDocumentsModule } from './data-modules/required-documents/required-documents.module';
import { RequiredFieldsModule } from './data-modules/required-fields/required-fields.module';
import { ResidenceCountriesModule } from './data-modules/residence-countries/residence-countries.module';
import { TransferPurposesModule } from './data-modules/transfer-purposes/transfer-purposes.module';
import { WorkflowsModule } from './data-modules/workflows/workflows.module';
import { DialogModule } from './functionality-modules/dialog-windows/dialog.module';
import { FreshchatModule } from './functionality-modules/freshchat/freshchat.module';
import { SnackbarModule } from './functionality-modules/snackbars/snackbar.module';
import { DashboardHistoryService } from './root/private/private-pages/dashboard/store/dashboard-history.service';
import {
  DashboardHistoryQuery,
  DashboardHistoryStore,
} from './root/private/private-pages/dashboard/store/dashboard-history.store';
import { DashboardReceiversService } from './root/private/private-pages/dashboard/store/dashboard-receivers.service';
import {
  DashboardReceiversQuery,
  DashboardReceiversStore,
} from './root/private/private-pages/dashboard/store/dashboard-receivers.store';
import { HistoryCountriesService } from './root/private/private-pages/history/store/history-countries.service';
import {
  HistoryCountriesQuery,
  HistoryCountriesStore,
} from './root/private/private-pages/history/store/history-countries.store';
import { HistoryCurrenciesService } from './root/private/private-pages/history/store/history-currencies.service';
import {
  HistoryCurrenciesQuery,
  HistoryCurrenciesStore,
} from './root/private/private-pages/history/store/history-currencies.store';
import { HistoryRecipientsService } from './root/private/private-pages/history/store/history-recipients.service';
import {
  HistoryRecipientsQuery,
  HistoryRecipientsStore,
} from './root/private/private-pages/history/store/history-recipients.store';
import { HistoryService } from './root/private/private-pages/history/store/history.service';
import { HistoryQuery, HistoryStore } from './root/private/private-pages/history/store/history.store';
import { AdditionalDocumentsService } from './root/private/private-pages/profile/additional-documents/additional-documents.service';
import { IdentityDocumentService } from './root/private/private-pages/profile/identity-documents/identity-document/identity-document.service';
import { NewIdentityDocumentService } from './root/private/private-pages/profile/identity-documents/new-identity-document/new-identity-document.service';
import { ReceiverEditService } from './root/private/private-pages/receivers/store/receiver-edit.service';
import { ReceiverEditQuery, ReceiverEditStore } from './root/private/private-pages/receivers/store/receiver-edit.store';
import { ReceiversService } from './root/private/private-pages/receivers/store/receivers.service';
import { ReceiversQuery, ReceiversStore } from './root/private/private-pages/receivers/store/receivers.store';
import { CheckoutPaymentService } from './root/private/private-pages/send/containers/payment-gateway/checkout/checkout-payment.service';
import { FFPGSignalRService } from './root/private/private-pages/send/containers/payment-gateway/ff-pg/ff-pg-signalr.service';
import { Transact365Service } from './root/private/private-pages/send/containers/payment-gateway/transact365/transact365.service';
import { SendAutofillService } from './root/private/private-pages/send/containers/send/send.autofill.service';
import { SecureTradingGuard } from './root/private/private-pages/send/secure-trading.guard';
import { TemplatesListService } from './root/private/private-pages/templates/containers/templates-list/templates-list.service';
import { TemplatesListStore } from './root/private/private-pages/templates/containers/templates-list/templates-list.store';

import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import * as defaultLocalizationJson from './../assets/localizations/_default.localization.json';
import { DarkThemeSwitchModule } from './functionality-modules/form-fields/darktheme-switch/dark-theme-switch.module';
import { HistoryFilterService } from './root/private/private-pages/history/store/history-filter.service';
import { SumsubModule } from './functionality-modules/dialog-windows/sumsub-websdk/sumsub.module';
import { IdentityDocumentsService } from './root/private/private-pages/profile/identity-documents/identity-documents.service';
import {
  ExtraDocumentsQuery,
  ExtraDocumentsStore
} from './root/private/private-pages/profile/additional-documents/store/extra-documents.store';
import { DraftQuery, DraftStore } from './root/private/private-pages/send/containers/draft/draft.store';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS, MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MAT_DATEPICKER_SCROLL_STRATEGY, MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY } from '@angular/material/datepicker';
import { Overlay } from '@angular/cdk/overlay';
import { FF_MATERIAL_CONTROLS_CONFIG_TOKEN, FFMaterialControlsConfig } from '@ff/material-controls/core/configs';
import { FFMaterialControlsConfigService } from '@ff/material-controls/core/services';

const FF_CONTROL_DATE_FORMAT = environment.appSettings.controls.dateFormat;

const materialControlsConfig: FFMaterialControlsConfig = {
  floatLabel: environment.appSettings.controls.floatLabel,
  appearance: environment.appSettings.controls.appearance,
  tooltipDuration: environment.appSettings.tooltip.duration,
  tooltipThrottleTime: environment.appSettings.tooltip.throttleTime,
  autocompleteDebounceTime: environment.appSettings.controls.inputDebounceTime,
  filtersConfig: {
    numeric: '%[text-input.filters.numeric]%',
    alphaSpace: '%[text-input.filters.alpha-space]%',
    alphaNumeric: '%[text-input.filters.alpha-numeric]%',
    alphaNumericNoSpace: '%[text-input.filters.alpha-numeric-no-space]%',
    alphaNumericSpace: '%[text-input.filters.alpha-numeric-space]%',
    alphaNumericSymbols: '%[text-input.filters.alpha-numeric-symbols]%',
    alphaNumericDashSpace: '%[text-input.filters.latin-number-dash-space]%',
    alphaNumericDashSpaceCommaSlash: '%[text-input.filters.latin-number-dash-space-comma-slash]%',
    alphaCyrillicNumeric: '%[text-input.filters.latin-cyrillic-numbers]%',
    email: '%[text-input.filters.email]%',
    cityStreetBuilding: '%[text-input.filters.building]%',
    names: '%[text-input.filters.names]%',
  }
};

if (!environment.production) {
  akitaDevtools();
}

const defaultErrorMappings = {
  required: '%[global.error-messages.field-required]%',
  pattern: '%[global.error-messages.field-pattern]%',
  minlength: '%[global.error-messages.field-minlength]%',
  maxlength: '%[global.error-messages.field-maxlength]%',
  requiredTrue: '%[global.error-messages.field-required-true]%',
  email: '%[global.error-messages.field-email]%',
  max: '%[global.error-messages.field-max]%',
  min: '%[global.error-messages.field-min]%',
  noPasswordMatch: '%[global.error-messages.field-passwords-do-not-match]%',
  dateNotLaterThan: '%[global.error-messages.field-date-not-later-than]%',
  dateNotEarlierThan: '%[global.error-messages.field-date-not-earlier-than]%',

  // material controls errors
  matDatepickerMin: '%[global.error-messages.material.datepicker-min]%',
  matDatepickerMax: '%[global.error-messages.material.datepicker-max]%',
  matDatepickerFilter: '%[global.error-messages.material.datepicker-filter]%',
  matDatepickerParse: '%[global.error-messages.material.datepicker-parse]%',
};

// localizations config
const localizationConfig: LocalizationModuleConfig = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultLocalizations: (defaultLocalizationJson as any)?.default,
  defaultLocalizationKeys: defaultErrorMappings,
  localizationFilesUrl: '/assets/localizations',
  replaceValueWithDefaultLocalizationIfEmptyString: true,
  urlSalt: packageInfo.version,
  fileType: EFileType.json
};

const routes: Routes = [
  { path: '', loadChildren: () => import('./root/root.module').then((m) => m.RootModule) },
  {
    path: 'payment-awaiting',
    loadChildren: () => import('./payment-awaiting/payment-awaiting.module').then((m) => m.PaymentAwaitingModule),
  }
];

@NgModule({
  imports: [
    BrowserModule,
    HttpModule,
    FreshchatModule,
    AuthenticationCountriesModule,
    SnackbarModule,
    AppInitializerModule,
    BrowserAnimationsModule,
    VersionModule,
    PersistModule,
    MatSnackBarModule,
    LocalizationModule.forRoot(localizationConfig),
    DeviceInfoModule,
    NgxMaskModule.forRoot(),
    NgxPageScrollCoreModule,
    GoogleAnalyticsModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'ignore',
      preloadingStrategy: PreloadAllModules,
      enableTracing: false,
      relativeLinkResolution: 'legacy',
    }),
    CitizenshipCountriesModule,
    IdIssuedCountriesModule,
    RemittanceCountriesModule,
    CountriesModule,
    ResidenceCountriesModule,
    RequiredDocumentsModule,
    CurrenciesSourceModule,
    CurrenciesDestinationModule,
    WorkflowsModule,
    GatewaysModule,
    DocTypesIdentityModule,
    DocTypesResidenceConfirmationModule,
    BankAccountModule,
    PointsModule,
    TransferPurposesModule,
    DirectionCitiesModule,
    BanksModule,
    BankBranchesModule,
    CalculatorModule,
    MyReceiversModule,
    RequiredFieldsModule,
    DocTypesSourceOfIncomeModule,
    AuthModule,
    DialogModule,
    DarkThemeSwitchModule,
    SumsubModule,
    MatLuxonDateModule
  ],
  exports: [],
  declarations: [OnlineAppComponent],
  bootstrap: [OnlineAppComponent],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: FF_CONTROL_DATE_FORMAT },
    { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATEPICKER_SCROLL_STRATEGY, deps: [Overlay], useFactory: MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY },
    { provide: FF_MATERIAL_CONTROLS_CONFIG_TOKEN, deps: [FFMaterialControlsConfigService], useValue: materialControlsConfig },
    LogoutService,
    UserSessionsSignalRService,
    ProfileService,
    ProfileQuery,
    ProfileStore,
    PersonalDataFormService,
    PersonalDataFormStore,
    PersonalDataFormQuery,
    ResidenceConfirmationsStore,
    ResidenceConfirmationsQuery,
    IdentityDocumentsStore,
    IdentityDocumentsQuery,
    SourceOfIncomeStore,
    SourcesOfIncomeQuery,
    ExtraDocumentsStore,
    ExtraDocumentsQuery,
    SendService,
    SendStore,
    SendQuery,
    ProfileFlagsService,
    IdenfyResultsStore,
    IdenfyResultsQuery,
    LocationsService,
    LocationsStore,
    LocationsQuery,
    DraftService,
    DraftStore,
    DraftQuery,
    BeneficiaryGeneralService,
    BeneficiaryGeneralFormStore,
    BeneficiaryGeneralFormQuery,
    BeneficiaryAddressService,
    BeneficiaryAddressStore,
    BeneficiaryAddressQuery,
    BeneficiaryAdditionalFieldsFormStore,
    BeneficiaryAdditionalFieldsFormQuery,
    BeneficiaryAdditionalFieldsService,
    BeneficiaryService,
    MyBeneficiariesService,
    MyBeneficiaryStore,
    MyBeneficiaryQuery,
    SaveTemplatePanelService,
    SaveTemplatePanelStore,
    SaveTemplatePanelQuery,
    UtmMarksService,
    VersionCheckService,
    SecuritySettingsService,
    SecuritySettingsStore,
    SecuritySettingsQuery,
    PasswordService,
    PasswordStore,
    PasswordQuery,
    TwoFactorService,
    TwoFactorStore,
    TwoFactorQuery,
    ProfileAdditionalAttributesService,
    ProfileAdditionalAttributesStore,
    ProfileAdditionalAttributesQuery,
    TemplatesListService,
    TemplatesListStore,
    ReceiversStore,
    ReceiversQuery,
    ReceiversService,
    ReceiverEditStore,
    ReceiverEditService,
    ReceiverEditQuery,
    DashboardHistoryStore,
    DashboardHistoryQuery,
    DashboardHistoryService,
    DashboardReceiversService,
    DashboardReceiversStore,
    DashboardReceiversQuery,
    HistoryCountriesService,
    HistoryCountriesStore,
    HistoryCountriesQuery,
    HistoryCurrenciesStore,
    HistoryCurrenciesQuery,
    HistoryCurrenciesService,
    HistoryRecipientsStore,
    HistoryRecipientsQuery,
    HistoryRecipientsService,
    HistoryService,
    HistoryFilterService,
    HistoryStore,
    HistoryQuery,
    SendAutofillService,
    CheckoutPaymentService,
    SecureTradingGuard,
    NewIdentityDocumentService,
    IdentityDocumentService,
    IdentityDocumentsService,
    AdditionalDocumentsService,
    Transact365Service,
    FFPGSignalRService,
    FfpgErrorHelper,
  ],
})
export class OnlineAppModule {}
