import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { BeneficiaryGeneralFormQuery, BeneficiaryGeneralFormStore } from './beneficiary-general.store';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MyBeneficiariesService } from '../my-beneficiaries/my-beneficiaries.service';
import { IBeneficiaryGeneralForm } from './beneficiary-general.model';
import { map, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { FormStoreMapper } from '../../../../../../../utils/helpers/form-store-mapper';
import { IMyReceiver } from '../../../../../../../data-modules/my-receivers/my-receivers.models';
import { IDocTypeValue } from '../../../../../../../data-modules/doctypes-identity/doctypes-identity.model';
import { SourcesOfIncomeQuery } from '../../../../profile/additional-documents/store/source-of-income.store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RequiredDocumentsQuery } from '../../../../../../../data-modules/required-documents/required-documents.store';
import { FFValidators, toIsoString } from '@ff/utils';
import { subYears } from 'date-fns';
import { environment } from '../../../../../../../../environments/environment';

@UntilDestroy()
@Injectable()
export class BeneficiaryGeneralService {

  beneficiaryGeneralForm: FormGroup = new FormGroup({
    name: new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$'), Validators.min(5)]),
    surname: new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$'), Validators.min(5)]),
    middleName: new FormControl(null),
    sex: new FormControl(null, [Validators.required]),
    dateOfBirth: new FormControl(null, [
      FFValidators.dateNotEarlierThan(() => toIsoString(subYears(new Date(), 100))),
      FFValidators.dateNotLaterThan(() => toIsoString(subYears(new Date(), 18)))]),
    phoneNumber: new FormControl(null),
    remittancePurpose: new FormControl(null, [Validators.required]),
    senderSourceOfIncomeType: new FormControl(null)
  });

  formMapper: FormStoreMapper = new FormStoreMapper(this.beneficiaryGeneralForm);

  constructor(
    private _beneficiaryGeneralFormStore: BeneficiaryGeneralFormStore,
    private _beneficiaryGeneralFormQuery: BeneficiaryGeneralFormQuery,
    private _myBeneficiariesService: MyBeneficiariesService,
    private _sourcesOfIncomeQuery: SourcesOfIncomeQuery,
    private _requiredDocumentsQuery: RequiredDocumentsQuery
  ) {
  }

  suckSoiValueFromAddDocs$(): Observable<void> {
    return of(void 0)
      .pipe(
        switchMap(() => combineLatest([
          this._requiredDocumentsQuery.isSourceOfIncomeRequired$$,
          this._sourcesOfIncomeQuery.isHasCustomerSourceOfIncomeBody$,
          this._sourcesOfIncomeQuery.isCustomerSourceOfIncomeExpired$,
          this._sourcesOfIncomeQuery.isHasCustomerSourceOfIncomeCopy$,
          this._sourcesOfIncomeQuery.isCustomerSourceOfIncomeCopyBad$
        ])), take(1),
        switchMap(([
                     isSourceOfIncomeTransactionLimitExceededOrAboutToBeExceeded,
                     isHasCustomerSourceOfIncomeBody,
                     isCustomerSourceOfIncomeExpired,
                     isHasCustomerSourceOfIncomeCopy,
                     isCustomerSourceOfIncomeCopyBad
                   ]) => {

          if (
            isSourceOfIncomeTransactionLimitExceededOrAboutToBeExceeded === true &&
            isHasCustomerSourceOfIncomeBody &&
            !isCustomerSourceOfIncomeExpired &&
            isHasCustomerSourceOfIncomeCopy &&
            !isCustomerSourceOfIncomeCopyBad
          ) {
            return this._sourcesOfIncomeQuery.firstValidSoi$.pipe(
              take(1),
              map((soi): IDocTypeValue | null => soi?.document.typeDocument ?? null),
              switchMap((value: IDocTypeValue | null) => this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({ senderSourceOfIncomeType: value }))
            );
          }

          return of(void 0);
        }),
      );
  }

  pdReact$$(): Observable<void> {
    return this._beneficiaryGeneralFormQuery.select().pipe(
      map((x: IBeneficiaryGeneralForm) => ({
        name: x.name,
        surname: x.surname,
        middleName: x.middleName,
        dateOfBirth: x.dateOfBirth,
        sex: x.sex,
        phoneNumber: x.phoneNumber,
        remittancePurpose: x.remittancePurpose,
        senderSourceOfIncomeType: x.senderSourceOfIncomeType,
      })),
      tap((value) => this.formMapper.patchValue(value)),
      switchMap(() => this._addOrRemoveValidatorOnSendersSoi$())
    );
  }

  pdFormUpdate(): void {
    this.formMapper.formChange<string>('name', (value: string) => this._setComplex$('name', value));
    this.formMapper.formChange<string>('surname', (value: string) => this._setComplex$('surname', value));
    this.formMapper.formChange<string>('middleName', (value: string) => this._setComplex$('middleName', value));
    this.formMapper.formChange<Date>('dateOfBirth', (value: Date) =>
      this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({ dateOfBirth: value })
    );
    this.formMapper.formChange<string>('sex', (value: string) =>
      this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({ sex: value })
    );
    this.formMapper.formChange<string>('phoneNumber', (value: string) =>
      this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({ phoneNumber: value })
    );
    this.beneficiaryGeneralForm.controls.remittancePurpose?.valueChanges.pipe(
      switchMap(value => this._beneficiaryGeneralFormStore.setComplexRemittancePurpose$(value)),
      untilDestroyed(this)
    ).subscribe();
    this.formMapper.formChange<IDocTypeValue>('senderSourceOfIncomeType', (value: IDocTypeValue) =>
      this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({ senderSourceOfIncomeType: value })
    );

  }

  private _setComplex$(fieldName: keyof IBeneficiaryGeneralForm, data: string | IMyReceiver): Observable<void> {
    if (typeof data === 'string') {
      const payload = {};
      // @ts-ignore TODO: fix this ignore
      payload[fieldName] = data.toUpperCase();
      return this._beneficiaryGeneralFormStore.setBeneficiaryFormField$(payload);
    }
    return this._myBeneficiariesService.setBeneficiary$$(data);
  }

  private _addOrRemoveValidatorOnSendersSoi$(): Observable<void> {
    return of(environment.appSettings.showInactiveBlock).pipe(
        take(1),
        tap((isSourceOfIncomeTransactionLimitExceededOrAboutToBeExceeded: boolean | null) => {
          if (isSourceOfIncomeTransactionLimitExceededOrAboutToBeExceeded === true) {
            this.beneficiaryGeneralForm.controls.senderSourceOfIncomeType?.setValidators([Validators.required]);
          } else {
            this.beneficiaryGeneralForm.controls.senderSourceOfIncomeType?.clearValidators();
          }
        }),
        mapTo(void 0)
      );
  }

}
