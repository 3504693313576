import { Injectable } from '@angular/core';
import { BeneficiaryGeneralService } from './beneficiary-general/beneficiary-general.service';
import { BeneficiaryAddressService } from './beneficiary-address/beneficiary-address.service';
import { BeneficiaryAdditionalFieldsService } from './beneficiary-additional-fields/beneficiary-additional-fields.service';

@Injectable()
export class BeneficiaryService {

  constructor(
    public beneficiaryGeneralService: BeneficiaryGeneralService,
    public beneficiaryAddressService: BeneficiaryAddressService,
    public beneficiaryAdditionalFieldsService: BeneficiaryAdditionalFieldsService,
  ) { }

}
