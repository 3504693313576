import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, mapTo, switchMap, take, tap } from 'rxjs/operators';

import { BeneficiaryAddressService } from '../beneficiary-address/beneficiary-address.service';
import { BeneficiaryAdditionalFieldsService } from '../beneficiary-additional-fields/beneficiary-additional-fields.service';
import { withTransaction } from '@datorama/akita';
import { MyBeneficiaryStore } from '../beneficiary.store';
import { BeneficiaryGeneralFormStore } from '../beneficiary-general/beneficiary-general.store';
import { GatewaysQuery } from '../../../../../../../data-modules/gateways/gateways.store';
import { CountriesService } from '../../../../../../../data-modules/countries/countries.service';
import { BanksQuery } from '../../../../../../../data-modules/banks/banks.store';
import { BankBranchesQuery } from '../../../../../../../data-modules/bank-branches/bank-branches.store';
import { IDetail, IDetailData, IMyReceiver } from '../../../../../../../data-modules/my-receivers/my-receivers.models';
import { ILookupStr } from '../../../../../private-models/lookup-str';
import { IBank } from '../../../../../../../data-modules/banks/banks.model';
import { IBankBranch } from '../../../../../../../data-modules/bank-branches/bank-branches.model';
import { BeneficiaryAdditionalFieldsFormStore } from '../beneficiary-additional-fields/beneficiary-additional-fields.store';
import { IBeneficiaryAdditionalFieldsForm } from '../beneficiary-additional-fields/beneficiary-additional-fields.model';

@Injectable()
export class MyBeneficiariesService {

  constructor(
    private _beneficiaryAddressService: BeneficiaryAddressService,
    private _beneficiaryAdditionalFieldsService: BeneficiaryAdditionalFieldsService,
    private _beneficiaryAdditionalFieldsFormStore: BeneficiaryAdditionalFieldsFormStore,
    private _gatewayQuery: GatewaysQuery,
    private _countriesService: CountriesService,
    private _banksQ: BanksQuery,
    private _bankBranchesQ: BankBranchesQuery,
    private _beneficiaryGeneralFormStore: BeneficiaryGeneralFormStore,
    private _myBeneficiaryStore: MyBeneficiaryStore
  ) { }

  setBeneficiary$$(beneficiary: IMyReceiver): Observable<void> {
    return of(void 0)
      .pipe(
        switchMap(() => combineLatest([
          this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({ id: beneficiary.id }),
          this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({ name: beneficiary.name }),
          this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({ surname: beneficiary.surname }),
          this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({ middleName: beneficiary.middleName }),
          this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({ dateOfBirth: beneficiary.dateOfBirth }),
          this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({ phoneNumber: beneficiary.phoneNumber }),
          this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({ sex: beneficiary.sex }),
        ])),
        switchMap(() => combineLatest([
          this._beneficiaryAddressService.updateAddressForm({ apartment: beneficiary.apartment }),
          this._beneficiaryAddressService.updateAddressForm({ building: beneficiary.building }),
          this._beneficiaryAddressService.updateAddressForm({ city: beneficiary.city }),
          this._beneficiaryAddressService.updateAddressForm({ street: beneficiary.street }),
          this._beneficiaryAddressService.updateAddressForm({ country: this._countriesService.transform(beneficiary.country) }),
          this._beneficiaryAddressService.updateAddressForm({ postCode: beneficiary.postCode })
        ])),
        withTransaction(() => {
          this._myBeneficiaryStore.update(x => ({ ...x, myBeneficiary: beneficiary }));
        }),
        map((): IDetailData[] => beneficiary.detailData),
      )
      .pipe(
        switchMap(data => {
          if (data == null) {
            return of(void 0);
          }

          return of(data)
            .pipe(
              switchMap((datas: IDetailData[]) => {
                return this._gatewayQuery.getDetailByDirection$$(datas);
              }),
              take(1),
              map((detailData: IDetailData | undefined): IDetail[] | undefined => detailData?.detail),
              switchMap((details: IDetail[] | undefined) => {
                if (details == null) {
                  this._beneficiaryAdditionalFieldsService.resetStore();
                  return of(void 0);
                }

                return of(void 0)
                  .pipe(
                    tap(() => {
                      for (const detail of details) {

                        if (detail.key === 'TransferPurpose') {
                          const purpose: ILookupStr = { id: detail.value as string, title: detail.value as string };
                          this._beneficiaryGeneralFormStore.setRemittancePurpose$(purpose).subscribe();
                        }
                        if (detail.key === 'BeneficiaryAccountNumber') {
                          const v: Partial<IBeneficiaryAdditionalFieldsForm> = { beneficiaryAccountNumber: detail.value as string };
                          this._beneficiaryAdditionalFieldsFormStore.setAdditionalField(v).subscribe();
                        }

                        if (detail.key === 'BeneficiaryPan') {
                          const v: Partial<IBeneficiaryAdditionalFieldsForm> = { beneficiaryPan: detail.value as string };
                          this._beneficiaryAdditionalFieldsFormStore.setAdditionalField(v).subscribe();
                        }

                        if (detail.key === 'BeneficiaryIDNumber') {
                          const v: Partial<IBeneficiaryAdditionalFieldsForm> = { beneficiaryIDNumber: detail.value as string };
                          this._beneficiaryAdditionalFieldsFormStore.setAdditionalField(v).subscribe();
                        }

                        if (detail.key === 'BeneficiaryBankBic') {
                          const v: Partial<IBeneficiaryAdditionalFieldsForm> = { beneficiaryBankBic: detail.value as string };
                          this._beneficiaryAdditionalFieldsFormStore.setAdditionalField(v).subscribe();
                        }

                        if (detail.key === 'BeneficiaryBankBranchBic') {
                          const v: Partial<IBeneficiaryAdditionalFieldsForm> = { beneficiaryBankBranchBic: detail.value as string };
                          this._beneficiaryAdditionalFieldsFormStore.setAdditionalField(v).subscribe();
                        }

                        if (detail.key === 'SourceOfIncome') {
                          this._beneficiaryAdditionalFieldsService.additionalFieldsFormGroup.controls
                            .senderSourceOfIncomeType?.setValue(detail.value);
                        }
                      }
                    }),
                    tap(() => {
                      for (const detail of details) {
                        if (detail.key === 'BeneficiaryBankCode') {
                          this._banksQ.selectAll().pipe(
                            filter(bs => bs.length > 0),
                            take(1),
                            // eslint-disable-next-line max-nested-callbacks
                            map((bs: IBank[]): IBank | undefined => bs.find(b => b.code === detail.value)),
                            tap((bs: IBank | undefined) => {
                              if (bs != null) {
                                const v: Partial<IBeneficiaryAdditionalFieldsForm> = { beneficiaryBankCode: bs };
                                this._beneficiaryAdditionalFieldsFormStore.setAdditionalField(v).subscribe();
                              }
                            }),
                            mapTo(void 0)
                          ).subscribe();
                        }
                        if (detail.key === 'BeneficiaryBankBranchCode') {
                          this._bankBranchesQ.selectAll().pipe(
                            filter(brs => brs.length > 0),
                            take(1),
                            // eslint-disable-next-line max-nested-callbacks
                            map((brs: IBankBranch[]): IBankBranch | undefined => brs.find(br => br.code === detail.value)),
                            tap((bs: IBank | undefined) => {
                              if (bs != null) {
                                const v: Partial<IBeneficiaryAdditionalFieldsForm> = { beneficiaryBankBranchCode: bs };
                                this._beneficiaryAdditionalFieldsFormStore.setAdditionalField(v).subscribe();
                              }
                            }),
                            mapTo(void 0)
                          ).subscribe();
                        }
                      }
                    }),
                    mapTo(void 0)
                  );
              })
            );
        }),
      );
  }

}
