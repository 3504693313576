import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { IAttachment } from '../../../../../private-models/attachment.model';

@Injectable()
export class ImageModalService {
  bufferedImage$: ReplaySubject<IAttachment | null> = new ReplaySubject<IAttachment | null>(1);
  isModalEdit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  flowType$: ReplaySubject<'camera' | 'file'> = new ReplaySubject(1);
}
