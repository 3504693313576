import { NgModule } from '@angular/core';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { environment } from '../../../environments/environment';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';


@NgModule({
  imports: [
    GoogleTagManagerModule.forRoot({
      id: environment.google.googleTagManagerId,
    }),
    NgxGoogleAnalyticsModule.forRoot(environment.google.googleAnalyticsId),
    NgxGoogleAnalyticsRouterModule,
  ],
  exports: [],
  declarations: [],
  providers: [],
})
export class GoogleAnalyticsModule {}
