<mat-dialog-content class="dialog-content">
  <div class="row">
    <div class="col-12">
      <p class="dialog-content__text">{{ data.text }}</p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="col-3"></div>
  <div class="col-6">
    <button mat-flat-button color="primary" class="info-btn" cdkFocusInitial [mat-dialog-close]="true">
      {{ '%[info-dialog.button.ok]%' | tr }}
    </button>
  </div>
  <div class="col-3"></div>
</mat-dialog-actions>
