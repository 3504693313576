import { Injectable } from '@angular/core';
import { IReceiverDetailDto, IReceiverDetailResults, ReceiversStore } from './receivers.store';
import { EMPTY, Observable, of } from 'rxjs';
import { withTransaction } from '@datorama/akita';
import { catchError, mapTo, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ReceiversService {
  constructor(
    private _receiversStore: ReceiversStore,
    private _http: HttpClient
  ) {}

  loadReceivers$(): Observable<void> {
    return of(void 0).pipe(
      withTransaction(() => {
        this._receiversStore.reset();
        this._receiversStore.setLoading(true);
      }),
      switchMap(() => this._http.get<IReceiverDetailResults>('receivers')),
      catchError(() => {
        return EMPTY;
      }),
      withTransaction(resp => {
        this._receiversStore.setLoading(false);
        this._receiversStore.upsertMany(resp?.results);
        if (resp?.results != null && resp?.results?.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this._receiversStore.setActive(resp.results[0]!.id);
        }
      }),
      mapTo(void 0)
    );
  }

  public updateActiveReceiver(state: IReceiverDetailDto): void {
    this._receiversStore.updateActive(st => ({
      ...st,
      name: state.name,
      surname: state.surname,
      middleName: state.middleName,
      sex: state.sex,
      phoneNumber: state.phoneNumber,
      dateOfBirth: state.dateOfBirth
    }));
  }

  public showMoreClick(): Observable<void> {
    return of(0).pipe(
      tap(() => this._receiversStore.update(x => ({ ...x, showAll: true }))),
      mapTo(void 0)
    );
  }

  setEdit$(state: boolean): Observable<void> {
    return of(void 0)
      .pipe(
        tap(() => this._receiversStore.update(x => ({ ...x, isEdit: state }))),
        mapTo(void 0)
      );
  }

  public setActiveReceiver$$(id: number): Observable<void> {
    return of(void 0)
      .pipe(
        tap(() => {
          this._receiversStore.setActive(id);
        }),
        mapTo(void 0)
      );
  }

}
