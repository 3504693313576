<div class="row locations-grid">
  <mat-accordion displayMode="flat">
    <mat-expansion-panel class="mat-expansion-panel-solid-border" hideToggle [disabled]="true">
      <mat-expansion-panel-header class="locations-grid__header">
        <mat-panel-title>
          <div class="row w-100">
            <div class="col-2 col-sm-1">
              <mat-checkbox
                *ngIf="isShowSelectedColumn$$ | async"
                [checked]="areAllPointsChecked$$ | async"
                [disabled]="areAllPartnerSelected$$ | async"
                (change)="onSelectedAllClickCheckbox()"
              >
              </mat-checkbox>
            </div>
            <div class="col-10 col-sm-11">
              <div class="row">
                <div class="col-12 col-sm-5 locations-grid__header-name">
                  {{ '%[location.table.head.partner-name]%' | tr }}
                </div>
                <div class="col-12 col-sm-7 locations-grid__header-address">
                  {{ '%[location.table.head.address]%' | tr }}
                </div>
              </div>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
    <ng-container *ngIf="(filteredPointsList$$ | async)?.length > 0; else notLocations">
      <div class="locations-grid__group" *ngIf="filteredPointsList$$ | async as filteredPoints">
        <mat-expansion-panel
          class="mat-expansion-panel-solid-border"
          *ngFor="let point of filteredPoints"
          [disabled]="isPointDisabled(point)"
          [expanded]="point.expanded"
          (afterCollapse)="onExpandOrCollapse(point?.pointId, 'COLLAPSE')"
          (afterExpand)="onExpandOrCollapse(point?.pointId, 'EXPAND')"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="row w-100">
                <div class="col-2 col-sm-1">
                  <mat-checkbox
                    *ngIf="isShowSelectedCheckbox$$ | async"
                    [checked]="point?.selected"
                    [disabled]="point?.disabled"
                    (click)="$event.stopPropagation()"
                    (keydown)="$event.stopPropagation()"
                    (change)="onSelectCheckbox(point?.pointId)"
                  ></mat-checkbox>
                </div>
                <div class="col-10 col-sm-11">
                  <div class="row">
                    <div class="col-12 col-sm-5 locations-grid__partner-name">{{ point?.partnerName }}</div>
                    <div class="col-12 col-sm-7 locations-grid__address">{{ point?.address?.fullAddress }}</div>
                  </div>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row locations-grid__expanded-body">
            <div class="col-2 col-sm-1"></div>
            <div class="col-10 col-sm-11">
              <div class="row">
                <div class="col-12 col-sm-5 locations-grid__contacts">{{ point?.contacts }}</div>
                <div class="col-12 col-sm-6 locations-grid__work-time">{{ point?.workTime }}</div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
    </ng-container>
    <ng-template #notLocations>
      <div class="no-results">
        <div class="no-results__content">
          <app-img-svg class="no-results__content-content-img" [url]="emptyListImageUrl$ | async"></app-img-svg>
          <p class="no-results__text">
            {{ '%[location.table.no-results]%' | tr }}
          </p>
        </div>
      </div>
    </ng-template>
  </mat-accordion>
</div>
