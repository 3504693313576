import { NgModule } from '@angular/core';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { SnackbarService } from './snackbar.service';
import { MatButtonModule } from '@angular/material/button';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { SnackbarTimerComponent } from './snackbar-timer/snackbar-timer.component';
import { CommonModule } from '@angular/common';
import { environment } from '../../../environments/environment';
import { SnackbarDarkmodeInfoComponent } from './snackbar-darkmode-info/snackbar-darkmode-info.component';


@NgModule({
  imports: [
    CommonModule,
    MatButtonModule
  ],
  exports: [SnackbarComponent, SnackbarTimerComponent, SnackbarDarkmodeInfoComponent],
  declarations: [SnackbarComponent, SnackbarTimerComponent, SnackbarDarkmodeInfoComponent],
  providers: [
    SnackbarService,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: environment.appSettings.snackbar.shortDuration,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      }
    },
  ],
})
export class SnackbarModule {}
