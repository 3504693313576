import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map, mapTo, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { PointsQuery, PointsStore } from './points.store';
import { IPoint, IPointsResponseModel } from './points.model';
import { applyTransaction, withTransaction } from '@datorama/akita';
import { IGateway } from '../gateways/gateways.model';
import { IRemittanceCountry } from '../remittance-countries/remittance-countries.model';
import { LocationsQuery, LocationsStore } from '../../functionality-modules/dialog-windows/locations/locations.store';
import { environment } from '../../../environments/environment';
import { CalculatorQuery } from '../calculator/calculator.store';
import { GatewaysQuery } from '../gateways/gateways.store';

export interface IGetPointsQueryParamsPartial {
  countryId: string,
  city: string,
  address: string
}

export interface IGetPointsQueryParamsFull extends IGetPointsQueryParamsPartial {
  directionId: string,
  offSet: number,
  pageSize: number
}

@Injectable()
export class PointsService {

  constructor(
    private _http: HttpClient,
    private _pointsQuery: PointsQuery,
    private _pointsStore: PointsStore,
    private _locationsStore: LocationsStore,
    private _locationsQuery: LocationsQuery,
    private _gatewaysQuery: GatewaysQuery,
    private _calculatorQuery: CalculatorQuery
  ) {
  }

  getPoints$(country: IRemittanceCountry, city: string, address: string, activeGateway?: IGateway | undefined): Observable<void> {
    const pageSize = environment.appSettings.payoutLocations.pagePointsSize;

    return of(void 0)
      .pipe(
        tap(() => {
          this._pointsStore.reset();
          this._pointsStore.setLoading(true);
        }),
        switchMap(() => {

          let params: IGetPointsQueryParamsPartial | IGetPointsQueryParamsFull;

          params = { countryId: country.id, city, address };

          if (activeGateway != null) {
            params = {
              countryId: country.id,
              city,
              address,
              directionId: activeGateway.directionId,
              offSet: 0,
              pageSize
            };
          }

          return this._http.get<IPointsResponseModel>('reference/points', { params: { ...params } })
            .pipe(
              catchError(() => {
                this._pointsStore.setLoading(false);
                return EMPTY;
              }),
            );
        }),
        map((response: IPointsResponseModel): IPointsResponseModel => ({
          totalCount: response.totalCount,
          points: response.points?.map(point => ({
            ...point,
            selected: false,
            expanded: false
          }))
        })),
        withTransaction((response: IPointsResponseModel) => {
          this._pointsStore.setLoading(false);
          this._pointsStore.upsertMany(response.points);
          this._pointsStore.update((st) => ({
            ...st,
            total: response.totalCount,
            offset: st.offset + response.points.length
          }));
        }),
        mapTo(void 0)
      );
  }

  updateMorePoints$(): Observable<void> {
    const pageSize = environment.appSettings.payoutLocations.pagePointsSize;
    return of(void 0).pipe(
      tap(() => this._pointsStore.setLoading(true)),
      withLatestFrom(
        this._calculatorQuery.select(x => x.destinationCountry),
        this._locationsQuery.select(x => x.city),
        this._locationsQuery.select(x => x.search),
        this._pointsQuery.select(x => x.offset),
        this._gatewaysQuery.selectActive()),
      switchMap(([_, destinationCountry, city, search, offset, allLocations]) => {
        return this._http.get<IPointsResponseModel>('reference/points', {
          params: {
            countryId: destinationCountry?.id ?? '',
            city: city?.title ?? '',
            address: search ?? '',
            directionId: allLocations?.directionId ?? '',
            offSet: offset + pageSize,
            pageSize
          }
        });
      }),
      withTransaction((serverResponse: IPointsResponseModel) => {
        const remappedData: IPoint[] = serverResponse.points;
        applyTransaction(() => {
          this._pointsStore.upsertMany(remappedData);
          this._pointsStore.update((st) => ({
            ...st,
            total: serverResponse.totalCount,
            offset: st.offset + serverResponse.points.length
          }));
          this._pointsStore.setLoading(false);
        });
      }),
      catchError(err => {
        this._pointsStore.setLoading(false);
        return throwError(err);
      }),
      mapTo(void 0)
    );
  }

  selectedAllPoints$(isCheckedAll: boolean): Observable<void> {
    return of(void 0)
      .pipe(
        switchMap(() => this._pointsQuery.selectAll()),
        take(1),
        map((points: IPoint[]) => points.map((point: IPoint) => ({ ...point, selected: isCheckedAll }))),
        withTransaction((points: IPoint[]) => {
          this._pointsStore.upsertMany(points);
          if (isCheckedAll !== true && this._pointsQuery.getAll().filter(x => x.selected).length === 0) {
            this._locationsStore.update({ allPartners: isCheckedAll });
          }
        }),
        mapTo(void 0)
      );
  }

  disabledAllPoints$(isDisabledAll: boolean): Observable<void> {
    return of(void 0)
      .pipe(
        switchMap(() => this._pointsQuery.selectAll()),
        take(1),
        map((points: IPoint[]) => {
          return points.map((point: IPoint) => ({
            ...point,
            disabled: isDisabledAll,
            selected: false,
          }));
        }),
        withTransaction(state => {
          this._pointsStore.upsertMany(state);
          this._locationsStore.update(st => ({ allPartners: isDisabledAll }));
        }),
        mapTo(void 0)
      )
      ;
  }


}
