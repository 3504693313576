<mat-dialog-content class="dialog-content mat-dialog-content-max-height-none">
  <div class="row">
    <div class="col-12">
      <p class="dialog-content__text">{{ data.message }}</p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="col-3"></div>
  <div class="col-6">
    <button mat-flat-button class="w-100" color="primary" cdkFocusInitial [mat-dialog-close]="true">
      {{ '%[idenfy-result.label.got-it]%' | tr }}
    </button>
  </div>
  <div class="col-3"></div>
</mat-dialog-actions>
