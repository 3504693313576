<mat-dialog-content class="mat-dialog-content-max-height-none dialog-content mat-dialog-content-pb-3 wizard-awaiting">
  <div class="row">
    <div class="col-12">
      <p mat-dialog-title class="wizard-awaiting__title">
        {{ '%[send.error.title.money-transfer-unavailable]%' | tr }}
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12 wizard-awaiting__image-container">
      <img class="wizard-awaiting__img" [src]="awaitingImageUrl$ | async" alt="" />
    </div>
    <span class="wizard-awaiting__text">
      {{ data.message }}
    </span>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="row flex-grow-1">
    <div class="col-12 wizard-awaiting__actions">
      <button mat-flat-button class="wizard-awaiting__actions__btn" (click)="onCloseClick()" color="primary">
        {{ '%[send.error.button.ok]%' | tr }}
      </button>
      <div class="col-sm-3 d-none d-sm-block"></div>
    </div>
  </div>
</mat-dialog-actions>
