import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Observable } from 'rxjs';
import { ICountry } from './countries.model';

export interface ICountriesState extends EntityState<ICountry, string> {
  filter: string
}

@Injectable()
@StoreConfig({ name: 'countries' })
export class CountriesStore extends EntityStore<ICountriesState> {

  constructor() {
    super({ filter: 'ALL' });
  }
}

@Injectable()
export class CountriesQuery extends QueryEntity<ICountriesState> {
  countries$$: Observable<ICountry[]> = this.selectAll();

  constructor(protected override store: CountriesStore) {
    super(store);
  }
}
