import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WorkflowsStore } from './workflows.store';
import { EMPTY, Observable, of } from 'rxjs';
import { IGetRemittanceCountryDirectionResponse, IWorkflow } from './workflows.models';
import { catchError, map, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { GatewaysStore } from '../gateways/gateways.store';
import { withTransaction } from '@datorama/akita';
import { CalculatorQuery } from '../calculator/calculator.store';
import { IRemittanceCountry } from '../remittance-countries/remittance-countries.model';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { PointsStore } from '../points/points.store';

@Injectable()
export class WorkflowsService {

  constructor(
    private _gatewaysStore: GatewaysStore,
    private _workflowsStore: WorkflowsStore,
    private _http: HttpClient,
    private pointsStore: PointsStore,
    private calculatorQuery: CalculatorQuery,
    private _googleAnalyticsService: GoogleAnalyticsService
  ) { }

  activateWorkflow$(workflow: IWorkflow): Observable<void> {
    return of(void 0).pipe(
      tap(() => this._workflowsStore.setActive(workflow.id)),
      tap(() => this.pointsStore.reset()),
      tap(() => this._gatewaysStore.set(workflow.gateways)),
      tap(() => this._googleAnalyticsService.event('choose', 'online', 'choose_method'))
    );
  }

  downloadAndStoreWorkflows$(): Observable<void> {
    return of(void 0).pipe(
      switchMap(() => this.calculatorQuery.select(x => x.destinationCountry)),
      take(1),
      map((destinationCountry: IRemittanceCountry | null): string => {
        if (destinationCountry == null) {
          throw new Error('destination Country is null');
        }
        return destinationCountry.id;
      }),
    ).pipe(
      withTransaction(_ => {
        this._gatewaysStore.reset();
        this._workflowsStore.setLoading(true);
      }),
      switchMap(countryId => {
        return this._http.get<IGetRemittanceCountryDirectionResponse>('reference/remittance-country-direction', { params: { country: countryId } })
          .pipe(
            catchError(() => {
              this._workflowsStore.setLoading(true);
              return EMPTY;
            })
          );
      }),
      withTransaction((response: IGetRemittanceCountryDirectionResponse) => {
        this._workflowsStore.setLoading(false);
        this._workflowsStore.set(response.workflows);
      }),
      mapTo(void 0)
    );
  }

}
