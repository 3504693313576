import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DarkThemeSwitchComponent } from './dark-theme-switch.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [DarkThemeSwitchComponent],
  declarations: [DarkThemeSwitchComponent],
  providers: [],
})
export class DarkThemeSwitchModule {}
