import { Injectable } from '@angular/core';
import { Query, Store, StoreConfig } from '@datorama/akita';
import { IUtmMarksModel } from './utm-marks.model';

interface IUtmMarksState {
  data: IUtmMarksModel | null
}

export function createInitialState(): IUtmMarksState {
  return {
    data: {
      utmSource: null,
      utmMedium: null,
      utmCampaign: null,
      utmTerm: null,
      utmContent: null
    }
  };
}

@StoreConfig({ name: 'utm-marks' })
@Injectable({ providedIn: 'root' })
export class UtmMarksStore extends Store<IUtmMarksState> {
  constructor() {
    super(createInitialState());
  }
}

@Injectable({ providedIn: 'root' })
export class UtmMarksQuery extends Query<IUtmMarksState> {

  constructor(protected override store: UtmMarksStore) {
    super(store);
  }
}
