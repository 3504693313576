import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SumsubService } from './sumsub.service';
import { DocDefinitions } from '@sumsub/websdk/types/types';

@Component({
  selector: 'app-sumsub-websdk',
  templateUrl: './sumsub-websdk.component.html',
  styleUrls: ['./sumsub-websdk.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SumsubWebsdkComponent implements OnInit {
  isIdWasScanned$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$$: Observable<boolean> = this._service.getLoading$();
  token$$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  language$$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  documentDefinitions$$: BehaviorSubject<DocDefinitions | null> = new BehaviorSubject<DocDefinitions | null>(null);
  hasToken$$: Observable<boolean> = this.token$$.pipe(
    map(token => token != null)
  );

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private _data: { token: string, language: string, documentDefinitions: DocDefinitions },
    private _dialogRef: MatDialogRef<SumsubWebsdkComponent>,
    private _service: SumsubService
  ) { }

  ngOnInit(): void {
    this._subscribeOnInit();
  }

  onCloseClick(): void {
    this._service.updateIsIdWasScanned(false);
    this._dialogRef.close();
  }

  onIdWasScanned(isIdWasScanned: boolean): void {
    of(void 0).pipe(
      tap(() => this._service.updateIsIdWasScanned(isIdWasScanned)),
      tap(() => this._dialogRef.close())
    ).subscribe();
  }

  onSumsubReady(isReady: boolean): void {
    this._service.updateLoading(!isReady);
  }

  private _subscribeOnInit(): void {
    this.token$$.next(this._data.token);
    this.language$$.next(this._data.language);
    this.documentDefinitions$$.next(this._data.documentDefinitions);
  }
}
