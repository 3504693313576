import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: 'snackbar-darkmode-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush

})

export class SnackbarDarkmodeInfoComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { message: string, title: string, icon: string, buttonLabel: string }, public snackbarRef: MatSnackBarRef<SnackbarDarkmodeInfoComponent>) { }

  onNoClick(): void {
    this.snackbarRef.dismiss();
  }

  onSubmitClick(): void {
    this.snackbarRef.dismissWithAction();
  }
}
