import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IBank } from './banks.model';

export interface IBanksState extends EntityState<IBank, string> {}

@Injectable()
@StoreConfig({ name: 'banks' })
export class BanksStore extends EntityStore<IBanksState> {
  constructor() {
    super();
  }
}

@Injectable()
export class BanksQuery extends QueryEntity<IBanksState> {

  banks$$: Observable<IBank[]> = this.selectAll();

  constructor(protected override store: BanksStore) {
    super(store);
  }
}
