import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { withTransaction } from '@datorama/akita';
import { catchError, map, mapTo, switchMap, tap } from 'rxjs/operators';
import {
  IEndChangeSettingsRequest,
  IEndChangeSettingsResponse,
  IStartChangeSettingsRequest,
  IStartChangeSettingsResponse
} from './two-factor.model';
import { IUserSettingsDto } from '../../../models/user-settings';
import { TwoFactorStore } from './two-factor.store';
import { HttpClient } from '@angular/common/http';
import { SecuritySettingsStore } from '../../../store/security.store';
import { PersistenceQuery } from '../../../../../../../data-modules/persist/persist.store';
import { LocalizationQuery } from '@ff/localization';
import { SnackbarService } from '../../../../../../../functionality-modules/snackbars/snackbar.service';

@Injectable()
export class TwoFactorService {

  constructor(
    private _securitySettingsStore: SecuritySettingsStore,
    private _persistenceQuery: PersistenceQuery,
    private _localizationQ: LocalizationQuery,
    private _snackbarService: SnackbarService,
    private _twoFactorStore: TwoFactorStore,
    private _http: HttpClient
  ) { }

  startChangeTwoFactor$(body: IStartChangeSettingsRequest): Observable<void> {
    return of(void 0)
      .pipe(
        withTransaction(() => this._twoFactorStore.update({
          isShowLoader: true,
          isSaveButtonEnabled: false,
          isFieldsEnabled: false
        })),
        switchMap(() => this._http.post<IStartChangeSettingsResponse>('security/start-change-settings', body)),
        catchError(() => {
          this._twoFactorStore.update({ isShowLoader: false, isSaveButtonEnabled: true, isFieldsEnabled: true });
          return EMPTY;
        }),
        tap(() => {
          this._twoFactorStore.update({ isShowLoader: false, isCodeFieldEnabled: true, isShowCodeField: true });
          return this._snackbarService.openInfoV2({
            message: this._localizationQ.transform('%[authentication.sms-send.message]%'),
            title: this._localizationQ.transform('%[authentication.sms-send.title]%')
          });
        }),
        mapTo(void 0)
      );
  }

  endChangeTwoFactor$(code: string): Observable<void> {
    return of(void 0)
      .pipe(
        tap(() => this._twoFactorStore.update({ isShowLoader: true, isCodeFieldEnabled: false })),
        map((): IEndChangeSettingsRequest => ({
          confirmCode: code,
          phone: this._persistenceQuery.getValue().phone
        })),
        switchMap((request: IEndChangeSettingsRequest) => this._http.post<IEndChangeSettingsResponse>('security/end-change-settings', request)),
        catchError(() => {
          this._twoFactorStore.update({ isShowLoader: false, isCodeFieldEnabled: true, code: '' });
          return EMPTY;
        }),
        tap(() => this._twoFactorStore.reset()),
        tap(() => {
          this._securitySettingsStore.update({ isEdit: false });
          return this._snackbarService.openSuccess(this._localizationQ.transform('%[security.label.settings-change.success]%'));
        }),
        mapTo(void 0)
      );
  }

  getUserSettings$(): Observable<void> {
    return of(void 0)
      .pipe(
        withTransaction(() => this._twoFactorStore.update({ isLoadingSettings: true })),
        switchMap(() => this._http.get<IUserSettingsDto>('security/user-settings')),
        catchError(() => {
          this._twoFactorStore.update({ isLoadingSettings: false });
          return EMPTY;
        }),
        tap((response: IUserSettingsDto) => this._twoFactorStore.update({
          isLoadingSettings: false,
          enableTwoFactor: response.twoFactorEnabled
        })),
        mapTo(void 0)
      );
  }
}
