import { ElementRef, Injectable, Renderer2 } from '@angular/core';
import { IFFCheckoutRetrievePaymentMethodsModel } from 'ff-checkout/models/payment-methods.model';
import { Observable, of, throwError } from 'rxjs';
import { IFFCheckoutRetrievePaymentMethodsResponse } from 'ff-checkout/models/http/retrieve-payment-methods.model';
import { combineQueries } from '@datorama/akita';
import { catchError, concatMap, filter, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { DeviceInfoQuery } from './device-info/device-info.store';
import { LanguagesQuery, LocalizationQuery } from '@ff/localization';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ThemeService } from './theme/theme.service';
import { SnackbarService } from '../functionality-modules/snackbars/snackbar.service';
import { PersistenceQuery } from './persist/persist.store';
import { PersistenceService } from './persist/persistence.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutPaymentInstrumentsService {

  constructor(
    private _deviceInfoQuery: DeviceInfoQuery,
    private _languageQuery: LanguagesQuery,
    private _http: HttpClient,
    private _themeService: ThemeService,
    private _localizationQuery: LocalizationQuery,
    private _snackbarService: SnackbarService,
    private _persistenceService: PersistenceService,
    private _persistenceQuery: PersistenceQuery
  ) { }

  getPayoutInstruments$(data: IFFCheckoutRetrievePaymentMethodsModel, baseApiUrl: string | null, innerIp: string): Observable<IFFCheckoutRetrievePaymentMethodsResponse> {
    let checkout: IFFCheckout = {} as IFFCheckout;
    let deviceId: string;
    let locale: string;
    return combineQueries([this._deviceInfoQuery.deviceId$$, this._languageQuery.selectActiveId()]).pipe(
      take(1),
      tap(([deviceId, lang]) => {
        deviceId = deviceId != null && deviceId !== '' ? deviceId : 'fingerprint_placeholder';
        locale = lang ?? 'en-gb';
      }),
      switchMap(() => this._http.get<string>('remittance/payment-token')),
      switchMap(token => {
        const baseUrl = `${environment.baseUrl}remittance/payment/redirect/${data.order.orderId}`;
        // Checkout init
        try {
          checkout = new FFCheckout(
            token,
            innerIp,
            deviceId,
            {
              cvcSecure: true,
              language: this._languageQuery.getActive()?.id ?? ''
            },
            {
              ffpgApiUrl: baseApiUrl,
              redirectFailUrl: `${baseUrl}?locale=${locale}&companyName=${this._themeService.activeThemeName()}`,
              redirectSuccessUrl: `${baseUrl}?locale=${locale}&companyName=${this._themeService.activeThemeName()}&status=OK`,
            }
          );
        } catch (error) {
          return throwError(error);
        }
        return checkout.retrievePaymentMethods(data);
      }),
      catchError(error => {
        return this._openApiError$(error);
      })
    );
  }

  checkAndLoadScript$(element: ElementRef, renderer: Renderer2): Observable<void> {
    return this._persistenceQuery.isCheckoutJSLoaded$$.pipe(
      take(1),
      switchMap(isCheckoutJSLoaded => isCheckoutJSLoaded ? of(void 0) : this._loadScript(environment.thirdPartyServices.checkoutScriptUrl, element, renderer)),
    );
  }

  private _loadScript(src: string, element: ElementRef, renderer: Renderer2): Observable<void> {
    return this._persistenceQuery.isCheckoutJSLoaded$$.pipe(
      filter(isCheckoutJSLoaded => isCheckoutJSLoaded === false),
      take(1),
      tap(() => {
        const script = renderer.createElement('script');
        script.src = src;
        script.type = 'text/javascript';
        script.async = true;
        script.onload = () => {
          this._persistenceService.updatePersistenceStore({ isCheckoutJSLoaded: true });
        };
        script.onerror = () => {
          const title = this._localizationQuery.transform('%[send.snackbar.title.load-script-failed]%');
          const message = this._localizationQuery.transform('%[send.snackbar.message.load-script-failed]%');
          this._snackbarService.openFailure$(`${message} checkout.`, { title }).subscribe();
        };
        renderer.appendChild(element.nativeElement, script);
      }),
      mapTo(void 0)
    );
  }

  private _openApiError$(error: HttpErrorResponse): Observable<never> {
    return this._snackbarService.openFailure$(this._parseServerError(error),
      { duration: environment.appSettings.snackbar.duration }).pipe(
      concatMap(() => throwError(error))
    );
  }

  private _parseServerError(errorMessage: HttpErrorResponse): string {
    if (errorMessage != null) {
      if (errorMessage?.error != null && errorMessage.error?.errors != null && errorMessage.error?.errors.length > 0) {
        return errorMessage.error.errors[0].message;
      }
      return errorMessage.message;
    } else {
      const message = this._localizationQuery.transform('%[stored-procedure.error.unknown-error]%');
      return message;
    }
  }
}
