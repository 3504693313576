import { EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IAmlQStoreAnswersDto,
  IAmlQStoreModel,
  IAmlStoreQuestionsModel
} from './aml-questionnaire-modal.model';

export function createInitialState(): IAmlQStoreModel {
  return {
    questions: [],
    answers: [] // formValue
  };
}

@StoreConfig({ name: 'aml-questionnaire-store' })
export class AmlQuestionnaireModalStore extends EntityStore<IAmlQStoreModel> {
  constructor() {
    super(createInitialState());
  }
}

@Injectable()
export class AmlQuestionnaireModalQuery extends QueryEntity<IAmlQStoreModel> {

  isLoading$$: Observable<boolean> = this.selectLoading();
  state$$: Observable<IAmlQStoreModel> = this.select(state => state);
  amlQuestions$$: Observable<IAmlStoreQuestionsModel[]> = this.select(state => state.questions);
  amlAnswers$$: Observable<IAmlQStoreAnswersDto[]> = this.select(state => state.answers);

  constructor(protected override store: AmlQuestionnaireModalStore) {
    super(store);
  }

}
