/* eslint-disable change-detection-strategy/on-push */
import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Inject,
  InjectionToken,
  OnChanges,
  SimpleChanges, Optional
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';
import { nextOnChange } from '@ff/utils';

export const SPINNER_INJECTION_TOKEN = new InjectionToken<string>('SPINNER_INJECTION_TOKEN');

@UntilDestroy()
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent implements OnChanges {
  text$$: ReplaySubject<string> = new ReplaySubject<string>(1);

  @Input() color: 'primary' | 'accent' | 'warn' = 'primary';
  @Input() mode: 'determinate' | 'indeterminate' = 'indeterminate';
  @Input() value: number | null = null;
  @Input() diameter: number = 60;
  @Input() isFullScreen: boolean = false;
  @Input() text: string = '';

  constructor(
    @Inject(SPINNER_INJECTION_TOKEN) @Optional() data: string
  ) {
    this.text$$.next(data);
  }

  ngOnChanges(changes: SimpleChanges): void {
    nextOnChange(changes.text, this.text$$);
  }
}
