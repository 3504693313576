import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { concatMap, filter, take, tap } from 'rxjs/operators';
import { SecuritySettingsStore } from '../store/security.store';
import { Router } from '@angular/router';
import { SendQuery } from '../../send/store/send.store';
import { SendService } from '../../send/containers/send/send.service';

@Injectable()
export class SecuritySettingsService {

  constructor(
    private _securitySettingsStore: SecuritySettingsStore,
    private _router: Router,
    private _sendQuery: SendQuery,
    private _sendService: SendService
  ) {}

  setTwoFactorEnabled(val: boolean): Observable<void> {
    return of(void 0).pipe(
      tap(_ => {
        this._securitySettingsStore.update(st => ({ ...st, twoFactorEnabled: val }));
      }));
  }

  redirectToSend$(): Observable<void> {
    return from(this._router.navigate(['private', 'send'])).pipe(
      concatMap(() => this._sendQuery.select(x => x.isSendPageFullyLoaded)),
      filter(e => e === true), take(1),
      concatMap(() => this._sendService.scrollToFirstEmptyField$()),
      concatMap(() => this._securitySettingsStore.setWizard$(false))
    );
  }
}
