import { Query, Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IProfileAdditionalAttributes } from '../private-models/profile-additional-attributes';
import { Observable } from 'rxjs';

export function createInitialState(): IProfileAdditionalAttributes {
  return {
    dontNotifyBeReady: false,
    restoreId: null
  };
}

@StoreConfig({ name: 'profile-additional-attributes' })
export class ProfileAdditionalAttributesStore extends Store<IProfileAdditionalAttributes> {
  constructor() {
    super(createInitialState());
  }
}

@Injectable()
export class ProfileAdditionalAttributesQuery extends Query<IProfileAdditionalAttributes> {

  restoreId$$: Observable<string | null> = this.select(x => x.restoreId);
  isDontNotifyBeReady$$: Observable<boolean> = this.select(x => x.dontNotifyBeReady);
  constructor(
    protected override store: ProfileAdditionalAttributesStore,
  ) {
    super(store);
  }
}
