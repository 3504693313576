import { NgModule } from '@angular/core';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FfButtonToggleGroupComponent } from './ff-button-toggle-group.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    MatCheckboxModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    CommonModule
  ],
  exports: [FfButtonToggleGroupComponent],
  declarations: [FfButtonToggleGroupComponent]
})
export class FfButtonToggleGroupModule {}
