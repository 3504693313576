import { Injectable } from '@angular/core';
import { Query, Store, StoreConfig } from '@datorama/akita';
import {
  AdditionalDocsFlags,
  BankAccountOwnershipFlags,
  EmailValidatePossessionFlags,
  IAdditionalDocsFlagsType, IBankAccountOwnershipType,
  IdentityDocumentsFlags,
  IdentityDocumentsFlagsType,
  EIdentityDocumentVerificationFlags,
  IIdentityDocumentsVerificationFlagsType,
  IKYCQuestionnaireFlagsType,
  IRequiredDocumentsState,
  KYCQuestionnaireFlags,
  PersonalDataFlags,
  EVerificationFlags
} from './required-documents.model';
import { Observable } from 'rxjs';
import { FFObject } from '@ff/utils';

export function createInitialState(): IRequiredDocumentsState {
  return {
    isMockDataUsed: false,
    refusedService: null,
    refusedToSend: null,
    readonlyAccess: null,
    bankAccountOwnership: null,
    personalData: null,
    identityDocument: null,
    identityDocumentVerification: null,
    kycQuestionnaire: null,
    proofOfAddress: null,
    sourceOfIncome: null,
    extraDocuments: null,
    emailValidatePossession: null
  };
}

@Injectable()
@StoreConfig({ name: 'required-documents' })
export class RequiredDocumentsStore extends Store<IRequiredDocumentsState> {

  constructor() {
    super(createInitialState());
  }

}

@Injectable()
export class RequiredDocumentsQuery extends Query<IRequiredDocumentsState> {
  isMockDataUsed$$: Observable<boolean> = this.select(state => state.isMockDataUsed);
  requiredDocumentsState$$: Observable<IRequiredDocumentsState> = this.select(state => state);
  isCustomerDataAllowEditing$$: Observable<boolean> = this.select(state => isFlagsEqual(state.personalData, PersonalDataFlags.AllowEditing));
  isCustomerDataDisallowEditing$$: Observable<boolean> = this.select(state => isFlagsEqual(state.personalData, PersonalDataFlags.DisallowEditing));
  isPersonalDataRequired$$: Observable<boolean> = this.select(state => isFlagsEqual(state.personalData, PersonalDataFlags.Required));
  isServiceRefused$$: Observable<boolean | null> = this.select(state => state.refusedService);
  isReadonlyAccess$$: Observable<boolean | null> = this.select(state => state.readonlyAccess);
  isSendingRefused$$: Observable<boolean | null> = this.select(state => state.refusedToSend);
  isIdentityDocumentRequired$$: Observable<boolean> = this.select(state => isFlagsEqual(state.identityDocument, IdentityDocumentsFlags.Required)
    || isFlagsEqual(state.identityDocument, IdentityDocumentsFlags.Expired));

  identityDocument$$: Observable<IdentityDocumentsFlagsType | null> = this.select(state => state.identityDocument);
  isIdentityDocumentExpired$$: Observable<boolean> = this.select(state => isFlagsEqual(state.identityDocument, IdentityDocumentsFlags.Expired));
  isIdentityDocumentAllowEditing$$: Observable<boolean> = this.select(state => isFlagsEqual(state.identityDocument, IdentityDocumentsFlags.AllowEditing));
  isIdentityDocumentDisallowEditing$$: Observable<boolean> = this.select(state => isFlagsEqual(state.identityDocument, IdentityDocumentsFlags.DisallowEditing));
  isPaymentAwaiting$$: Observable<boolean> = this.select(state => isFlagsEqual(state.bankAccountOwnership, BankAccountOwnershipFlags.Wait));
  isRequiredDocumentsLoaded$$: Observable<boolean> = this.select(state => FFObject.keys(state).map(key => state[key]).every(value => value != null));
  isKYCQuestionnaireRequiredOrExpired$$: Observable<boolean> = this.select(state => isFlagsEqual(state.kycQuestionnaire, KYCQuestionnaireFlags.RequiredBasic)
    || isFlagsEqual(state.kycQuestionnaire, KYCQuestionnaireFlags.RequiredExtended)
    || isFlagsEqual(state.kycQuestionnaire, KYCQuestionnaireFlags.Expired));

  kycQuestionnaire$$: Observable<IKYCQuestionnaireFlagsType | null> = this.select(state => state.kycQuestionnaire);
  isKYCQuestionnaireExpired$$: Observable<boolean> = this.select(state => isFlagsEqual(state.kycQuestionnaire, KYCQuestionnaireFlags.Expired));
  isKYCQuestionnaireRequiredBasic$$: Observable<boolean> = this.select(state => isFlagsEqual(state.kycQuestionnaire, KYCQuestionnaireFlags.RequiredBasic));
  isKYCQuestionnaireRequiredExtended$$: Observable<boolean> = this.select(state => isFlagsEqual(state.kycQuestionnaire, KYCQuestionnaireFlags.RequiredExtended));

  isIDVerificationRequired$$: Observable<boolean> = this.select(state => isFlagsEqual(state.identityDocumentVerification, EIdentityDocumentVerificationFlags.Required));

  identityDocumentVerification$$: Observable<IIdentityDocumentsVerificationFlagsType | null> = this.select(state => state.identityDocumentVerification);
  isIDOnVerification$$: Observable<boolean> = this.select(state => isFlagsEqual(state.identityDocumentVerification, EVerificationFlags.OnVerification));
  isIDVerificationWait$$: Observable<boolean> = this.select(state => isFlagsEqual(state.identityDocumentVerification, EVerificationFlags.Wait));
  isIDVerificationSuccess$$: Observable<boolean> = this.select(state => isFlagsEqual(state.identityDocumentVerification, EVerificationFlags.Success));
  isProofOfAddressRequired$$: Observable<boolean> = this.select(state => isFlagsEqual(state.proofOfAddress, AdditionalDocsFlags.Required)
    || isFlagsEqual(state.proofOfAddress, AdditionalDocsFlags.NoAttach)
    || isFlagsEqual(state.proofOfAddress, AdditionalDocsFlags.Wait)
    || isFlagsEqual(state.proofOfAddress, AdditionalDocsFlags.BadAttach)
    || isFlagsEqual(state.proofOfAddress, AdditionalDocsFlags.Expired));

  proofOfAddress$$: Observable<IAdditionalDocsFlagsType | null> = this.select(state => state.proofOfAddress);
  isProofOfAddressOnVerification$$: Observable<boolean> = this.select(state => isFlagsEqual(state.proofOfAddress, AdditionalDocsFlags.OnVerification));
  isProofOfAddressWait$$: Observable<boolean> = this.select(state => isFlagsEqual(state.proofOfAddress, AdditionalDocsFlags.Wait));

  isSourceOfIncomeRequired$$: Observable<boolean> = this.select(state => isFlagsEqual(state.sourceOfIncome, AdditionalDocsFlags.Required)
    || isFlagsEqual(state.sourceOfIncome, AdditionalDocsFlags.NoAttach)
    || isFlagsEqual(state.sourceOfIncome, AdditionalDocsFlags.Wait)
    || isFlagsEqual(state.sourceOfIncome, AdditionalDocsFlags.BadAttach)
    || isFlagsEqual(state.sourceOfIncome, AdditionalDocsFlags.Expired));

  sourceOfIncome$$: Observable<IAdditionalDocsFlagsType | null> = this.select(state => state.sourceOfIncome);
  isSourceOfIncomeOnVerification$$: Observable<boolean> = this.select(state => isFlagsEqual(state.sourceOfIncome, AdditionalDocsFlags.OnVerification));
  isSourceOfIncomeWait$$: Observable<boolean> = this.select(state => isFlagsEqual(state.sourceOfIncome, AdditionalDocsFlags.Wait));

  isExtraDocumentsRequired$$: Observable<boolean> = this.select(state => isFlagsEqual(state.extraDocuments, AdditionalDocsFlags.Required)
    || isFlagsEqual(state.extraDocuments, AdditionalDocsFlags.NoAttach)
    || isFlagsEqual(state.extraDocuments, AdditionalDocsFlags.Wait)
    || isFlagsEqual(state.extraDocuments, AdditionalDocsFlags.BadAttach)
    || isFlagsEqual(state.extraDocuments, AdditionalDocsFlags.Expired));

  extraDocuments$$: Observable<IAdditionalDocsFlagsType | null> = this.select(state => state.extraDocuments);
  isExtraDocumentsOnVerification$$: Observable<boolean> = this.select(state => isFlagsEqual(state.extraDocuments, AdditionalDocsFlags.OnVerification));
  isExtraDocumentsWait$$: Observable<boolean> = this.select(state => isFlagsEqual(state.extraDocuments, AdditionalDocsFlags.Wait));

  isEmailValidatePossessionRequired$$: Observable<boolean> = this.select(state => isFlagsEqual(state.emailValidatePossession, EmailValidatePossessionFlags.Required));

  bankAccountOwnership$$: Observable<IBankAccountOwnershipType | null> = this.select(x => x.bankAccountOwnership);

  constructor(protected override store: RequiredDocumentsStore) {
    super(store);
  }
}

function isFlagsEqual(requiredDocumentFlag: unknown, flagToComparison: string): boolean {
  if (requiredDocumentFlag == null) {
    return false;
  }
  return typeof requiredDocumentFlag === 'string' ? requiredDocumentFlag.toLowerCase() === flagToComparison : false;
}
