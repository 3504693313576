import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Observable } from 'rxjs';
import { IRemittanceCountry } from '../../../../../data-modules/remittance-countries/remittance-countries.model';
import { ILookupStr } from '../../../private-models/lookup-str';
import { WorkflowType } from '../../../../../data-modules/workflows/workflows.store';
import { DocumentStatus } from '../../../private-models/document-status';


export interface IWorkflowItemJsonDto {
  onlineCode: WorkflowType,
  systemCode: string,
  priority: number
}

export interface IGetHistoryResultDto {
  id: string,
  date: string,
  directionCountry: IRemittanceCountry | null,
  receiverName: string | null,
  amount: number,
  amountCurrency: ILookupStr,
  ref: string | null,
  outerRef: string | null,
  documentStatus: { id: number, alias: DocumentStatus, description: string, name: string } | null,
  receiverGets: number | null,
  receiverGetsCurrency: ILookupStr | null,
  commission: number,
  exchangeRate: number | null,
  accountNumber: string | null,
  bankName: string | null,
  isRefundAllowed: boolean | null,
  workflow: IWorkflowItemJsonDto
}

export interface IHistoryResults {
  results: IGetHistoryResultDto[],
  totalCount: number
}

export interface IGetHistoryResultDto {
  id: string,
  transactionId: string,
  date: string,
  directionCountry: IRemittanceCountry | null,
  receiverName: string | null,
  amount: number,
  amountCurrency: ILookupStr,
  ref: string | null,
  outerRef: string | null,
  documentStatus: { id: number, alias: DocumentStatus, description: string, name: string } | null,
  receiverGets: number | null,
  receiverGetsCurrency: ILookupStr | null,
  commission: number,
  exchangeRate: number | null,
  accountNumber: string | null,
  bankName: string | null,
  workflow: IWorkflowItemJsonDto
}

export interface IDashboardHistoryState extends EntityState<IGetHistoryResultDto> {
  loading: boolean,
  ui: {
    lastClickedTransactionId: string | null
  }
}

const idKey: keyof IGetHistoryResultDto = 'id';

@StoreConfig({ name: 'dashboard-history', idKey })
@Injectable()
export class DashboardHistoryStore extends EntityStore<IDashboardHistoryState> {
  constructor() {
    super({
      loading: false,
      ui: {
        lastClickedTransactionId: null
      }
    });
  }

  updateTransactionId(transactionId: string | null): void {
    this.update({ ui: { lastClickedTransactionId: transactionId } });
  }
}

@Injectable()
export class DashboardHistoryQuery extends QueryEntity<IDashboardHistoryState> {
  lastClickedTransactionId$: Observable<string | null> = this.select(state => state.ui.lastClickedTransactionId);
  isLoading$$: Observable<boolean> = this.selectLoading();

  constructor(protected override store: DashboardHistoryStore) {
    super(store);
  }
}
