import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()

export class SvgService {

  constructor(private _sanitizer: DomSanitizer,
              private _http: HttpClient,
              _back: HttpBackend) {
    this._http = new HttpClient(_back);
  }

  getSvgImage$(url: string): Observable<SafeHtml> {
    return this._http.get(url, { responseType: 'text' }).pipe(
      map(x => {
        return this._sanitizer.bypassSecurityTrustHtml(x);
      })
    );
  }

}
