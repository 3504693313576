import { NgModule } from '@angular/core';
import { DeviceInfoService } from './device-info.service';
import { DeviceInfoQuery, DeviceInfoStore } from './device-info.store';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    DeviceInfoService,
    DeviceInfoStore,
    DeviceInfoQuery,
  ],
})
export class DeviceInfoModule {}
