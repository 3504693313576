import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { RateHasChangedService } from './rate-has-changed.service';
import { CalculatorQuery } from '../../../data-modules/calculator/calculator.store';

@Component({
  selector: 'app-rate-has-changed',
  styleUrls: ['rate-has-changed.component.scss'],
  templateUrl: 'rate-has-changed.component.html',
  providers: [RateHasChangedService],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class RateHasChangedComponent {

  showSourceCase: Observable<boolean> = this._calculatorQuery.select(x => x.amountKey === 0);
  showDestinationCase: Observable<boolean> = this._calculatorQuery.select(x => x.amountKey === 1);

  oldAmount$: Observable<number | null> = this._calculatorQuery.select(x => x.amountKey).pipe(
    take(1),
    switchMap((amountKey: 0 | 1) => {
      if (amountKey === 0) {
        return this._calculatorQuery.select(x => x.previousCalculationResult.destination.amount);
      } else {
        return this._calculatorQuery.select(x => x.previousCalculationResult.source.totalAmount);
      }
    }),
  );

  newAmount$: Observable<number | null> = this._calculatorQuery.select(x => x.amountKey).pipe(
    take(1),
    switchMap((amountKey: 0 | 1) => {
      if (amountKey === 0) {
        return this._calculatorQuery.select(x => x.calculationResult.destination.amount);
      } else {
        return this._calculatorQuery.select(x => x.calculationResult.source.totalAmount);
      }
    }),
  );

  oldCurrency$: Observable<string> = this._calculatorQuery.select(x => x.amountKey).pipe(
    take(1),
    switchMap((amountKey: 0 | 1) => {
      if (amountKey === 0) {
        return this._calculatorQuery.select(x => x.previousCalculationResult.destination.currency.title);
      } else {
        return this._calculatorQuery.select(x => x.previousCalculationResult.source.currency.title);
      }
    }),
  );

  newCurrency$: Observable<string> = this._calculatorQuery.select(x => x.amountKey).pipe(
    take(1),
    switchMap((amountKey: 0 | 1) => {
      if (amountKey === 0) {
        return this._calculatorQuery.select(x => x.calculationResult.destination.currency.title);
      } else {
        return this._calculatorQuery.select(x => x.calculationResult.source.currency.title);
      }
    }),
  );

  constructor(
    private _calculatorQuery: CalculatorQuery,
    public dialogRef: MatDialogRef<RateHasChangedComponent>
  ) { }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
