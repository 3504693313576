import { Injectable } from '@angular/core';
import { Query, Store, StoreConfig } from '@datorama/akita';
import { Observable } from 'rxjs';
import { ITwoFactorState } from './two-factor.model';

export function createInitialState(): ITwoFactorState {
  return {
    enableTwoFactor: false,
    isShowCancelButton: true,
    isSaveButtonEnabled: true,
    isFieldsEnabled: true,
    isCodeFieldEnabled: true,
    isShowCodeField: false,
    isShowLoader: false,
    isLoadingSettings: false,
    secondsLeft: 0,
    code: ''
  };
}

@StoreConfig({ name: 'security-2fa' })
@Injectable()
export class TwoFactorStore extends Store<ITwoFactorState> {
  constructor() {
    super(createInitialState());
  }
}

@Injectable()
export class TwoFactorQuery extends Query<ITwoFactorState> {

  state$$: Observable<ITwoFactorState> = this.select(x => x);
  code$$: Observable<string> = this.select(x => x.code);
  isShowLoader$$: Observable<boolean> = this.selectLoading();
  isSaveButtonEnabled$$: Observable<boolean> = this.select(x => x.isSaveButtonEnabled);
  isCodeFieldEnabled$$: Observable<boolean> = this.select(x => x.isCodeFieldEnabled);
  isShowCodeField$$: Observable<boolean> = this.select(x => x.isShowCodeField);
  secondsLeft$$: Observable<number> = this.select(x => x.secondsLeft);
  isShowCancelButton$$: Observable<boolean> = this.select(x => x.isShowCancelButton);
  isFieldsEnabled$$: Observable<boolean> = this.select(x => x.isFieldsEnabled);

  constructor(
    protected override store: TwoFactorStore
  ) {
    super(store);
  }
}
