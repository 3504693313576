import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, mapTo, switchMap, tap } from 'rxjs/operators';
import { DocTypesSourceIncomeStore } from './doctypes-source-income.store';
import { IDocTypeValueResponseModel } from '../doctypes-identity/doctypes-identity.model';
import { withTransaction } from '@datorama/akita';

@Injectable()
export class DocTypesSourceIncomeService {

  constructor(
    private _http: HttpClient,
    private docTypesSourceIncomeStore: DocTypesSourceIncomeStore
  ) { }

  getDocTypesSourceIncome$(): Observable<void> {
    return of(0).pipe(
      tap(() => this.docTypesSourceIncomeStore.setLoading(true)),
      switchMap(() => this._http.get<IDocTypeValueResponseModel[]>('reference/doctype/source-income')),
      catchError(() => {
        this.docTypesSourceIncomeStore.setLoading(false);
        return EMPTY;
      }),
      withTransaction(docTypesSourceIncome => {
        this.docTypesSourceIncomeStore.upsertMany(docTypesSourceIncome);
        this.docTypesSourceIncomeStore.setLoading(false);
      }),
      mapTo(void 0)
    );
  }
}
