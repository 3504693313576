import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, mapTo, switchMap, tap } from 'rxjs/operators';
import { RemittanceCountriesStore } from './remittance-countries.store';
import { IRemittanceCountryResponseModel } from './remittance-countries.model';

@Injectable()
export class RemittanceCountriesService {

  constructor(
    private _http: HttpClient,
    private _remittanceCountriesStore: RemittanceCountriesStore
  ) { }

  getRemittanceCountries$(): Observable<void> {
    return of(void 0).pipe(
      tap(() => this._remittanceCountriesStore.setLoading(true)),
      switchMap(() => this._http.get<IRemittanceCountryResponseModel[]>('reference/remittance-countries')),
      catchError(() => {
        this._remittanceCountriesStore.setLoading(false);
        return EMPTY;
      }),
      tap(remittanceCountries => {
        this._remittanceCountriesStore.upsertMany(remittanceCountries?.sort((a, b) => a?.title?.localeCompare(b?.title)));
        this._remittanceCountriesStore.setLoading(false);
      }),
      mapTo(void 0)
    );
  }
}
