import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { LocationsQuery } from '../locations.store';
import { IPoint } from '../../../../data-modules/points/points.model';
import { PointsQuery } from '../../../../data-modules/points/points.store';
import { PointsService } from '../../../../data-modules/points/points.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ThemeService } from '../../../../data-modules/theme/theme.service';
import { LocationsService } from '../locations.service';

@UntilDestroy()
@Component({
  selector: 'app-locations-grid',
  templateUrl: './locations-grid.component.html',
  styleUrls: ['./locations-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationsGridComponent implements OnInit {

  private _clickOnSelectAll$$: Subject<null> = new Subject<null>();

  areAllPointsChecked$$: Observable<boolean> = this._pointsQuery.state$$.pipe(
    map((points: IPoint[]) => points.length === 0 ? false : points.every(p => p.selected))
  );

  isShowSelectedColumn$$: Observable<boolean> = this._locationsQuery.select(x => !x.allLocations);
  filteredPointsList$$: Observable<IPoint[]> = this._pointsQuery.state$$;
  isShowSelectedCheckbox$$: Observable<boolean> = this._locationsQuery.select(x => !x.allLocations);
  areAllPartnerSelected$$: Observable<boolean> = this._locationsQuery.state$$.pipe(
    map(item => item?.allPartners === true)
  );

  emptyListImageUrl$: Observable<string> = this._themeService.activeThemeMode$$.pipe(
    map(() => this._themeService.getSharedImageUrlWithDarkMode('/img/empty-searching-list.svg')),
  );

  constructor(
    private _themeService: ThemeService,
    private _pointsQuery: PointsQuery,
    private _locationsQuery: LocationsQuery,
    private _locationsService: LocationsService,
    private _pointsService: PointsService,
  ) { }

  ngOnInit(): void {
    this._subscribeOnSelectClick();
  }

  isPointDisabled(point: IPoint): boolean {
    return point?.disabled || (point?.contacts == null && point?.workTime == null);
  }

  onSelectCheckbox(pointId: number | null): void {
    if (pointId == null) {
      return;
    }
    this._locationsService.selectedCheckbox(pointId);
  }

  onSelectedAllClickCheckbox(): void {
    this._clickOnSelectAll$$.next(null);
  }

  onExpandOrCollapse(pointId: number | null, action: 'EXPAND' | 'COLLAPSE'): void {
    if (pointId == null) {
      return;
    }
    const isExpand: boolean = action === 'EXPAND';
    this._locationsService.expandOrCollapseExpansionPanel(pointId, isExpand);
  }

  private _subscribeOnSelectClick(): void {
    this._clickOnSelectAll$$.pipe(
      withLatestFrom(this.areAllPointsChecked$$),
      switchMap(([, isChecked]) => this._pointsService.selectedAllPoints$(!isChecked)),
      untilDestroyed(this)
    ).subscribe();
  }
}


