import { Query, Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { DEFAULT_PERSONAL_FORM_DATA, IEmailStatus, IPersonalDataForm, IPersonalFormData } from './personal-data.model';
import { Observable } from 'rxjs';

export function createInitialState(): IPersonalDataForm {
  return {
    current: DEFAULT_PERSONAL_FORM_DATA,
    draft: DEFAULT_PERSONAL_FORM_DATA,
    isEdit: false,
  };
}

@StoreConfig({ name: 'personal-data-form' })
export class PersonalDataFormStore extends Store<IPersonalDataForm> {
  constructor() {
    super(createInitialState());
  }
}

@Injectable()
export class PersonalDataFormQuery extends Query<IPersonalDataForm> {
  state$$: Observable<IPersonalDataForm | null> = this.select(x => x);
  name$$: Observable<string | null> = this.select(x => x.draft.name);
  surname$$: Observable<string | null> = this.select(x => x.draft.surname);
  email$$: Observable<string | null> = this.select(x => x.current.email);
  draftEmail$$: Observable<string | null> = this.select(x => x.draft.email);
  emailStatus$$: Observable<IEmailStatus> = this.select(x => x.current.emailStatus);
  dateOfBirth$$: Observable<string | null> = this.select(x => x.draft.dateOfBirth);
  personalDataCurrent$$: Observable<IPersonalFormData> = this.select(x => x.current);
  isVirginAccount$$: Observable<boolean> = this.select(x => x.current.name == null);
  isEdit$$: Observable<boolean> = this.select(x => x.isEdit);
  isShowSurname$$: Observable<boolean> = this.select(x => x.draft.surname != null);
  isShowName$$: Observable<boolean> = this.select(x => x.draft.name != null);
  isApproved$$: Observable<boolean> = this.select(x => x.current.emailStatus === 'Approved');
  isReceivePaymentInfoByEmail$$: Observable<boolean> = this.select(x => x.current.isReceivePaymentInfoByEmail);
  isEmailEqual$$: Observable<boolean> = this.select(x => x.current.email === x.draft.email);
  isHasCustomerPersonalData$$: Observable<boolean> = this.select(x => x.current.name != null);

  constructor(protected override store: PersonalDataFormStore) {
    super(store);
  }
}
