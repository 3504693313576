import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { ThemeService } from '../../../../data-modules/theme/theme.service';
import { map, mapTo, takeWhile, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-provide-kyc-questionnaire',
  templateUrl: './provide-kyc-questionnaire.component.html',
  styleUrls: ['./provide-kyc-questionnaire.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProvideKycQuestionnaireComponent implements OnInit {
  private readonly _countDown: number = 50;
  secondsLeft$: BehaviorSubject<number> = new BehaviorSubject(this._countDown);
  interval$: Observable<number> = interval(1000);
  route$: Subject<void> = new Subject<void>();

  questionnaireImageUrl$: Observable<string> = this._themeService.activeThemeMode$$.pipe(
    map(() => this._themeService.getSharedImageUrlWithDarkMode('/img/questionnaire.svg')),
  );

  constructor(
    private _dialogRef: MatDialogRef<ProvideKycQuestionnaireComponent>,
    private _themeService: ThemeService
  ) {}

  ngOnInit(): void {
    this._subscribeOnInterval();
    this._subscribeOnRouteEvent();
  }

  private _subscribeOnRouteEvent(): void {
    this.route$
      .pipe(
        tap(() => {
          this._dialogRef.close(true);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private _subscribeOnInterval(): void {
    this.interval$
      .pipe(
        map((timeElapsed: number): number => this._countDown - timeElapsed),
        takeWhile((timeLeft: number): boolean => timeLeft >= 0),
        tap((timeLeft: number) => {
          this.secondsLeft$.next(timeLeft);

          if (timeLeft === 0) {
            this.route$.next();
          }
        }),
        mapTo(void 0),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
