import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDocTypeValue } from '../doctypes-identity/doctypes-identity.model';

export interface IDocTypesResidenceConfirmationState extends EntityState <IDocTypeValue, string> {
  filter: string
}
@Injectable()
@StoreConfig({ name: 'doctypes-residence-confirmation' })
export class DocTypesResidenceConfirmationStore extends EntityStore<IDocTypesResidenceConfirmationState> {
  constructor() {
    super({ filter: 'ALL' });
  }
}

@Injectable()
export class DocTypesResidenceConfirmationQuery extends QueryEntity<IDocTypesResidenceConfirmationState> {
  docTypesResidenceConfirmation$$: Observable<IDocTypeValue[]> = this.selectAll();

  constructor(protected override store: DocTypesResidenceConfirmationStore) {
    super(store);
  }
}
