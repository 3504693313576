import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { DocTypesIdentityQuery, DocTypesIdentityStore } from './doctypes-identity.store';
import { IDocTypeValue, IDocTypeValueResponseModel } from './doctypes-identity.model';
import { IDocumentBody } from '../../root/private/private-models/document.model';

@Injectable()
export class DoctypesIdentityService {

  constructor(
    private _http: HttpClient,
    private _doctypesIdentityStore: DocTypesIdentityStore,
    private _doctypesIdentityQuery: DocTypesIdentityQuery
  ) { }

  getDoctypesIdentity$(): Observable<void> {

    return of(void 0).pipe(
      tap(() => this._doctypesIdentityStore.setLoading(true)),
      switchMap(() => this._http.get<IDocTypeValueResponseModel[]>('reference/doctype/identity')),
      take(1),
      catchError(() => {
        this._doctypesIdentityStore.setLoading(false);
        return EMPTY;
      }),
      tap(doctypesIdentity => {
          this._doctypesIdentityStore.setLoading(false);
          this._doctypesIdentityStore.upsertMany(doctypesIdentity);
        }
      ),
      mapTo(void 0)
    );
  }

  getSideCount(doc: IDocumentBody): Observable<number | null> {
    return this._doctypesIdentityQuery.docTypesIdentity$$
      .pipe(
        map((doctypes: IDocTypeValue[]): IDocTypeValue | undefined => doctypes.find(doctype => doctype.title === doc.document.typeDocument?.title)),
        map((doctype: IDocTypeValue | undefined) => doctype != null ? doctype.countSide : 0)
      );
  }
}
