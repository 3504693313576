import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, concatMap, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '../../../data-modules/auth/auth.service';
import { PersistenceQuery } from '../../../data-modules/persist/persist.store';
import { LogoutService } from '../../../data-modules/logout.service';
import { LocalizationQuery } from '@ff/localization';
import { environment } from '../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class HttpErrorsHandlerInterceptor implements HttpInterceptor {
  private _isRefreshingToken: boolean = false;
  private _tokenSubject: Subject<string | null> = new Subject<string | null>();

  constructor(
    private _authService: AuthService,
    private _localizationQuery: LocalizationQuery,
    private _persistQuery: PersistenceQuery,
    private _logoutService: LogoutService,
    private _dialog: MatDialog){
  }

  intercept(request: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {
    let retries = 0;

    return next.handle(request)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (retries > 0) {
            return this.logout$$(
              this._localizationQuery.transform('%[snackbar.title.forced-logout-refresh-token-expired]%'),
              this._localizationQuery.transform('%[snackbar.message.forced-logout-refresh-token-expired]%', { companyName: environment.vendor.company.name }));
          }

          if (err.status === 401 && retries === 0) {
            retries += 1;
            if (this._isRefreshingToken === false) {
              this._tokenSubject.next(null);
              this._isRefreshingToken = true;
              return this._authService.refreshCredentials().pipe(
                tap((accessToken: string) => {
                  this._tokenSubject.next(accessToken);
                }),
                switchMap(() => this.addBearerAndHeaders$(request)),
                switchMap((newReq) => next.handle(newReq)),
                catchError(() => {
                  console.warn('logged out due to refresh token timeout');
                  this._dialog.closeAll();
                  return this.logout$$(
                    this._localizationQuery.transform('%[snackbar.default-title.failure]%'),
                    this._localizationQuery.transform('%[stored-procedure.label.401-error]%'));
                }),
                finalize(() => {
                  this._isRefreshingToken = false;
                })
              );
            } else {
              return this._tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(() => this.addBearerAndHeaders$(request)),
                switchMap((newReq) => next.handle(newReq)),
              );
            }
          }
          return throwError(err);
        })
      );
  }

  logout$$(title: string, message: string): Observable<HttpEvent<string>> {
    return this._logoutService.forcedLogout$$(
      title, message
    ).pipe(concatMap(() => EMPTY));
  }

  addBearerAndHeaders$(req: HttpRequest<string>): Observable<HttpRequest<string>> {
    const isNullOrWhiteSpace = (token: string | null): boolean => token == null || token.trim() === '';
    return this._persistQuery.select(x => x.accessToken).pipe(
      take(1),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      map(token => isNullOrWhiteSpace(token) ? req : req.clone({ setHeaders: { Authorization: `Bearer ${token}` } }))
    );
  }
}
