import { NgModule } from '@angular/core';

import { ImageUploadComponent } from './image-upload.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LocalizationModule } from '@ff/localization';

@NgModule({
  imports: [
    MatIconModule,
    MatButtonModule,
    LocalizationModule
  ],
  exports: [ImageUploadComponent],
  declarations: [ImageUploadComponent],
  providers: [],
})
export class ImageUploadModule {}
