import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, mapTo, switchMap, take, tap } from 'rxjs/operators';

import { IdentityDocumentsQuery, IdentityDocumentsStore } from '../store/identity-documents.store';
import { combineQueries } from '@datorama/akita';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ProfileQuery, ProfileStore } from '../../../../private-store/profile.store';
import { ProfileService } from '../../../../private-store/profile.service';
import { IDocumentBody, IDocumentBodyUI, IDocumentFormData, ISuccessfulSavingResponse } from '../../../../private-models/document.model';
import { RequiredDocumentsService } from '../../../../../../data-modules/required-documents/required-documents.service';
import { AdditionalDocumentsService } from '../../additional-documents/additional-documents.service';
import { RequiredDocumentsQuery } from '../../../../../../data-modules/required-documents/required-documents.store';

@Injectable()
export class NewIdentityDocumentService {

  constructor(
    private _profileStore: ProfileStore,
    private _http: HttpClient,
    private _identityDocumentsStore: IdentityDocumentsStore,
    private _identityDocumentsQuery: IdentityDocumentsQuery,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _requiredDocumentsService: RequiredDocumentsService,
    private _requiredDocumentsQuery: RequiredDocumentsQuery,
    private _profileQuery: ProfileQuery,
    private _profileService: ProfileService,
    private _additionalDocumentsService: AdditionalDocumentsService
  ) { }

  saveFormEffect$(savedId: string): Observable<void> {
    return combineQueries([this._profileQuery.isWizardMode$$, this._requiredDocumentsQuery.isIDVerificationRequired$$]).pipe(
      take(1),
      switchMap(([isWizard, isIdentityDocumentVerificationRequired]) => {
        if (!isIdentityDocumentVerificationRequired && isWizard) {
          return this._additionalDocumentsService.wizardAfterEffects$(true);
        }
        return this._identityDocumentsQuery.selectAll().pipe(
          take(1),
          tap((docs: IDocumentBody[]) => {
            this._identityDocumentsStore.ui.update(x => ({ ...x, newDocFormIsOpen: false }));
            docs.forEach(doc => {
              // @ts-ignore todo: fixit
              this._identityDocumentsStore.ui.upsert(doc.id, (x: IDocumentBodyUI) => ({
                ...x,
                isExpanded: false,
                isEdit: false
              }));
            });
            // @ts-ignore todo: fixit
            this._identityDocumentsStore.ui.upsert(savedId, (x: IDocumentBodyUI) => ({
              ...x,
              isExpanded: true,
              isEdit: isIdentityDocumentVerificationRequired && isWizard || isIdentityDocumentVerificationRequired && !isWizard
            }));
          }),
          concatMap(() => this._profileService.updateProfile$()),
        );
      }),
      mapTo(void 0),
    );
  }

  saveDocument$(documentFormData: IDocumentFormData): Observable<void> {
    let savedId = '';
    return of(void 0)
      .pipe(
        tap(() => this._googleAnalyticsService.event('save-identity-document-click', '', '')),
        tap(() => this._profileStore.setLoading(true)),
        switchMap(() => this._http.post<ISuccessfulSavingResponse>('profile/document', documentFormData)),
        catchError(() => {
          this._profileStore.setLoading(false);
          return EMPTY;
        }),
        tap((response: ISuccessfulSavingResponse) => {
          savedId = response.id;
          this._profileStore.setLoading(false);
          // force redraw id docs
          this._identityDocumentsStore.reset();
        }),
      )
      .pipe(
        tap(() => this._googleAnalyticsService.event('save-identity-document-success', '', '')),
        switchMap(() => this._requiredDocumentsService.updateRequiredDocuments$()),
        switchMap(() => this.saveFormEffect$(savedId)),
        mapTo(void 0)
      );
  }

}
