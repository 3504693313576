import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AmlQuestionnaireModalQuery, AmlQuestionnaireModalStore } from './store/aml-questionnaire-modal.store';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AmlQuestionnaireModalService } from './aml-questionnaire-modal.service';
import {
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  IAmlAddressModel,
  IAmlQStoreAnswersDto,
  IAmlStoreQuestionsModel,
  IRemittanceGetAmlQuestionsModel, QuestionnaireControlType, QuestionValidationType
} from './store/aml-questionnaire-modal.model';
import { deepEquals, FFValidators, isString, toIsoString } from '@ff/utils';
import { LocalizationQuery } from '@ff/localization';
import { environment } from '../../../../../../../../../environments/environment';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { GoogleMapsService } from '../../../../../../../../functionality-modules/google-maps/google-maps.service';
import { SnackbarService } from '../../../../../../../../functionality-modules/snackbars/snackbar.service';
import { subYears } from 'date-fns';
import {
  SITE_TEMPLATES,
  SiteTemplateType
} from '../../../../../../../../functionality-modules/static-resource-dialog/models/site-template.type';
import { StaticResourceDialogComponent } from '../../../../../../../../functionality-modules/static-resource-dialog/static-resource-dialog.component';
import { FFRegexFilter } from '@ff/material-controls';

@UntilDestroy()
@Component({
  selector: 'app-aml-check-form-modal',
  templateUrl: './aml-questionnaire-modal.component.html',
  styleUrls: ['./aml-questionnaire-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AmlQuestionnaireModalQuery, AmlQuestionnaireModalStore, AmlQuestionnaireModalService, GoogleMapsService],
})
export class AmlQuestionnaireModalComponent implements OnInit {
  private readonly _filters: FFRegexFilter[];
  private _debounceTime: number = environment.appSettings.controls.inputDebounceTime;
  private _amlQuestions$$: Observable<IAmlStoreQuestionsModel[]> = this._amlCheckFormModalQuery.amlQuestions$$;
  private _questionnaireAnswersValue$$: Observable<IAmlQStoreAnswersDto[]> = this._amlCheckFormModalQuery.amlAnswers$$;

  isLoading$$: Observable<boolean> = this._amlCheckFormModalQuery.isLoading$$;
  questionnaireFields$$: Observable<IAmlStoreQuestionsModel[]> = this._amlCheckFormModalQuery.amlQuestions$$;
  form$$: BehaviorSubject<FormGroup | null> = new BehaviorSubject<FormGroup | null>(null);
  appearance: MatFormFieldAppearance = environment.appSettings.controls.appearance;
  minAge$$: Observable<number> = this.questionnaireFields$$.pipe(
    map(questions => {
      const dobQuestion = questions.find(question => question.id === 'RECEIVER_DATE_OF_BIRTH');
      if (dobQuestion?.validation === 'dob14') {
        return 14;
      }
      if (dobQuestion?.validation === 'dob18') {
        return 18;
      }
      return 0;
    })
  );

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public _data: { transactionId: string },
    private _amlCheckFormModalQuery: AmlQuestionnaireModalQuery,
    private _amlQuestionnaireModalService: AmlQuestionnaireModalService,
    private _localizationQuery: LocalizationQuery,
    private _dialogRef: MatDialogRef<AmlQuestionnaireModalComponent>,
    private _googleMapsService: GoogleMapsService,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog
  ) {
    this._filters = [
      {
        type: 'alphaNumericSymbols',
        value: '[^a-zA-Z 0-9\\-_"\'[:#${}()%., \\]]*',
        title: this._localizationQuery.transform('%[text-input.filters.alpha-numeric-symbols]%'),
      },
    ];
  }

  ngOnInit(): void {
    this._subscribeOnInit();
  }

  onSubmitEvent(): void {
    this.form$$
      .pipe(
        take(1),
        switchMap((form: FormGroup | null) => {
          if (form != null && form.valid === false) {
            form.markAsDirty();
            form.markAllAsTouched();
            form.updateValueAndValidity({ emitEvent: false, onlySelf: true });
            return EMPTY;
          }
          return of(form);
        }),
        concatMap((form: FormGroup | null) => {
          const companyAddress = this._amlQuestionnaireModalService.collectAddress(form?.value['RECEIVER_ADDRESS'] as IAmlAddressModel);
          return this._googleMapsService.checkAddress(companyAddress);
        }),
        concatMap((isValidAddress) => {
          if (!isValidAddress) {
            return this._showInvalidAddressSnackbar();
          }
          return this._amlQuestionnaireModalService.saveQuestionnaire$(this._data.transactionId, true);
        }),
        tap(() => this._dialogRef.close(true)),
        catchError(() => this._showInvalidAddressSnackbar()),
        untilDestroyed(this)
      )
      .subscribe();
  }

  trackByFn(index: number, item: IRemittanceGetAmlQuestionsModel): number {
    return item.order;
  }

  onCloseClick(): void {
    this._dialogRef.close(false);
  }

  getControlName(id: string, isCustomField?: boolean): string {
    return `${isCustomField === true ? 'CF|' : ''}${id}`;
  }

  getFilter(preventInputType: string): FFRegexFilter | null {
    return this._filters.find((f) => f.type === preventInputType) ?? null;
  }

  getCustomControlData(
    currentQuestion: IRemittanceGetAmlQuestionsModel
  ): { isShow: boolean, controlName: string, controlLabel: string } {
    const currentFormValue = this.form$$.getValue();
    const currentAnswer: string = currentFormValue?.get(currentQuestion.id)?.value ?? null;
    const currentAnswerVariant = currentQuestion?.answers.find((x) => x.id === currentAnswer);
    return {
      isShow: currentAnswerVariant?.isCustomText ?? false,
      controlName: this.getControlName(`${currentQuestion.id}|${currentAnswerVariant?.id}`, true) ?? '',
      controlLabel: currentAnswerVariant?.title ?? '',
    };
  }

  hasRequiredAsterisk$(id: string): Observable<boolean> {
    return this.form$$.pipe(
      map((form: FormGroup | null): boolean => form?.controls[this.getControlName(id)]?.hasValidator(FFValidators.requiredNoWhitespaces) ?? false)
    );
  }

  onShowMoreInfoClick(annotationLink: unknown): void {
    const httpRegexp = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
    if (typeof annotationLink === 'string' && httpRegexp.test(annotationLink)) {
      window.open(annotationLink, '_blank');
    }
    if (typeof annotationLink === 'string' && SITE_TEMPLATES.includes(annotationLink as SiteTemplateType)) {
      this._dialog.open(StaticResourceDialogComponent, {
        width: '920px',
        panelClass: 'full-width-dialog',
        data: { template: annotationLink }
      });
    }
  }

  private _toFormGroup(data: IRemittanceGetAmlQuestionsModel[]): FormGroup {
    const group: { [key: string]: FormControl } = {};

    data.map((formField) => {
      const controlName = this.getControlName(formField.id);
      if (formField.type === 'Checkbox') {
        group[controlName] = this._createFormControl(controlName, formField.type, false, formField.answers[0]?.id ?? '');
      } else {
        group[controlName] = this._createFormControl(controlName, formField.type, false, null, formField.validation);
      }

      if (formField.answers.length === 0) {
        return;
      }
      formField.answers.map((variant) => {
        if (variant.isCustomText) {
          const customTextControlName = this.getControlName(`${formField.id}|${variant.id}`, true);
          group[customTextControlName] = this._createFormControl(customTextControlName, 'Text', true);
        }
      });
    });
    return new FormGroup(group);
  }

  private _createFormControl(
    name: string,
    type: QuestionnaireControlType = 'Text',
    isCustomControl: boolean = false,
    initialValue?: unknown,
    validation?: QuestionValidationType | null
  ): FormControl {
    if (type === 'Address' || type === 'Checkbox') {
      return new FormControl(initialValue ?? null, [FFValidators.requiredNoWhitespaces]);
    } else if (type === 'DateOfBirth') {
      let minAge: number;
      switch (validation) {
        case 'dob14':
          minAge = 14;
          break;
        case 'dob18':
          minAge = 18;
          break;
        default:
          minAge = 0;
          break;
      }
      return new FormControl(initialValue ?? null, [
        Validators.required,
        FFValidators.dateNotEarlierThan(() => toIsoString(subYears(new Date(), 100))),
        FFValidators.dateNotLaterThan(() => toIsoString(subYears(new Date(), minAge)))
      ]);
    } else {
      return new FormControl(
        initialValue ?? null,
        isCustomControl ? [] : [FFValidators.requiredNoWhitespaces, Validators.minLength(2)]
      );
    }
  }

  private _subscribeOnInit(): void {
    this._amlQuestionnaireModalService.loadAMLQuestionnaire$().subscribe();
    this._googleMapsService.init().subscribe();
    // Called if there was any inner google errors when trying to geocode address
    this._googleMapsService.googleInnerErrorTrigger$$.pipe(
      take(1),
      concatMap(() => this._saveAMLWithGoogleErrors())
    ).subscribe();

    // Create form
    this.questionnaireFields$$
      .pipe(
        filter((qFields) => qFields?.length > 0),
        take(1),
        map((fields) => this._toFormGroup(fields)),
        tap((form) => this.form$$.next(form))
      )
      .subscribe();

    // Map form values to store
    this.form$$
      .pipe(
        switchMap((form: FormGroup | null): Observable<FormGroup | never> => (form == null ? EMPTY : of(form))),
        debounceTime(this._debounceTime),
        switchMap((form: FormGroup) => form.valueChanges.pipe(distinctUntilChanged((prev, curr) => deepEquals(prev, curr)))),
        withLatestFrom(this._amlQuestions$$),
        tap(([formValue, amlQuestions]) => {
          const result: IAmlQStoreAnswersDto[] = this._mapDataToStore(formValue, amlQuestions);
          this._amlQuestionnaireModalService.updateFormData(result);
        }),
        untilDestroyed(this)
      )
      .subscribe();

    // Map values from store on form
    this._questionnaireAnswersValue$$.pipe(
      withLatestFrom(this.form$$),
      tap(([answers, form]) => {
        if (form == null && answers == null) { return; }
        answers.map((field: IAmlQStoreAnswersDto) => {
          const isCustomField: boolean = this._isNotEmptyString(field.selectedValue)
            && this._isNotEmptyString(field.customText);
          if (isCustomField) {
            const customControlName: string = this.getControlName(`${field.questionId}|${field.selectedValue}`, true);
            form?.controls[field.questionId]?.patchValue(field.selectedValue, { emitEvent: false });
            form?.controls[customControlName]?.patchValue(field.customText, { emitEvent: false });
          } else {
            const valueToSet: unknown | null = this._isNotEmptyString(field.customText) ? field.customText : field.selectedValue;
            form?.controls[field.questionId]?.patchValue(valueToSet, { emitEvent: false });
          }
        });
      }),
      untilDestroyed(this)
    ).subscribe();

  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _mapDataToStore(formValue: any, amlQuestions: IAmlStoreQuestionsModel[]): any {
    return amlQuestions.map(
      (question: IAmlStoreQuestionsModel) => {
        const currentAnswerForField: string | null = formValue[question.id];

        if (question.type === 'Text') {
          return {
            questionId: question.id,
            selectedValue: null,
            customText: currentAnswerForField
          };
        } else {
          const isCustomField = question.answers.find(x => x.id === currentAnswerForField);
          if (isCustomField?.isCustomText === true) {
            return {
              questionId: question.id,
              selectedValue: currentAnswerForField,
              customText: formValue[this.getControlName(`${question.id}|${currentAnswerForField}`, true)] ?? ''
            };
          } else {
            return {
              questionId: question.id,
              selectedValue: currentAnswerForField,
              customText: null
            };
          }
        }
      }
    );
  }

  private _isNotEmptyString(value: unknown | null): boolean {
    if (value == null) return false;
    return isString(value) && value?.length > 0;
  }

  private _showInvalidAddressSnackbar(): Observable<void> {
    const message = this._localizationQuery.transform('%[private.aml-questionnaire-form.error.message.invalid-address]%');
    const title = this._localizationQuery.transform('%[private.aml-questionnaire-form.error.title.invalid-address]%');
    return this._snackbarService.openFailure$(message, { title, duration: environment.appSettings.snackbar.duration }).pipe(
      concatMap(() => EMPTY)
    );
  }

  private _saveAMLWithGoogleErrors(): Observable<void> {
    return this._amlQuestionnaireModalService.saveQuestionnaire$(this._data.transactionId, false);
  }
}
