import { NgModule } from '@angular/core';
import { PointsService } from './points.service';
import { PointsQuery, PointsStore } from './points.store';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    PointsService,
    PointsStore,
    PointsQuery,
  ],
})
export class PointsModule {}
