import { NgModule } from '@angular/core';
import { VersionService } from './version.service';
import { VersionQuery, VersionStore } from './version.store';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    VersionService,
    VersionStore,
    VersionQuery,
  ],
})
export class VersionModule {}
