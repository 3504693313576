import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { withTransaction } from '@datorama/akita';
import {
  IEndChangePasswordRequest,
  IEndChangePasswordResponse,
  IStartChangePasswordRequest,
  IStartChangePasswordResponse
} from './password.model';
import { SecuritySettingsStore } from '../../../store/security.store';
import { HttpClient } from '@angular/common/http';
import { IPasswordState, PasswordStore } from './password.store';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { PersistenceQuery, PersistenceStore } from '../../../../../../../data-modules/persist/persist.store';
import { SnackbarService } from '../../../../../../../functionality-modules/snackbars/snackbar.service';
import { LocalizationQuery } from '@ff/localization';

@Injectable()
export class PasswordService {

  constructor(
    private _securitySettingsStore: SecuritySettingsStore,
    private _persistenceQuery: PersistenceQuery,
    private _snackbarService: SnackbarService,
    private _localizationQ: LocalizationQuery,
    private _http: HttpClient,
    private _passwordStore: PasswordStore,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _persistenceStore: PersistenceStore
  ) {
  }

  startChangePassword$(form: IStartChangePasswordRequest): Observable<void> {
    return of(void 0)
      .pipe(
        withTransaction(() => {
          this._passwordStore.update({ isEnableSaveButton: false, isShowLoader: true, isEnableFields: false });
        }),
        switchMap(() => this._http.post<IStartChangePasswordResponse>('profile/start-change-password', form)),
        catchError(() => {
          this._passwordStore.update({ isShowLoader: false, isEnableFields: true, isEnableSaveButton: false });
          return EMPTY;
        }),
        tap(() => {
          this._passwordStore.update({ isShowLoader: false, isShowCodeField: true });
          return this._snackbarService.openInfoV2({
            message: this._localizationQ.transform('%[authentication.sms-send.message]%'),
            title: this._localizationQ.transform('%[authentication.sms-send.title]%')
          });
        }),
        mapTo(void 0),
      );
  }

  setPasswordVisible(isPasswordVisible: boolean): void {
    this._updateUI({ isPasswordVisible });
  }

  endChangePassword$(code: string): Observable<void> {
    return of(void 0)
      .pipe(
        tap(() => {
          this._passwordStore.update({ isShowLoader: true, isEnableCodeField: false });
        }),
        map((): IEndChangePasswordRequest => ({
          confirmCode: code,
          phone: this._persistenceQuery.getValue().phone
        })),
        switchMap((request: IEndChangePasswordRequest) => this._http.post<IEndChangePasswordResponse>('profile/end-change-password', request)),
        catchError(() => {
          this._passwordStore.update({ isShowLoader: false, isEnableCodeField: true, code: '' });
          return EMPTY;
        }),
        tap(() => {
          this._passwordStore.reset();
          this._securitySettingsStore.update({ isEdit: false });
          this._persistenceStore.update({ isForgotPassword: false });
          this._googleAnalyticsService.event('save', 'online', 'password_success');
          return this._snackbarService.openSuccess(this._localizationQ.transform('%[security.label.password-change.success]%'));
        }),
        mapTo(void 0),
      );
  }

  updatePassword$(password: string): Observable<void> {
    return of(void 0).pipe(
      tap(() => this._passwordStore.update({ password }))
    );
  }

  updateConfirmPassword$(confirmPassword: string): Observable<void> {
    return of(void 0).pipe(
      tap(() => this._passwordStore.update({ confirmPassword }))
    );
  }

  updateCode$(code: string): Observable<void> {
    return of(void 0).pipe(
      tap(() => this._passwordStore.update({ code }))
    );
  }

  private _updateUI(ui: Partial<IPasswordState['ui']>): void {
    this._passwordStore.update(st => ({ ...st, ui: { ...st.ui, ...ui } }));
  }

}
