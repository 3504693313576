import { EntityState } from '@datorama/akita';

export type CountryRiskRating = 'Low' | 'Medium' | 'High' | 'Prohibited';

export interface IIdIssuedCountriesResponseModel {
  id: string,
  countryId: string,
  iso2Code: string,
  name: string,
  title: string,
  capitalCity: string,
  capitalTimeZoneName: string,
  isoCurrencyCode: string,
  riskRatingId: number,
  riskRating: CountryRiskRating
}

export interface IIdIssuedCountriesModel {
  id: string,
  countryId: string,
  iso2Code: string,
  name: string,
  title: string,
  capitalCity: string,
  capitalTimeZoneName: string,
  isoCurrencyCode: string,
  riskRatingId: number,
  riskRating: CountryRiskRating
}

export const idKey: keyof IIdIssuedCountriesModel = 'countryId';


export interface IAuthCountry2 {
  id: string,
  iso2Code: string,
  title: string,
  phoneCode: string,
  phoneCodes: [string]
}

export interface ICountries2State extends EntityState<IIdIssuedCountriesModel, string> {}
