import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ILookupStr } from '../../root/private/private-models/lookup-str';

export interface IBankAccountTypesState extends EntityState<ILookupStr, string> {
  filter: string
}
@Injectable()
@StoreConfig({ name: 'bank-account-types' })
export class BankAccountTypesStore extends EntityStore<IBankAccountTypesState> {
  constructor() {
    super({ filter: 'ALL' });
  }
}

@Injectable()
export class BankAccountTypesQuery extends QueryEntity<IBankAccountTypesState> {

  bankAccountTypes$$: Observable<ILookupStr[]> = this.selectAll();

  constructor(protected override store: BankAccountTypesStore) {
    super(store);
  }
}
