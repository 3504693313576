import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { combineQueries, withTransaction } from '@datorama/akita';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { LocationsService } from './locations.service';
import { ILocationsState, LocationsQuery, LocationsStore } from './locations.store';
import { ILookupInt, ILookupStr } from '../../../root/private/private-models/lookup-str';
import { CalculatorQuery } from '../../../data-modules/calculator/calculator.store';
import { GatewaysService } from '../../../data-modules/gateways/gateways.service';
import { PointsQuery, PointsStore } from '../../../data-modules/points/points.store';
import { LocalizationQuery } from '@ff/localization';
import { GatewaysQuery } from '../../../data-modules/gateways/gateways.store';
import { IPoint } from '../../../data-modules/points/points.model';
import { IGateway } from '../../../data-modules/gateways/gateways.model';
import {
  CurrenciesDestinationService
} from '../../../data-modules/currencies-destination/currencies-destination.service';
import { DirectionCitiesService } from '../../../data-modules/direction-cities/direction-cities.service';
import { DirectionCitiesQuery } from '../../../data-modules/direction-cities/direction-cities.store';
import { RequiredFieldsService } from '../../../data-modules/required-fields/required-fields.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CurrenciesDestinationQuery } from '../../../data-modules/currencies-destination/currencies-destination.store';
import { RequiredFieldsQuery } from '../../../data-modules/required-fields/required-fields.store';
import { ICountry } from '../../../data-modules/countries/countries.model';
import { CountriesQuery } from '../../../data-modules/countries/countries.store';
import { environment } from '../../../../environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-locations',
  styleUrls: ['./locations.component.scss'],
  templateUrl: './locations.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LocationsService]
})

export class LocationsComponent implements OnInit {

  private _state$$: Observable<ILocationsState> = this._locationsQuery.state$$;
  private _loadAllOnScroll$$: Subject<null> = new Subject<null>();
  private _offset$$: Observable<number> = this._directionCitiesQuery.offset$$;
  private _totalResult$$: Observable<number> = this._directionCitiesQuery.totalResult$$;
  private _clickedDirectionId$$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private _debounceTime: number = environment.appSettings.controls.inputDebounceTime;

  directionCities$$: Observable<ILookupStr[]> = this._directionCitiesQuery.state$$;
  gateways$$: Observable<IGateway[]> = this._gatewaysQuery.gateways$$;
  activeGatewayDirectionId$$: Observable<string | undefined> = this._gatewaysQuery.activeGatewayDirectionId$$;
  selectedPointsCount$$: Observable<number> = this._pointsQuery.state$$.pipe(
    map((points: IPoint[]) => points.filter(p => p.selected)),
    map((points: IPoint[]) => points.length)
  );

  isLoadingCities$$: Observable<boolean> = this._directionCitiesQuery.isLoading$$;

  isDownloadPdfButtonDisabled$$: Observable<boolean> = combineQueries([
    this._locationsQuery.isPdfDownloading$$,
    this._pointsQuery.state$$.pipe(map(all => all.filter(x => x.selected).some(is => is)))
  ]).pipe(map(([isDownloading, isSomethingSelected]) => !isSomethingSelected || isDownloading));

  locationsFormGroup: FormGroup = new FormGroup({
    city: new FormControl(null, [Validators.required]),
    search: new FormControl(null, [Validators.minLength(3)]),
    allPartners: new FormControl(false)
  });

  isLoading$$: Observable<boolean> = combineQueries([
    this._pointsQuery.selectLoading(),
    this._currenciesDestinationQuery.loading$$,
    this._requiredFieldsQuery.selectLoading(),
    this._calculationsQuery.selectLoading()
  ]).pipe(map(all => all.some(is => is)));

  selectedPointsInfoText$$: Observable<string> = combineLatest([
    this.selectedPointsCount$$,
    this._pointsQuery.state$$
  ]).pipe(
    map(([selectedPoints, allPoints]) => {
      return this._localizationQuery.transform('%[location.selected-points.selected-text]%', { selected: selectedPoints.toString(), allPoints: allPoints.length });
    })
  );

  isAddButtonDisabled$$: Observable<boolean> = this.selectedPointsCount$$
    .pipe(map((spN: number) => spN === 0));

  countries$$: Observable<ICountry[]> = this._countriesQuery.countries$$.pipe(
    map((countries: ICountry[]): ICountry[] => countries.map(country => ({
      id: country.id,
      title: country.title,
      phoneCode: country.phoneCode,
      iso2Code: country.iso2Code
    })))
  );

  constructor(
    private _calculatorQuery: CalculatorQuery,
    private _directionCitiesService: DirectionCitiesService,
    private _directionCitiesQuery: DirectionCitiesQuery,
    private _locationsStore: LocationsStore,
    private _gatewaysService: GatewaysService,
    private _locationsService: LocationsService,
    private _locationsQuery: LocationsQuery,
    private _pointsQuery: PointsQuery,
    private _pointsStore: PointsStore,
    private _currenciesDestinationQuery: CurrenciesDestinationQuery,
    private _requiredFieldsQuery: RequiredFieldsQuery,
    private _calculationsQuery: CalculatorQuery,
    private _dialogRef: MatDialogRef<LocationsComponent>,
    private _gatewaysQuery: GatewaysQuery,
    private _currenciesDestinationService: CurrenciesDestinationService,
    private _requiredFieldsService: RequiredFieldsService,
    private _localizationQuery: LocalizationQuery,
    private _countriesQuery: CountriesQuery,
    @Inject(MAT_DIALOG_DATA)
    public data: { showSkipButton: boolean, showCloseConfirmation: boolean }
  ) {
  }

  ngOnInit(): void {
    this._updateStoreOnFormChange();
    this._updateFormOnLocalStoreChange();
    this._updateCities$().subscribe();
    this._subscribeOnLoadAllOnScroll();
    this._subscribeOnClickedPartner();
  }

  onFinishClick(): void {
    this._pointsQuery.state$$.pipe(
      map((points: IPoint[]) => points.filter(point => point.selected)),
      tap((points: IPoint[]) => this._dialogRef.close(points))
    ).subscribe();
  }

  onDownloadPdfPointsClick(): void {
    this._locationsService.downloadPointsPdf();
  }

  onLoadAllOnScroll(): void {
    this._loadAllOnScroll$$.next(null);
  }

  // app-button-toggle-group ничего не знает об directionId, при клике возвращает Id
  // после клика мы находим directionId по ID, который нам пришел в параметры
  onPartnerClick(id: string): void {
    of(void 0).pipe(
      withLatestFrom(this.gateways$$),
      tap(([_, gateways]) => {
        let directionId = null;
        if (gateways.length > 0) {
          directionId = gateways?.find(item => item?.id === id)?.directionId;
        }
        if (directionId != null) {
          this._clickedDirectionId$$.next(directionId);
        }
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  private _subscribeOnClickedPartner(): void {
    this._clickedDirectionId$$.pipe(
      filter(directionId => directionId != null),
      switchMap(directionId => this._gatewaysService.activateGateway$$(directionId)),
      switchMap(() => this._updateCities$()),
      switchMap(() => this._locationsService.setAllLocations(false, false)),
      switchMap(() => this._calculatorQuery.sourceCurrency$$),
      switchMap(sc => sc == null ? EMPTY : of(sc)),
      switchMap((sc: ILookupInt) => this._currenciesDestinationService.getCurrenciesDestination$(sc.id, this._clickedDirectionId$$.getValue())),
      switchMap(_ => this._requiredFieldsService.getRequiredFields$(this._clickedDirectionId$$.getValue()))
    ).subscribe();
  }

  private _subscribeOnLoadAllOnScroll(): void {
    this._loadAllOnScroll$$.pipe(
      withLatestFrom(this._offset$$, this._totalResult$$),
      switchMap(([_, offset, total]) => {
        if (total === 0 || offset < total) {
          return this._directionCitiesService.updateMoreCities$();
        }
        return EMPTY;
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  private _updateFormOnLocalStoreChange(): void {
    this._state$$.pipe(
      tap((location: ILocationsState) => {
        this.locationsFormGroup.controls.city?.patchValue(location?.city, { emitEvent: false });
        this.locationsFormGroup.controls.search?.patchValue(location?.search, { emitEvent: false });
        this.locationsFormGroup.controls.allPartners?.patchValue(location?.allPartners, { emitEvent: false });
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  private _updateStoreOnFormChange(): void {
    this.locationsFormGroup.controls.city?.valueChanges.pipe(
      switchMap((value) => this._locationsService.setCity(value)),
      untilDestroyed(this)
    ).subscribe();

    this.locationsFormGroup.controls.search?.valueChanges.pipe(
      debounceTime(this._debounceTime),
      distinctUntilChanged(),
      switchMap((value) => this._locationsService.setSearch(value)),
      untilDestroyed(this)
    ).subscribe();

    this.locationsFormGroup.controls.allPartners?.valueChanges.pipe(
      switchMap((partners) => this._locationsService.setAllPartners(partners)),
      untilDestroyed(this)
    ).subscribe();
  }

  private _updateCities$(): Observable<void> {
    // init component
    let selectedCity: ILookupStr | null = null;
    return of(0).pipe(
      // get cities if direction is selected
      switchMap(() => combineLatest([
        this._calculatorQuery.destinationCountry$$,
        this._gatewaysQuery.selectActive()
      ]).pipe(take(1))),
      switchMap(([country, gateway]) => country == null ? of(void 0) : this._directionCitiesService.getDirectionCities$(country, gateway))
    ).pipe(
      // reset stores if no points selected
      switchMap(() => this.selectedPointsCount$$.pipe(take(1))),
      withLatestFrom(this._locationsQuery.city$$),
      withTransaction(([selectedPoints, city]) => {
        selectedCity = city;
        if (selectedPoints === 0) {
          this._locationsStore.reset();
          this._pointsStore.reset();
        }
      })
    ).pipe(
      // set all locations flag
      switchMap(() => this._gatewaysQuery.selectActive().pipe(take(1))),
      switchMap((active: IGateway | undefined | null) => active == null ? this._locationsService.setAllLocations(true) : of(void 0))
    ).pipe(
      // select city if it is single
      switchMap(() => {
        const cities = this._directionCitiesQuery.getAll();
        if (cities.length === 1 && this._locationsQuery.getValue().city == null) {
          const city: ILookupStr | null = this._directionCitiesQuery.getAll()[0] ?? null;
          if (city == null) {
            console.error('_updateCities$ city is null');
            return EMPTY;
          }
          return this._locationsService.setCity(city);
        }
        if (selectedCity != null && cities.some((el) => el.title === selectedCity?.title)) {
          return this._locationsService.setCity(selectedCity);
        }
        return of(void 0);
      })
    );
  }
}
