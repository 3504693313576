import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalizationQuery } from '@ff/localization';

interface IHttpErrorsMapModel {
  code: number | null,
  message: string
}

@Injectable()
export class StoredProcedureErrorService {

  httpErrorLocMapping: IHttpErrorsMapModel[] = [
    {
      code: 3000,
      message: this._localizationQuery.transform('%[stored-procedure.error-messages.field-sms-timeout]%')
    },
    {
      code: 3001,
      message: this._localizationQuery.transform('%[stored-procedure.error-messages.field-email-timeout]%')
    },
    {
      code: 3002,
      message: this._localizationQuery.transform('%[stored-procedure.error-messages.field-sms-expired]%')
    },
    {
      code: 3004,
      message: this._localizationQuery.transform('%[stored-procedure.error-messages.field-profile-not-found]%')
    },
    {
      code: 3005,
      message: this._localizationQuery.transform('%[stored-procedure.error-messages.field-argument-verification-expired]%')
    },
    {
      code: 9999,
      message: this._localizationQuery.transform('%[stored-procedure.error-messages.field-validation-exception]%')
    },
    {
      code: 62015,
      message: this._localizationQuery.transform('%[stored-procedure.error-messages.field-pass]%')
    },
    {
      code: 62002,
      message: this._localizationQuery.transform('%[stored-procedure.error-messages.field-pass]%')
    }
  ];

  constructor(private _localizationQuery: LocalizationQuery) { }

  matchAndManage(error: HttpErrorResponse): IHttpErrorsMapModel {
    const errorMessageFromServer = (error?.error != null && error.error?.errors != null) ? error?.error?.errors[0]?.message : null;
    const errorCodeFromServer = (error?.error != null && error.error?.errors != null) ? error?.error?.errors[0]?.code : null;

    const errorMessage: IHttpErrorsMapModel = {
      code: errorCodeFromServer,
      message: errorMessageFromServer
    };

    if (errorMessage.message != null) {
      return errorMessage;
    } else if (errorMessage.message == null) {
      const message = this.httpErrorLocMapping?.find(item => item.code === errorCodeFromServer);
      errorMessage.message = message?.message ?? this._localizationQuery.transform('%[stored-procedure.error.unknown-error]%');
    }

    return errorMessage;
  }

}
