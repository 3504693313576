import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { CitizenshipCountriesStore } from './citizenship-countries.store';
import { HttpClient } from '@angular/common/http';
import { IIdIssuedCountriesModel, IIdIssuedCountriesResponseModel } from '../id-issued-countries/id-issued-countries.model';
import { withTransaction } from '@datorama/akita';

@Injectable()
export class CitizenshipCountriesService {

  constructor(
    private _http: HttpClient,
    private _citizenshipCountriesStore: CitizenshipCountriesStore
  ) { }

  getCitizenshipCountries$(): Observable<IIdIssuedCountriesModel[]> {
    return of(0).pipe(
      tap(() => this._citizenshipCountriesStore.setLoading(true)),
      switchMap(() => this._http.get<IIdIssuedCountriesResponseModel[]>('reference/citizenship-countries')),
      catchError(() => {
        this._citizenshipCountriesStore.setLoading(false);
        return EMPTY;
      }),
      withTransaction(remittanceCountries => {
        this._citizenshipCountriesStore.setLoading(false);
        this._citizenshipCountriesStore.upsertMany(remittanceCountries);
      }),

    );
  }
}
