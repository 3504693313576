import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-send-check',
  styleUrls: ['send-check.component.scss'],
  templateUrl: 'send-check.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SendCheckComponent {

  control: FormControl = new FormControl(null, [Validators.required, Validators.email]);

  constructor(
    private _dialogRef: MatDialogRef<SendCheckComponent>
  ) {
  }

  onNoClick(): void {
    this._dialogRef.close();
  }

  onSaveClick(): void {
    this._dialogRef.close(this.control.value);
  }
}
