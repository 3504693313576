import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import {
  AdditionalDocsFlags,
  IAdditionalDocsFlagsType, IKYCQuestionnaireFlagsType,
  IRequiredDocumentsRequest,
  IRequiredDocumentsResponse,
  IVerificationFlagsType, KYCQuestionnaireFlags,
  EVerificationFlags,
} from './required-documents.model';
import { CalculatorQuery } from '../calculator/calculator.store';
import { GatewaysQuery } from '../gateways/gateways.store';
import { IGateway } from '../gateways/gateways.model';
import { concatMap, map, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { ProfileQuery } from '../../root/private/private-store/profile.store';
import { RequiredDocumentsQuery, RequiredDocumentsStore } from './required-documents.store';
import { combineQueries } from '@datorama/akita';
import { LocalizationQuery } from '@ff/localization';
import { DialogService } from '../../functionality-modules/dialog-windows/dialog.service';
import { EProfileTabs } from '../../root/private/private-models/profile-tabs.model';

@Injectable()
export class RequiredDocumentsService {
  isNewPersonalDataRequiredAndWizard$$: Observable<boolean> = combineLatest([this._profileQuery.select(x => x.isWizardMode), this._requiredDocumentsQuery.isPersonalDataRequired$$]).pipe(
    map(([isWizardMode, isPersonalDataRequired]) => {
      return isWizardMode && isPersonalDataRequired;
    })
  );

  constructor(
    private _http: HttpClient,
    private _calculatorQuery: CalculatorQuery,
    private _gatewayQuery: GatewaysQuery,
    private _profileQuery: ProfileQuery,
    private _requiredDocumentsStore: RequiredDocumentsStore,
    private _requiredDocumentsQuery: RequiredDocumentsQuery,
    private _localizationQuery: LocalizationQuery,
    private _dialogService: DialogService
  ) {}

  isIdentityVerificationFlagInState$(states: IVerificationFlagsType[], isWizardModeCheck: boolean): Observable<boolean> {
    return combineLatest([
      this._profileQuery.isWizardMode$$,
      this._requiredDocumentsQuery.identityDocumentVerification$$
    ]).pipe(
      map(([isWizardMode, identityDocumentVerificationFlag]) => {
        const isInCheckedState: boolean = identityDocumentVerificationFlag != null
          ? states.map((state): string => EVerificationFlags[state]).includes(identityDocumentVerificationFlag.toLowerCase()) : false;

        if (isWizardModeCheck) {
          return isInCheckedState && isWizardMode;
        }
        return isInCheckedState;
      })
    );
  }

  isPoaFlagIsState$$(states: IAdditionalDocsFlagsType[], isWizardModeCheck: boolean): Observable<boolean> {
    return combineLatest([
      this._profileQuery.isWizardMode$$,
      this._requiredDocumentsQuery.proofOfAddress$$
    ]).pipe(
      map(([isWizardMode, proofOfAddressFlag]) => {
        const isInCheckedState: boolean = proofOfAddressFlag != null
          ? states.map((state): string => AdditionalDocsFlags[state]).includes(proofOfAddressFlag.toLowerCase()) : false;

        if (isWizardModeCheck) {
          return isInCheckedState && isWizardMode;
        }
        return isInCheckedState;
      })
    );
  }

  isSoiFlagIsState$$(states: IAdditionalDocsFlagsType[], isWizardModeCheck: boolean): Observable<boolean> {
    return combineLatest([
      this._profileQuery.isWizardMode$$,
      this._requiredDocumentsQuery.sourceOfIncome$$
    ]).pipe(
      map(([isWizardMode, sourceOfIncomeFlag]) => {
        const isInCheckedState: boolean = sourceOfIncomeFlag != null
          ? states.map((state): string => AdditionalDocsFlags[state]).includes(sourceOfIncomeFlag.toLowerCase()) : false;

        if (isWizardModeCheck) {
          return isInCheckedState && isWizardMode;
        }
        return isInCheckedState;
      })
    );
  }

  isEDFlagIsState$$(states: IAdditionalDocsFlagsType[], isWizardModeCheck: boolean): Observable<boolean> {
    return combineLatest([
      this._profileQuery.isWizardMode$$,
      this._requiredDocumentsQuery.extraDocuments$$
    ]).pipe(
      map(([isWizardMode, extraDocumentsFlag]) => {
        const isInCheckedState: boolean = extraDocumentsFlag != null
          ? states.map((state): string => AdditionalDocsFlags[state]).includes(extraDocumentsFlag.toLowerCase()) : false;

        if (isWizardModeCheck) {
          return isInCheckedState && isWizardMode;
        }
        return isInCheckedState;
      })
    );
  }

  isKycFlagIsState$$(states: IKYCQuestionnaireFlagsType[], isWizardModeCheck: boolean): Observable<boolean> {
    return combineLatest([
      this._profileQuery.isWizardMode$$,
      this._requiredDocumentsQuery.kycQuestionnaire$$
    ]).pipe(
      map(([isWizardMode, kycQuestionnaireFlag]) => {
        const isInCheckedState: boolean = kycQuestionnaireFlag != null
          ? states.map((state): string => KYCQuestionnaireFlags[state]).includes(kycQuestionnaireFlag.toLowerCase()) : false;

        if (isWizardModeCheck) {
          return isInCheckedState && isWizardMode;
        }
        return isInCheckedState;
      })
    );
  }

  updateRequiredDocuments$(): Observable<void> {
    return combineLatest([
      this._calculatorQuery.select(x => x.sourceAmount),
      this._calculatorQuery.select(x => x.sourceCurrency),
      this._gatewayQuery.selectActive<IGateway>(),
      this._requiredDocumentsQuery.isMockDataUsed$$
    ]).pipe(
      take(1),
      map(([sourceAmount, sourceCurrency, gw, isMockDataUsed]): [IRequiredDocumentsRequest, boolean] => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result: any = {};
        if (gw != null) {
          result.directionId = gw.directionId;
        }
        if (sourceAmount != null) {
          result.sourceAmount = sourceAmount.toFixed(2);
        }

        if (sourceCurrency != null) {
          result.sourceCurrencyId = sourceCurrency.id.toString(10);
        }
        return [result, isMockDataUsed];
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      switchMap(([params, isMockDataUsed]) => isMockDataUsed ? of(void 0) : this._http.get<IRequiredDocumentsResponse>('profile/required-documents-flags', { params: { ...params } })),
      tap((response: IRequiredDocumentsResponse | undefined) => {
        if (response != null) {
          this._requiredDocumentsStore.update({ ...response });
        }
      }),
      mapTo(void 0)
    );
  }

  updateRequiredDocumentsWithMockData(newFlags: Partial<IRequiredDocumentsResponse>): void {
    if (this._isRefusedToSendByPD(newFlags) || this._isRefusedToSendByID(newFlags) || this._isRefusedToSendByKYC(newFlags)
      || this._isRefusedToSendByIDV(newFlags) || this._isRefusedToSendByBAO(newFlags) || this._isRefusedToSendByPOA(newFlags) || this._isRefusedToSendBySOI(newFlags)
      || this._isRefusedToSendByED(newFlags) || this._isRefusedToSendByEVP(newFlags)
    ) {
      newFlags.refusedToSend = true;
    }
    this._requiredDocumentsStore.update({ ...newFlags });
  }

  setIsMockDataUsed(isMockDataUsed: boolean): void {
    this._requiredDocumentsStore.update({ isMockDataUsed });
  }

  checkAwaitingFlags$(isBankAccountOwnershipCheck?: boolean, profileTab?: EProfileTabs): Observable<void> {
    return combineQueries([
      this._requiredDocumentsQuery.isPaymentAwaiting$$,
      this._requiredDocumentsQuery.isIDVerificationWait$$,
      this._requiredDocumentsQuery.isProofOfAddressWait$$,
      this._requiredDocumentsQuery.isSourceOfIncomeWait$$,
      this._requiredDocumentsQuery.isExtraDocumentsWait$$
    ]).pipe(
      take(1),
      concatMap(([isPaymentAwaiting, isIDVerificationWait, isProofOfAddressWait, isSourceOfIncomeWait, isExtraDocumentsWait]) => {
        let awaitingMessage: string;
        if (isPaymentAwaiting && isBankAccountOwnershipCheck !== false) {
          awaitingMessage = this._localizationQuery.transform('%[send.error.message.wait-transfer-confirmation]%');
          return this._openAwaitingModal$(awaitingMessage);
        }
        if (isIDVerificationWait && profileTab == null) {
          awaitingMessage = this._localizationQuery.transform('%[send.error.message.check-identity-document]%');
          return this._openAwaitingModal$(awaitingMessage);
        }
        if (profileTab == null ? isProofOfAddressWait : isProofOfAddressWait && profileTab === EProfileTabs.AdditionalDocuments) {
          awaitingMessage = this._localizationQuery.transform('%[send.error.message.check-poa]%');
          return this._openAwaitingModal$(awaitingMessage);
        }
        if (profileTab == null ? isSourceOfIncomeWait : isSourceOfIncomeWait && profileTab === EProfileTabs.AdditionalDocuments) {
          awaitingMessage = this._localizationQuery.transform('%[send.error.message.check-soi]%');
          return this._openAwaitingModal$(awaitingMessage);
        }
        if (profileTab == null ? isExtraDocumentsWait : isExtraDocumentsWait && profileTab === EProfileTabs.AdditionalDocuments) {
          awaitingMessage = this._localizationQuery.transform('%[send.error.message.check-extra-documents]%');
          return this._openAwaitingModal$(awaitingMessage);
        }
        return of(void 0);
      })
    );
  }

  private _openAwaitingModal$(message: string): Observable<void> {
    return this._dialogService.openWizardAwaiting$(message).pipe(
      concatMap(() => EMPTY)
    );
  }

  private _isRefusedToSendByPD(newFlags: Partial<IRequiredDocumentsResponse>): boolean {
    return newFlags.personalData != null && newFlags.personalData.toLowerCase() === 'required';
  }

  private _isRefusedToSendByID(newFlags: Partial<IRequiredDocumentsResponse>): boolean {
    return newFlags.identityDocument != null && (newFlags.identityDocument.toLowerCase() === 'required' || newFlags.identityDocument.toLowerCase() === 'expired');
  }

  private _isRefusedToSendByKYC(newFlags: Partial<IRequiredDocumentsResponse>): boolean {
    return newFlags.kycQuestionnaire != null && (newFlags.kycQuestionnaire.toLowerCase() === 'required' || newFlags.kycQuestionnaire.toLowerCase() === 'expired');
  }

  private _isRefusedToSendByIDV(newFlags: Partial<IRequiredDocumentsResponse>): boolean {
    return newFlags.identityDocumentVerification != null && (newFlags.identityDocumentVerification.toLowerCase() === 'noattach' || newFlags.identityDocumentVerification.toLowerCase() === 'wait'
      || newFlags.identityDocumentVerification.toLowerCase() === 'badattach' || newFlags.identityDocumentVerification.toLowerCase() === 'onverification');
  }

  private _isRefusedToSendByBAO(newFlags: Partial<IRequiredDocumentsResponse>): boolean {
    return newFlags.bankAccountOwnership != null && newFlags.bankAccountOwnership.toLowerCase() === 'wait';
  }

  private _isRefusedToSendByPOA(newFlags: Partial<IRequiredDocumentsResponse>): boolean {
    return newFlags.proofOfAddress != null && (newFlags.proofOfAddress.toLowerCase() === 'required' || newFlags.proofOfAddress.toLowerCase() === 'noattach'
      || newFlags.proofOfAddress.toLowerCase() === 'wait' || newFlags.proofOfAddress.toLowerCase() === 'badattach' || newFlags.proofOfAddress.toLowerCase() === 'expired');
  }

  private _isRefusedToSendBySOI(newFlags: Partial<IRequiredDocumentsResponse>): boolean {
    return newFlags.sourceOfIncome != null && (newFlags.sourceOfIncome.toLowerCase() === 'required' || newFlags.sourceOfIncome.toLowerCase() === 'noattach'
      || newFlags.sourceOfIncome.toLowerCase() === 'wait' || newFlags.sourceOfIncome.toLowerCase() === 'badattach' || newFlags.sourceOfIncome.toLowerCase() === 'expired');
  }

  private _isRefusedToSendByED(newFlags: Partial<IRequiredDocumentsResponse>): boolean {
    return newFlags.extraDocuments != null && (newFlags.extraDocuments.toLowerCase() === 'required' || newFlags.extraDocuments.toLowerCase() === 'noattach'
      || newFlags.extraDocuments.toLowerCase() === 'wait' || newFlags.extraDocuments.toLowerCase() === 'badattach' || newFlags.extraDocuments.toLowerCase() === 'expired');
  }

  private _isRefusedToSendByEVP(newFlags: Partial<IRequiredDocumentsResponse>): boolean {
    return newFlags.emailValidatePossession != null && newFlags.emailValidatePossession.toLowerCase() === 'required';
  }

}
