import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withTransaction } from '@datorama/akita';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mapTo, switchMap, take } from 'rxjs/operators';
import { EXISTING_FIELDS, FIELDS_WILL_DE_IGNORED } from './ignored-fields';
import { IRequiredField, IRequiredFieldResponseModel, RequiredFieldsStore } from './required-fields.store';
import { SnackbarService } from '../../functionality-modules/snackbars/snackbar.service';
import { BankAccountTypesService } from '../bank-account-types/bank-account-types.service';
import { BanksService } from '../banks/banks.service';
import { uuidv4 } from '../../utils/functions/uuid';

@Injectable()
export class RequiredFieldsService {

  constructor(
    private _requiredFieldsStore: RequiredFieldsStore,
    private _http: HttpClient,
    private _snackbarService: SnackbarService,
    private _bankAccountTypesService: BankAccountTypesService,
    private _banksService: BanksService
  ) { }

  getRequiredFields$(directionId: string | null): Observable<void> {
    if (directionId == null) {
      return EMPTY;
    }
    return of(0).pipe(
      withTransaction(() => {
        this._requiredFieldsStore.reset();
        this._requiredFieldsStore.setLoading(true);
      }),
      switchMap(() => this._http.get<IRequiredFieldResponseModel[]>('reference/required-fields', { params: { directionId } })),
      map((requiredFields: IRequiredField[]): IRequiredField[] => {
        const willBeIgnoredFieldNames = FIELDS_WILL_DE_IGNORED.map(field => field.name);
        const existingFieldNames = EXISTING_FIELDS.map(field => field.name);
        return requiredFields?.filter(field => ![...willBeIgnoredFieldNames, ...existingFieldNames].includes(field.name));
      }),
      map((requiredFields: IRequiredField[]) => requiredFields?.map((requiredField: IRequiredField) => ({ ...requiredField, id: uuidv4() }))),
      withTransaction((requiredFields: IRequiredField[]) => {
        if (requiredFields != null) {
          this._requiredFieldsStore.upsertMany(requiredFields);
        }
        this._requiredFieldsStore.setLoading(false);
      }),
      switchMap((requiredFields: IRequiredField[]) => {
        const reqFieldNames = requiredFields?.map(requiredField => requiredField.name) ?? [];
        const streams: Observable<void | undefined>[] = [of(void 0)];

        if (reqFieldNames.includes('BeneficiaryBankCode')) {
          streams.push(
            this._banksService.getBanks$(directionId)
          );
        }

        if (reqFieldNames.includes('BeneficiaryAccountType')) {
          streams.push(
            this._bankAccountTypesService.getBankAccountTypes$(directionId)
          );
        }
        return combineLatest(streams);
      }),
      take(1),
      catchError((e: Error) => {
        this._requiredFieldsStore.setLoading(false);
        return this._snackbarService.openApiError$(e);
      }),
      mapTo(void 0)
    );
  }
}
