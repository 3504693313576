import { NgModule } from '@angular/core';
import { RequiredFieldsService } from './required-fields.service';
import { RequiredFieldsQuery, RequiredFieldsStore } from './required-fields.store';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    RequiredFieldsService,
    RequiredFieldsStore,
    RequiredFieldsQuery,
  ],
})
export class RequiredFieldsModule {}
