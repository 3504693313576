import { Injectable } from '@angular/core';
import { from, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, concatMap, map, mapTo, tap } from 'rxjs/operators';
import { Loader } from '@googlemaps/js-api-loader';
import GeocoderStatus = google.maps.GeocoderStatus;
import { isNullOrEmpty, randomString } from '@ff/utils';
import { environment } from '../../../environments/environment';

@Injectable()
export class GoogleMapsService {
  private _geocoder: google.maps.Geocoder;
  private _googleInnerErrorTrigger$$: ReplaySubject<string> = new ReplaySubject<string>(1);

  googleInnerErrorTrigger$$: Observable<string> = this._googleInnerErrorTrigger$$.asObservable();

  constructor() {
    // @ts-ignore
    window.gm_authFailure = () => this.handleGoogleErrors();
  }

  init(): Observable<void> {
    const loader = new Loader({
      apiKey: environment.google.maps.apiKey,
      version: 'weekly',
    });
    return from(loader.load()).pipe(
      concatMap(() => from(google.maps.importLibrary('geocoding'))),
      tap((lib) => {
        const geocoder = (lib as google.maps.GeocodingLibrary).Geocoder;
        this._geocoder = geocoder.prototype;
      }),
      mapTo(void 0)
    );
  }


  checkAddress(address: string | null): Observable<boolean> {
    if (this._geocoder == null || isNullOrEmpty(address)) {
      return of(false);
    }
    let geocoderStatus: GeocoderStatus;
    return from(this._geocoder.geocode({ address }, (results, status) => {
      geocoderStatus = status;
    })).pipe(
      map((response) => {
        const results = response.results;
        if (geocoderStatus === google.maps.GeocoderStatus.OK) {
          // Get the formatted Google result
          const geometry: google.maps.GeocoderGeometry | null = results[0]?.geometry ?? null;

          /* Count the commas in the fomatted address. This doesn't look great, but it helps us understand how specific the geocoded address is.  For example, "CA" will geocde to "California, USA". */
          const numCommas: number = address?.match(/,/g)?.length ?? 0;

          /* A full street address will have at least 2 commas.  Alternate techniques involve fetching the address_components returned by Google Maps */
          if (geometry == null || (geometry.location_type === 'APPROXIMATE') || numCommas < 2) {
            /* Google Maps was able to geocode the address, but it wasn't specific enough (not enough commas) to be a valid street address. */
            return false;
          } else {
            // We have a valid geocoded address
            return true;
          }
        // Otherwise the address is invalid
        } else {
          return false;
        }
      }),
      catchError(err => of(false))
    );
  }

  handleGoogleErrors(): void {
    this._googleInnerErrorTrigger$$.next(randomString());
  }
}
