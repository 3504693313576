import { NgModule } from '@angular/core';
import { DoctypesIdentityService } from './doctypes-identity.service';
import { DocTypesIdentityQuery, DocTypesIdentityStore } from './doctypes-identity.store';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    DoctypesIdentityService,
    DocTypesIdentityStore,
    DocTypesIdentityQuery,
  ],
})
export class DocTypesIdentityModule {}
