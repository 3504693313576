import { FFThemeCompanyName, FFThemeVendor } from '../online-app/data-modules/theme/theme.service';
import { FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field/form-field';
import { ISiteLocalizationModel } from '../online-app/data-modules/site-localization/site-localization.model';
import { MatDateFormats } from '@angular/material/core';

export enum EFreshChatChannel {
  'devSite' = 'public_site',
  'bestPayOnline' = 'private_bestpay_site',
  'everestremitOnline' = 'public_everestremit_site',
  'remitFinchOnline' = 'public_remitfinch_site'
}


export interface IEnvironment {
  production: boolean,
  baseUrl: string,
  webSocketHostOrigin: string,
  onlineLocationOrigin: string,
  stNotificationsUrl: string,

  thirdPartyServices: {
    checkoutScriptUrl: string,
    castlePublicKey: string,

    paymentForm: {
      checkoutcom: {
        checkoutURI: string,
        checkout3ds: boolean
      },
      transact365: {
        test: boolean,
        checkoutURI: string,
        description: string
      }
    },

    freshchat: {
      enable: boolean,
      apiKey: string,
      host: string,
      widgetUrl: string,
      tags: EFreshChatChannel,
      // description: http://confluence.bmservice.local:8090/pages/viewpage.action?pageId=12517537
      fillColor: string
    },

    sentryKey: string
  },

  appSettings: {
    controls: {
      inputDebounceTime: number,
      appearance: MatFormFieldAppearance,
      floatLabel: FloatLabelType,
      inputFocusDuration: number,
      dateFormat: MatDateFormats,
      passwordMinLength: number,
      passwordMaxLength: number
  },
    tooltip: {
      duration: number,
      throttleTime: number
    },
    snackbar: {
      duration: number,
      shortDuration: number,
      longDuration: number
    },
    locale: {
      date: string,
      time: string
    },
    mobileApp: {
      androidUrl: string | null,
      iosUrl: string | null,
      galleryUrl: string | null
    },
    identityDocsCheckFlow: {
      name: 'SUMSUB' | 'IDENFY',
      checkResultsDuration: number
    },
    payoutLocations: {
      pagePointsSize: number,
      pageCitiesSize: number
    },
    errorCodes: {
      ratesHasChangedErrorCode: number
    },
    showInactiveBlock: boolean,
    bankAccountOwnershipTimeoutMs: number | null,
    geoIpRequestTimeoutMs: number | null,
    defaultCalculatorSourceCurrency: string,
    isPersonalInfoEmailAlwaysRequired: boolean,
    defaultAuthCountryIso2Code: string,
    showEmailNotificationCheckbox: boolean,
    isAdditionalDocsShouldHaveScans: boolean,
    extraDocuments: {
      maxFileSize: number
    }
  },

  google: {
    captcha: {
      gcaptchaEnabled: boolean,
      gcaptchaSitekey: string
    },
    maps: {
      apiKey: string
    },
    googleTagManagerId: string,
    googleAnalyticsId: string
  },

  vendor: {
    company: {
      name: FFThemeCompanyName,
      siteAddress: string,
      contactUsExternalUrl: string,
      siteLocales: ISiteLocalizationModel[]
    },
    theme: {
      name: FFThemeVendor,
      devMode: boolean,
      autoDarkMode: boolean,
      isUseCustomLogo: boolean
    }
  }
}
