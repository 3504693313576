import { NgModule } from '@angular/core';

import { BankBranchesService } from './bank-branches.service';
import { BankBranchesQuery, BankBranchesStore } from './bank-branches.store';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    BankBranchesService,
    BankBranchesStore,
    BankBranchesQuery,
  ],
})
export class BankBranchesModule {}
