import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIQuery, EntityUIStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { defer, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDocumentBody, IDocumentBodyUI } from '../../../../private-models/document.model';
import { AttachmentStatus } from '../../../../private-models/attachment.model';
import { CountryRiskRating } from '../../../../../../data-modules/id-issued-countries/id-issued-countries.model';

interface IIdentityDocumentsState extends EntityState<IDocumentBody, string> {}

export interface IIdDocsUIState extends EntityState<IDocumentBodyUI, string> {
  newDocFormIsOpen: boolean
}

@StoreConfig({ name: 'identity-documents' })
export class IdentityDocumentsStore extends EntityStore<IIdentityDocumentsState> {
  override ui: EntityUIStore<IIdDocsUIState>;

  constructor() {
    super();
    this.createUIStore();
  }
}

@Injectable()
export class IdentityDocumentsQuery extends QueryEntity<IIdentityDocumentsState> {
  override ui: EntityUIQuery<IIdDocsUIState>;

  state$$: Observable<IIdentityDocumentsState> = this.selectAll();
  stateUi$$: Observable<IIdentityDocumentsState> = defer(() => this.ui.selectAll());

  isNewExpanded$$: Observable<boolean> = defer(() => this.ui.select(x => x.newDocFormIsOpen));
  isPrimaryDocumentNotExists$$: Observable<boolean> = this.selectAll().pipe(
    map((idDocs: IDocumentBody[]): IDocumentBody | undefined => idDocs.find((doc) => doc.document.isPrimary)),
    map((idDoc: IDocumentBody | undefined) => idDoc == null)
  );

  constructor(protected override store: IdentityDocumentsStore) {
    super(store);
    this.createUIQuery();
  }

  isPrimaryDocumentExistsAndCountryHasRiskRating$$(riskRatings: CountryRiskRating[]): Observable<boolean> {
    return this.selectAll().pipe(
      map((idDocs: IDocumentBody[]): IDocumentBody | undefined => idDocs.find((doc) => doc.document.isPrimary)),
      map((idDoc: IDocumentBody | undefined) => {
        if (idDoc == null || idDoc.document.countryIssued == null) {
          return false;
        }
        return riskRatings.includes(idDoc.document.countryIssued.riskRating);
      })
    );
  }

  isPrimaryDocumentScansInStatus$$(attachmentStatuses: AttachmentStatus[]): Observable<boolean> {
    return this.selectAll().pipe(
      map((idDocs: IDocumentBody[]): IDocumentBody | undefined => idDocs.find((x) => x.document.isPrimary)),
      map((doc: IDocumentBody | undefined) => {
        if (doc == null) {
          return false;
        }
        return doc.scans
          .map((s) => s.attachmentFileStatus)
          .filter((s) => s != null)
          .some(dto => dto != null ? attachmentStatuses.includes(dto.alias) : false);
      })
    );
  }
}
