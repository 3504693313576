import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { BaseService } from './base.service';
import { DashboardHistoryStore, IGetHistoryResultDto, IHistoryResults } from './dashboard-history.store';
import { catchError, concatMap, mapTo, tap } from 'rxjs/operators';
import { DialogService } from '../../../../../functionality-modules/dialog-windows/dialog.service';
import { DocumentStatus } from '../../../private-models/document-status';
import { LocalizationQuery } from '@ff/localization';
import { applyTransaction, logAction } from '@datorama/akita';
import { RequiredDocumentsService } from '../../../../../data-modules/required-documents/required-documents.service';

@Injectable()
export class DashboardHistoryService extends BaseService {

  constructor(
    private _historyStore: DashboardHistoryStore,
    private _http: HttpClient,
    private _dialogService: DialogService,
    private _localizationQ: LocalizationQuery,
    private _requiredDocumentsService: RequiredDocumentsService
  ) {
    super(_http);
  }

  public loadHistory$(): Observable<void> {
    const pageSize = 6;
    const documentStatuses: DocumentStatus[] = ['ReadyToPay', 'HQReady', 'Paid', 'Created', 'AwaitingDelivery', 'SentForDelivery']; // FFO-502, //FFO-1313
    const params: { [key: string]: string | string[] } = { offset: '0', pageSize: pageSize.toString(), documentStatuses };
    return super.loadGETCollectionToStore<IHistoryResults, IGetHistoryResultDto>('history', this._historyStore, resp => resp.results, params);
  }

  public cancelCreatedTx(txId: string): Observable<void> {
    return this._dialogService.openConfirmDialog$(this._localizationQ.transform('%[history.dialog.cancel]%')).pipe(
      concatMap((result: boolean) => {
        if (!result) {
          return EMPTY;
        }
        return this._cancelTransaction$(txId);
      })
    );
  }

  private _cancelTransaction$(txId: string): Observable<void> {
    return of(void 0)
      .pipe(
        tap(() => this._historyStore.setLoading(true)),
        tap(() => this._historyStore.updateTransactionId(txId)),
        concatMap(() => this._http.post(`remittance/cancel/${txId}`, {})),
        tap(() => this._historyStore.updateTransactionId(null)),
        concatMap(() => this._requiredDocumentsService.updateRequiredDocuments$()),
        concatMap(() => this.loadHistory$()),
        catchError(() => {
          applyTransaction(() => {
            logAction('DashboardHistoryService. Cancel transaction error');
            this._historyStore.updateTransactionId(null);
            this._historyStore.setLoading(false);
          });
          return EMPTY;
        }),
        mapTo(void 0)
      );
  }
}
