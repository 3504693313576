import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, interval, Observable, of, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo, skip, switchMap, take, tap } from 'rxjs/operators';
import { IFreshchatConfig, IFreshchatFirstResponse, IFreshchatSecondResponse } from './freshchat.models';
import { combineQueries } from '@datorama/akita';
import { ProfileQuery } from '../../root/private/private-store/profile.store';
import { PersonalDataFormQuery } from '../../root/private/private-pages/profile/personal-data/personal-data.store';
import { PersistenceQuery } from '../../data-modules/persist/persist.store';
import { ProfileAdditionalAttributesQuery } from '../../root/private/private-store/profile-additional-attributes.store';
import {
  ProfileAdditionalAttributesService
} from '../../root/private/private-store/profile-additional-attributes.service';

@Injectable()
export class FreshchatService {

  private _freshchatTrackById$$: Observable<string> = this._profileQuery.freshchatTrackById$$.pipe(
    distinctUntilChanged(),
    filter(item => item != null),
    map(item => item.toString(10))
  );

  private _freshChatFirstName$$: Observable<string | null> = this._personalDataFormQuery.name$$;
  private _freshChatLastName$$: Observable<string | null> = this._personalDataFormQuery.surname$$;
  private _freshChatEmail$$: Observable<string | null> = this._personalDataFormQuery.email$$;
  private _freshChatPhone$$: Observable<string> = this._persistenceQuery.phone$$;
  private _restoreId$$: Observable<string | null> = this._profileAdditionalAttributesQuery.restoreId$$;
  private _memberId$$: Observable<number> = this._profileQuery.memberId$$.pipe(
    map((memberId: number | null): number => memberId ?? 0)
  );

  private _freshChatConfig$: Observable<IFreshchatConfig> = combineQueries([
    this._freshchatTrackById$$,
    this._freshChatFirstName$$,
    this._freshChatLastName$$,
    this._freshChatEmail$$,
    this._freshChatPhone$$,
    this._restoreId$$,
    this._memberId$$
  ]).pipe(
    map(([
           externalId,
           firstName,
           lastName,
           email,
           phone,
           restoreId,
           customerId
         ]) =>
      ({ externalId, firstName, lastName, email, phone, restoreId, customerId })),
    take(1)
  );

  private _isFreshChatOpened$$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private _isFreshChatReady$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  elementName: string = 'freshchat';

  constructor(
    private _profileQuery: ProfileQuery,
    private _personalDataFormQuery: PersonalDataFormQuery,
    private _persistenceQuery: PersistenceQuery,
    private _profileAdditionalAttributesQuery: ProfileAdditionalAttributesQuery,
    private _profileAdditionalAttributesService: ProfileAdditionalAttributesService
  ) {
  }

  openDialog(): void {
    this._isFreshChatOpened$$.next((window?.fcWidget?.isOpen() ?? false));
  }

  reconstruct$(config: IFreshchatConfig, userCreatedFunc: (restoreId: string) => void): Observable<void> {
    if (!environment.thirdPartyServices.freshchat.enable) { return of(void 0); }
    return of(void 0).pipe(
      switchMap(() => this.destruct$()),
      tap(() => {
        const initFreshChat = (): void => {
          // @ts-ignore
          window.fcWidget.init({
            token: environment.thirdPartyServices.freshchat.apiKey,
            host: environment.thirdPartyServices.freshchat.host,
            externalId: config.externalId,
            restoreId: config.restoreId,
            tags: [environment.thirdPartyServices.freshchat.tags],
            config: {
              headerProperty: {
                backgroundColor: environment.thirdPartyServices.freshchat.fillColor
              }
            }
          });
          // @ts-ignore
          window.fcWidget.user.get((resp: IFreshchatFirstResponse) => {
            // console.log('response', resp);
            // @ts-ignore
            window.fcWidget.user.setProperties({
              firstName: config.firstName,              // user’s first name
              lastName: config.lastName,                // user’s last name
              email: config.email,    // user’s email address
              phone: config.phone,          // phone number without country code
              customerId: config.customerId,
              profileId: config.externalId,
            });

            // @ts-ignore
            window.fcWidget.on('user:created', (secondResponse: IFreshchatSecondResponse) => {
              if (secondResponse.status === 200) {
                const restoreId = secondResponse.data.restoreId;

                if (restoreId != null) {
                  userCreatedFunc(restoreId);
                }
              }
            });
          });
        };

        const e = document.createElement('script');
        e.id = this.elementName;
        e.async = true;
        e.src = environment.thirdPartyServices.freshchat.widgetUrl;
        e.onload = initFreshChat;
        document.head.appendChild(e);
      })
    );
  }

  destruct$(): Observable<void> {
    return of(void 0)
      .pipe(
        tap(() => {
          // @ts-ignore
          if (window.fcWidget != null) {
            // @ts-ignore
            window.fcWidget.destroy();
          }
          if (document.getElementById(this.elementName) != null) {
            document.getElementById(this.elementName)?.remove();
          }
        }));
  }

  createChat$(): Observable<void> {
    return this._freshChatConfig$.pipe(
      take(1),
      switchMap((freshchatConfig: IFreshchatConfig) => {
        const updateFn = (restoreId: string): void => { this._profileAdditionalAttributesService.setAdditionalAttribute$({ restoreId }).subscribe(); };

        return this.reconstruct$(freshchatConfig, updateFn);
      }),
      mapTo(void 0)
    );
  }

  subscribeOnFreshChat$(): Observable<void> {
    return interval(1000).pipe(
      take(60),
      filter(() => window.fcWidget?.isInitialized() ?? false),
      take(1),
      tap(() => {
        if (window.fcWidget != null) {
          this._isFreshChatReady$$.next(window.fcWidget.isInitialized());
          this._isFreshChatOpened$$.next(window.fcWidget?.isOpen());
        }
      }),
      switchMap(() => this._isFreshChatOpened$$),
      skip(1),
      tap(state => state === true ? window.fcWidget?.close() : window.fcWidget?.open()),
      mapTo(void 0)
    );
  }

}
