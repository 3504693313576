import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withTransaction } from '@datorama/akita';
// @ts-ignore
import { saveAs } from 'file-saver';
import { combineLatest, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mapTo, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { LocationsQuery, LocationsStore } from './locations.store';
import { PointsService } from '../../../data-modules/points/points.service';
import { GatewaysQuery } from '../../../data-modules/gateways/gateways.store';
import { CalculatorQuery } from '../../../data-modules/calculator/calculator.store';
import { PointsQuery, PointsStore } from '../../../data-modules/points/points.store';
import { ILookupStr } from '../../../root/private/private-models/lookup-str';
import { IPoint } from '../../../data-modules/points/points.model';
import { convertBase64ToBlob } from '../../../utils/functions/convert-base64-to-blob';

interface IReceiptResult {
  extension: string,
  bytes: string
}

interface IReceiptResponseModel {
  extension: string,
  bytes: string
}

@Injectable()
export class LocationsService {

  constructor(
    private _locationsStore: LocationsStore,
    private _locationsQuery: LocationsQuery,
    private _pointsService: PointsService,
    private _gatewaysQuery: GatewaysQuery,
    private _calculatorQuery: CalculatorQuery,
    private _pointsQuery: PointsQuery,
    private _pointsStore: PointsStore,
    private _http: HttpClient
  ) { }

  setCity(city: ILookupStr): Observable<void> {
    return of(void 0).pipe(
      tap(() => this._locationsStore.update(x => ({ ...x, city }))),
      switchMap(() => this.getPoints()),
      tap(() => this.selectFirstPoints()),
      mapTo(void 0)
    );
  }

  selectFirstPoints(count: number = 5): void {
    const activeGateway = this._gatewaysQuery.getActive();
    if (activeGateway != null) {
      const allPoints = this._pointsQuery.getAll();
      const selectedPointsCount = allPoints.filter(p => p.selected === true).length;
      if (selectedPointsCount === 0) {
        let pointsToUpdate: IPoint[] = allPoints.slice(0, count > 0 ? count : 0);
        pointsToUpdate = pointsToUpdate.map((p): IPoint => ({ ...p, selected: true }));
        this._pointsStore.upsertMany(pointsToUpdate);
      }
    }
  }

  setSearch(search: string): Observable<void> {
    return of(void 0).pipe(
      withTransaction(() => this._locationsStore.update(x => ({ ...x, search }))),
      switchMap(() => this.getPoints()),
      mapTo(void 0)
    );
  }

  setAllLocations(value: boolean, triggerEffect: boolean = true): Observable<void> {
    return of(void 0).pipe(
      withTransaction(() => {
        this._locationsStore.update(x => ({ ...x, allLocations: value, allPartners: false }));
      }),
      switchMap(() => triggerEffect ? this.getPoints() : of(void 0)),
    );
  }

  setAllPartners(value: boolean): Observable<void> {
    return of(void 0).pipe(
      switchMap(() => this._pointsService.disabledAllPoints$(value))
    );
  }

  getPoints(): Observable<void> {
    return combineLatest([
      this._calculatorQuery.select(x => x.destinationCountry),
      this._locationsQuery.select(x => x.city),
      this._locationsQuery.select(x => x.search),
      this._locationsQuery.select(x => x.allLocations),
      this._gatewaysQuery.selectActive()
    ]).pipe(
      take(1),
      switchMap(([destinationCountry, city, search, isAllLocations, activeGateway]) => {

        if (destinationCountry == null || city == null || [1, 2].includes(search.length)) {
          return of(void 0);
        }

        if (activeGateway != null) {
          return this._pointsService.getPoints$(destinationCountry, city.title, search, activeGateway);
        }

        if (isAllLocations) {
          return this._pointsService.getPoints$(destinationCountry, city.title, search);
        }

        return this._pointsService.getPoints$(destinationCountry, city.title, search);
      })
    );
  }

  downloadPointsPdf(): void {
    this._pointsQuery.selectAll().pipe(
      take(1),
      map(points => points.filter(x => x.selected === true)),
      map((points): number[] => points.map(p => p.pointId)),
      filter(ids => ids.length > 0),
      mergeMap(ids => this._getPdfBytes$(ids))
    ).subscribe(receipt => {
      const blob = convertBase64ToBlob(receipt.bytes, 'application/octet-stream');
      saveAs(blob, 'receive_locations.pdf');
    });
  }

  expandOrCollapseExpansionPanel(pointId: number, isExpand: boolean): void {
    this._pointsStore.update(pointId, x => {
      if ('expanded' in x) {
        return ({ ...x, expanded: isExpand });
      }
      return { ...x };
    });
  }

  selectedCheckbox(pointId: number): void {
    this._pointsStore.upsert(pointId, x => {
      if ('selected' in x) {
        return ({ ...x, selected: !x.selected });
      }
      return { ...x };
    });
    const selectedPointsLength = this._pointsQuery.getAll().filter(p => p.selected === true).length;
    // reset "select all" value if no points are selected
    if (this._locationsStore.getValue().allPartners && selectedPointsLength === 0) {
      this._locationsStore.update({ allPartners: false });
    }
  }

  private _getPdfBytes$(pointIds: number[]): Observable<IReceiptResult> {
    return of(0).pipe(
      tap(() => this._locationsStore.update(state => ({ ...state, isPdfDownloading: true }))),
      switchMap((): Observable<IReceiptResponseModel> => {
        const params: { [key: string]: string[] } = { id: pointIds.map(x => x.toString(10)) };
        return this._http.get<IReceiptResponseModel>(`reports/points-by-id`, { params });
      }),
      catchError(() => {
        this._locationsStore.update(state => ({ ...state, isPdfDownloading: false }));
        return EMPTY;
      }),
      tap(() => this._locationsStore.update(state => ({ ...state, isPdfDownloading: false }))),
      catchError(err => {
        this._locationsStore.update(state => ({ ...state, isPdfDownloading: false }));
        return throwError(err);
      }));
  }


}
