<button mat-icon-button class="btn-mat-icon btn-mat-close" (click)="onCloseClick()">
  <i class="ff-icon ff-close icon-size-3"></i>
</button>
<mat-dialog-content class="sumsub-content">
  <ng-container *ngIf="isLoading$$ | async">
    <div class="row">
      <div class="col-12 spinner-wrapper">
        <app-spinner class="d-block" [isFullScreen]="false"></app-spinner>
      </div>
    </div>
  </ng-container>
  <div *ngIf="hasToken$$ | async">
    <app-sumsum-websdk-container
      [token]="token$$ | async"
      [language]="language$$ | async"
      [documentDefinitions]="documentDefinitions$$ | async"
      (isIdWasScanned)="onIdWasScanned($event)"
      (isSumsubReady)="onSumsubReady($event)"
    ></app-sumsum-websdk-container>
  </div>
  <h3 *ngIf="isIdWasScanned$$ | async" class="checked-info">{{ '%[sumsub.label.we-check-doc-info]%' | tr }}</h3>
</mat-dialog-content>
