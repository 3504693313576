import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { IPaymentGatewayParams } from '../../../store/send.store';
import { SnackbarService } from '../../../../../../../functionality-modules/snackbars/snackbar.service';
import { LocalizationQuery } from '@ff/localization';
import { environment } from '../../../../../../../../environments/environment';

export interface ICheckoutHostedPaymentsResponse {
  reference?: string,
  _links: {
    redirect: {
      href: string
    }
  }
}


@Injectable()
export class CheckoutPaymentService {
  private _http: HttpClient;

  constructor(
    backend: HttpBackend,
    private _router: Router,
    private _snackbarService: SnackbarService,
    private _localizationQuery: LocalizationQuery
  ) {
    this._http = new HttpClient(backend); // to ignore interceptor
  }

  public startConnection(paymentGatewayParams: IPaymentGatewayParams): Observable<string> { // start checkout service
    // calculate devider by currency by https://docs.checkout.com/resources/calculating-the-value#Calculatingthevalue-fullvalue
    // const devider = this._getDevider(paymentGatewayParams.amountInfo?.totalAmountCurrency);
    return of(0).pipe(
      switchMap(() => {
        const body = {
          'amount': paymentGatewayParams.amountInfo?.totalAmount,
          'currency': paymentGatewayParams.amountInfo?.totalAmountCurrency,
          'billing': {
            address: {
              country: paymentGatewayParams.remitterInfo?.address?.countryCode
            }
          },
          'customer': {
            name: `${paymentGatewayParams.remitterInfo?.name} ${paymentGatewayParams.remitterInfo?.surname}`,
            email: paymentGatewayParams.remitterInfo?.email
          },
          'reference': paymentGatewayParams.orderreference,
          'successUrl': `${paymentGatewayParams.redirectEndpoints?.successUrl}/${paymentGatewayParams.orderreference}/OK`,
          'failureUrl': `${paymentGatewayParams.redirectEndpoints?.failureUrl}/${paymentGatewayParams.orderreference}/Failed`,
          'cancelUrl': `${paymentGatewayParams.redirectEndpoints?.cancelUrl}/private/history`,
          'metadata': {
            mtnotificationurl: paymentGatewayParams.mtNotificationUrl,
            mtcancelonfail: paymentGatewayParams.mtCancelOnFail,
            sitereference: paymentGatewayParams.siteReference,
            merchantid: paymentGatewayParams.merchantId,
            stamp: paymentGatewayParams.stamp,
            sitesecuritytimestamp: paymentGatewayParams.sitesecuritytimestamp,
            sitesecurity: paymentGatewayParams.hash,
            paymenttype: 'Card'
          },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '3rs': {
            enabled: environment.thirdPartyServices.paymentForm.checkoutcom.checkout3ds
          }
        };

        const headers = new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization', paymentGatewayParams.token);

        return this._http.post<ICheckoutHostedPaymentsResponse>(`${environment.thirdPartyServices.paymentForm.checkoutcom.checkoutURI}/hosted-payments`, body, { headers });
      }),
      catchError((err) => {
        console.error(err);
        this._router.navigate(['private', 'history']).then();
        this._snackbarService.openFailure$(this._localizationQuery.transform('%[send.snackbar.start-failed]%') + ':' + err.message).subscribe();
        return EMPTY;
      }),
      map(r => r._links.redirect.href)
    );
  }
}
