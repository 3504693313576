import { NgModule } from '@angular/core';

import { FfSlideToggleComponent } from './ff-slide-toggle.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  imports: [
    MatCheckboxModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatSlideToggleModule
  ],
  exports: [FfSlideToggleComponent],
  declarations: [FfSlideToggleComponent]
})
export class FfSlideToggleModule {}
