import { NgModule } from '@angular/core';

import { ClockSpinnerComponent } from './clock-spinner.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,

  ],
  exports: [ClockSpinnerComponent],
  declarations: [ClockSpinnerComponent],
  providers: [],
})
export class ClockSpinnerModule {}
