import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Observable, timer } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { LocalizationQuery } from '@ff/localization';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-snackbar-timer',
  templateUrl: 'snackbar-timer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SnackbarTimerComponent {

  countDownMessage$$: Observable<string> = timer(0, 1000).pipe(
    map((timeElapsed: number): number => this.data.countdownTimer - timeElapsed),
    takeWhile(timeLeft => timeLeft > 0),
    map((timeLeft: number) => {
      return this._localizationQuery.transform('%[version-check-service.warning-reload-message-snackbar]%', { timeLeft: timeLeft.toString() });
    }),
    untilDestroyed(this)
  );

  constructor(
    private _localizationQuery: LocalizationQuery,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: { countdownTimer: number, title: string, icon: string },
    public snackbarRef: MatSnackBarRef<SnackbarTimerComponent>
  ) { }

  onNoClick(): void {
    this.snackbarRef.dismissWithAction();
  }

}
