import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { ICountry, ICountryResponseModel } from './countries.model';
import { catchError, mapTo, switchMap } from 'rxjs/operators';
import { CountriesStore } from './countries.store';
import { withTransaction } from '@datorama/akita';
import { ILookupStr } from '../../root/private/private-models/lookup-str';

@Injectable()
export class CountriesService {

  constructor(
    private _http: HttpClient,
    private _countriesStore: CountriesStore
  ) { }

  getCountries$(): Observable<void> {
    return of(0).pipe(
      withTransaction(() => {
        this._countriesStore.reset();
        this._countriesStore.setLoading(true);
      }),
      switchMap(() => this._http.get<ICountryResponseModel[]>('reference/countries')),
      catchError(() => {
        this._countriesStore.setLoading(false);
        return EMPTY;
      }),
      withTransaction(countries => {
        this._countriesStore.setLoading(false);
        this._countriesStore.upsertMany(
          countries
            .sort((a, b) => a.title.localeCompare(b.title))
            .map(c => ({ ...c, phoneCode: c.phoneCode.replace('+', '') }))
            .map(c => ({ ...c, weight: c.id === 'RUS' ? 1 : 0 }))
        );
      }),
      mapTo(void 0)
    );
  }

  transform(value: ILookupStr | null | undefined): ICountry | null {
    if (value == null) { return null; }
    const entities = this._countriesStore.getValue().entities;
    if (entities == null) { return null; }
    return entities[value.id] ?? null;
  }
}
