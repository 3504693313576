<mat-dialog-content class="dialog-content">
  <p mat-dialog-title>{{ '%[send.error.title.not-available]%' | tr }}</p>
  <div class="row">
    <div class="col-12">
      <app-img-svg class="dialog-content__img" [url]="themeService.getSharedImageUrl('img/girl.svg')"></app-img-svg>
      <p class="dialog-content__text">
        {{ '%[send.error.message.service-not-available]%' | tr }}
      </p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="row flex-grow-1 gy-5">
    <div class="col-12 col-sm-6 order-2 order-sm-1">
      <button (click)="onLogoutClick()" mat-stroked-button>{{ '%[send.error.button.logout]%' | tr }}</button>
    </div>
    <div class="col-12 col-sm-6 order-1 order-sm-2">
      <button (click)="onContactUsClick()" mat-flat-button color="primary">
        {{ '%[send.error.button.support]%' | tr }}
      </button>
    </div>
  </div>
</mat-dialog-actions>
