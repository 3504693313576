import { Injectable } from '@angular/core';
import { EMPTY, from, Observable, of } from 'rxjs';
import { concatMap, filter, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ProfileQuery, ProfileStore } from '../../../private-store/profile.store';
import { SnackbarService } from '../../../../../functionality-modules/snackbars/snackbar.service';
import { IDocumentBody } from '../../../private-models/document.model';
import { ISOIUIState, SourceOfIncomeStore, SourcesOfIncomeQuery } from './store/source-of-income.store';
import {
  IRCUIState,
  ResidenceConfirmationsQuery,
  ResidenceConfirmationsStore
} from './store/residence-confirmations.store';
import { Router } from '@angular/router';
import { SendService } from '../../send/containers/send/send.service';
import { SendQuery } from '../../send/store/send.store';
import { RequiredDocumentsService } from '../../../../../data-modules/required-documents/required-documents.service';
import { ProfileService } from '../../../private-store/profile.service';
import { LocalizationQuery } from '@ff/localization';
import { IdentityDocumentsQuery, IdentityDocumentsStore } from '../identity-documents/store/identity-documents.store';
import { UntilDestroy } from '@ngneat/until-destroy';
import { environment } from '../../../../../../environments/environment';
import { RequiredDocumentsQuery } from '../../../../../data-modules/required-documents/required-documents.store';
import { EProfileTabs } from '../../../private-models/profile-tabs.model';
import {
  AdditionalDocsFlags,
  IAdditionalDocsFlagsType,
  IdentityDocumentsFlags,
  IdentityDocumentsFlagsType,
  EIdentityDocumentVerificationFlags,
  IIdentityDocumentsVerificationFlagsType
} from '../../../../../data-modules/required-documents/required-documents.model';

export interface IQuestionnaireAnswer {
  customText: null | string,
  questionId: string,
  selectedValue: null | string
}

export interface IQuestionnaireData {
  answers: IQuestionnaireAnswer[],
  expiredDate: string
}

export interface IQuestionnaireDataResponseModel {
  answers: IQuestionnaireAnswer[],
  expiredDate: string
}

@UntilDestroy()
@Injectable()
export class AdditionalDocumentsService {

  constructor(
    private _profileQuery: ProfileQuery,
    private _profileStore: ProfileStore,
    private _profileService: ProfileService,
    private _snackbarService: SnackbarService,
    private _sourcesOfIncomeQ: SourcesOfIncomeQuery,
    private _sourcesOfIncomeStore: SourceOfIncomeStore,
    private _residenceConfirmationsQ: ResidenceConfirmationsQuery,
    private _residenceConfirmationsStore: ResidenceConfirmationsStore,
    private _identityDocumentsStore: IdentityDocumentsStore,
    private _identityDocumentsQ: IdentityDocumentsQuery,
    private _router: Router,
    private _sendService: SendService,
    private _sendQuery: SendQuery,
    private _requiredDocumentsService: RequiredDocumentsService,
    private _localizationQ: LocalizationQuery,
    private _requiredDocumentsQuery: RequiredDocumentsQuery
  ) { }

  wizardAfterEffects$(routeToSendOnSuccess: boolean): Observable<void> {
    return this._requiredDocumentsService.updateRequiredDocuments$().pipe(
      concatMap(() => this._profileService.updateProfile$()),
      concatMap(() => this._profileQuery.isWizardMode$$),
      take(1),
      concatMap(isWizardMode => isWizardMode ? this._wizardEffects$() : of(void 0)),
      concatMap(() => this._checkWizardAndRedirect(routeToSendOnSuccess))
    );
  }

  updatePoaUiState(newState: Partial<IRCUIState>): void {
    this._residenceConfirmationsStore.ui.update({ ...newState });
  }

  updateSoiUiState(newState: Partial<ISOIUIState>): void {
    this._sourcesOfIncomeStore.ui.update({ ...newState });
  }

  private _wizardEffects$(): Observable<void>{
    return this._requiredDocumentsService.isNewPersonalDataRequiredAndWizard$$.pipe(
      take(1),
      concatMap((isPersonalDataRequired: boolean) => this._checkPersonalData$(isPersonalDataRequired))
    ).pipe(
      concatMap(() => this._requiredDocumentsQuery.isIdentityDocumentRequired$$),
      take(1),
      concatMap((isIdentityDocumentRequired: boolean) => this._checkIdentityDocument$(isIdentityDocumentRequired))
    ).pipe(
      concatMap(() => this._requiredDocumentsQuery.isIDVerificationRequired$$),
      take(1),
      concatMap((isIDVerificationRequired: boolean) => this._checkIDVerification$(isIDVerificationRequired))
    ).pipe(
      concatMap(() => this._requiredDocumentsQuery.isKYCQuestionnaireRequiredOrExpired$$),
      take(1),
      concatMap((isKYCQuestionnaireRequired: boolean) => this._checkKYCQuestionnaire$(isKYCQuestionnaireRequired))
    ).pipe(
      concatMap(() => this._requiredDocumentsQuery.isProofOfAddressRequired$$),
      take(1),
      concatMap((isProofOfAddressRequired) => this._checkPOA$(isProofOfAddressRequired))
    ).pipe(
      concatMap(() => this._requiredDocumentsQuery.isSourceOfIncomeRequired$$),
      take(1),
      concatMap((isSourceOfIncomeRequired) => this._checkSOI$(isSourceOfIncomeRequired))
    ).pipe(
      concatMap(() => this._requiredDocumentsQuery.isExtraDocumentsRequired$$),
      take(1),
      concatMap((isExtraDocumentsRequired) => this._checkED$(isExtraDocumentsRequired))
    );
  }

  private _checkPersonalData$(isPersonalDataRequired: boolean): Observable<void> {
    if (isPersonalDataRequired) {
      return this._profileStore.setActiveTab$(EProfileTabs.PersonalInfo)
        .pipe(
          concatMap(() => this._snackbarService.openWarning(
            this._localizationQ.transform('%[flags.snackbar.message.pd-required]%'), {
              title: this._localizationQ.transform('%[flags.snackbar.title.pd-required]%'),
              duration: environment.appSettings.snackbar.longDuration
            })),
          concatMap(() => EMPTY)
        );
    }
    return of(void 0);
  }

  private _checkIdentityDocument$(isIdentityDocumentRequired: boolean): Observable<void> {
    if (isIdentityDocumentRequired) {
      return this._profileStore.setActiveTab$(EProfileTabs.IdentityDocuments)
        .pipe(
          tap(() => {
            this._identityDocumentsStore.ui.update(x => ({ ...x, newDocFormIsOpen: true }));
          }),
          concatMap(() => this._requiredDocumentsQuery.identityDocument$$),
          take(1),
          concatMap((identityDocument: IdentityDocumentsFlagsType | null) => {
            let message: string;
            let title: string;
            const identityDocumentFlag = identityDocument?.toLowerCase() ?? null;
            switch (identityDocumentFlag) {
              case IdentityDocumentsFlags.Required:
                message = this._localizationQ.transform('%[flags.snackbar.message.id-required-no-doc]%');
                title = this._localizationQ.transform('%[flags.snackbar.title.id-required-no-doc]%');
                break;
              case IdentityDocumentsFlags.Expired:
                message = this._localizationQ.transform('%[flags.snackbar.message.id-required-expired]%');
                title = this._localizationQ.transform('%[flags.snackbar.title.id-required-expired]%');
                break;
              default:
                message = '';
                title = '';
                break;
            }
            return this._snackbarService.openWarning(message, { title, duration: environment.appSettings.snackbar.longDuration });
          }),
          withLatestFrom(this._identityDocumentsQ.selectAll(), this._requiredDocumentsQuery.isIdentityDocumentExpired$$),
          tap(([_, idDocs, isDocExpired]) => {
            if (!isDocExpired) {
              const firstDoc = idDocs[0];
              if (firstDoc == null) { return; }
              this._identityDocumentsStore.ui.upsert(firstDoc.id, { isExpanded: true, isEdit: true });
            }
          }),
          concatMap(() => EMPTY)
        );
    }
    return of(void 0);
  }

  private _checkKYCQuestionnaire$(isKYCQuestionnaireRequired: boolean): Observable<void> {
    if (isKYCQuestionnaireRequired) {
      return this._profileStore.setActiveTab$(EProfileTabs.KYC)
        .pipe(
          concatMap(() => this._snackbarService.openWarning(
            this._localizationQ.transform('%[flags.snackbar.message.kyc-required]%'), {
              title: this._localizationQ.transform('%[flags.snackbar.title.kyc-required]%'),
              duration: environment.appSettings.snackbar.longDuration
            })),
          concatMap(() => EMPTY)
        );
    }
    return of(void 0);
  }

  private _checkIDVerification$(isIDVerificationRequired: boolean): Observable<void> {
    const badOrRequiredAttach$: Observable<void> = this._identityDocumentsQ.selectAll().pipe(
      take(1),
      tap((idDocs: IDocumentBody[]) => {
        const firstDoc = idDocs[0];
        if (firstDoc == null) { return; }
        this._identityDocumentsStore.ui.upsert(firstDoc.id, { isExpanded: true, isEdit: true });
      }),
      concatMap(() => EMPTY)
    );
    let identityDocumentVerificationFlag: string | null;
    if (isIDVerificationRequired) {
      return this._profileStore.setActiveTab$(EProfileTabs.IdentityDocuments)
        .pipe(
          concatMap(() => this._requiredDocumentsQuery.identityDocumentVerification$$),
          take(1),
          concatMap((identityDocumentVerification: IIdentityDocumentsVerificationFlagsType | null) => {
            let message: string;
            let title: string;
            identityDocumentVerificationFlag = identityDocumentVerification?.toLowerCase() ?? null;
            switch (identityDocumentVerification?.toLowerCase()) {
              case EIdentityDocumentVerificationFlags.Required:
                message = this._localizationQ.transform('%[flags.snackbar.message.id-required-no-attach]%');
                title = this._localizationQ.transform('%[flags.snackbar.title.id-required-no-attach]%');
                break;
              case EIdentityDocumentVerificationFlags.Wait:
                message = this._localizationQ.transform('%[flags.snackbar.message.id-required-wait]%');
                title = this._localizationQ.transform('%[flags.snackbar.title.id-required-wait]%');
                break;
              default:
                message = '';
                title = '';
                break;
            }
            return this._snackbarService.openWarning(message, { title, duration: environment.appSettings.snackbar.longDuration });
          }),
          concatMap(() => {
            if (identityDocumentVerificationFlag == null || identityDocumentVerificationFlag === 'wait') {
              return EMPTY;
            }
            return badOrRequiredAttach$;
          })
        );
    }
    return of(void 0);
  }

  private _checkPOA$(isPOAScansRequired: boolean): Observable<void> {
    let proofOfAddressFlag: string | null;
    if (isPOAScansRequired) {
      return this._profileStore.setActiveTab$(EProfileTabs.AdditionalDocuments)
        .pipe(
          concatMap(() => this._requiredDocumentsQuery.proofOfAddress$$),
          take(1),
          concatMap((proofOfAddress: IAdditionalDocsFlagsType | null) => {
            let message: string;
            let title: string;
            proofOfAddressFlag = proofOfAddress?.toLowerCase() ?? null;
            switch (proofOfAddressFlag) {
              case AdditionalDocsFlags.Required:
                message = this._localizationQ.transform('%[flags.snackbar.message.poa-required-no-attach]%');
                title = this._localizationQ.transform('%[flags.snackbar.title.poa-required-no-attach]%');
                break;
              case AdditionalDocsFlags.NoAttach:
                message = this._localizationQ.transform('%[flags.snackbar.message.poa-required-no-attach]%');
                title = this._localizationQ.transform('%[flags.snackbar.title.poa-required-no-attach]%');
                break;
              case AdditionalDocsFlags.Wait:
                message = this._localizationQ.transform('%[flags.snackbar.message.poa-required-wait]%');
                title = this._localizationQ.transform('%[flags.snackbar.title.poa-required-wait]%');
                break;
              case AdditionalDocsFlags.BadAttach:
                message = this._localizationQ.transform('%[flags.snackbar.message.poa-required-bad-attach]%');
                title = this._localizationQ.transform('%[flags.snackbar.title.poa-required-bad-attach]%');
                break;
              default:
                message = '';
                title = '';
                break;
            }
            return this._snackbarService.openWarning(message, { title, duration: environment.appSettings.snackbar.longDuration });
          }),
          concatMap(() => this._residenceConfirmationsQ.selectAll()),
          take(1),
          tap((rcs: IDocumentBody[]) => {
            if (proofOfAddressFlag === AdditionalDocsFlags.Required) {
              this._residenceConfirmationsStore.ui.update(x => ({ ...x, newDocFormIsOpen: true }));
            } else if (proofOfAddressFlag === AdditionalDocsFlags.NoAttach) {
              if (rcs.length > 0) {
                rcs.forEach((rc: IDocumentBody) => {
                  this._residenceConfirmationsStore.ui.upsert(rc.id, { isExpanded: true, isEdit: true });
                });
              } else {
                this._residenceConfirmationsStore.ui.update(x => ({ ...x, newDocFormIsOpen: true }));
              }
            } else {
              const firstDoc = rcs[0];
              if (firstDoc == null) { return; }
              this._residenceConfirmationsStore.ui.upsert(firstDoc.id, { isExpanded: true, isEdit: true });
            }
          }),
          concatMap(() => EMPTY)
        );
    }
    return of(void 0);
  }

  private _checkSOI$(isSOIScansRequired: boolean): Observable<void> {
    let sourceOfIncomeFlag: string | null;
    if (isSOIScansRequired) {
      return this._profileStore.setActiveTab$(EProfileTabs.AdditionalDocuments)
        .pipe(
          concatMap(() => this._requiredDocumentsQuery.sourceOfIncome$$),
          take(1),
          concatMap((sourceOfIncome: IAdditionalDocsFlagsType | null) => {
            let message: string;
            let title: string;
            sourceOfIncomeFlag = sourceOfIncome?.toLowerCase() ?? null;
            switch (sourceOfIncomeFlag) {
              case AdditionalDocsFlags.Required:
                message = this._localizationQ.transform('%[flags.snackbar.message.soi-required-no-attach]%');
                title = this._localizationQ.transform('%[flags.snackbar.title.soi-required-no-attach]%');
                break;
              case AdditionalDocsFlags.NoAttach:
                message = this._localizationQ.transform('%[flags.snackbar.message.soi-required-no-attach]%');
                title = this._localizationQ.transform('%[flags.snackbar.title.soi-required-no-attach]%');
                break;
              case AdditionalDocsFlags.Wait:
                message = this._localizationQ.transform('%[flags.snackbar.message.soi-required-wait]%');
                title = this._localizationQ.transform('%[flags.snackbar.title.soi-required-wait]%');
                break;
              case AdditionalDocsFlags.BadAttach:
                message = this._localizationQ.transform('%[flags.snackbar.message.soi-required-bad-attach]%');
                title = this._localizationQ.transform('%[flags.snackbar.title.soi-required-bad-attach]%');
                break;
              default:
                message = '';
                title = '';
                break;
            }
            return this._snackbarService.openWarning(message, { title, duration: environment.appSettings.snackbar.longDuration });
          }),
          concatMap(() => this._sourcesOfIncomeQ.selectAll()),
          take(1),
          tap((rcs: IDocumentBody[]) => {
            if (sourceOfIncomeFlag === AdditionalDocsFlags.Required) {
              this._sourcesOfIncomeStore.ui.update(x => ({ ...x, newDocFormIsOpen: true }));
            } else if (sourceOfIncomeFlag === AdditionalDocsFlags.NoAttach) {
              if (rcs.length > 0) {
                rcs.forEach((rc: IDocumentBody) => {
                  this._sourcesOfIncomeStore.ui.upsert(rc.id, { isExpanded: true, isEdit: true });
                });
              } else {
                this._sourcesOfIncomeStore.ui.update(x => ({ ...x, newDocFormIsOpen: true }));
              }
            } else {
              const firstDoc = rcs[0];
              if (firstDoc == null) { return; }
              this._sourcesOfIncomeStore.ui.upsert(firstDoc.id, { isExpanded: true, isEdit: true });
            }
          }),
          concatMap(() => EMPTY)
        );
    }
    return of(void 0);
  }

  private _checkED$(isEDScansRequired: boolean): Observable<void> {
    let extraDocumentsFlag: string | null;
    if (isEDScansRequired) {
      return this._profileStore.setActiveTab$(EProfileTabs.AdditionalDocuments)
        .pipe(
          concatMap(() => this._requiredDocumentsQuery.extraDocuments$$),
          take(1),
          concatMap((extraDocuments: IAdditionalDocsFlagsType | null) => {
            let message: string;
            let title: string;
            extraDocumentsFlag = extraDocuments?.toLowerCase() ?? null;
            switch (extraDocumentsFlag) {
              case AdditionalDocsFlags.Required:
                message = this._localizationQ.transform('%[flags.snackbar.message.ed-required-no-attach]%');
                title = this._localizationQ.transform('%[flags.snackbar.title.ed-required-no-attach]%');
                break;
              case AdditionalDocsFlags.NoAttach:
                message = this._localizationQ.transform('%[flags.snackbar.message.ed-required-no-attach]%');
                title = this._localizationQ.transform('%[flags.snackbar.title.ed-required-no-attach]%');
                break;
              case AdditionalDocsFlags.Wait:
                message = this._localizationQ.transform('%[flags.snackbar.message.ed-required-wait]%');
                title = this._localizationQ.transform('%[flags.snackbar.title.ed-required-wait]%');
                break;
              case AdditionalDocsFlags.BadAttach:
                message = this._localizationQ.transform('%[flags.snackbar.message.ed-required-bad-attach]%');
                title = this._localizationQ.transform('%[flags.snackbar.title.ed-required-bad-attach]%');
                break;
              default:
                message = '';
                title = '';
                break;
            }
            return this._snackbarService.openWarning(message, { title, duration: environment.appSettings.snackbar.longDuration });
          }),
          // TODO: После подготовки задач по ED раскомментить и донастроить логику
          // concatMap(() => this._sourcesOfIncomeQ.selectAll()),
          // take(1),
          // tap((rcs: IDocumentBody[]) => {
          //   if (extraDocumentsFlag === AdditionalDocsFlags.Required) {
          //     this._sourcesOfIncomeStore.ui.update(x => ({ ...x, newDocFormIsOpen: true }));
          //   } else if (extraDocumentsFlag === AdditionalDocsFlags.NoAttach) {
          //     if (rcs.length > 0) {
          //       rcs.forEach((rc: IDocumentBody) => {
          //         this._sourcesOfIncomeStore.ui.upsert(rc.id, { isExpanded: true, isEdit: true });
          //       });
          //     } else {
          //       this._sourcesOfIncomeStore.ui.update(x => ({ ...x, newDocFormIsOpen: true }));
          //     }
          //   } else {
          //     const firstDoc = rcs[0];
          //     if (firstDoc == null) { return; }
          //     this._sourcesOfIncomeStore.ui.upsert(firstDoc.id, { isExpanded: true, isEdit: true });
          //   }
          // }),
          concatMap(() => EMPTY)
        );
    }
    return of(void 0);
  }

  private _checkWizardAndRedirect(routeToSendOnSuccess: boolean): Observable<void> {
    return this._profileQuery.select(x => x.isWizardMode).pipe(
      take(1),
      concatMap((isWizardMode: boolean) => isWizardMode || routeToSendOnSuccess ? this._redirectToSend$() : of(void 0))
    );
  }

  private _redirectToSend$(): Observable<void> {
    return from(this._router.navigate(['private', 'send'])).pipe(
      switchMap(() => {
        return this._sendQuery.select(x => x.isSendPageFullyLoaded);
      }),
      filter(e => e === true), take(1),
      concatMap(() => this._sendService.scrollToFirstEmptyField$()),
      tap(() => {
        this._profileStore.update(x => ({ ...x, isWizardMode: false }));
      })
    );
  }

}
