import { EFreshChatChannel, IEnvironment } from './environment.model';

export const defaultEnvironment: IEnvironment = {
    production: false,
    baseUrl: '',
    webSocketHostOrigin: '',
    onlineLocationOrigin: '',
    stNotificationsUrl: '',

    thirdPartyServices: {
      checkoutScriptUrl: 'https://checkout.ffsw.dev/script/v2/finflowcheckout.js',
      castlePublicKey: 'pk_9TbdZ35S5dkx1ymuC8KXseatosAByxte',

      paymentForm: {
        checkoutcom: {
          checkoutURI: 'https://api.sandbox.checkout.com',
          checkout3ds: true
        },
        transact365: {
          test: true,
          checkoutURI: 'https://payment.transact365.com/ctp/api',
          description: 'Wazzup, it`s just test payment'
        }
      },

      freshchat: {
        enable: true,
        apiKey: 'f69ba3ff-51e9-4762-b80e-79d481013c7f',
        host: 'https://wchat.eu.freshchat.com',
        widgetUrl: 'https://wchat.eu.freshchat.com/js/widget.js',
        tags: EFreshChatChannel.devSite,
        fillColor: '#6a6a6a'
      },

      sentryKey: 'https://fb4b0f70ed0f49ed846d8c644f0f3aba@o402215.ingest.sentry.io/5262940'
    },

    appSettings: {
      controls: {
        inputDebounceTime: 500,
        appearance: 'outline',
        floatLabel: 'auto',
        inputFocusDuration: 300,
        dateFormat: {
          parse: {
            dateInput: 'dd/MM/yyyy' // this is how your date will be parsed from Input
          },
          display: {
            dateInput: 'dd/MM/yyyy', // this is how your date will get displayed on the Input
            monthYearLabel: 'MMMM yyyy',
            dateA11yLabel: 'LL',
            monthYearA11yLabel: 'MMMM YYYY'
          }
        },
        passwordMinLength: 8,
        passwordMaxLength: 30
      },
      locale: {
        date: 'dd/MM/yyyy',
        time: 'H:mm'
      },
      tooltip: {
        duration: 15000,
        throttleTime: 100
      },
      snackbar: {
        duration: 15000,
        shortDuration: 7500,
        longDuration: 30000
      },
      mobileApp: {
        androidUrl: null,
        iosUrl: null,
        galleryUrl: null
      },
      identityDocsCheckFlow: {
        name: 'SUMSUB',
        checkResultsDuration: 20000
      },
      payoutLocations: {
        pagePointsSize: 10,
        pageCitiesSize: 20
      },
      errorCodes: {
        ratesHasChangedErrorCode: 100902
      },
      showInactiveBlock: false,
      bankAccountOwnershipTimeoutMs: 30000,
      geoIpRequestTimeoutMs: null,
      isPersonalInfoEmailAlwaysRequired: true,
      defaultCalculatorSourceCurrency: 'EUR',
      defaultAuthCountryIso2Code: 'CZ',
      showEmailNotificationCheckbox: false,
      isAdditionalDocsShouldHaveScans: true,
      extraDocuments: {
        maxFileSize: 1048576
      }
    },

    google: {
      captcha: {
        gcaptchaEnabled: true,
        gcaptchaSitekey: '6Lfs2lAbAAAAAB3DWNW0bAHCNYpWPvQICFRR-j3f'
      },
      maps: {
        apiKey: 'AIzaSyCONVy7xirTRt4r1SzUcdudoQDY0o1KX4g'
      },
      googleTagManagerId: 'GTM-PHH7PM4',
      googleAnalyticsId: 'G-R9D7JQGNNY'
    },

    vendor: {
      company: {
        name: 'BESTPAY',
        siteAddress: '',
        contactUsExternalUrl: '',
        siteLocales: []
      },
      theme: {
        name: 'BESTPAY',
        devMode: false,
        autoDarkMode: true,
        isUseCustomLogo: false
      }
    }
  }
;
