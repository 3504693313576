import { NgModule } from '@angular/core';
import { MyReceiversService } from './my-receivers.service';
import { MyReceiversQuery, MyReceiversStore } from './my-receivers.store';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    MyReceiversService,
    MyReceiversStore,
    MyReceiversQuery,
  ],
})
export class MyReceiversModule {}
