import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IDeviceInfo } from './device-info.model';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { DeviceInfoQuery, DeviceInfoStore } from './device-info.store';
import { tap } from 'rxjs/operators';

@Injectable()
export class DeviceInfoService {

  constructor(
    private _deviceDetectorService: DeviceDetectorService,
    private _deviceInfoStore: DeviceInfoStore,
    public deviceInfoQuery: DeviceInfoQuery
  ) { }

  getDeviceInfo$(): Observable<IDeviceInfo> {
    const data: DeviceInfo = this._deviceDetectorService.getDeviceInfo();
    const info: IDeviceInfo = {
      browser: data.browser,
      browserVersion: data.browser_version,
      deviceId: '',
      fingerprint: '',
      os: data.os,
      osVersion: data.os_version,
      userAgent: data.userAgent,
      device: data.device,
    };
    return of(info).pipe(tap(() => this._deviceInfoStore.setDeviceInfo(info)));
  }
}
