import { Injectable } from '@angular/core';
import { LocalizationQuery } from '@ff/localization';
import { SnackbarService } from '../../../../../../../functionality-modules/snackbars/snackbar.service';
import { environment } from '../../../../../../../../environments/environment';

interface IMapError {
  errorLocalizedKey: string,
  errorMessage: string
}

@Injectable()
export class FfpgErrorHelper {
  private _errorMap: Map<number, IMapError> = new Map<number, IMapError>([
    // COMMON INTERNAL ERROR 10xxx
    [
      10000,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Internal error occurred.',
      },
    ],
    [
      10001,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Invalid request.',
      },
    ],
    [
      10002,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Invalid operation type.',
      },
    ],
    [
      10003,
      {
        errorLocalizedKey: '%[ff-pg.error.service-unavailable]%',
        errorMessage: 'Service temporary unavailable.',
      },
    ],
    [
      10010,
      {
        errorLocalizedKey: '%[ff-pg.error.kek-service-error]%',
        errorMessage: 'KEK service error occurred.',
      },
    ],
    [
      10100,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Invalid session state.',
      },
    ],
    [
      10101,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Session not found.',
      },
    ],
    [
      10102,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Session expired.',
      },
    ],
    [
      10103,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Invalid auth token.',
      },
    ],
    [
      10104,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Invalid routing token.',
      },
    ],
    [
      10105,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Invalid IP address.',
      },
    ],
    [
      10106,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Invalid fingerprint.',
      },
    ],
    [
      10107,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Invalid signature.',
      },
    ],
    [
      10108,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Invalid session context.',
      },
    ],
    [
      10109,
      {
        errorLocalizedKey: '%[ff-pg.error.invalid-notification-payload]%',
        errorMessage: 'Invalid notification payload.',
      },
    ],
    [
      10110,
      {
        errorLocalizedKey: '%[ff-pg.error.invalid-customer-id]%',
        errorMessage: 'Invalid customer Id.',
      },
    ],
    [
      10210,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Invalid merchant.',
      },
    ],
    [
      10211,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Merchant is disabled.',
      },
    ],
    [
      10212,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Merchant not found.',
      },
    ],
    [
      10220,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Invalid terminal.',
      },
    ],
    [
      10221,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Terminal is disabled.',
      },
    ],
    [
      10222,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Terminal not found.',
      },
    ],
    [
      10223,
      {
        errorLocalizedKey: '%[ff-pg.error.charge-not-found]%',
        errorMessage: 'Charge not found.',
      },
    ],
    [
      10224,
      {
        errorLocalizedKey: '%[ff-pg.error.order-not-found]%',
        errorMessage: 'Order not found.',
      },
    ],
    [
      10225,
      {
        errorLocalizedKey: '%[ff-pg.error.source-not-found]%',
        errorMessage: 'Source not found.',
      },
    ],
    [
      10300,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Invalid operation state.',
      },
    ],
    [
      10301,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Invalid payment schema SMS/DMS.',
      },
    ],
    [
      10302,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Invalid channel currency.',
      },
    ],
    [
      10303,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Currency is not allowed for current terminal.',
      },
    ],
    [
      10304,
      {
        errorLocalizedKey: '%[ff-pg.error.invalid-operation]%',
        errorMessage: 'Invalid operation.',
      },
    ],
    [
      10305,
      {
        errorLocalizedKey: '%[ff-pg.error.operation-not-allowed]%',
        errorMessage: 'Operation is not allowed.',
      },
    ],
    [
      10306,
      {
        errorLocalizedKey: '%[ff-pg.error.invalid-payment-instrument]%',
        errorMessage: 'Invalid payment instrument.',
      },
    ],
    [
      10400,
      {
        errorLocalizedKey: '%[ff-pg.error.common-error]%',
        errorMessage: 'Channel is disabled.',
      },
    ],

    // COMMON INTERNAL CARD VALIDATION ERROR 11xxx
    [
      11000,
      {
        errorLocalizedKey: '%[ff-pg.error.card-data]%',
        errorMessage: 'Invalid card data.',
      },
    ],
    [
      11001,
      {
        errorLocalizedKey: '%[ff-pg.error.card-holder]%',
        errorMessage: 'Invalid card holder.',
      },
    ],
    [
      11002,
      {
        errorLocalizedKey: '%[ff-pg.error.card-validation]%',
        errorMessage: 'Card validation error.',
      },
    ],
    [
      11003,
      {
        errorLocalizedKey: '%[ff-pg.error.card-expired]%',
        errorMessage: 'Card expired.',
      },
    ],

    // COMMON EXTERNAL ERROR 12xxx
    [
      12000,
      {
        errorLocalizedKey: '%[ff-pg.error.external-gateway]%',
        errorMessage: 'External gateway error occurred.',
      },
    ],
    [
      12001,
      {
        errorLocalizedKey: '%[ff-pg.error.external-service]%',
        errorMessage: 'Invalid external service response.',
      },
    ],
    [
      12002,
      {
        errorLocalizedKey: '%[ff-pg.error.external-operation-type]%',
        errorMessage: 'Invalid external service operation type.',
      },
    ],
    [
      12003,
      {
        errorLocalizedKey: '%[ff-pg.error.external-gateway-invalid-request]%',
        errorMessage: 'External gateway invalid request.',
      },
    ],
    [
      12004,
      {
        errorLocalizedKey: '%[ff-pg.error.external-gateway-unauthorized-request]%',
        errorMessage: 'External gateway unauthorized request.',
      },
    ],
    [
      12005,
      {
        errorLocalizedKey: '%[ff-pg.error.external-gateway-unavailable]%',
        errorMessage: 'External gateway is unavailable.',
      },
    ],
    [
      12006,
      {
        errorLocalizedKey: '%[ff-pg.error.external-gateway-request-timeout]%',
        errorMessage: 'External gateway request timeout.',
      },
    ],
    [
      12007,
      {
        errorLocalizedKey: '%[ff-pg.error.external-gateway-resource-not-found]%',
        errorMessage: 'External gateway resource not found.',
      },
    ],
    [
      12008,
      {
        errorLocalizedKey: '%[ff-pg.error.payment-details-verification-error]%',
        errorMessage: 'The card number was previously used by another customer.',
      },
    ],

    // ORDER ERROR 20xxx
    [
      20000,
      {
        errorLocalizedKey: '%[ff-pg.error.order-create]%',
        errorMessage: 'Create order error.',
      },
    ],
    [
      20001,
      {
        errorLocalizedKey: '%[ff-pg.error.order-model-state]%',
        errorMessage: 'Invalid order model state.',
      },
    ],
    [
      20002,
      {
        errorLocalizedKey: '%[ff-pg.error.order-format]%',
        errorMessage: 'Invalid order format.',
      },
    ],
    [
      20003,
      {
        errorLocalizedKey: '%[ff-pg.error.order-object]%',
        errorMessage: 'Invalid order object.',
      },
    ],
    [
      20100,
      {
        errorLocalizedKey: '%[ff-pg.error.order-id]%',
        errorMessage: 'Invalid order id.',
      },
    ],
    [
      20101,
      {
        errorLocalizedKey: '%[ff-pg.error.order-amount]%',
        errorMessage: 'Invalid order amount.',
      },
    ],
    [
      20102,
      {
        errorLocalizedKey: '%[ff-pg.error.order-currency]%',
        errorMessage: 'Invalid order currency.',
      },
    ],
    [
      20210,
      {
        errorLocalizedKey: '%[ff-pg.error.order-customer]%',
        errorMessage: 'Invalid order customer.',
      },
    ],
    [
      20211,
      {
        errorLocalizedKey: '%[ff-pg.error.order-customer-phone]%',
        errorMessage: 'Invalid order customer phone.',
      },
    ],
    [
      20220,
      {
        errorLocalizedKey: '%[ff-pg.error.order-receipt-email]%',
        errorMessage: 'Invalid receipt email.',
      },
    ],
    [
      20221,
      {
        errorLocalizedKey: '%[ff-pg.error.order-receipt-phone-number]%',
        errorMessage: 'Invalid receipt phone number.',
      },
    ],
    [
      20222,
      {
        errorLocalizedKey: '%[ff-pg.error.order-receipt-phone-country-code]%',
        errorMessage: 'Invalid receipt phone country code.',
      },
    ],

    // PAYMENT(CHARGE) ERROR 30xxx
    [
      30000,
      {
        errorLocalizedKey: '%[ff-pg.error.payment-general]%',
        errorMessage: 'Payment general error.',
      },
    ],
    [
      30001,
      {
        errorLocalizedKey: '%[ff-pg.error.payment-declined.invalid-response]%',
        errorMessage: 'Payment declined. Invalid payment response from external service.',
      },
    ],
    [
      30002,
      {
        errorLocalizedKey: '%[ff-pg.error.payment-declined.confirm-operation-failed]%',
        errorMessage: 'Payment declined. Confirm payment operation failed.',
      },
    ],

    // PAYMENT(CHARGE) 3DS ERROR 303xx
    [
      30300,
      {
        errorLocalizedKey: '%[ff-pg.error.payment-declined.card-not-enrolled]%',
        errorMessage: 'Payment declined. Card not enrolled for 3DS secure.',
      },
    ],
    [
      30301,
      {
        errorLocalizedKey: '%[ff-pg.error.payment-declined.authorization-failed]%',
        errorMessage: 'Payment declined. 3DS authorization failed.',
      },
    ],
    [
      30302,
      {
        errorLocalizedKey: '%[ff-pg.error.payment-declined.confirmation-timeout]%',
        errorMessage: 'Payment declined. 3DS confirmation timeout.',
      },
    ],

    // PAYMENT (CAPTURE) ERROR 31xxx
    [
      31000,
      {
        errorLocalizedKey: '%[ff-pg.error.capture-general]%',
        errorMessage: 'Payment capture error.',
      },
    ],
    [
      31001,
      {
        errorLocalizedKey: '%[ff-pg.error.capture-charge-status]%',
        errorMessage: 'Capture error. Invalid charge status.',
      },
    ],
    [
      31002,
      {
        errorLocalizedKey: '%[ff-pg.error.capture-payment-status]%',
        errorMessage: 'Capture error. Invalid payment status.',
      },
    ],

    // PAYMENT CANCEL(VOID) ERROR 32xxx
    [
      32000,
      {
        errorLocalizedKey: '%[ff-pg.error.payment-cancel]%',
        errorMessage: 'Payment cancel error.',
      },
    ],
    [
      32001,
      {
        errorLocalizedKey: '%[ff-pg.error.invalid-status-cancel]%',
        errorMessage: 'Invalid status for Cancel operation.',
      },
    ],

    // PAYMENT REFUND ERROR 33xxx
    [
      33000,
      {
        errorLocalizedKey: '%[ff-pg.error.payment-refund-error]%',
        errorMessage: 'Payment refund error.',
      },
    ],
    [
      33001,
      {
        errorLocalizedKey: '%[ff-pg.error.confirm-refund-failed]%',
        errorMessage: 'Confirm refund operation failed.',
      },
    ],
    [
      33002,
      {
        errorLocalizedKey: '%[ff-pg.error.invalid-status-refund]%',
        errorMessage: 'Invalid status for Refund operation.',
      },
    ],
    [
      33003,
      {
        errorLocalizedKey: '%[ff-pg.error.invalid-refund-amount]%',
        errorMessage: 'Invalid refund amount.',
      },
    ],

    // ACQUIRING CHANNEL ERROR 40xxx
    // ACQUIRING CHANNEL PAYMENT ERROR 41xxx
    [
      41000,
      {
        errorLocalizedKey: '%[ff-pg.error.payment-channel-error]%',
        errorMessage: 'Payment channel error',
      },
    ],
    [
      41001,
      {
        errorLocalizedKey: '%[ff-pg.error.invalid-blik-code]%',
        errorMessage: 'Invalid BLIK code',
      },
    ],
    [
      41002,
      {
        errorLocalizedKey: '%[ff-pg.error.blik-code-expired]%',
        errorMessage: 'BLIK code expired',
      },
    ],
    [
      41003,
      {
        errorLocalizedKey: '%[ff-pg.error.already-used-blik-code]%',
        errorMessage: 'Invalid BLIK code (already used)',
      },
    ],
    [
      41004,
      {
        errorLocalizedKey: '%[ff-pg.error.not-unique-blik-alias]%',
        errorMessage: 'Not unique BLIK alias',
      },
    ],
    [
      41005,
      {
        errorLocalizedKey: '%[ff-pg.error.blik-alias-declined]%',
        errorMessage: 'BLIK alias declined',
      },
    ],
    [
      41006,
      {
        errorLocalizedKey: '%[ff-pg.error.blik-alias-not-found]%',
        errorMessage: 'BLIK alias not found',
      },
    ],
  ]);

  constructor(private _snackbarService: SnackbarService, private _localizationQ: LocalizationQuery) {}

  public handlePaymentError(
    titleKey: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    err: any
  ): void {
    const title = this._localizationQ.transform(titleKey);
    const message = this.getLocalizedError(err.code, err.message);
    this._snackbarService.openFailure$(message, { title, duration: environment.appSettings.snackbar.duration }).subscribe();
  }

  public handleCardListLoadError(): void {
    const title = this._localizationQ.transform('%[pay-card-list.update-list.failed]%');
    const message = this._localizationQ.transform('%[pay-card-list.update-list.failed-message]%');
    this._snackbarService.openFailure$(message, { title, duration: environment.appSettings.snackbar.duration }).subscribe();
  }

  public getLocalizedError(code: number | null, message?: string): string {
    if (code != null && code !== 0) {
      const ffpgerrorkey = this._errorMap.get(code);
      if (ffpgerrorkey != null) {
        return this._localizationQ.transform(ffpgerrorkey.errorLocalizedKey);
      }
      return message ?? this._localizationQ.transform('%[ff-pg.error.service-handled-default]%');
    }
    return message ?? this._localizationQ.transform('%[ff-pg.error.service-unavailable]%');
  }
}
