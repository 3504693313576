import { Injectable } from '@angular/core';
import { Query, Store, StoreConfig } from '@datorama/akita';
import { Observable } from 'rxjs';
// import { ICountry } from '../countries/countries.model';
import { IPersistState } from './persist.models';
import { IAuthenticationCountry } from '../authentication-countries/auth-countries.models';
import { FFThemeMode } from '../theme/theme.service';
import { map } from 'rxjs/operators';

// export const DEFAULT_COUNTRY: ICountry = { phoneCode: '44', id: 'GBR', title: 'United Kingdom', iso2Code: 'GB' };

export function createInitialState(): IPersistState {
  return {
    authenticationCountry: null,
    accessToken: '',
    refreshToken: '',
    phone: '',
    previousPhone: '',
    submitAvailableTime: null,
    currentLocationCountry: null,
    isWizardChecksEnabled: true,
    selectedLanguageCode: '',
    isForgotPassword: false,
    isCheckoutJSLoaded: false,
    isPasswordExpired: false,
    activeThemeMode: null,
    systemThemeMode: null
  };
}

@Injectable()
@StoreConfig({ name: 'persistence' })
export class PersistenceStore extends Store<IPersistState> {

  constructor() {
    super(createInitialState());
  }
}

@Injectable()
export class PersistenceQuery extends Query<IPersistState> {

  phone$$: Observable<string> = this.select(state => state.phone ?? '');
  authenticationCountry$$: Observable<IAuthenticationCountry | null> = this.select(state => state.authenticationCountry);
  accessToken$$: Observable<string | null> = this.select(state => state.accessToken);
  selectedLanguageCode$$: Observable<string> = this.select(state => state.selectedLanguageCode);
  isWizardChecksEnabled$$: Observable<boolean> = this.select(state => state.isWizardChecksEnabled);
  isForgotPassword$$: Observable<boolean> = this.select(state => state.isForgotPassword);
  isCheckoutJSLoaded$$: Observable<boolean> = this.select(state => state.isCheckoutJSLoaded);
  isPasswordExpired$$: Observable<boolean> = this.select(state => state.isPasswordExpired);
  activeThemeMode$$: Observable<FFThemeMode | null> = this.select(state => state.activeThemeMode);
  systemThemeMode$$: Observable<FFThemeMode | null> = this.select(state => state.activeThemeMode);

  remappedPhone$$: Observable<string> = this.phone$$.pipe(
    map(phone => phone?.replace(/^330/, '33')) // ffo-4504
  );

  constructor(protected override store: PersistenceStore) {
    super(store);
  }
}
