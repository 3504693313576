import { NgModule } from '@angular/core';

import { RemittanceCountriesService } from './remittance-countries.service';
import { RemittanceCountriesQuery, RemittanceCountriesStore } from './remittance-countries.store';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    RemittanceCountriesService,
    RemittanceCountriesStore,
    RemittanceCountriesQuery,
  ],
})
export class RemittanceCountriesModule {}
