import { NgModule } from '@angular/core';
import { GatewaysService } from './gateways.service';
import { GatewaysQuery, GatewaysStore } from './gateways.store';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    GatewaysService,
    GatewaysStore,
    GatewaysQuery,
  ],
})
export class GatewaysModule {}
