import { Injectable } from '@angular/core';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { catchError, filter, map, mapTo, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TemplatesListStore } from './templates-list.store';
import { ITemplate } from '../../models/template.model';
import { WorkflowsQuery } from '../../../../../../data-modules/workflows/workflows.store';
import { GatewaysQuery } from '../../../../../../data-modules/gateways/gateways.store';
import { CalculatorQuery, CalculatorStore } from '../../../../../../data-modules/calculator/calculator.store';
import { CalculatorService } from '../../../../../../data-modules/calculator/calculator.service';
import {
  RemittanceCountriesQuery
} from '../../../../../../data-modules/remittance-countries/remittance-countries.store';
import { GatewaysService } from '../../../../../../data-modules/gateways/gateways.service';
import { CurrenciesSourceQuery } from '../../../../../../data-modules/currencies-source/currencies-source.store';
import {
  CurrenciesDestinationQuery
} from '../../../../../../data-modules/currencies-destination/currencies-destination.store';
import {
  CurrenciesDestinationService
} from '../../../../../../data-modules/currencies-destination/currencies-destination.service';

import { MyReceiversService } from '../../../../../../data-modules/my-receivers/my-receivers.service';
import { MyReceiversQuery } from '../../../../../../data-modules/my-receivers/my-receivers.store';

import { Router } from '@angular/router';

import { DialogService } from '../../../../../../functionality-modules/dialog-windows/dialog.service';
import { LocalizationQuery } from '@ff/localization';

import { CountriesService } from '../../../../../../data-modules/countries/countries.service';
import { RequiredFieldsService } from '../../../../../../data-modules/required-fields/required-fields.service';
import { combineQueries } from '@datorama/akita';
import { IRemittanceCountry } from '../../../../../../data-modules/remittance-countries/remittance-countries.model';
import { IGateway } from '../../../../../../data-modules/gateways/gateways.model';
import { ILookupInt, ILookupStr } from '../../../../private-models/lookup-str';
import { IMyReceiver } from '../../../../../../data-modules/my-receivers/my-receivers.models';
import { fromPromise } from 'rxjs/internal-compatibility';
import {
  BeneficiaryGeneralFormQuery,
  BeneficiaryGeneralFormStore
} from '../../../send/containers/beneficiary/beneficiary-general/beneficiary-general.store';
import {
  BeneficiaryAdditionalFieldsFormQuery,
  BeneficiaryAdditionalFieldsFormStore
} from '../../../send/containers/beneficiary/beneficiary-additional-fields/beneficiary-additional-fields.store';
import { MyBeneficiariesService } from '../../../send/containers/beneficiary/my-beneficiaries/my-beneficiaries.service';
import {
  BeneficiaryAddressQuery
} from '../../../send/containers/beneficiary/beneficiary-address/beneficiary-address.store';
import {
  BeneficiaryAddressService
} from '../../../send/containers/beneficiary/beneficiary-address/beneficiary-address.service';
import { SaveTemplatePanelQuery } from '../../../send/containers/save-template-panel/save-template-panel.store';
import { IWorkflow } from '../../../../../../data-modules/workflows/workflows.models';
import { WorkflowsService } from '../../../../../../data-modules/workflows/workflows.service';
import { CurrenciesSourceService } from '../../../../../../data-modules/currencies-source/currencies-source.service';
import { IDocTypeValue } from '../../../../../../data-modules/doctypes-identity/doctypes-identity.model';
import { BanksService } from '../../../../../../data-modules/banks/banks.service';
import { BankBranchesQuery } from '../../../../../../data-modules/bank-branches/bank-branches.store';
import { BanksQuery } from '../../../../../../data-modules/banks/banks.store';
import { BankBranchesService } from '../../../../../../data-modules/bank-branches/bank-branches.service';
import { BankAccountTypesQuery } from '../../../../../../data-modules/bank-account-types/bank-account-types.store';
import { BankAccountTypesService } from '../../../../../../data-modules/bank-account-types/bank-account-types.service';
import { DocTypesIdentityQuery } from '../../../../../../data-modules/doctypes-identity/doctypes-identity.store';
import { DoctypesIdentityService } from '../../../../../../data-modules/doctypes-identity/doctypes-identity.service';
import { CountriesQuery } from '../../../../../../data-modules/countries/countries.store';
import {
  DocTypesSourceIncomeService
} from '../../../../../../data-modules/doctypes-source-income/doctypes-source-income.service';
import {
  DocTypesSourceIncomeQuery
} from '../../../../../../data-modules/doctypes-source-income/doctypes-source-income.store';
import { IBank } from '../../../../../../data-modules/banks/banks.model';
import { IBankBranch } from '../../../../../../data-modules/bank-branches/bank-branches.model';
import { ICountry } from '../../../../../../data-modules/countries/countries.model';
import { withTransaction } from '@datorama/akita';

@Injectable()
export class TemplatesListService {

  constructor(
    private _http: HttpClient,
    private _templatesListStore: TemplatesListStore,
    private _workflowsQuery: WorkflowsQuery,
    private _gatewaysQuery: GatewaysQuery,
    private _calculatorQuery: CalculatorQuery,
    private _calculatorStore: CalculatorStore,
    private _calculatorService: CalculatorService,
    private _remittanceCountriesQuery: RemittanceCountriesQuery,
    private _beneficiaryGeneralFormQuery: BeneficiaryGeneralFormQuery,
    private _gatewaysService: GatewaysService,
    private _beneficiaryGeneralFormStore: BeneficiaryGeneralFormStore,
    private _currenciesSourceQuery: CurrenciesSourceQuery,
    private _currenciesSourceService: CurrenciesSourceService,
    private _currenciesDestinationQuery: CurrenciesDestinationQuery,
    private _currenciesDestinationService: CurrenciesDestinationService,
    private _additionalFieldsFormQuery: BeneficiaryAdditionalFieldsFormQuery,
    private _additionalFieldsFormStore: BeneficiaryAdditionalFieldsFormStore,
    private _myReceiversService: MyReceiversService,
    private _myReceiversQuery: MyReceiversQuery,
    private _myBeneficiaries: MyBeneficiariesService,
    private _router: Router,
    private _saveTemplatePanelQuery: SaveTemplatePanelQuery,
    private _beneficiaryAddressQuery: BeneficiaryAddressQuery,
    private _dialogService: DialogService,
    private _localizationQ: LocalizationQuery,
    private _beneficiaryAddressService: BeneficiaryAddressService,
    private _countriesService: CountriesService,
    private _workflowsService: WorkflowsService,
    private _banksService: BanksService,
    private _banksQuery: BanksQuery,
    private _bankBranchesQuery: BankBranchesQuery,
    private _bankBranchesService: BankBranchesService,
    private _bankAccountTypesQuery: BankAccountTypesQuery,
    private _bankAccountTypesService: BankAccountTypesService,
    private _docTypesIdentityQuery: DocTypesIdentityQuery,
    private _doctypesIdentityService: DoctypesIdentityService,
    private _countriesQuery: CountriesQuery,
    private _doctypesSourceIncomeService: DocTypesSourceIncomeService,
    private _doctypesSourceIncomeQuery: DocTypesSourceIncomeQuery,
    private _requiredFieldsService: RequiredFieldsService
  ) { }

  getTemplatesList$(): Observable<void> {
    return of(void 0)
      .pipe(
        tap(() => this._templatesListStore.setLoading(true)),
        switchMap(() => this._http.get<ITemplate[]>('templates')),
        catchError(() => {
          this._templatesListStore.setLoading(false);
          return EMPTY;
        }),
        map((templates: ITemplate[]) => templates.map(t => ({ ...t, currentName: t.templateName }))),
        withTransaction((templates: ITemplate[]) => {
          this._templatesListStore.upsertMany(templates);
          this._templatesListStore.setLoading(false);
        }),
        mapTo(void 0)
      );
  }

  getTemplate$(id: string): Observable<void> {
    return of(void 0)
      .pipe(
        tap(() => this._templatesListStore.setLoading(true)),
        switchMap(() => this._http.get<ITemplate>(`templates/${id}`)),
        catchError(() => {
          this._templatesListStore.setLoading(false);
          return EMPTY;
        }),
        map((t: ITemplate) => ({ ...t, currentName: t.templateName })),
        withTransaction((template: ITemplate) => {
          this._templatesListStore.upsertMany([template]);
          this._templatesListStore.setLoading(false);
        }),
        mapTo(void 0)
      );
  }

  createAndSaveTemplate$(): Observable<void> {
    return combineQueries([
      this._saveTemplatePanelQuery.select(x => x.templateName),
      this._workflowsQuery.selectActive(),
      this._gatewaysQuery.selectActive(),
      this._calculatorQuery.select(x => x.destinationCountry),
      this._calculatorQuery.select(x => x.sourceAmount),
      this._calculatorQuery.select(x => x.destinationAmount),
      this._calculatorQuery.select(x => x.sourceCurrency),
      this._calculatorQuery.select(x => x.destinationCurrency),
      this._beneficiaryGeneralFormQuery.select(x => x),
      this._beneficiaryAddressQuery.select(x => x),
      this._additionalFieldsFormQuery.select(x => x),
    ])
      .pipe(
        take(1),
        map(([
               templateName,
               workflow,
               gateway,
               destinationCountry,
               sourceAmount,
               destinationAmount,
               sourceCurrency,
               destinationCurrency,
               beneficiary,
               beneficiaryAddress,
               addFields
             ]): ITemplate => {
          return {
            templateName,
            currentName: templateName,
            destinationCountryId: destinationCountry != null ? destinationCountry.id : '',
            destinationCountry: destinationCountry != null ? destinationCountry.title : '',
            destinationCountryIso2Code: destinationCountry != null ? destinationCountry.iso2Code : '',
            workflowId: workflow != null ? workflow.id : '',
            workflow: workflow != null ? workflow.title : '',
            gatewayId: gateway != null ? gateway.id : '',
            gatewayName: gateway != null ? gateway.partnerName : '',
            directionId: gateway != null ? gateway.directionId : '',
            sourceAmount: Number(sourceAmount),
            destinationAmount: Number(destinationAmount),
            sourceCurrencyId: sourceCurrency != null ? sourceCurrency.id.toString() : '',
            sourceCurrency: sourceCurrency != null ? sourceCurrency.title.toString() : '',
            destinationCurrencyId: destinationCurrency != null ? destinationCurrency.id.toString() : '',
            destinationCurrency: destinationCurrency != null ? destinationCurrency.title : '',
            beneficiaryName: beneficiary.name,
            beneficiaryMiddlename: beneficiary.middleName,
            beneficiarySurname: beneficiary.surname,
            beneficiaryDateOfBirth: beneficiary.dateOfBirth != null ? new Date(beneficiary.dateOfBirth) : null,
            beneficiaryGender: Number(beneficiary.sex),
            beneficiaryTransferPurposeId: beneficiary.remittancePurpose != null ? beneficiary.remittancePurpose.id : '',
            beneficiaryTransferPurpose: beneficiary.remittancePurpose != null ? beneficiary.remittancePurpose.title : '',

            beneficiaryPan: addFields.beneficiaryPan,
            beneficiaryBank: addFields.beneficiaryBankCode?.title,

            beneficiaryDocumentTypeId: typeof addFields.beneficiaryIDType === 'string' ? addFields.beneficiaryIDType : addFields.beneficiaryIDType?.id,
            beneficiaryDocumentType: typeof addFields.beneficiaryIDType === 'string' ? addFields.beneficiaryIDType : addFields.beneficiaryIDType?.title,
            beneficiaryDocumentNumber: addFields.beneficiaryIDNumber,

            beneficiaryStreet: beneficiaryAddress.street,
            beneficiaryCitizenshipId: addFields.beneficiaryCitizenship?.id,
            beneficiaryCitizenship: addFields.beneficiaryCitizenship?.title,
            beneficiaryBuilding: beneficiaryAddress.building,
            beneficiaryCity: beneficiaryAddress.city,
            beneficiaryCountryId: beneficiaryAddress.country?.id,
            beneficiaryCountry: beneficiaryAddress.country?.title,
            beneficiaryPhone: beneficiary.phoneNumber,
            beneficiaryAccountNumber: addFields.beneficiaryAccountNumber,
            beneficiaryApartment: beneficiaryAddress.apartment,
            beneficiaryBankAccountType: addFields.beneficiaryAccountType?.title,
            beneficiaryBankAccountTypeId: addFields.beneficiaryAccountType?.id,
            beneficiaryBankBic: addFields.beneficiaryBankBic,
            beneficiaryBankBranch: addFields.beneficiaryBankBranchCode?.title,
            beneficiaryBankBranchId: addFields.beneficiaryBankBranchCode?.id,
            beneficiaryBankBranchBic: addFields.beneficiaryBankBranchBic,
            beneficiaryBankId: addFields.beneficiaryBankCode?.id,
            beneficiaryPostCode: beneficiaryAddress.postCode,
            senderSourceOfIncomeId: beneficiary.senderSourceOfIncomeType?.id ?? null
          };
        }),
        switchMap((template: ITemplate) => this._http.post<ITemplate>('templates', template)),
        switchMap(() => this.getTemplatesList$()),
        mapTo(void 0),
      );
  }

  onSendTemplate$(template: ITemplate): Observable<void> {
    return of(void 0).pipe(
      tap(() => this._templatesListStore.setLoading(true)),
      mergeMap(() => this._remittanceCountriesQuery.selectAll()), filter(arr => arr.length > 0), take(1),
      mergeMap(() => this._remittanceCountriesQuery.selectEntity(template.destinationCountryId)),
      tap((destinationCountry: IRemittanceCountry | undefined) => this._calculatorStore.update({ destinationCountry: destinationCountry ?? null })),
      mergeMap(() => this._workflowsQuery.selectAll()), filter(arr => arr.length > 0), take(1),
      switchMap(() => this._workflowsService.downloadAndStoreWorkflows$()),
      mergeMap(() => this._workflowsQuery.selectEntity(template.workflowId)),
      mergeMap((workFlow: IWorkflow) => this._workflowsService.activateWorkflow$(workFlow)),

      mergeMap(() => this._gatewaysQuery.selectAll()), filter(arr => arr.length > 0), take(1),
      mergeMap((gateway) => this._gatewaysQuery.selectEntity(template?.directionId ?? '')),
      filter((gateway) => gateway != null),
      take(1),
      mergeMap((gateway: IGateway) => this._gatewaysService.activateGateway$$(gateway.directionId)
        .pipe(
          mergeMap(_ => this._calculatorQuery.select(x => x.sourceCurrency)), take(1),
          mergeMap((sc: ILookupInt | null) => this._currenciesDestinationService.getCurrenciesDestination$(Number(template.sourceCurrencyId), gateway.directionId)),
          mergeMap(_ => this._requiredFieldsService.getRequiredFields$(gateway.directionId)),
        )),

      mergeMap(() => this._currenciesSourceService.getCurrenciesSource()),
      mergeMap(() => this._currenciesSourceQuery.selectAll()), filter(arr => arr.length > 0), take(1),
      mergeMap(() => this._currenciesSourceQuery.selectEntity(template.sourceCurrencyId)),
      mergeMap((sourceCurrency: ILookupInt) => this._calculatorService.setSourceCurrency$(sourceCurrency)),

      mergeMap(() => this._currenciesDestinationQuery.selectAll()), filter(arr => arr.length > 0), take(1),
      mergeMap(() => this._currenciesDestinationQuery.selectEntity(template.destinationCurrencyId)),
      tap((destinationCurrency: ILookupInt) => this._calculatorService.setDestinationCurrency(destinationCurrency)),
      mergeMap(() => this._calculatorService.setSourceAmount$(template.sourceAmount)),
      mergeMap(() => this._calculatorService.executeCalculationAndUpdateStore$()),
      catchError(() => {
        this._templatesListStore.setLoading(false);
        return EMPTY;
      }),

      // get and select receiver
      mergeMap(() => this._myReceiversService.getMyReceivers$()),
      mergeMap(() => this._myReceiversQuery.selectAll()), filter(arr => arr.length > 0), take(1),
      map((myReceivers: IMyReceiver[]): IMyReceiver | undefined => myReceivers.find(
        x => x.name === template.beneficiaryName && x.surname === template.beneficiarySurname
      )),
      mergeMap((beneficiary: IMyReceiver | undefined) =>
        beneficiary != null ? this._myBeneficiaries.setBeneficiary$$(beneficiary) : of(void 0)),
      // fill general form
      mergeMap(() => this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({
        name: template.beneficiaryName,
        surname: template.beneficiarySurname,
        middleName: template.beneficiaryMiddlename,
        dateOfBirth: template.beneficiaryDateOfBirth,
        phoneNumber: template.beneficiaryPhone,
        sex: template.beneficiaryGender.toString(10)
      })),
      // fill address form
      mergeMap(() => this._beneficiaryAddressService.updateAddressForm({
        apartment: template.beneficiaryApartment,
        building: template.beneficiaryBuilding,
        city: template.beneficiaryCity,
        street: template.beneficiaryStreet,
        country: this._countriesService.transform(
          { id: template.beneficiaryCountryId ?? '', title: template.beneficiaryCountry ?? '' }
        ),
        postCode: template.beneficiaryApartment
      })),
      // fill additional fields
      mergeMap(() => combineLatest([
          this._additionalFieldsFormStore.setAdditionalField({
            beneficiaryPan: template.beneficiaryPan,
            beneficiaryAccountNumber: template.beneficiaryAccountNumber,
            beneficiaryBankBic: template.beneficiaryBankBic,
            beneficiaryBankBranchBic: template.beneficiaryBankBranchBic,
            beneficiaryIDNumber: template.beneficiaryDocumentNumber
          }),
          template.beneficiaryBankId == null ? of(void 0) : of(void 0)
            .pipe(
              switchMap(() => this._banksService.getBanks$(template.directionId)),
              switchMap(() => this._banksQuery.selectAll().pipe(filter(e => e.length > 0), take(1))),
              switchMap(() => this._banksQuery.selectEntity(template.beneficiaryBankId ?? '')),
              switchMap((bank: IBank | undefined) => this._additionalFieldsFormStore.setAdditionalField({ beneficiaryBankCode: bank ?? null }))
            ),
          template.beneficiaryBankBranchId == null ? of(void 0) : of(void 0)
            .pipe(
              switchMap(() => this._banksService.getBanks$(template.directionId)),
              switchMap(() => this._banksQuery.selectAll().pipe(filter(e => e.length > 0), take(1))),
              switchMap(() => this._banksQuery.selectEntity(template.beneficiaryBankId ?? '')),

              switchMap((bank: IBank | undefined) => this._bankBranchesService.getBankBranches$(template.directionId, bank?.code ?? '')),
              switchMap(() => this._bankBranchesQuery.selectAll().pipe(filter(e => e.length > 0), take(1))),
              switchMap(() => this._bankBranchesQuery.selectEntity(template.beneficiaryBankBranchId ?? '')),
              switchMap((branch: IBankBranch | undefined) => this._additionalFieldsFormStore.setAdditionalField({ beneficiaryBankBranchCode: branch ?? null }))
            ),
          template.beneficiaryBankAccountTypeId == null ? of(void 0) : of(void 0)
            .pipe(
              switchMap(() => this._bankAccountTypesService.getBankAccountTypes$(template.directionId)),
              switchMap(() => this._bankAccountTypesQuery.selectAll().pipe(filter(e => e.length > 0), take(1))),
              switchMap(() => this._bankAccountTypesQuery.selectEntity(template.beneficiaryBankAccountTypeId ?? '')),
              switchMap((accType: ILookupStr | undefined) => this._additionalFieldsFormStore.setAdditionalField({ beneficiaryAccountType: accType ?? null }))
            ),
          template.beneficiaryDocumentTypeId == null ? of(void 0) : of(void 0)
            .pipe(
              switchMap(() => this._doctypesIdentityService.getDoctypesIdentity$()),
              switchMap(() => this._docTypesIdentityQuery.selectAll().pipe(filter(e => e.length > 0), take(1))),
              switchMap(() => this._docTypesIdentityQuery.selectEntity(template.beneficiaryDocumentTypeId ?? '')),
              switchMap((doctype: IDocTypeValue | undefined) => this._additionalFieldsFormStore.setAdditionalField({ beneficiaryIDType: doctype ?? null }))
            ),
          template.beneficiaryCitizenshipId == null ? of(void 0) : of(void 0)
            .pipe(
              switchMap(() => this._countriesService.getCountries$()),
              switchMap(() => this._countriesQuery.selectAll().pipe(filter(e => e.length > 0), take(1))),
              switchMap(() => this._countriesQuery.selectEntity(template.beneficiaryCitizenshipId ?? '')),
              switchMap((country: ICountry | undefined) => country != null ? this._additionalFieldsFormStore.setAdditionalField({ beneficiaryCitizenship: country }) : of(void 0))
            ),
          template.senderSourceOfIncomeId == null ? of(void 0) : of(void 0)
            .pipe(
              switchMap(() => this._doctypesSourceIncomeService.getDocTypesSourceIncome$()),
              switchMap(() => this._doctypesSourceIncomeQuery.selectAll().pipe(filter(e => e.length > 0), take(1))),
              switchMap(() => this._doctypesSourceIncomeQuery.selectEntity(template.senderSourceOfIncomeId ?? '')),
              switchMap((soiType: IDocTypeValue | undefined) => this._beneficiaryGeneralFormStore.setBeneficiaryFormField$({ senderSourceOfIncomeType: soiType ?? null }))
            ),
        ]),
      )
    ).pipe(
      mergeMap(() => {
        this._templatesListStore.setLoading(false);
        return fromPromise(this._router.navigate(['private/send']));
      }),
      mapTo(void 0)
    );
  }

  putTemplate$(template: ITemplate, newName: string): Observable<void> {
    const newTemplate: ITemplate = { ...template, templateName: newName };
    return of(void 0).pipe(
      tap(() => this._templatesListStore.setLoading(true)),
      switchMap(() => this._http.put<ITemplate>(`templates`, newTemplate)),
      catchError(() => {
        this._templatesListStore.setLoading(false);
        return EMPTY;
      }),
      withTransaction((savedTemplate: ITemplate) => {
        this._templatesListStore.upsertMany([savedTemplate]);
        this._templatesListStore.ui.upsert(savedTemplate.id ?? '', { isEdit: false });
        this._templatesListStore.setLoading(false);
      }),
      mapTo(void 0)
    );
  }

  deleteTemplate$(template: ITemplate): Observable<void> {
    return this._dialogService.openConfirmDialog$(this._localizationQ.transform('%[templates.dialog.are-you-sure-delete]%')).pipe(
      filter(result => result),
      tap(() => this._templatesListStore.setLoading(true)),
      switchMap(() => this._http.delete(`templates/${template.id}`)),
      catchError(() => {
        this._templatesListStore.setLoading(false);
        return EMPTY;
      }),
      withTransaction(() => {
        this._templatesListStore.setLoading(false);
        this._templatesListStore.remove(template.id ?? '');
        history.back();
      }),
      mapTo(void 0)
    );
  }

  setEdit$(id: string, boo: boolean): Observable<void> {
    return of(void 0).pipe(
      tap(() => this._templatesListStore.ui.upsert(id, { isEdit: boo }))
    );
  }

}
