import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { delay, map, switchMap, take, tap } from 'rxjs/operators';
import {
  BeneficiaryAdditionalFieldsFormQuery,
  BeneficiaryAdditionalFieldsFormStore
} from './beneficiary-additional-fields.store';
import { FormStoreMapper } from '../../../../../../../utils/helpers/form-store-mapper';
import {
  IRequiredField,
  RequiredFieldsQuery
} from '../../../../../../../data-modules/required-fields/required-fields.store';
import { GatewaysQuery } from '../../../../../../../data-modules/gateways/gateways.store';
import { BankBranchesService } from '../../../../../../../data-modules/bank-branches/bank-branches.service';
import { ILookupStr } from '../../../../../private-models/lookup-str';
import { IBank } from '../../../../../../../data-modules/banks/banks.model';
import { IBankBranch } from '../../../../../../../data-modules/bank-branches/bank-branches.model';

@Injectable()
export class BeneficiaryAdditionalFieldsService {

  additionalFieldsFormGroup: FormGroup = new FormGroup({
    beneficiaryPan: new FormControl(null, Validators.required),
    beneficiaryIDType: new FormControl(null, Validators.required),
    beneficiaryIDNumber: new FormControl(null, Validators.required),
    beneficiaryAccountNumber: new FormControl(null, Validators.required),
    beneficiaryCitizenship: new FormControl(null, Validators.required),
    beneficiaryBankCode: new FormControl(null, Validators.required),
    beneficiaryBankBranchCode: new FormControl(null, Validators.required),
    beneficiaryAccountType: new FormControl(null, Validators.required),
    beneficiaryBankBic: new FormControl(null, Validators.required),
    beneficiaryBankBranchBic: new FormControl(null, Validators.required),
  });

  formMapper: FormStoreMapper = new FormStoreMapper(this.additionalFieldsFormGroup);

  constructor(
    private _requiredFieldsQuery: RequiredFieldsQuery,
    private _gatewaysQuery: GatewaysQuery,
    private _bankBranchesService: BankBranchesService,
    private _beneficiaryAdditionalFieldsFormQuery: BeneficiaryAdditionalFieldsFormQuery,
    private _beneficiaryAdditionalFieldsFormStore: BeneficiaryAdditionalFieldsFormStore,
  ) {
    this._pdFormUpdate();
    this._pdReact();
  }

  resetStore(): void {
    this._beneficiaryAdditionalFieldsFormStore.reset();
  }

  calculateIsShow$(additionalFieldName: string, displayMethod: 'List' | 'Manual' | 'ListAndManual'): Observable<boolean> {
    return of(void 0)
      .pipe(
        switchMap(() => this._requiredFieldsQuery.state$$),
        map((reqFields: IRequiredField[]): IRequiredField | undefined => reqFields.find((rf: IRequiredField) => rf.name === additionalFieldName && rf.sourceType === displayMethod)),
        map((result: IRequiredField | undefined) => Boolean(result))
      );
  }

  private _pdFormUpdate(): void {
    this.formMapper.formChange<string>('beneficiaryPan', (value: string) =>
      this._beneficiaryAdditionalFieldsFormStore.setAdditionalField({ beneficiaryPan: value })
    );
    this.formMapper.formChange<ILookupStr>('beneficiaryIDType', (value: ILookupStr) =>
      this._beneficiaryAdditionalFieldsFormStore.setAdditionalField({ beneficiaryIDType: value })
    );

    this.formMapper.formChange<string>('beneficiaryIDNumber', (value: string) =>
      this._beneficiaryAdditionalFieldsFormStore.setAdditionalField({ beneficiaryIDNumber: value })
    );

    this.formMapper.formChange<string>('beneficiaryAccountNumber', (value: string) =>
      this._beneficiaryAdditionalFieldsFormStore.setAdditionalField({ beneficiaryAccountNumber: value })
    );

    this.formMapper.formChange<ILookupStr>('beneficiaryCitizenship', (value: ILookupStr) =>
      this._beneficiaryAdditionalFieldsFormStore.setAdditionalField({ beneficiaryCitizenship: value })
    );

    this.formMapper.formChange<IBank>('beneficiaryBankCode', (value: IBank) => this._setBank$(value));

    this.formMapper.formChange<IBankBranch>('beneficiaryBankBranchCode', (value: IBankBranch) =>
      this._beneficiaryAdditionalFieldsFormStore.setAdditionalField({ beneficiaryBankBranchCode: value })
    );

    this.formMapper.formChange<ILookupStr>('beneficiaryAccountType', (value: ILookupStr) =>
      this._beneficiaryAdditionalFieldsFormStore.setAdditionalField({ beneficiaryAccountType: value })
    );

    this.formMapper.formChange<string>('beneficiaryBankBic', (value: string) =>
      this._beneficiaryAdditionalFieldsFormStore.setAdditionalField({ beneficiaryBankBic: value })
    );

    this.formMapper.formChange<string>('beneficiaryBankBranchBic', (value: string) =>
      this._beneficiaryAdditionalFieldsFormStore.setAdditionalField({ beneficiaryBankBranchBic: value })
    );

  }

  private _pdReact(): void {
    this._beneficiaryAdditionalFieldsFormQuery.select(x => ({
      beneficiaryPan: x.beneficiaryPan,
      beneficiaryIDType: x.beneficiaryIDType,
      beneficiaryIDNumber: x.beneficiaryIDNumber,
      beneficiaryAccountNumber: x.beneficiaryAccountNumber,
      beneficiaryCitizenship: x.beneficiaryCitizenship,
      beneficiaryBankCode: x.beneficiaryBankCode,
      beneficiaryBankBranchCode: x.beneficiaryBankBranchCode,
      beneficiaryAccountType: x.beneficiaryAccountType,
      beneficiaryBankBic: x.beneficiaryBankBic,
      beneficiaryBankBranchBic: x.beneficiaryBankBranchBic
    })).pipe(
      tap(data => this.formMapper.patchValue(data))
    ).subscribe();
  }

  private _setBank$(bank: IBank): Observable<void> {
    return of(void 0)
      .pipe(
        switchMap(() => this._beneficiaryAdditionalFieldsFormStore.setAdditionalField({ beneficiaryBankBranchCode: null })),
        switchMap(() => this._beneficiaryAdditionalFieldsFormStore.setAdditionalField({ beneficiaryBankCode: bank })),
        switchMap(() => {
          const activeGateway = this._gatewaysQuery.getActive();

          if (activeGateway == null || bank == null) {
            return of(void 0).pipe(delay(0));
          }
          return this._bankBranchesService.getBankBranches$(activeGateway.directionId, bank.code);
        }),
        take(1)
      );
  }

}
