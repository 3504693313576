import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IAttachment, IAttachmentResponseModel } from '../../../root/private/private-models/attachment.model';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-document-viewer',
  templateUrl: 'document-viewer.component.html',
  styleUrls: ['document-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DocumentViewerComponent implements OnInit {
  private _pictureData$$: Subject<IAttachment> = new Subject<IAttachment>();
  isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  sanitizedImage$$: Observable<{ img: SafeResourceUrl, alt?: string | null } | null> = this._pictureData$$.pipe(
    map((pictureData: IAttachment): { img: SafeResourceUrl, alt?: string | null } | null => {
      if (pictureData?.dataBase64 != null) {
        return {
          img: this.sanitize(pictureData.dataBase64),
          alt: pictureData.name
        };
      }
      return null;
    })
  );

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { attachment: IAttachment, msg: string },
    private _sanitizer: DomSanitizer,
    private _http: HttpClient,
    private _dialogRef: MatDialogRef<DocumentViewerComponent>
  ) { }

  ngOnInit(): void {
    this.getData$(this.data.attachment).subscribe();
  }

  getData$(attachment: IAttachment): Observable<void> {
    return this._http.get<IAttachmentResponseModel>(`file/load/json/${attachment.id}`).pipe(
      catchError(() => EMPTY),
      tap((richAttachment) => {
        this._pictureData$$.next(richAttachment);
      }),
      mapTo(void 0)
    );
  }

  onLoad(): void {
    this.isLoading$$.next(false);
  }

  sanitize(url: string | null): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + url);
  }

  onCloseClick(): void {
    this._dialogRef.close();
  }
}
