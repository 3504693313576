import { Injectable } from '@angular/core';
import { PersistenceStore } from './persist.store';
import { IPersistState } from './persist.models';
import { logAction } from '@datorama/akita';

@Injectable()
export class PersistenceService {

  constructor(
    private _persistenceStore: PersistenceStore
  ) { }

  updatePersistenceStore(updatedValues: Partial<IPersistState>, logActionText?: string): void {
    logAction(logActionText ?? 'update persistence store');
    this._persistenceStore.update({ ...updatedValues });
  }
}
