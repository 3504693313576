import { Injectable } from '@angular/core';
import { GatewaysStore } from './gateways.store';
import { EMPTY, Observable, of } from 'rxjs';
import { mapTo, tap } from 'rxjs/operators';
import { withTransaction } from '@datorama/akita';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { LocationsStore } from '../../functionality-modules/dialog-windows/locations/locations.store';
import { PointsStore } from '../points/points.store';

@Injectable()
export class GatewaysService {

  constructor(
    private _gatewaysStore: GatewaysStore,
    private _locationsStore: LocationsStore,
    private _pointsStore: PointsStore,
    private _googleAnalyticsService: GoogleAnalyticsService
  ) { }

  activateGateway$$(directionId: string | null): Observable<void> {
    if (directionId == null) {
      return EMPTY;
    }
    return of(void 0).pipe(
      withTransaction(() => {
        this._gatewaysStore.setActive(directionId);
        this._locationsStore.update({ allPartners: false }); // reset "show selected"
        this._pointsStore.remove(); // clear items immediately
      }),
      tap(() => {
        this._googleAnalyticsService.event('choose', 'online', 'choose_partner');
      }),
      mapTo(void 0)
    );
  }

  resetSelectedGateway(): void {
    this._gatewaysStore.setActive(null);
  }

}
