import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, mapTo, switchMap, tap } from 'rxjs/operators';
import { DocTypesResidenceConfirmationStore } from './doctypes-residence-confirmation.store';
import { IDocTypeValueResponseModel } from '../doctypes-identity/doctypes-identity.model';

@Injectable()
export class DoctypesResidenceConfirmationService {

  constructor(
    private _http: HttpClient,
    private _doctypesResidenceConfirmationStore: DocTypesResidenceConfirmationStore
  ) { }

  getDoctypesResidenceConfirmation$(): Observable<void> {
    return of(void 0).pipe(
      tap(() => this._doctypesResidenceConfirmationStore.setLoading(true)),
      switchMap(() => this._http.get<IDocTypeValueResponseModel[]>('reference/doctype/residence-confirmation')),
      catchError(() => {
        this._doctypesResidenceConfirmationStore.setLoading(false);
        return EMPTY;
      }),
      tap((doctypesResidenceConfirmation) => {
        this._doctypesResidenceConfirmationStore.upsertMany(doctypesResidenceConfirmation);
        this._doctypesResidenceConfirmationStore.setLoading(false);
      }),
      mapTo(void 0)
    );
  }
}
