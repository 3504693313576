<button mat-icon-button class="btn-mat-close locations__close--btn" (click)="onFinishClick()">
  <i class="ff-icon ff-close icon-size-2"></i>
</button>
<mat-dialog-content class="mat-dialog-content-max-height-none locations">
  <p mat-dialog-title class="locations__title">
    <span
      matTooltip="{{ '%[location.title.locations]%' | tr }}"
      matTooltipPosition="above"
      matTooltipClass="d-block d-md-none locations__title--text"
    >
      {{ '%[location.title.locations]%' | tr }}</span
    >
    <span
      matTooltip="{{ '%[location.title.locations.tooltip]%' | tr }}"
      matTooltipPosition="right"
      matTooltipClass="mat-tooltip-large"
      class="locations__title--tooltip"
    >
      <i class="ff-icon ff-info-circle icon-size-3 ms-4"></i>
    </span>
  </p>

  <div class="row mb-2">
    <div class="col-12 locations__filters">
      <div class="row">
        <div class="col-12 col-md-6">
          <ng-container [formGroup]="locationsFormGroup">
            <ff-select-input
              label="{{ '%[location.label.city]%' | tr }}"
              class="locations__city--input"
              emptyOptionText="{{ '%[global.select-input.label.no-options]%' | tr }}"
              [options]="directionCities$$ | async"
              formControlName="city"
              [hideRequiredMarker]="true"
              [showLoading]="isLoadingCities$$ | async"
              (loadAllOnScroll)="onLoadAllOnScroll()"
              [countries]="countries$$ | async"
              [searchable]="true"
            ></ff-select-input>
          </ng-container>
        </div>
        <div class="col-12 col-md-6">
          <!--{{ '%[profile.personal-data.label.city]%' | tr }}-->

          <ff-text-input
            class="w-100 locations__search--input"
            label="{{ '%[location.label.search]%' | tr }}"
            type="text"
            preventInputType="alphaNumericSymbols"
            [formControl]="locationsFormGroup.controls.search"
          ></ff-text-input>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="gateways$$ | async as gateways">
    <div class="col-12 locations-grid">
      <div class="row">
        <div class="col-10 text-start ms-1 mb-6 mb-sm-6 mb-md-0" *ngIf="gateways.length > 1">
          <span class="me-5 fw-bold">{{ '%[location.subtitle.locations]%' | tr }}</span>
          <app-slide-toggle [formControlRef]="locationsFormGroup.controls.allPartners">
            <span>{{ '%[location.checkbox.label.all-partners]%' | tr }}</span>
          </app-slide-toggle>
        </div>
        <div class="col-12 locations-grid-options">
          <ng-container *ngIf="gateways.length > 1; else oneGateway">
            <app-button-toggle-group
              [items]="gateways"
              [activeDirectionId]="activeGatewayDirectionId$$ | async"
              (clickEvent)="onPartnerClick($event)"
            ></app-button-toggle-group>
          </ng-container>

          <ng-template #oneGateway>
            <span class="me-5 fw-bold"
              >{{ '%[location.subtitle.locations]%' | tr }} - {{ gateways[0]?.partnerName?.toUpperCase() }}</span
            >
          </ng-template>

          <button
            mat-button
            class="btn-mat-link my-5 my-sm-0 d-flex"
            color="primary"
            [disabled]="isDownloadPdfButtonDisabled$$ | async"
            (click)="onDownloadPdfPointsClick()"
          >
            <span>{{ '%[location.button.save-as-pdf.label]%' | tr }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-locations-grid></app-locations-grid>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="row flex-grow-1 gy-5">
    <div class="col-12 col-md-6 locations__selected--points">
      <span *ngIf="(selectedPointsCount$$ | async) > 0">
        {{ selectedPointsInfoText$$ | async }}
      </span>
    </div>
    <div class="col-12 col-md-6 locations__action--buttons action-buttons">
      <button class="order-2 order-sm-1 action-buttons__btn" (click)="onFinishClick()" mat-stroked-button>
        {{ '%[location.button.skip.label]%' | tr }}
      </button>
      <button
        class="order-1 order-sm-2 action-buttons__btn"
        [disabled]="isAddButtonDisabled$$ | async"
        (click)="onFinishClick()"
        mat-flat-button
        color="primary"
        cdkFocusInitial
      >
        {{ '%[location.label.add]%' | tr }}
      </button>
    </div>
  </div>
  <!--FFO-1486-->
</mat-dialog-actions>

<ng-container *ngIf="isLoading$$ | async">
  <app-spinner [isFullScreen]="true"></app-spinner>
</ng-container>
