import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, mapTo, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ProfileAdditionalAttributesStore } from './profile-additional-attributes.store';
import {
  IProfileAdditionalAttributes,
  IProfileAdditionalAttributesResponse
} from '../private-models/profile-additional-attributes';

@Injectable()
export class ProfileAdditionalAttributesService {

  constructor(
    private profileAdditionalAttributesStore: ProfileAdditionalAttributesStore,
    private _http: HttpClient
  ) { }

  getAdditionalAttributes$(): Observable<void> {
    return of(void 0)
      .pipe(
        switchMap(() => this._http.get<IProfileAdditionalAttributesResponse>(`profile-additional-attributes`)),
        tap((response: IProfileAdditionalAttributesResponse) => {
          this.profileAdditionalAttributesStore.update((x: IProfileAdditionalAttributesResponse) => ({ ...x, ...response }));
        }),
        mapTo(void 0)
      );
  }

  setAdditionalAttribute$(partial: Partial<IProfileAdditionalAttributes>): Observable<void> {
    return of(void 0)
      .pipe(
        switchMap(() => this._http.post(`profile-additional-attributes`, partial)),
        catchError(() => {
          return EMPTY;
        }),
        tap(() => {
          this.profileAdditionalAttributesStore.update((x: IProfileAdditionalAttributes) => ({ ...x, ...partial }));
        }),
        mapTo(void 0)
      );
  }
}
