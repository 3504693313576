<mat-dialog-content class="dialog-content mat-dialog-content-pb-3">
  <div class="row">
    <div class="col-12">
      <p mat-dialog-title>
        {{ '%[send.prepare.title.redirect-to-payment]%' | tr }}
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <img class="dialog-content__img" [src]="themeService.getSharedImageUrl('/img/bank-card.svg')" alt="bank card" />
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <p class="dialog-content__text">
        <span>{{ '%[send.prepare.paragraph-1]%' | tr }}</span>
        <span>{{ '%[send.prepare.paragraph-2]%' | tr }}</span>
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <p class="dialog-content__redirect">
        <span (click)="onTextClick($event)">
          {{ '%[send.prepare.redirecting-after]%' | tr }}
        </span>
        <span>{{ secondsLeft$$ | async }}</span>
        <span>
          {{ '%[send.prepare.seconds]%' | tr }}
        </span>
      </p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="row flex-grow-1">
    <div class="col-sm-3 d-none d-sm-block"></div>
    <div class="col-12 col-sm-6">
      <button (click)="route$$.next()" mat-flat-button color="primary">
        {{ '%[send.prepare.proceed-to-payment]%' | tr }}
      </button>
    </div>
    <div class="col-sm-3 d-none d-sm-block"></div>
    <div class="col-12 mt-3">
      <mat-checkbox (change)="onCheckboxChange($event)">
        {{ '%[send.prepare.dont-notify]%' | tr }}
      </mat-checkbox>
    </div>
  </div>
</mat-dialog-actions>
