import { NgModule } from '@angular/core';

import { AuthService } from './auth.service';
import { AuthQuery, AuthStore } from './auth.store';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AuthService,
    AuthStore,
    AuthQuery
  ],
})
export class AuthModule {}
