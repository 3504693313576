import { Injectable } from '@angular/core';
import { Query, Store, StoreConfig } from '@datorama/akita';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import {
  IPaymentGatewayInfoDto,
  IReceiverInfo,
  IRedirectEndpoints, IRemittanceDestinationCountry,
  IRemitterInfo,
  ISummaryAmountInfo
} from '../containers/send/send.models';

export type PaymentGatewayType = 'SecureTrading' | 'DotPay' | 'Transact365' | 'Checkout' | 'FFPG';

export type PaymentGatewayInstrumentType =
  | 'Card'
  | 'Blik'
  | 'Sofort'
  | 'Trustly'
  | 'Giropay'
  | 'GooglePay'
  | 'ApplePay'
  | 'Przelewy24'
  | 'BlueMediaPBL';

export interface IPaymentGatewayGooglePaySettings {
  gateway: string | null,
  gatewayMerchantId: string | null,
  merchantId: string | null,
  merchantName: string | null
}

export interface IPaymentGatewayParams {
  currency: string | null,
  amount: string | null, // total amount
  hash: string | null,
  accountId: string | null,
  orderreference: string | null,
  successfulurlnotification: string | null,
  declinedurlnotification: string | null,
  // secure trading
  version: string | null,
  stprofile: string | null,
  stdefaultprofile: string | null,
  settlestatus: string | null,
  siteReference: string | null,
  sitesecuritytimestamp: string | null,
  token: string,
  mtNotificationUrl: string | null,
  mtCancelOnFail: boolean | null,
  stamp: string | null,
  merchantId: string | null,

  // dotpay
  control: string | null,
  channel: string | null,
  redirectUrl: string | null,
  description: string | null,
  type: string | null,
  remitterInfo: IRemitterInfo | null,
  receiverInfo: IReceiverInfo | null,
  remittanceCountry: IRemittanceDestinationCountry | null,

  // ffpg
  innerIp: string,
  baseApiUrl: string | null,
  paymentCustomerId: string | null,
  amountInfo: ISummaryAmountInfo | null,

  redirectEndpoints: IRedirectEndpoints | null,
  paymentInstruments: PaymentGatewayInstrumentType[],
  googlePaySettings: IPaymentGatewayGooglePaySettings | null
}

export interface ISendState {
  paymentGatewayParams: IPaymentGatewayParams,
  paymentGatewayType: PaymentGatewayType | null,
  isSendPageFullyLoaded: boolean
}

export function createInitialState(): ISendState {
  return {
    paymentGatewayParams: {
      currency: '',
      amount: '',
      version: '2',
      hash: '',
      stprofile: 'default',
      stdefaultprofile: 'st_paymentcardonly',
      settlestatus: '2',
      sitesecuritytimestamp: '',
      orderreference: '',
      successfulurlnotification: `${environment.stNotificationsUrl}notifications/strading`,
      declinedurlnotification: `${environment.stNotificationsUrl}notifications/strading`,
      accountId: '',
      channel: '',
      control: '',
      redirectUrl: '',
      siteReference: '',
      description: '',
      type: '',
      remitterInfo: null,
      receiverInfo: null,
      remittanceCountry: null,
      mtNotificationUrl: '',
      mtCancelOnFail: null,
      token: '',
      stamp: '',
      merchantId: '',
      innerIp: '',
      baseApiUrl: '',
      amountInfo: null,
      paymentCustomerId: '',
      redirectEndpoints: null,
      paymentInstruments: [],
      googlePaySettings: null,
    },
    paymentGatewayType: null,
    isSendPageFullyLoaded: false,
  };
}

@StoreConfig({ name: 'send' })
export class SendStore extends Store<ISendState> {
  constructor() {
    super(createInitialState());
  }


  setRedirectsWithToken(postBackToken: string | null): void {
    this.update((state) => ({
      ...state,
      paymentGatewayParams: {
        ...state.paymentGatewayParams,
        successfulurlnotification: `${environment.stNotificationsUrl}notifications/strading`,
        declinedurlnotification: `${environment.stNotificationsUrl}notifications/strading`,
      },
    }));
  }

  setPaymentGatewayDetails(paymentGatewayInfo: IPaymentGatewayInfoDto): void {
    this.update((state) => ({
      ...state,
      paymentGatewayParams: {
        ...state.paymentGatewayParams,
        ...paymentGatewayInfo,
        orderreference: paymentGatewayInfo.transactionId,
        sitesecuritytimestamp: paymentGatewayInfo.siteSecurityTimestamp,
      },
    }));
  }

  setPaymentGatewayType(paymentGatewayType: PaymentGatewayType): void {
    this.update((state) => ({
      ...state,
      paymentGatewayType,
    }));
  }
}

@Injectable()
export class SendQuery extends Query<ISendState> {
  paymentGatewayParams$: Observable<IPaymentGatewayParams> = this.select(state => state.paymentGatewayParams);
  paymentGatewayType$: Observable<PaymentGatewayType | null> = this.select(state => state.paymentGatewayType);
  isSendPageFullyLoaded$$: Observable<boolean> = this.select(state => state.isSendPageFullyLoaded);

  isLoading$$: Observable<boolean> = this.selectLoading();

  constructor(protected override store: SendStore) {
    super(store);
  }
}
