import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {
  concatMap,
  delay,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  pairwise,
  switchMap,
  take, takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { CalculatorQuery, CalculatorStore } from './calculator.store';
import { CalculatorService } from './calculator.service';
import { BehaviorSubject, combineLatest, EMPTY, merge, Observable, of, race } from 'rxjs';
import { CurrenciesDestinationQuery } from '../currencies-destination/currencies-destination.store';
import { WorkflowsService } from '../workflows/workflows.service';
import { WorkflowsQuery } from '../workflows/workflows.store';
import { IRemittanceCountry } from '../remittance-countries/remittance-countries.model';
import { GatewaysQuery } from '../gateways/gateways.store';
import { CurrenciesDestinationService } from '../currencies-destination/currencies-destination.service';
import { GatewaysService } from '../gateways/gateways.service';
import { RequiredFieldsService } from '../required-fields/required-fields.service';
import { environment } from '../../../environments/environment';
import { CurrenciesSourceService } from '../currencies-source/currencies-source.service';
import { CurrenciesSourceQuery } from '../currencies-source/currencies-source.store';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { FormStoreMapper } from '../../utils/helpers/form-store-mapper';
import { ProfileService } from '../../root/private/private-store/profile.service';
import { ILookupInt } from '../../root/private/private-models/lookup-str';
import {
  BeneficiaryAdditionalFieldsService
} from '../../root/private/private-pages/send/containers/beneficiary/beneficiary-additional-fields/beneficiary-additional-fields.service';
import { RequiredDocumentsService } from '../required-documents/required-documents.service';
import { RequiredFieldsQuery } from '../required-fields/required-fields.store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineQueries } from '@datorama/akita';
import { deepEquals } from '@ff/utils';
import { IWorkflow } from '../workflows/workflows.models';
import { IGateway } from '../gateways/gateways.model';
import { IFeesResponse } from './calculator.models';

@UntilDestroy()
@Injectable()
export class CalculatorFormService {

  private _currentControlFocus$$: BehaviorSubject<'sourceAmount' | 'destinationAmount' | 'none'> = new BehaviorSubject<'sourceAmount' | 'destinationAmount' | 'none'>('none');
  private _sourceAmountLostFocus$$: Observable<void> = this._currentControlFocus$$.pipe(
    pairwise(),
    filter(([prev, curr]) => prev === 'sourceAmount' && curr !== 'sourceAmount'),
    mapTo(void 0)
  );

  private _destinationAmountLostFocus$$: Observable<void> = this._currentControlFocus$$.pipe(
    pairwise(),
    filter(([prev, curr]) => prev === 'destinationAmount' && curr !== 'destinationAmount'),
    mapTo(void 0)
  );

  calculatorFormGroup: FormGroup = new FormGroup({
    destinationCountry: new FormControl(null, [Validators.required]),
    sourceAmount: new FormControl(''),
    sourceCurrency: new FormControl(null, [Validators.required]),
    destinationAmount: new FormControl(''),
    destinationCurrency: new FormControl(null, [Validators.required]),
  });

  formMapper: FormStoreMapper = new FormStoreMapper(this.calculatorFormGroup);

  constructor(
    private _calculatorStore: CalculatorStore,
    private _calculatorQuery: CalculatorQuery,
    private _calculatorService: CalculatorService,
    private _currenciesSourceService: CurrenciesSourceService,
    private _currenciesSourceQuery: CurrenciesSourceQuery,
    private _workflowsService: WorkflowsService,
    private _profileService: ProfileService,
    private _gatewaysQuery: GatewaysQuery,
    private _gatewayQuery: GatewaysQuery,
    private _gatewaysService: GatewaysService,
    private _currenciesDestinationService: CurrenciesDestinationService,
    private _requiredFieldsService: RequiredFieldsService,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _beneficiaryAdditionalFieldsService: BeneficiaryAdditionalFieldsService,
    private _requiredDocumentsService: RequiredDocumentsService,
    private _currenciesDestinationQuery: CurrenciesDestinationQuery,
    private _requiredFieldsQuery: RequiredFieldsQuery,
    private _workflowsQuery: WorkflowsQuery
  ) {
    this._updateStoreOnFormChange();
    this._updateControlOnStoreChange();
    this._setDisabledFormState();
  }

  setCurrentControlFocus(controlName: 'sourceAmount' | 'destinationAmount' | 'none'): void {
    this._currentControlFocus$$.next(controlName);
  }

  private _setDisabledFormState(): void {

    combineQueries([
      this._currenciesSourceQuery.loading$$,
      this._calculatorQuery.isFeeLoading$$,
      this._currenciesDestinationQuery.loading$$,
      this._requiredFieldsQuery.loading$$,
      this._workflowsQuery.loading$$
    ]).pipe(
      map((arr: boolean[]) => {
        const isDisabled = arr.some(e => e);
        if (isDisabled) {
          this.calculatorFormGroup.controls?.destinationCountry?.disable({ emitEvent: false });
        } else {
          this.calculatorFormGroup.controls?.destinationCountry?.enable({ emitEvent: false });
        }
      }),
      untilDestroyed(this)
    ).subscribe();

    combineQueries([
      this._calculatorService.partnerIsNotSelected$$,
      this._currenciesSourceQuery.loading$$,
      this._currenciesDestinationQuery.loading$$,
      this._workflowsQuery.loading$$
    ]).pipe(
      map((arr: boolean[]) => {
        const isDisabled = arr.some(e => e);
        if (isDisabled) {
          this.calculatorFormGroup.controls?.sourceAmount?.disable({ emitEvent: false });
          this.calculatorFormGroup.controls?.destinationAmount?.disable({ emitEvent: false });
        } else {
          this.calculatorFormGroup.controls?.sourceAmount?.enable({ emitEvent: false });
          this.calculatorFormGroup.controls?.destinationAmount?.enable({ emitEvent: false });
        }
      }),
      untilDestroyed(this)
    ).subscribe();

    combineQueries([
      this._calculatorService.partnerIsNotSelected$$,
      this._currenciesDestinationQuery.loading$$,
      this._currenciesSourceQuery.loading$$,
    ]).pipe(
      map((arr: boolean[]) => {
        const isDisabled = arr.some(e => e);
        if (isDisabled) {
          this.calculatorFormGroup.controls?.sourceCurrency?.disable({ emitEvent: false });
          this.calculatorFormGroup.controls?.destinationCurrency?.disable({ emitEvent: false });
        } else {
          this.calculatorFormGroup.controls?.sourceCurrency?.enable({ emitEvent: false });
          this.calculatorFormGroup.controls?.destinationCurrency?.enable({ emitEvent: false });
        }
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  private _updateStoreOnFormChange(): void {
    this.calculatorFormGroup.controls.destinationCountry?.valueChanges.pipe(
      map(val => val as IRemittanceCountry | null),
      withLatestFrom(this._calculatorQuery.destinationCountry$$),
      filter(([countryFromControl, countryInStore]) => (countryFromControl?.id ?? null) !== (countryInStore?.id ?? null))
    ).pipe(
      tap(([countryFromControl]) => this._calculatorStore.update({ destinationCountry: countryFromControl })),
      switchMap(() => this._currenciesSourceService.getCurrenciesSource()),
      withLatestFrom( of(environment.appSettings.defaultCalculatorSourceCurrency), this._currenciesSourceQuery.selectAll()),
      switchMap(([_, defaultCalculatorSourceCurrency, sourceCurrencies]) => {
        let defaultSourceCurrency: ILookupInt | undefined = sourceCurrencies.find((x: ILookupInt) => x.id === Number(defaultCalculatorSourceCurrency));
        if (defaultSourceCurrency == null) {
          defaultSourceCurrency = sourceCurrencies[0];
        }
        return this._calculatorService.setSourceCurrency$(defaultSourceCurrency);
      }),
      switchMap(() => this._workflowsService.downloadAndStoreWorkflows$()),
      switchMap(() => this._checkWorkflows()),
      tap(() => this._googleAnalyticsService.event('select-remittance-country-success', '', '')),
      untilDestroyed(this)
    ).subscribe();

    this.formMapper.formChange<string>('sourceAmount',
      value => race([
        of(value).pipe(delay(2000)),
        this._sourceAmountLostFocus$$.pipe(mapTo(value))
      ]).pipe(
        take(1),
        map((v: string) => parseFloat(v)),
        switchMap((val: number) => {
          if ((val === 0) || isNaN(val)) {
            return of(void 0);
          }
          return of(val)
            .pipe(
              switchMap(() => this._calculatorService.setSourceAmount$(val)),
              tap(() => this.calculatorFormGroup.controls.destinationCountry?.markAsTouched({ onlySelf: true })),
              switchMap(() => this._calculatorService.executeCalculationAndUpdateStore$()),
              switchMap(() => this._manageMinMaxValidator()),
              concatMap(() => this._checkCalculatorAndUpdateFlags()),
              switchMap(() => this._profileService.updateProfile$()),
            );
        }),
        tap(() => {
          this._googleAnalyticsService.event('change-source-amount-success', '', '');
        }),
        takeUntil(merge(
          this.calculatorFormGroup.controls.sourceCurrency?.valueChanges ?? EMPTY,
          this.calculatorFormGroup.controls.destinationAmount?.valueChanges ?? EMPTY,
          this.calculatorFormGroup.controls.destinationCurrency?.valueChanges ?? EMPTY
        ))
      ), { cancelExecutionOnValueChanges: true });

    this.formMapper.formChange<string>('destinationAmount',
      value => race([
        of(value).pipe(delay(2000)),
        this._destinationAmountLostFocus$$.pipe(mapTo(value))
      ]).pipe(
        map((v: string) => parseFloat(v)),
        switchMap((val: number) => {
          if ((val === 0) || isNaN(val)) { return of(void 0); }
          return of(val).pipe(
            switchMap(() => this._calculatorService.setDestinationAmount$(val)),
            tap(() => this.calculatorFormGroup.controls.destinationCountry?.markAllAsTouched()),
            switchMap(() => this._calculatorService.executeCalculationAndUpdateStore$()),
            switchMap(() => this._manageMinMaxValidator()),
            concatMap(() => this._checkCalculatorAndUpdateFlags()),
            switchMap(() => this._profileService.updateProfile$())
          );
        }),
        tap(() => this._googleAnalyticsService.event('change-destination-amount-success', '', '')),
        takeUntil(merge(
          this.calculatorFormGroup.controls.sourceAmount?.valueChanges ?? EMPTY,
          this.calculatorFormGroup.controls.sourceCurrency?.valueChanges ?? EMPTY,
          this.calculatorFormGroup.controls.destinationCurrency?.valueChanges ?? EMPTY
        ))
      ), { cancelExecutionOnValueChanges: true }
    );

    this.calculatorFormGroup.controls.sourceCurrency?.valueChanges.pipe(
      distinctUntilChanged((x, y) => deepEquals(x, y)),
      filter((value) => value != null),
      mergeMap(sourceCurrency => this._sourceCurrency$$(sourceCurrency)),
      untilDestroyed(this)
    ).subscribe();


    this.calculatorFormGroup.controls?.destinationCurrency?.valueChanges.pipe(
      distinctUntilChanged((x, y) => deepEquals(x, y)),
      filter((value) => value != null),
      tap(value => this._calculatorService.setDestinationCurrency(value)),
      switchMap(() => this._calculatorService.executeCalculationAndUpdateStore$()),
      switchMap(() => this._profileService.updateProfile$()),
      concatMap(() => this._checkCalculatorAndUpdateFlags()),
      tap(() => this._googleAnalyticsService.event('change-destination-currency-success', '', '')),
      untilDestroyed(this)
    ).subscribe();
  }

  private _sourceCurrency$$(sourceCurrency: ILookupInt): Observable<void> {
    return of(sourceCurrency).pipe(
      mergeMap(() => this._calculatorService.setSourceCurrency$(sourceCurrency)),
      mergeMap(() => this._gatewaysQuery.selectActive()),
      take(1),
      mergeMap(gw => {
        if (gw == null) {
          console.error('gw can not be empty');
          return EMPTY;
        }
        return this._currenciesDestinationService.getCurrenciesDestination$(sourceCurrency?.id, gw?.directionId);
      }),
      switchMap(() => this._calculatorService.executeCalculationAndUpdateStore$()),
      switchMap(() => this._profileService.updateProfile$()),
      concatMap(() => this._checkCalculatorAndUpdateFlags()),
      tap(() => this._googleAnalyticsService.event('change-source-currency-success', '', ''))
    );
  }

  private _updateControlOnStoreChange(): void {
    this._calculatorQuery.destinationCountry$$.pipe(
      tap(destinationCountry => {
        this.calculatorFormGroup.patchValue({ destinationCountry }, { emitEvent: false });
      }),
      untilDestroyed(this)
    ).subscribe();

    this._calculatorQuery.sourceAmount$$.pipe(
      map(sourceAmount =>
        sourceAmount?.toFixed(2)?.replace('.00', '') ?? ''),
      tap(sourceAmount => this.calculatorFormGroup.patchValue({ sourceAmount }, { emitEvent: false })),
      untilDestroyed(this)
    ).subscribe();

    this._calculatorQuery.sourceCurrency$$.pipe(
      tap(sourceCurrency => this.calculatorFormGroup.patchValue({ sourceCurrency }, { emitEvent: false, onlySelf: true })),
      untilDestroyed(this)
    ).subscribe();

    this._calculatorQuery.destinationAmount$$.pipe(
      map(destinationAmount =>
        destinationAmount?.toFixed(2)?.replace('.00', '') ?? ''),
      tap(destinationAmount => this.calculatorFormGroup.patchValue({ destinationAmount }, { emitEvent: false })),
      untilDestroyed(this)
    ).subscribe();

    this._calculatorQuery.destinationCurrency$$.pipe(
      tap(destinationCurrency => this.calculatorFormGroup.patchValue({ destinationCurrency }, { emitEvent: false })),
      untilDestroyed(this)
    ).subscribe();

  }

  private _partnerAmountMaxValidator(max: number | null): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return of(void 0)
        .pipe(
          map(_ => {
            control.markAllAsTouched();
            return { partnerAmountMax: max };
          })
        );
    };
  }

  private _partnerAmountMinValidator(min: number | null): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return of(void 0)
        .pipe(
          map(_ => {
            control.markAllAsTouched();
            return { partnerAmountMin: min };
          })
        );
    };
  }

  private _manageMinMaxValidator(): Observable<void> {
    return of(void 0)
      .pipe(
        switchMap(() => this._calculatorQuery.calculationResult$$),
        take(1),
        tap((calcResult: IFeesResponse) => {
          if (Number(calcResult.source.amount) > Number(calcResult.source.amountMax)) {
            this.calculatorFormGroup.controls.sourceAmount?.setAsyncValidators([this._partnerAmountMaxValidator(calcResult.source.amountMax)]);
          } else if (Number(calcResult.source.amount) < Number(calcResult.source.amountMin)) {
            this.calculatorFormGroup.controls.sourceAmount?.setAsyncValidators([this._partnerAmountMinValidator(calcResult.source.amountMin)]);
          } else {
            this.calculatorFormGroup.controls.sourceAmount?.clearAsyncValidators();
          }
          this.calculatorFormGroup.controls.sourceAmount?.updateValueAndValidity({ emitEvent: false });
        }),
        mapTo(void 0)
      );
  }

  private _activateGatewayAndLoadDependencies(gateway: IGateway): Observable<void> {
    return this._gatewaysService.activateGateway$$(gateway.directionId).pipe(
      switchMap(_ => this._calculatorQuery.sourceCurrency$$),
      take(1),
      switchMap(sc => {
        if (sc == null) {
          console.error('sc can not be empty');
          return EMPTY;
        }
        return of(sc);
      }),
      switchMap((sc: ILookupInt) => this._currenciesDestinationService.getCurrenciesDestination$(sc.id, gateway.directionId)),
      switchMap(_ => this._requiredFieldsService.getRequiredFields$(gateway.directionId)),
      tap(() => this._beneficiaryAdditionalFieldsService.resetStore()),
      switchMap(() => this._calculatorService.executeCalculationAndUpdateStore$()),
      concatMap(() => this._checkCalculatorAndUpdateFlags())
    );
  }

  private _updateActiveWorkflow(wf: IWorkflow): Observable<void> {
    return this._workflowsService.activateWorkflow$(wf)
      .pipe(
        switchMap(() => this._gatewaysQuery.selectAll()),
        take(1),
        switchMap((gateways) => {
          if (gateways.length === 1 && gateways[0] != null) {
            return this._activateGatewayAndLoadDependencies(gateways[0]);
          }
          return of(void 0);
        }),
        mapTo(void 0)
      );
  }

  private _resetStoresWorkflowsEmptyOrMoreThanOne(): Observable<void> {
    return this._calculatorService.resetStoresWorkflowsEmptyOrMoreThanOne().pipe(
      tap(() => {
        this.calculatorFormGroup.controls.sourceAmount?.reset();
        this.calculatorFormGroup.controls.destinationAmount?.reset();
        this.calculatorFormGroup.controls.destinationCurrency?.reset();
      })
    );
  }

  private _checkWorkflows(): Observable<void> {
    return this._workflowsQuery.selectAll().pipe(
      take(1),
      switchMap((wfs: IWorkflow[]) => {
        if (wfs.length === 1 && wfs[0] != null) {
          return this._updateActiveWorkflow(wfs[0]);
        }
        return this._resetStoresWorkflowsEmptyOrMoreThanOne();
      })
    );
  }

  private _checkCalculatorAndUpdateFlags(): Observable<void> {
    return combineLatest([
      this._calculatorQuery.sourceAmount$$,
      this._calculatorQuery.sourceCurrency$$,
      this._gatewayQuery.activeGateway$$
    ]).pipe(
    take(1),
    concatMap((values) => values.every(v => v != null) ? this._requiredDocumentsService.updateRequiredDocuments$() : of(void 0)));
  }
}
