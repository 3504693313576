import { Injectable } from '@angular/core';
import { Query, Store, StoreConfig } from '@datorama/akita';
import { Observable } from 'rxjs';

export interface IPasswordState {
  isShowLoader: boolean,
  isEnableSaveButton: boolean,
  isEnableFields: boolean,
  isShowCancelButton: boolean,
  isShowCodeField: boolean,
  isEnableCodeField: boolean,
  isPasswordSet: boolean,
  secondsLeft: number,
  code: string,
  password: string,
  confirmPassword: string,
  ui: {
    isPasswordVisible: boolean
  }
}

export function createInitialState(): IPasswordState {
  return {
    isShowLoader: false,
    isEnableSaveButton: true,
    isEnableFields: true,
    isPasswordSet: false,
    isShowCancelButton: true,
    isShowCodeField: false,
    isEnableCodeField: true,
    secondsLeft: 0,
    code: '',
    confirmPassword: '',
    password: '',
    ui: {
      isPasswordVisible: false,
    }
  };
}

@StoreConfig({ name: 'security-password' })
@Injectable()
export class PasswordStore extends Store<IPasswordState> {
  constructor() {
    super(createInitialState());
  }
}

@Injectable()
export class PasswordQuery extends Query<IPasswordState> {

  state$$: Observable<IPasswordState> = this.select(x => x);
  confirmPassword$$: Observable<string> = this.select(x => x.confirmPassword);
  password$$: Observable<string> = this.select(x => x.password);
  code$$: Observable<string> = this.select(x => x.code);
  isPasswordVisible$$: Observable<boolean> = this.select(x => x.ui.isPasswordVisible);
  isEnableCodeField$$: Observable<boolean> = this.select(x => x.isEnableCodeField);
  isShowCodeField$$: Observable<boolean> = this.select(x => x.isShowCodeField);
  isEnableSaveButton$$: Observable<boolean> = this.select(x => x.isEnableSaveButton);
  isShowCancelButton$$: Observable<boolean> = this.select(x => x.isShowCancelButton);
  isEnableFields$$: Observable<boolean> = this.select(x => x.isEnableFields);
  secondsLeft$$: Observable<number> = this.select(x => x.secondsLeft);
  isLoading$$: Observable<boolean> = this.selectLoading();

  constructor(
    protected override store: PasswordStore
  ) {
    super(store);
  }
}
