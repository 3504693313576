import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { IPaymentGatewayParams } from '../../../store/send.store';
import { SnackbarService } from '../../../../../../../functionality-modules/snackbars/snackbar.service';
import { LocalizationQuery } from '@ff/localization';
import { environment } from '../../../../../../../../environments/environment';


export interface ITransact365HostedPaymentsResponse {
  checkout: {
    token: string,
    redirectUrl: string,
    brands: [],
    company: unknown,
    description: string,
    cardInfo: unknown
  }
}

export interface ITransact365CreateTokenBody {
  checkout: {
    test: boolean,
    transactionType: 'authorization' | 'payment' | 'tokenization',
    attempts?: number,
    order: {
      currency: string,
      amount: number,
      description: string,
      trackingId?: string,
      expiredAt?: string,
      additionalData?: {
        receiptText?: string[],
        contract?: ('recurring' | 'oneclick' | 'credit' | 'card_on_file' | '')[]
      },
      cardOnFile?: {
        initiator: 'merchant' | 'customer',
        type: 'delayed_charge' | 'increment' | 'resubmission' | 'reauthorization' | 'no_show'
      }
    },
    settings: {
      returnUrl?: string,
      successUrl?: string,
      declineUrl?: string,
      failUrl?: string,
      cancelUrl?: string,
      notificationUrl?: string,
      verificationUrl?: string,
      autoReturn?: number,
      buttonText?: string,
      buttonNextText?: string,
      language?: string | 'en' | 'es' | 'tr' | 'de' | 'it' | 'ru' | 'zh' | 'fr' | 'da' | 'sv' | 'no' | 'fi' | 'pl' | 'ja' | 'uk' | 'be' | 'ka',
      customerFields: {
        visible?: ('first_name' | 'last_name' | 'email' | 'address' | 'city' | 'state' | 'zip' | 'phone' | 'country' | 'birth_date')[],
        readOnly?: ('first_name' | 'last_name' | 'email' | 'address' | 'city' | 'state' | 'zip' | 'phone' | 'country' | 'birth_date')[]
      } | null
    },
    customer: {
      creditCardFields?: {
        holder?: 'string',
        readOnly?: boolean
      },
      email: string,
      firstName?: string,
      lastName?: string,
      address: string,
      city: string,
      state?: 'US' | 'CA',
      zip?: string, // only in us
      phone?: string,
      birthDate?: string, // YYYY-MM-DD
      country: string | null | undefined
    }
  }
}


@Injectable()
export class Transact365Service {
  private _http: HttpClient;

  constructor(
    backend: HttpBackend,
    private _router: Router,
    private _snackbarService: SnackbarService,
    private _localizationQuery: LocalizationQuery
  ) {
    this._http = new HttpClient(backend);
  }

  startConnection$(paymentGatewayParams: IPaymentGatewayParams): Observable<string> {
    return of(0).pipe(
      switchMap(() => {
        const headers = new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('X-API-Version', '2')
          .set('Accept', 'application/json')
          .set('Authorization', paymentGatewayParams.token);

        const body: ITransact365CreateTokenBody = {
          checkout: {
            test: environment.thirdPartyServices.paymentForm.transact365.test,
            transactionType: 'authorization',
            settings: {
              successUrl: `${paymentGatewayParams.redirectEndpoints?.successUrl}/${paymentGatewayParams.orderreference}/OK` ?? '',
              cancelUrl: `${paymentGatewayParams.redirectEndpoints?.cancelUrl}/private/history` ?? '',
              failUrl: `${paymentGatewayParams.redirectEndpoints?.failureUrl}/${paymentGatewayParams.orderreference}/Failed` ?? '',
              notificationUrl: paymentGatewayParams.baseApiUrl ?? '',
              customerFields: null,
              language: paymentGatewayParams.remitterInfo?.language ?? 'en'
            },
            order: {
              currency: paymentGatewayParams.amountInfo?.totalAmountCurrency ?? '',
              amount: Math.trunc((paymentGatewayParams.amountInfo?.totalAmount ?? 0) * 100), // amount 	cost in minimal currency units, e.g. $32.45 must be sent as 3245
              description: environment.thirdPartyServices.paymentForm.transact365.description,
              trackingId: paymentGatewayParams.control ?? ''
            },
            customer: {
              address: `${paymentGatewayParams.remitterInfo?.address?.street} ${paymentGatewayParams.remitterInfo?.address?.place}`,
              country: paymentGatewayParams.remitterInfo?.address?.countryCode ?? '',
              city: paymentGatewayParams.remitterInfo?.address?.city ?? '',
              email: paymentGatewayParams.remitterInfo?.email ?? ''
            }
          }
        };

        return this._http.post<ITransact365HostedPaymentsResponse>(` ${environment.thirdPartyServices.paymentForm.transact365.checkoutURI}/checkouts`, body, { headers });
      }),
      catchError((err) => {
        console.error(err);
        this._router.navigate(['private', 'history']).then();
        this._snackbarService.openFailure$(this._localizationQuery.transform('%[send.snackbar.start-failed]%') + ':' + err.message).subscribe();
        return EMPTY;
      }),
      map(r => r.checkout.redirectUrl)
    );
  }
}
