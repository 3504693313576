import { EntityState, EntityStore, EntityUIQuery, EntityUIStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { defer, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IDocumentBody, IDocumentBodyUI } from '../../../../private-models/document.model';
import { AttachmentStatus, IAttachment } from '../../../../private-models/attachment.model';

export interface ISourceOfIncomeState extends EntityState<IDocumentBody, string> {}

export interface ISOIUIState extends EntityState<IDocumentBodyUI, string> {
  newDocFormIsOpen: boolean
}

@StoreConfig({ name: 'sources-of-income' })
export class SourceOfIncomeStore extends EntityStore<ISourceOfIncomeState> {
  override ui!: EntityUIStore<ISOIUIState>;

  constructor() {
    super();
    this.createUIStore();
  }
}

@Injectable()
export class SourcesOfIncomeQuery extends QueryEntity<ISourceOfIncomeState> {
  override ui: EntityUIQuery<ISOIUIState>;
  isLoading$$: Observable<boolean> = this.selectLoading();
  state$$: Observable<ISourceOfIncomeState> = this.selectAll();
  stateUI$$: Observable<ISourceOfIncomeState> = defer(() => this.ui.selectAll());

  firstValidSoi$: Observable<IDocumentBody | undefined> = this.selectAll()
    .pipe(
      map((sois: IDocumentBody[]): IDocumentBody | undefined => sois.find(soi => {

        const goodStatuses: AttachmentStatus[] = ['New', 'AcceptBot', 'AcceptManualInner', 'AcceptManualOuter'];
        const isScansGood: boolean = soi.scans.every(scan => goodStatuses.includes(scan.attachmentFileStatus?.alias as AttachmentStatus));
        return !soi.document.isExpired && isScansGood;
      })),
    );

  isHasCustomerSourceOfIncomeBody$: Observable<boolean> = this.selectAll()
    .pipe(
      map((sourcesOfIncome: IDocumentBody[]) => sourcesOfIncome.length > 0)
    );

  isCustomerSourceOfIncomeExpired$: Observable<boolean> = this.selectAll()
    .pipe(
      map((sourcesOfIncome: IDocumentBody[]) => sourcesOfIncome.every(poa => poa.document.isExpired)),
    );

  isHasCustomerSourceOfIncomeCopy$: Observable<boolean> = this.selectAll()
    .pipe(
      map((sourcesOfIncome: IDocumentBody[]): IDocumentBody | undefined => sourcesOfIncome.find(soi => !soi.document.isExpired)),
      map((soi: IDocumentBody | undefined) => {
        if (soi == null) {
          return false;
        }
        return soi.scans.length > 0;
      })
    );

  isCustomerSourceOfIncomeCopyBad$: Observable<boolean> = this.selectAll()
    .pipe(
      map((sourcesOfIncome: IDocumentBody[]) => sourcesOfIncome.find(soi => !soi.document.isExpired)),
      switchMap((soi: IDocumentBody | undefined) => {
        if (soi == null) {
          return of(false);
        }
        return of(soi.scans[0])
          .pipe(
            map((scan: IAttachment | undefined) => {
              if (scan == null || scan.attachmentFileStatus == null) {
                return false;
              }
              return ['DenyBot', 'DenyManualInner', 'DenyManualOuter'].includes(scan.attachmentFileStatus.alias);
            })
          );
      }),
    );

  isNewSoiOpen$$: Observable<boolean> = defer(() => this.ui.select(x => x.newDocFormIsOpen));

  isCustomerSourceOfIncomeCopyNewOrInApproval$: Observable<boolean> = this.selectAll()
    .pipe(
      map((sourcesOfIncome: IDocumentBody[]) => sourcesOfIncome.find(soi => !soi.document.isExpired)),
      switchMap((soi: IDocumentBody | undefined) => {
        if (soi == null) {
          return of(false);
        }
        return of(soi.scans[0])
          .pipe(
            map((scan: IAttachment | undefined) => {
              if (scan == null || scan.attachmentFileStatus == null) {
                return false;
              }
              return ['New', 'OnBoardingConfirmation'].includes(scan.attachmentFileStatus.alias);
            })
          );
      }),
    );

  constructor(protected override store: SourceOfIncomeStore) {
    super(store);
    this.createUIQuery();
  }

  isCustomerSourceOfIncomeCopyHasStatuses(statuses: AttachmentStatus[]): Observable<boolean> {
    return this.selectAll()
      .pipe(
        map((proofsOfAddress: IDocumentBody[]) => proofsOfAddress.find(poa => !poa.document.isExpired)),
        switchMap((poa: IDocumentBody | undefined) => {
          if (poa == null) {
            return of(false);
          }
          return of(poa.scans[0])
            .pipe(
              map((scan: IAttachment | undefined) => {
                if (scan == null || scan.attachmentFileStatus == null) {
                  return false;
                }
                return statuses.includes(scan.attachmentFileStatus.alias);
              })
            );
        }),
      );
  }
}
