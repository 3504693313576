<mat-dialog-content class="dialog-content mat-dialog-content-pb-3">
  <div class="row">
    <div class="col-1 d-none d-md-block"></div>
    <div class="col-12 col-md-10">
      <p mat-dialog-title>{{ '%[change-country.label.are-you-sure]%' | tr }}</p>
    </div>
    <div class="col-1 d-none d-md-block"></div>
  </div>
  <div class="row">
    <div class="col-12">
      <p class="dialog-content__text">{{ '%[change-country.label.country-is-changed]%' | tr }}</p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="row flex-grow-1 gy-5 justify-content-center">
    <div class="col-12 col-sm-4 order-2 order-sm-1">
      <button mat-stroked-button class="btn-block" (click)="onCloseClick(false)">
        {{ '%[change-country.label.no]%' | tr }}
      </button>
    </div>
    <div class="col-12 col-sm-4 order-1 order-sm-2">
      <button mat-flat-button color="primary" class="btn-block" (click)="onCloseClick(true)">
        {{ '%[change-country.label.yes]%' | tr }}
      </button>
    </div>
  </div>
</mat-dialog-actions>
