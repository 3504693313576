import { EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ICountries2State, idKey, IIdIssuedCountriesModel } from '../id-issued-countries/id-issued-countries.model';
import { Observable } from 'rxjs';

@Injectable()
@StoreConfig({ name: 'residence-countries', idKey })
export class ResidenceCountriesStore extends EntityStore<ICountries2State> {
  constructor() {
    super();
  }
}

@Injectable()
export class ResidenceCountriesQuery extends QueryEntity<ICountries2State> {

  state$$: Observable<IIdIssuedCountriesModel[]> = this.selectAll();

  constructor(protected override store: ResidenceCountriesStore) {
    super(store);
  }
}
