import { NgModule } from '@angular/core';
import { ResidenceCountriesService } from './residence-countries.service';
import { ResidenceCountriesQuery, ResidenceCountriesStore } from './residence-countries.store';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ResidenceCountriesService,
    ResidenceCountriesStore,
    ResidenceCountriesQuery,
  ],
})
export class ResidenceCountriesModule {}
