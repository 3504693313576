<mat-dialog-content>
  <div class="modal__header">
    <div class="row">
      <div class="col-12">
        <p mat-dialog-title class="text-center">{{ '%[navigation-left.label.log-out]%' | tr }}</p>
      </div>
    </div>
  </div>
  <div class="modal__content">
    <div class="row">
      <div class="col-12">
        <p class="text-center mb-0">{{ '%[info-dialog.logout.label]%' | tr }}</p>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="row flex-grow-1">
    <div class="col-6">
      <button mat-stroked-button class="w-100" [mat-dialog-close]="false">
        {{ '%[change-country.label.no]%' | tr }}
      </button>
    </div>
    <div class="col-6">
      <button mat-flat-button color="accent" class="w-100" [mat-dialog-close]="true" (click)="onLogOutClick($event)">
        {{ '%[change-country.label.yes]%' | tr }}
      </button>
    </div>
  </div>
</mat-dialog-actions>
