import { Injectable } from '@angular/core';

@Injectable()
export class RateHasChangedService {
  // public newRate$: ReplaySubject<IFeesResponse> = new ReplaySubject<IFeesResponse>(1);
  constructor(
    // private _workflowsQuery: WorkflowsQuery,
    // private _gatewaysQuery: GatewaysQuery,
    // private _googleAnalyticsService: GoogleAnalyticsService,
    // private _calculatorQuery: CalculatorQuery,
    // private _http: HttpClient,
    // private _pointsQuery: PointsQuery,
    // private _storedProcedureErrorService: StoredProcedureErrorService,
    // private beneficiaryGeneralFormQuery: BeneficiaryGeneralFormQuery,
    // private beneficiaryAdditionalFieldsFormQuery: BeneficiaryAdditionalFieldsFormQuery,
    // private _liQuestionnaireQuery: LithuanianQuestionnaireQuery,
    // private _beneficiaryService: BeneficiaryService,
    // private _calculatorFormService: CalculatorFormService,
    // private _languagesQ: LanguagesQuery
  ) {
    // this.getNewRate().subscribe();
  }

  // startRemittance(): Observable<void> {
  //   return of(void 0)
  //     .pipe(
  //       switchMap(() => combineQueries([
  //         this._gatewaysQuery.activeGatewayNoNull$,
  //         this._workflowsQuery.activeWorkflow$,
  //         this._calculatorQuery.amountKey$,
  //         this.newRate$,
  //         this._calculatorQuery.destinationCountry$,
  //         this._pointsQuery.selectAll().pipe(
  //           map((points: IPoint[]) => points.filter(p => p.selected)),
  //           map((points: IPoint[]): number[] => points.map(p => p.pointId))
  //         ),
  //         this.beneficiaryGeneralFormQuery.select(x => x),
  //         this.beneficiaryAdditionalFieldsFormQuery.select(),
  //         this._liQuestionnaireQuery.select(x => x.ui.answers)
  //       ])),
  //       take(1),
  //       map(([
  //              activeGateway,
  //              activeWorkflow,
  //              amountKey,
  //              calculationResult,
  //              destinationCountry,
  //              selectedPoints,
  //              beneficiaryGeneralForm,
  //              beneficiaryAdditionalFieldsForm,
  //              questionnaireData
  //            ]): IStartRemittanceBody => {
  //
  //         const calculatorForm = this._calculatorFormService.calculatorFormGroup.value;
  //         const receiver = {
  //           ...{
  //             ...beneficiaryGeneralForm,
  //             dateOfBirth: beneficiaryGeneralForm.dateOfBirth === '' ? null : beneficiaryGeneralForm.dateOfBirth
  //           },
  //           ...this._beneficiaryService.beneficiaryAddressService.beneficiaryAddressForm.value
  //         };
  //
  //         const remappedQuestionnaireData = questionnaireData?.map(x => ({
  //           questionId: x.id,
  //           selectedValue: x.selectedValue,
  //           customText: x.customText
  //         }));
  //
  //         return {
  //           country: destinationCountry,
  //           gateway: activeGateway,
  //           workflow: activeWorkflow?.id,
  //           directionId: activeGateway.directionId,
  //           sourceCurrency: { ...calculatorForm.sourceCurrency, id: Number(calculatorForm.sourceCurrency.id) },
  //           destinationCurrency: calculatorForm.destinationCurrency,
  //           sourceAmount: calculationResult?.source.amount,
  //           destinationAmount: calculationResult?.destination.amount,
  //           commission: calculationResult?.source.commission,
  //           receiver: receiver,
  //           transferPurpose: receiver.remittancePurpose,
  //           sourceOfIncome: beneficiaryGeneralForm.senderSourceOfIncomeType ?? null,
  //           additionalFields: {
  //             beneficiaryPan: beneficiaryAdditionalFieldsForm.beneficiaryPan,
  //             beneficiaryIDType: typeof beneficiaryAdditionalFieldsForm.beneficiaryIDType === 'string'
  //               ? beneficiaryAdditionalFieldsForm.beneficiaryIDType
  //               : beneficiaryAdditionalFieldsForm.beneficiaryIDType?.id,
  //             beneficiaryIDNumber: beneficiaryAdditionalFieldsForm.beneficiaryIDNumber,
  //             beneficiaryAccountNumber: beneficiaryAdditionalFieldsForm.beneficiaryAccountNumber,
  //             beneficiaryCitizenship: beneficiaryAdditionalFieldsForm.beneficiaryCitizenship?.id,
  //             beneficiaryBankCode: beneficiaryAdditionalFieldsForm.beneficiaryBankCode?.code,
  //             beneficiaryBankBranchCode: beneficiaryAdditionalFieldsForm.beneficiaryBankBranchCode?.code,
  //             beneficiaryAccountType: beneficiaryAdditionalFieldsForm.beneficiaryAccountType?.id,
  //             beneficiaryBankBic: beneficiaryAdditionalFieldsForm.beneficiaryBankBic,
  //             beneficiaryBankBranchBic: beneficiaryAdditionalFieldsForm.beneficiaryBankBranchBic,
  //           },
  //           amountKey: amountKey,
  //           hash: [],
  //           pointIds: [...selectedPoints],
  //           questionnaireAnswers: remappedQuestionnaireData
  //         };
  //       }),
  //       switchMap((startRemittanceBody: IStartRemittanceBody): Observable<IPaymentGatewayInfoDto> => this.apiStartRemittance(startRemittanceBody)),
  //       tap((response) => {
  //         this._sendStore.setPaymentGatewayDetails(response);
  //         this._sendStore.setPaymentGatewayType(response.paymentGatewayType);
  //         this._sendStore.setRedirectsWithToken(response.transactionId);
  //       }),
  //       switchMap(() => this._profileAdditionalAttributesQuery.select(x => x.dontNotifyBeReady)), take(1),
  //       // switchMap((dontNotifyBeReady: boolean) => dontNotifyBeReady ? of(void 0) : this._dialogService.openPrepareForPayment())
  //     ).pipe(
  //       // switchMap(() => this.saveTemplatePanelQuery.select(x => x.saveAsTemplate)), take(1),
  //       // switchMap((saveAsTemplate: boolean) => {
  //       //   if (saveAsTemplate) {
  //       //     return this.templatesListService.createAndSaveTemplate();
  //       //   }
  //       //   return of(void 0);
  //       // }),
  //       switchMap(() => fromPromise(this._router.navigate(['private', 'send', 'secure']))),
  //       take(1),
  //       // tap(() => this._sendStore.setLoading(false)),
  //       switchMap(_ => this.vanishSendForm()),
  //       tap(() => {
  //         this._googleAnalyticsService.event('transfer_attempt', '', '');
  //       }),
  //       mapTo(void 0)
  //     );
  // }

  // apiStartRemittance(startRemittanceBody: IStartRemittanceBody): Observable<IPaymentGatewayInfoDto> {
  //   return of(void 0)
  //     .pipe(
  //       withLatestFrom(this._languagesQ.select(x => x.active)),
  //       // tap(() => this._sendStore.setLoading(true)),
  //       switchMap(([_, lang]) => this._http.post<IPaymentGatewayInfoDto>('remittance/start', startRemittanceBody, {
  //         params: new HttpParams().append('lang', lang as string)
  //       })),
  //       catchError(() => {
  //         const theError = error?.error?.errors[0];
  //         // this._sendStore.setLoading(false);
  //
  //         // if (theError.code === 155) {
  //         //   return this._dialogService.openRateHasChanged()
  //         //     .pipe(
  //         //       switchMap(() => EMPTY)
  //         //     );
  //         // }
  //
  //         if (error.error.errors.length == null) {
  //           return this._storedProcedureErrorService.matchAndManage({ code: 1, message: 'start body parse error' })
  //             .pipe(switchMap(() => EMPTY));
  //         }
  //
  //         return this._storedProcedureErrorService.matchAndManage(theError)
  //           .pipe(switchMap(() => EMPTY));
  //       }),
  //       take(1),
  //     );
  // }

  // public getNewRate(): Observable<IFeesResponse> {
  //   return of(void 0)
  //     .pipe(
  //       // tap(_ => this._calculatorStore.setLoading(true)),
  //       switchMap(() => combineQueries([
  //         this._gatewaysQuery.selectActive(),
  //         this._calculatorQuery.select()
  //       ])),
  //       take(1),
  //       switchMap(([activeGateway, calcQuery]) => {
  //         const isSourceAmountBad: boolean = calcQuery.sourceAmount == null || calcQuery.sourceAmount === 0;
  //         const isDestinationAmountBad: boolean = calcQuery.destinationAmount == null || calcQuery.destinationAmount === 0;
  //         if (activeGateway == null ||
  //           (isSourceAmountBad && isDestinationAmountBad) || calcQuery.amount === 0 ||
  //           (calcQuery.sourceCurrency == null) || (calcQuery.destinationCurrency == null)) {
  //           // this._calculatorStore.setLoading(false);
  //           return EMPTY;
  //         }
  //
  //         const RETRY_COUNT = 2;
  //         return of(void 0)
  //           .pipe(
  //             map((): IFeesBody => ({
  //               directionId: activeGateway.directionId,
  //               sourceCurrencyIso4217: calcQuery.sourceCurrency?.id.toString() ?? '',
  //               destinationCurrencyIso4217: calcQuery.destinationCurrency?.id.toString() ?? '',
  //               amount: calcQuery.amount.toString(10),
  //               amountKey: calcQuery.amountKey.toString(10)
  //             })),
  //             switchMap((body: IFeesBody) => {
  //               // this._googleAnalyticsService.event('view_fees_attempt', '', '');
  //               return this._http.get<IFeesResponse>('reference/fee', { params: { ...body } })
  //                 .pipe(
  //                   retryWhen(err => {
  //                     let retries = 0;
  //                     return err
  //                       .pipe(
  //                         delay(1000),
  //                         map(error => {
  //                           if (retries++ === RETRY_COUNT) {
  //                             throw error;
  //                           }
  //                           return error;
  //                         }),
  //                         catchError(() => {
  //                           // this._googleAnalyticsService.event('view_fees_error_', '', '');
  //                           const theError = error?.error?.errors[0];
  //                           return this._storedProcedureErrorService.matchAndManage(theError)
  //                             .pipe(
  //                               // tap(_ => {
  //                               //
  //                               //   this._calculatorStore.setLoading(false);
  //                               //   this._calculatorStore.update(x => ({
  //                               //     ...x,
  //                               //     calculationResult: EMPTY_CALC_RESULT,
  //                               //     // sourceCurrency: { id: '826', title: 'GBP' },
  //                               //     // destinationCurrency: null,
  //                               //     sourceAmount: null,
  //                               //     destinationAmount: null,
  //                               //     amountKey: 0,
  //                               //     amount: 0,
  //                               //     hasCalculated: false,
  //                               //     isCalculateOnOpen: false,
  //                               //   }));
  //                               // }),
  //                               switchMap(() => EMPTY)
  //                             );
  //                         }),
  //                       );
  //                   }),
  //                 );
  //             }),
  //             withTransaction((response: IFeesResponse) => {
  //               // this._googleAnalyticsService.event('view_fees_success', '', '');
  //               // this.calculatorStore.setHasCalculated(true);
  //               this.newRate$.next(response);
  //
  //               // const totalAmount = response.source.totalAmount.toFixed(2);
  //               // this._sendStore.setMainAmount(totalAmount);
  //               // this._sendStore.setCurrencyIso(response.source.currency.title);
  //
  //               // this.calculatorStore.update(x => ({
  //               //   ...x,
  //               //   sourceAmount: response.source.amount,
  //               //   destinationAmount: response.destination.amount,
  //               //   calculationResult: response
  //               // }));
  //             }),
  //             // tap(_ => this._calculatorStore.setLoading(false)),
  //           );
  //       }),
  //       // mapTo(void 0),
  //     );
  // }


}
