import { Injectable } from '@angular/core';
import { Query, Store, StoreConfig } from '@datorama/akita';
import { Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';

export interface ISecuritySettingsState {
  loading: boolean,
  isEdit: boolean,
  isPasswordSet: boolean,
  isWizardMode: boolean
}

export function createInitialState(): ISecuritySettingsState {
  return {
    loading: false,
    isEdit: false,
    isPasswordSet: false,
    isWizardMode: false
  };
}

@StoreConfig({ name: 'security' })
@Injectable()
export class SecuritySettingsStore extends Store<ISecuritySettingsState> {
  constructor() {
    super(createInitialState());
  }

  setWizard$(state: boolean): Observable<void> {
    return of(void 0)
      .pipe(
        take(1),
        tap(() => {
          this.update(x => ({ ...x, isWizardMode: state }));
        })
      );
  }
}

@Injectable()
export class SecuritySettingsQuery extends Query<ISecuritySettingsState> {

  isPasswordSet$$: Observable<boolean> = this.select(x => x.isPasswordSet);
  isEdit$$: Observable<boolean> = this.select(x => x.isEdit);
  isWizardMode$$: Observable<boolean> = this.select(state => state.isWizardMode);

  constructor(
    protected override store: SecuritySettingsStore
  ) {
    super(store);
  }
}
