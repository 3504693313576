<mat-dialog-content class="mat-dialog-content-max-height-none dialog-content mat-dialog-content-pb-3">
  <div class="row">
    <div class="col-12">
      <p mat-dialog-title>
        {{ '%[send.error.title.email-verification]%' | tr }}
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <app-img-svg class="dialog-content__img" [url]="themeService.getSharedImageUrl('/img/letter.svg')"></app-img-svg>
    </div>
    <p class="dialog-content__text">
      {{ '%[send.error.message.email-verification]%' | tr }}
    </p>
    <p class="dialog-content__redirect">
      {{ '%[send.error.message.email-verification-redirect]%' | tr }} {{ secondsLeft$ | async }}
      {{ '%[send.error.message.email-verification-seconds]%' | tr }}
    </p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="row flex-grow-1">
    <div class="col-sm-3 d-none d-sm-block"></div>
    <div class="col-12 col-sm-6">
      <button mat-flat-button (click)="route$.next()" color="primary">
        {{ '%[send.error.button.email-verification]%' | tr }}
      </button>
      <div class="col-sm-3 d-none d-sm-block"></div>
    </div>
  </div>
</mat-dialog-actions>
