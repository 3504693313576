import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { HistoryCountriesStore } from './history-countries.store';
import { withTransaction } from '@datorama/akita';
import { catchError, mapTo, switchMap } from 'rxjs/operators';
import { IHistoryResponseModel } from './history.models';

@Injectable()
export class HistoryCountriesService {
  constructor(
    private _historyCountriesStore: HistoryCountriesStore,
    private _http: HttpClient
  ) {}

  loadCountries$(): Observable<void> {
    return of(0).pipe(
      withTransaction(() => {
        this._historyCountriesStore.reset();
        this._historyCountriesStore.setLoading(true);
      }),
      switchMap(() => this._http.get<IHistoryResponseModel[]>('history/countries')),
      catchError(() => {
        this._historyCountriesStore.setLoading(false);
        return EMPTY;
      }),
      withTransaction((resp) => {
        this._historyCountriesStore.upsertMany(resp);
        this._historyCountriesStore.setLoading(false);
      }),
      mapTo(void 0)
    );
  }
}
