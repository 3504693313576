import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { HistoryRecipientsStore, IRecipientResultDto } from './history-recipients.store';
import { withTransaction } from '@datorama/akita';
import { catchError, mapTo, switchMap } from 'rxjs/operators';

@Injectable()
export class HistoryRecipientsService {
  constructor(
    private _recipientsStore: HistoryRecipientsStore,
    private _http: HttpClient
  ) {}

  loadRecipients$(): Observable<void> {
    return of(0).pipe(
      withTransaction(() => {
        this._recipientsStore.reset();
        this._recipientsStore.setLoading(true);
      }),
      switchMap(() => this._http.get<IRecipientResultDto[]>('history/recipients')),
      catchError(() => {
        this._recipientsStore.setLoading(false);
        return EMPTY;
      }),
      withTransaction((resp) => {
        this._recipientsStore.upsertMany(resp);
        this._recipientsStore.setLoading(false);
      }),
      mapTo(void 0)
    );
  }
}
