import { NgModule } from '@angular/core';
import { FfDatePipe } from './ff-date.pipe';


@NgModule({
  imports: [],
  exports: [FfDatePipe],
  declarations: [FfDatePipe],
  providers: [],
})
export class FFDateModule {}
