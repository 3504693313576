import { NgModule } from '@angular/core';
import { DoctypesResidenceConfirmationService } from './doctypes-residence-confirmation.service';
import {
  DocTypesResidenceConfirmationQuery,
  DocTypesResidenceConfirmationStore
} from './doctypes-residence-confirmation.store';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    DoctypesResidenceConfirmationService,
    DocTypesResidenceConfirmationStore,
    DocTypesResidenceConfirmationQuery,
  ],
})
export class DocTypesResidenceConfirmationModule {}
