import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { concatMap, map, take } from 'rxjs/operators';
import { PersistenceQuery } from '../../../data-modules/persist/persist.store';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
  constructor(
    private _persistQuery: PersistenceQuery,

  ) {}

  intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {
    return this.addBearerAndHeaders$(req).pipe(
      concatMap((newReq) => next.handle(newReq))
    );
  }

  addBearerAndHeaders$(req: HttpRequest<string>): Observable<HttpRequest<string>> {
    const isNullOrWhiteSpace = (token: string | null): boolean => token == null || token.trim() === '';
    return this._persistQuery.select(x => x.accessToken).pipe(
      take(1),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      map(token => isNullOrWhiteSpace(token) ? req : req.clone({ setHeaders: { Authorization: `Bearer ${token}` } }))
    );
  }
}
