import { NgModule } from '@angular/core';
import { CitizenshipCountriesService } from './citizenship-countries.service';
import { CitizenshipCountriesQuery, CitizenshipCountriesStore } from './citizenship-countries.store';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    CitizenshipCountriesService,
    CitizenshipCountriesStore,
    CitizenshipCountriesQuery,
  ],
})
export class CitizenshipCountriesModule {}
