import { Injectable } from '@angular/core';
import { Query, Store, StoreConfig } from '@datorama/akita';
import { Observable } from 'rxjs';

export interface IDraftState {
  isDraftPasting: boolean,
  isDraftLoading: boolean,
  isMonitoring: boolean
}

export function createInitialState(): IDraftState {
  return {
    isDraftPasting: false,
    isDraftLoading: false,
    isMonitoring: false
  };
}

@Injectable()
@StoreConfig({ name: 'draft' })
export class DraftStore extends Store<IDraftState> {

  constructor() {
    super(createInitialState());
  }

}

@Injectable()
export class DraftQuery extends Query<IDraftState> {
  isDraftPasting$$: Observable<boolean> = this.select(state => state.isDraftPasting);
  isDraftLoading$$: Observable<boolean> = this.select(state => state.isDraftLoading);
  isMonitoring$$: Observable<boolean> = this.select(state => state.isMonitoring);

  constructor(protected override store: DraftStore) {super(store);}
}
