import { NgModule } from '@angular/core';
import { SvgComponent } from './svg.component';
import { SvgService } from './svg.service';
import { CommonModule } from '@angular/common';

@NgModule({
  exports: [SvgComponent],
  declarations: [SvgComponent],
  imports: [
    CommonModule
  ],
  providers: [SvgService]
})
export class SvgModule {}
