import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, EntityUIQuery, EntityUIStore, QueryEntity, StoreConfig } from '@datorama/akita';
import formatISO from 'date-fns/formatISO';
import subDays from 'date-fns/subDays';
import {
  ICurrentSearchParameters,
  IFilterData,
  IGetHistoryResultDto,
  IHistoryItemUI,
  IPeriodDays
} from './history.models';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';

export interface IHistoryState extends EntityState<IGetHistoryResultDto>, ActiveState {
  active: string | null,
  loading: boolean,
  filterData: IFilterData,
  // after "apply" button click all params are copied here
  currentSearchParameters: ICurrentSearchParameters | null,
  filterState: {
    isFilterCollapsed: boolean
  }
}

interface IHistoryItemUIState extends EntityState<IHistoryItemUI> {}

export function createInitialFilterDataState(): { filterData: IFilterData } {
  return {
    filterData: {
      country: null,
      currency: null,
      dateFrom: DateTime.fromISO(formatISO(subDays(new Date(), 30), { representation: 'date' }) + 'T00:00:00'),
      dateTo: DateTime.fromISO(formatISO(new Date(), { representation: 'date' }) + 'T00:00:00'),
      periodDays: 30,
      receiver: null,
      refNumber: '',
    }
  };
}

export function createInitialFilterState(): { filterState: { isFilterCollapsed: boolean } } {
  return { filterState: { isFilterCollapsed: true } };
}

const idKey: keyof IGetHistoryResultDto = 'id';

@StoreConfig({ name: 'history', idKey })
export class HistoryStore extends EntityStore<IHistoryState> {
  override ui: EntityUIStore<IHistoryItemUIState>;
  constructor() {
    super({ loading: false, ...createInitialFilterDataState(), ...createInitialFilterState() });
    const defaults: IHistoryItemUI = { expanded: false };
    this.createUIStore().setInitialEntityState(defaults);
  }
}

@Injectable({ providedIn: 'root' })
export class HistoryQuery extends QueryEntity<IHistoryState> {
  override ui: EntityUIQuery<IHistoryItemUIState>;

  state$$: Observable<IGetHistoryResultDto[]> = this.selectAll();
  isShowMore$$: Observable<boolean> = this.select(x => (x.ids?.length ?? 0) < (x.currentSearchParameters?.totalCount ?? 0) && !x.loading);
  isLoading$$: Observable<boolean> = this.selectLoading();
  isFilterCollapsed$$: Observable<boolean> = this.select(x => x.filterState.isFilterCollapsed);
  filterData$$: Observable<IFilterData> = this.select(x => x.filterData);
  periodDays$$: Observable<IPeriodDays> = this.select(x => x.filterData.periodDays);

  constructor(protected override store: HistoryStore) {
    super(store);
    this.createUIQuery();
  }
}
