import { Injectable } from '@angular/core';

import { EMPTY, Observable, of } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { combineQueries } from '@datorama/akita';
import { IdentityDocumentsQuery, IdentityDocumentsStore } from '../store/identity-documents.store';
import { ProfileQuery, ProfileStore } from '../../../../private-store/profile.store';
import { ProfileService } from '../../../../private-store/profile.service';
import { DialogService } from '../../../../../../functionality-modules/dialog-windows/dialog.service';
import { ICreationCommandBody, IDocumentBody, IDocumentFormData, ISuccessfulSavingResponse } from '../../../../private-models/document.model';
import { IAttachment } from '../../../../private-models/attachment.model';
import { IDocTypeValue } from '../../../../../../data-modules/doctypes-identity/doctypes-identity.model';
import { IServerResponse } from '../../../../private-models/server-response';
import { RequiredDocumentsService } from '../../../../../../data-modules/required-documents/required-documents.service';
import { LocalizationQuery } from '@ff/localization';
import { AdditionalDocumentsService } from '../../additional-documents/additional-documents.service';

@Injectable()
export class IdentityDocumentService {

  constructor(
    private _profileStore: ProfileStore,
    private _profileService: ProfileService,
    private _http: HttpClient,
    private _identityDocumentsStore: IdentityDocumentsStore,
    private _identityDocumentsQuery: IdentityDocumentsQuery,
    private _profileQuery: ProfileQuery,
    private _localizationQ: LocalizationQuery,
    private _dialogService: DialogService,
    private _requiredDocumentsService: RequiredDocumentsService,
    private _additionalDocumentsService: AdditionalDocumentsService
  ) { }

  saveFormData(idDocFormId: string): Observable<void> {
    return of(void 0)
      .pipe(
        switchMap(() => this._identityDocumentsQuery.selectEntity(idDocFormId)), take(1),
        switchMap(doc => doc == null ? EMPTY : of(doc)),
        map((doc: IDocumentBody): IDocumentFormData => doc.document),
        tap(() => this._profileStore.setLoading(true)),
        switchMap((documentFormData: IDocumentFormData) => this._http.put<ISuccessfulSavingResponse>(`profile/document/${documentFormData.id}`, documentFormData)
          .pipe(
            catchError(() => {
              this._profileStore.setLoading(false);
              return EMPTY;
            }),
          )),
        tap(() => this._identityDocumentsStore.reset())
      ).pipe(
        tap(() => this._profileStore.setLoading(false)),
        switchMap(() => this._additionalDocumentsService.wizardAfterEffects$(false)
        ),
      );
  }

  // saveFormAfterEffects(savedIdDoc: string): Observable<void> {
  //   return of(void 0)
  //     .pipe(
  //       switchMap(() => this._requiredDocumentsService.updateRequiredDocuments$()),
  //       switchMap(_ => combineQueries([
  //         this._profileQuery.select(x => x.isWizardMode),
  //         this._requiredDocumentsService.isIdentityFlagIsState$$(['NoAttach'], true)
  //       ])),
  //       take(1),
  //       switchMap(([
  //                    isWizard,
  //                    isIdentityDocumentVerificationRequired
  //                  ]) => {
  //
  //         if (isWizard && !isIdentityDocumentVerificationRequired) {
  //           return of(void 0)
  //             .pipe(
  //               tap(() => this.sendStore.setLoading(true)),
  //               switchMap(() => fromPromise(this.router.navigate(['private', 'send']))),
  //               switchMap(() => this.sendQuery.select(x => x.isSendPageFullyLoaded)),
  //               filter(e => e === true), take(1),
  //               // delay(1000),
  //               switchMap(() => this.sendService.scrollToFirstEmptyField()),
  //               tap(() => this.sendStore.setLoading(false)),
  //             );
  //         }
  //
  //         return of(void 0);
  //
  //       }),
  //       mapTo(void 0)
  //     );
  // }

  deleteScans(attachments: IAttachment[], docId: string): Observable<void> {
    const ids: string[] = attachments.map(a => a.id);
    return of(void 0)
      .pipe(
        map((): ICreationCommandBody => {

          return {
            documentId: docId,
            create: [],
            delete: ids,
          };
        }),
        switchMap((body: ICreationCommandBody) => {
          this._profileStore.setLoading(true);
          return this._http.post<IServerResponse<void>>('profile/document/scans/', body)
            .pipe(
              catchError(() => {
                this._profileStore.setLoading(false);
                return EMPTY;
              })
            );
        }),
        tap(_ => {
          this._profileStore.setLoading(false);
          // this._identityDocumentsStore.upsert(docId, (x: IDocumentBody) => ({ ...x, scans: [...x.scans.filter(s => !ids.includes(s.id))] }));
        }),
        mapTo(void 0)
      );
  }

  setEdit(newState: boolean, docId: string): Observable<void> {
    return of(void 0)
      .pipe(
        tap(() => {
          // @ts-ignore todo: fix to the right typings
          this._identityDocumentsStore.ui.upsert(docId, (x: IDocumentBody) => ({ ...x, isEdit: newState }));
        }));
  }

  saveScanAfterEffect(): Observable<void> {
    return of(void 0)
      .pipe(
        switchMap(_ => combineQueries([
          this._profileQuery.isWizardMode$$,
          this._requiredDocumentsService.isIdentityVerificationFlagInState$(['NoAttach'], true)
          // this._profileFlagsService.isHasCustomerSelfie$$,
          // this._profileFlagsService.isCustomerToReplaceSelfie$$,
        ])),
        take(1),
        switchMap(([
                     isWizard,
                     isIdentityDocumentVerificationRequired,
                     // isHasSelfie,
                     // isBadSeflie,
                   ]) => {

          if (isWizard === true && isIdentityDocumentVerificationRequired) {

            // if (!isHasSelfie) {
            //   return of(void 0)
            //     .pipe(
            //       switchMap(() => this._profileStore.setActiveTab(2)),
            //       switchMap(() => this._snackbarService.openWarning(
            //         this.localizationQ.transform('%[flags.snackbar.message.selfie-empty]%').replace('{{limit}}', limit), {
            //           title: this.localizationQ.transform('%[flags.snackbar.title.selfie-empty]%'),
            //           environment.snackbar.shortDuration
            //         })),
            //     );
            // }

            // if (isBadSeflie) {
            //   return of(void 0)
            //     .pipe(
            //       switchMap(() => this._profileStore.setActiveTab(2)),
            //       switchMap(() => this._snackbarService.openWarning(
            //         this._localizationQ.transform('%[flags.snackbar.message.selfie-unreadable]%'), {
            //           title: this._localizationQ.transform('%[flags.snackbar.title.selfie-unreadable]%'),
            //           environment.snackbar.shortDuration
            //         })),
            //     );
            // }
          }

          return of(void 0);

        }),
      );
  }

  upsertIdentityDoc$<T>(docId: string, value: T, getDataForStore: (value: T) => Partial<IDocumentFormData>): Observable<void> {
    return of(void 0)
      .pipe(
        tap(() => {
          // @ts-ignore todo: Fix typings
          this._identityDocumentsStore.upsert(docId, (x: IDocumentBody): IDocumentBody => {
              return { ...x, document: { ...x.document, ...getDataForStore(value) } };
            }
          );
        }));
  }

  upsertTypeDocumentField$(docId: string, newValue: IDocTypeValue): Observable<void> {
    return this._identityDocumentsQuery.selectEntity(docId)
      .pipe(
        take(1),
        switchMap(doc => doc == null ? EMPTY : of(doc)),
        filter(doc => doc.document.typeDocument?.id !== newValue.id),
        map((doc: IDocumentBody) => doc.scans),
        switchMap((scans: IAttachment[]) => {
          if (scans.length > 0) {
            const yes = this._localizationQ.transform('%[profile.identity-documents.change-type-yes]%');
            const no = this._localizationQ.transform('%[profile.identity-documents.change-type-no]%');
            return this._dialogService.openConfirmDialog$(this._localizationQ.transform('%[profile.identity-documents.dialog.change-type]%'), yes, no)
              .pipe(
                switchMap((result: boolean) => result ? of(void 0) : EMPTY),
                switchMap(() => this.deleteScans(scans, docId))
              );
          }
          return of(void 0);
        }),
        switchMap(() => this._identityDocumentsQuery.selectEntity(docId)), take(1),
      ).pipe(
        switchMap(doc => doc == null ? EMPTY : of(doc)),
        map((doc: IDocumentBody): IDocumentFormData => ({ ...doc.document, typeDocument: newValue })),
        switchMap((documentFormData: IDocumentFormData) => this._http.put<ISuccessfulSavingResponse>(`profile/document/${docId}`, documentFormData)),
        switchMap(() => this._profileService.updateProfile$())
      );
  }

}
