import { EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ICountries2State, idKey } from './id-issued-countries.model';

@Injectable()
@StoreConfig({ name: 'id-issued-countries', idKey })
export class IdIssuedCountriesStore extends EntityStore<ICountries2State> {
  constructor() {
    super();
  }
}

@Injectable()
export class IdIssuedCountriesQuery extends QueryEntity<ICountries2State> {
  constructor(protected override store: IdIssuedCountriesStore) {
    super(store);
  }
}
