import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThemeService } from '../../../../data-modules/theme/theme.service';
import { map } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-wizard-awaiting',
  templateUrl: './wizard-awaiting.component.html',
  styleUrls: ['./wizard-awaiting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WizardAwaitingComponent {
  awaitingImageUrl$: Observable<string> = this._themeService.activeThemeMode$$.pipe(
    map(() => this._themeService.getSharedImageUrlWithDarkMode('/img/hourglass.svg')),
  );

  constructor(
    private _dialogRef: MatDialogRef<WizardAwaitingComponent>,
    private _themeService: ThemeService,
    @Inject(MAT_DIALOG_DATA) public data: { message: string }
  ) {}

  onCloseClick(): void {
    this._dialogRef.close();
  }

}
