import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ILookupInt } from '../../../private-models/lookup-str';
import { map } from 'rxjs/operators';

export interface IRecipientResultDto {
  id: number,
  title: string,
  fullName: string
}

export interface IHistoryRecipientsState extends EntityState<IRecipientResultDto> {
  loading: boolean
}

const idKey: keyof IRecipientResultDto = 'id';

@StoreConfig({ name: 'history-recipients', idKey })
@Injectable()
export class HistoryRecipientsStore extends EntityStore<IHistoryRecipientsState> {
  constructor() {
    super({ loading: false });
  }
}

@Injectable()
export class HistoryRecipientsQuery extends QueryEntity<IHistoryRecipientsState> {

  state$$: Observable<IRecipientResultDto[]> = this.selectAll();
  recipients$$: Observable<ILookupInt[]> = this.state$$.pipe(
    map(item => item.map(recipient => {
        return {
          id: recipient.id,
          title: recipient.fullName
        };
      })
    )
  );

  constructor(protected override store: HistoryRecipientsStore) {
    super(store);
  }
}
