import { NgModule } from '@angular/core';
import { CurrenciesSourceService } from './currencies-source.service';
import { CurrenciesSourceQuery, CurrenciesSourceStore } from './currencies-source.store';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    CurrenciesSourceService,
    CurrenciesSourceStore,
    CurrenciesSourceQuery
  ],
})
export class CurrenciesSourceModule {}
