import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LogoutService } from '../../../data-modules/logout.service';

@Component({
  selector: 'app-logout-dialog',
  styleUrls: ['logout-dialog.component.scss'],
  templateUrl: 'logout-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LogoutDialogComponent {
  constructor(
    private _logoutService: LogoutService,
    @Inject(MAT_DIALOG_DATA)
    public data: { },
    public dialogRef: MatDialogRef<LogoutDialogComponent>,
  ) { }

  onLogOutClick(event: MouseEvent): void {
    event.preventDefault();
    this._logoutService.voluntaryLogout();
  }
}
