import { EntityState, EntityStore, EntityUIQuery, EntityUIStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';
import {
  IExtraDocument,
  IExtraDocumentBodyUI,
  IExtraDocumentRequestStatus
} from '../../../../private-models/extra-document.model';

export interface IExtraDocumentsState extends EntityState<IExtraDocument> {
  customerExtraDocumentRequestId: number,
  addedDate: string,
  modifyDate: string,
  stamp: string,
  memberId: number,
  note: string,
  requestStatus: IExtraDocumentRequestStatus
}

export interface IEDUIState extends EntityState<IExtraDocumentBodyUI, number> {
  newDocFormIsOpen: boolean
}

const idKey: keyof IExtraDocument = 'customerExtraDocumentId';

@StoreConfig({ name: 'extra-documents', idKey })
export class ExtraDocumentsStore extends EntityStore<IExtraDocumentsState> {
  override ui: EntityUIStore<IEDUIState>;

  constructor() {
    super();
    this.createUIStore();
  }
}

@Injectable()
export class ExtraDocumentsQuery extends QueryEntity<IExtraDocumentsState> {
  override ui: EntityUIQuery<IEDUIState>;
  state$$: Observable<IExtraDocument[]> = this.selectAll();
  stateUI$$: Observable<IExtraDocumentBodyUI[]> = defer(() => this.ui.selectAll());
  isLoading$$: Observable<boolean> = this.selectLoading();
  requestNote$$: Observable<string> = this.select(state => state.note);

  constructor(protected override store: ExtraDocumentsStore) {
    super(store);
    this.createUIQuery();
  }
}
