import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LocalizationQuery } from '@ff/localization';
import { SnackbarTimerComponent } from './snackbar-timer/snackbar-timer.component';
import { environment } from '../../../environments/environment';
import { SnackbarDarkmodeInfoComponent } from './snackbar-darkmode-info/snackbar-darkmode-info.component';

interface ISnackbarArgs {
  message: string,
  title?: string,
  // icon?: string,
  duration?: number
}

@Injectable()
export class SnackbarService {

  public snackbarReferences: MatSnackBarRef<SnackbarComponent>[] = [];
  public snackbarReferencesAsync: MatSnackBarRef<SnackbarTimerComponent>[] = [];

  constructor(
    private _snackbar: MatSnackBar,
    private _localizationQ: LocalizationQuery
  ) { }

  openSuccess(message: string, options?: { title?: string, icon?: string, duration?: number }): Observable<void> {
    const title: string = options?.title ?? this._localizationQ.transform('%[snackbar.default-title.success]%');
    const icon: string = options?.icon ?? '';
    const duration: number = options?.duration ?? environment.appSettings.snackbar.duration;

    const snackbarRef: MatSnackBarRef<SnackbarComponent> =
      this._snackbar.openFromComponent(SnackbarComponent, {
        data: { message, title, icon },
        panelClass: 'snackbar-success',
        duration
      });

    this.snackbarReferences.push(snackbarRef);
    return snackbarRef.afterOpened();
  }

  openWarning(message: string, options?: { title?: string, duration?: number }): Observable<void> {
    const title: string = options?.title ?? this._localizationQ.transform('%[snackbar.default-title.warning]%');
    const duration: number = options?.duration ?? environment.appSettings.snackbar.duration;

    const snackbarRef: MatSnackBarRef<SnackbarComponent> =
      this._snackbar.openFromComponent(SnackbarComponent, {
        data: { message, title },
        panelClass: 'snackbar-warning',
        duration
      });

    this.snackbarReferences.push(snackbarRef);
    return snackbarRef.afterOpened();
  }

  openInfo(message: string, options?: { title?: string, duration?: number }): Observable<void> {
    const title: string = options?.title ?? this._localizationQ.transform('%[snackbar.default-title.info]%');
    const duration: number = options?.duration ?? environment.appSettings.snackbar.duration;

    const snackbarRef: MatSnackBarRef<SnackbarComponent> =
      this._snackbar.openFromComponent(SnackbarComponent, {
        data: { message, title },
        panelClass: 'snackbar-info',
        duration
      });

    this.snackbarReferences.push(snackbarRef);
    return snackbarRef.afterOpened();
  }

  openInfoTimer(options?: { title?: string, duration?: number }): Observable<void> {
    const title: string = options?.title ?? this._localizationQ.transform('%[version-check-service.message-new-version-is-available]%');
    const duration: number = options?.duration ?? environment.appSettings.snackbar.duration;

    const snackbarRef: MatSnackBarRef<SnackbarTimerComponent> =
      this._snackbar.openFromComponent(SnackbarTimerComponent, {
        data: { countdownTimer: 10, title },
        panelClass: 'snackbar-info',
        duration
      });

    this.snackbarReferencesAsync.push(snackbarRef);
    return snackbarRef.afterOpened();
  }

  openInfoV2(args: ISnackbarArgs): Observable<void> {
    const title: string = args.title ?? this._localizationQ.transform('%[snackbar.default-title.info]%');
    const duration: number = args.duration ?? environment.appSettings.snackbar.duration;
    const message: string = args.message;

    const snackbarRef: MatSnackBarRef<SnackbarComponent> =
      this._snackbar.openFromComponent(SnackbarComponent, {
        data: { message, title },
        panelClass: 'snackbar-info',
        duration
      });

    this.snackbarReferences.push(snackbarRef);
    return snackbarRef.afterOpened();
  }

  openFailure$(message: string, options?: { title?: string, duration?: number }): Observable<void> {
    const title: string = options?.title ?? this._localizationQ.transform('%[snackbar.default-title.failure]%');
    const duration: number = options?.duration ?? environment.appSettings.snackbar.duration;
    return this._snackbar.openFromComponent(SnackbarComponent, {
      data: { message, title },
      panelClass: 'snackbar-danger',
      duration
    }).afterOpened();
  }

  openApiError$(err: Error): Observable<void> {
    return of(void 0)
      .pipe(
        switchMap(() => this._snackbar.openFromComponent(SnackbarComponent,
          {
            data: { err: err.message, title: 'Api Error' },
            panelClass: 'snackbar-danger',
            duration: environment.appSettings.snackbar.shortDuration
          }).afterOpened()),
        switchMap(() => throwError(err)),
      );
  }

  openDarkModeInfo(message: string, options?: { title?: string, duration?: number, buttonLabel?: string }): Observable<void> {
    const title: string = options?.title ?? this._localizationQ.transform('%[snackbar.default-title.info]%');
    const duration: number = options?.duration ?? environment.appSettings.snackbar.duration;
    const buttonLabel: string = options?.buttonLabel ?? this._localizationQ.transform('%[snackbar.default-button-label.info]%');


    const snackbarRef: MatSnackBarRef<SnackbarDarkmodeInfoComponent> =
      this._snackbar.openFromComponent(SnackbarDarkmodeInfoComponent, {
        data: { message, title, buttonLabel },
        panelClass: 'snackbar-info',
        duration
      });

    this.snackbarReferences.push(snackbarRef);
    return snackbarRef.onAction();
  }

  dismissAll(): void {
    this.snackbarReferences.forEach(s => s.dismiss());
  }

}
