import { EntityState, EntityStore, withTransaction } from '@datorama/akita';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mapTo, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export class BaseService {
  constructor(
    private _baseHttp: HttpClient
  ) {}

  protected loadGETCollectionToStore<T, K>(url: string, store: EntityStore<EntityState<K>>, resultsFn: (arg: T) => K[],
                                           urlParams: {[key: string]: string | string[]} | null = null): Observable<void> {
    return of(0).pipe(
      withTransaction(() => {
        store.reset();
        store.setLoading(true);
      }),
      switchMap(() => urlParams == null ? this._baseHttp.get<T>(url) : this._baseHttp.get<T>(url, { params: urlParams })),
      withTransaction((resp) => {
        store.upsertMany(resultsFn(resp));
        store.setLoading(false);
      }),
      catchError(err => {
        store.setLoading(false);
        return throwError(err);
      }),
      mapTo(void 0)
    );
  }
}
