import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IMyReceiver } from './my-receivers.models';
import { map } from 'rxjs/operators';

export interface IMyReceiversState extends EntityState<IMyReceiver, string> {
  filter: string
}

@Injectable()
@StoreConfig({ name: 'my-receivers', idKey: 'id' })
export class MyReceiversStore extends EntityStore<IMyReceiversState> {
  constructor() {
    super({ filter: 'ALL' });
  }
}

@Injectable()
export class MyReceiversQuery extends QueryEntity<IMyReceiversState> {

  myReceivers$$: Observable<IMyReceiver[]> = this.selectAll();

  hasReceivers$$: Observable<boolean> = this.selectAll().pipe(map((receivers: IMyReceiver[]) => receivers.length > 0));

  constructor(protected override store: MyReceiversStore) {
    super(store);
  }
}
