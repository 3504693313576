import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  styleUrls: ['./confirm-dialog.component.scss'],
  templateUrl: 'confirm-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ConfirmDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { confirmText: string, yes: string, no: string },
    private _dialogRef: MatDialogRef<ConfirmDialogComponent>) {
  }

  onNoClick(): void {
    this._dialogRef.close(false);
  }
}
