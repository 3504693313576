import { NgModule } from '@angular/core';
import { DirectionCitiesService } from './direction-cities.service';
import { DirectionCitiesQuery, DirectionCitiesStore } from './direction-cities.store';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    DirectionCitiesService,
    DirectionCitiesStore,
    DirectionCitiesQuery,
  ],
})
export class DirectionCitiesModule {}
