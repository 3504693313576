import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, mapTo, switchMap, tap } from 'rxjs/operators';
import { CurrenciesSourceStore } from './currencies-source.store';
import { ICurrenciesSourceResponseModel } from './currencies-source.model';

@Injectable()
export class CurrenciesSourceService {

  constructor(
    private _http: HttpClient,
    private _currencyStore: CurrenciesSourceStore
  ) { }

  getCurrenciesSource(): Observable<void> {
    return of(void 0).pipe(
      tap(() => this._currencyStore.setLoading(true)),
      switchMap(() => this._http.get<ICurrenciesSourceResponseModel[]>('reference/currencies-source')),
      catchError(() => {
        this._currencyStore.setLoading(false);
        return EMPTY;
      }),
      tap(currencies => {
        this._currencyStore.setLoading(false);
        this._currencyStore.upsertMany(currencies);
      }),
      mapTo(void 0)
    );
  }

}
