import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ILookupStr } from '../../../private-models/lookup-str';
import { Observable } from 'rxjs';

export interface IHistoryCurrenciesState extends EntityState<ILookupStr> {
  loading: boolean
}

const idKey: keyof ILookupStr = 'id';

@StoreConfig({ name: 'history-currencies', idKey })
@Injectable()
export class HistoryCurrenciesStore extends EntityStore<IHistoryCurrenciesState> {
  constructor() {
    super({ loading: false });
  }
}

@Injectable()
export class HistoryCurrenciesQuery extends QueryEntity<IHistoryCurrenciesState> {

  state$$: Observable<ILookupStr[]> = this.selectAll();
  constructor(protected override store: HistoryCurrenciesStore) {
    super(store);
  }
}
