import { Injectable } from '@angular/core';
import { Query, Store, StoreConfig } from '@datorama/akita';
import { Observable } from 'rxjs';
import { EMPTY_CALC_RESULT, IFeesResponse } from './calculator.models';
import { ILookupInt } from '../../root/private/private-models/lookup-str';
import { IRemittanceCountry } from '../remittance-countries/remittance-countries.model';

export type AmountKeyType = 0 | 1;

export interface ICalculatorState {
  previousCalculationResult: IFeesResponse,
  calculationResult: IFeesResponse,
  sourceCurrency: ILookupInt | null,
  destinationCurrency: ILookupInt | null,
  destinationCountry: IRemittanceCountry | null,
  sourceAmount: number | null,
  destinationAmount: number | null,
  amountKey: AmountKeyType,
  amount: number,
  isShowSummary: boolean,
  isCalculateOnOpen: boolean,

  // loadings
  isFeeLoading: boolean
}

export function createInitialState(): ICalculatorState {
  return {
    previousCalculationResult: EMPTY_CALC_RESULT,
    calculationResult: EMPTY_CALC_RESULT,
    destinationCountry: null,
    sourceCurrency: null,
    destinationCurrency: null,
    sourceAmount: null,
    destinationAmount: null,
    amountKey: 0,
    amount: 0,
    isShowSummary: false,
    isCalculateOnOpen: false,

    // loadings
    isFeeLoading: false
  };
}

@Injectable()
@StoreConfig({ name: 'calculator' })
export class CalculatorStore extends Store<ICalculatorState> {
  constructor() {
    super(createInitialState());
  }

  override setLoading(loading?: boolean): void {
    throw new Error('setLoading cannot be used in this store');
  }

}

@Injectable()
export class CalculatorQuery extends Query<ICalculatorState> {
  isFeeLoading$$: Observable<boolean> = this.select(x => x.isFeeLoading);
  calculationResult$$: Observable<IFeesResponse> = this.select(x => x.calculationResult);
  destinationCountry$$: Observable<IRemittanceCountry | null> = this.select(x => x.destinationCountry);
  amountKey$$: Observable<AmountKeyType> = this.select(x => x.amountKey);
  isShowSummary$$: Observable<boolean> = this.select(x => x.isShowSummary);
  isCalculateOnOpen$$: Observable<boolean> = this.select(x => x.isCalculateOnOpen);

  sourceCurrency$$: Observable<ILookupInt | null> = this.select(x => x.sourceCurrency);
  destinationCurrency$$: Observable<ILookupInt | null> = this.select(x => x.destinationCurrency);
  sourceAmount$$: Observable<number | null> = this.select(x => x.sourceAmount);
  destinationAmount$$: Observable<number | null> = this.select(x => x.destinationAmount);
  commission$$: Observable<number> = this.select(x => x.calculationResult.source.commission);
  exchangeRate$$: Observable<number> = this.select(x => x.calculationResult.destination.rate);

  constructor(protected override store: CalculatorStore) {
    super(store);
  }

  override selectLoading(): Observable<boolean> {
    return this.isFeeLoading$$;
  }
}
