import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { mapTo, switchMap, take } from 'rxjs/operators';
import { BankAccountTypesStore } from './bank-account-types.store';
import { withTransaction } from '@datorama/akita';
import { IBankAccountTypesResponseModel } from './bank-account-types.models';

@Injectable()
export class BankAccountTypesService {

  constructor(
    private _http: HttpClient,
    private _bankAccountTypesStore: BankAccountTypesStore
  ) { }

  getBankAccountTypes$(directionId: string): Observable<void> {
    return of(void 0)
      .pipe(
        withTransaction(_ => {
          this._bankAccountTypesStore.remove();
          this._bankAccountTypesStore.setLoading(true);
        }),
        switchMap(() => this._http.get<IBankAccountTypesResponseModel[]>('reference/bank-account-types', { params: { directionId } })),
        take(1),
        withTransaction((bankAccountTypes: IBankAccountTypesResponseModel[]) => {
          this._bankAccountTypesStore.setLoading(false);
          this._bankAccountTypesStore.upsertMany(bankAccountTypes);
        }),
        mapTo(void 0)
      );
  }
}
