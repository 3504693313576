<mat-dialog-content class="mat-dialog-content-max-height-none dialog-content mat-dialog-content-pb-3">
  <div class="row">
    <div class="col-12">
      <p mat-dialog-title>
        {{ '%[send.error.title.prove-your-identity]%' | tr }}
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <img class="dialog-content__img" [src]="themeService.getSharedImageUrl('/img/proof-your-identity.svg')" alt="" />
    </div>
    <p class="dialog-content__text">
      {{ '%[send.error.message.prove-your-identity]%' | tr }}
    </p>
    <p class="dialog-content__redirect">
      {{ '%[send.error.message.prove-your-identity-redirect]%' | tr }} {{ secondsLeft$ | async }}
      {{ '%[send.error.message.prove-your-identity-seconds]%' | tr }}
    </p>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="dialog-actions">
  <button mat-flat-button (click)="onProvideDocumentClick()" color="primary">
    {{ '%[send.error.button.prove-your-identity]%' | tr }}
  </button>
</mat-dialog-actions>
