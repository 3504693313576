<mat-button-toggle-group [multiple]="multiple" [vertical]="vertical">
  <mat-button-toggle
    *ngFor="let item of items"
    [value]="item.id"
    [disabled]="disabled"
    [disableRipple]="disableRipple"
    [checked]="item?.id === activeDirectionId"
    (click)="onClick(item?.id)"
    >{{ item.title }}</mat-button-toggle
  >
</mat-button-toggle-group>
