import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as Castle from '@castleio/castle-js';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { EMPTY } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { catchError, delay, map, mapTo, skip, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { CitizenshipCountriesQuery } from './data-modules/citizenship-countries/citizenship-countries.store';
import { DeviceInfoService } from './data-modules/device-info/device-info.service';
import { IIdIssuedCountriesModel } from './data-modules/id-issued-countries/id-issued-countries.model';
import { PersistenceQuery } from './data-modules/persist/persist.store';
import { ResidenceCountriesQuery } from './data-modules/residence-countries/residence-countries.store';
import { UserSessionsSignalRService } from './data-modules/user-sessions-signalr.service';
import { UtmMarksService } from './data-modules/utm-marks/utm-marks.service';
import { VersionCheckService } from './data-modules/version-check.service';
import { VersionService } from './data-modules/version/version.service';
import { SnackbarService } from './functionality-modules/snackbars/snackbar.service';
import { PersonalDataFormService } from './root/private/private-pages/profile/personal-data/personal-data-form.service';
import { ProfileStore } from './root/private/private-store/profile.store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { uuidv4 } from './utils/functions/uuid';
import { DateTime } from 'luxon';
import { AssetsLocalizationService, LanguagesQuery } from '@ff/localization';
import { RequiredDocumentsService } from './data-modules/required-documents/required-documents.service';
import { PersistenceService } from './data-modules/persist/persistence.service';
import { IRequiredDocumentsResponse } from './data-modules/required-documents/required-documents.model';

// eslint-disable-next-line @typescript-eslint/naming-convention
const PDAndIDAndKYCRequired: Partial<IRequiredDocumentsResponse> = {
  personalData: 'Required',
  identityDocument: 'Required',
  kycQuestionnaire: 'RequiredBasic'
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const IDVRequired: Partial<IRequiredDocumentsResponse> = {
  identityDocumentVerification: 'Required'
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const POARequired: Partial<IRequiredDocumentsResponse> = {
  proofOfAddress: 'Required'
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const SOIRequired: Partial<IRequiredDocumentsResponse> = {
  sourceOfIncome: 'Required'
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const EDRequired: Partial<IRequiredDocumentsResponse> = {
  extraDocuments: 'Required'
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const EmailRequired: Partial<IRequiredDocumentsResponse> = {
  emailValidatePossession: 'Required'
};

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: 'online-app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnlineAppComponent implements OnInit {

  constructor(
    private _gtmService: GoogleTagManagerService,
    private _utmMarksService: UtmMarksService,
    private _versionCheckService: VersionCheckService,
    private _snackbarService: SnackbarService,
    private _persistQuery: PersistenceQuery,
    private _userSessionsSignalRService: UserSessionsSignalRService,
    private _router: Router,
    private _versionService: VersionService,
    private _deviceInfoService: DeviceInfoService,
    private _profileStore: ProfileStore,
    private _personalDataFormService: PersonalDataFormService,
    private _persistService: PersistenceService,
    private _residenceCountriesQuery: ResidenceCountriesQuery,
    private _citizenshipCountriesQuery: CitizenshipCountriesQuery,
    private _languagesQuery: LanguagesQuery,
    private _locService: AssetsLocalizationService,
    private _requiredDocumentsService: RequiredDocumentsService
  ) {}

  ngOnInit(): void {
    // try get utm marks from url
    const url = new URL(window.location.href);
    const utmSource = url.searchParams.get('utm_source');
    const utmMedium = url.searchParams.get('utm_medium');
    const utmCampaign = url.searchParams.get('utm_campaign');
    const utmTerm = url.searchParams.get('utm_term');
    const utmContent = url.searchParams.get('utm_content');
    this._utmMarksService.updateUtmMarks({
      utmSource,
      utmCampaign,
      utmMedium,
      utmContent,
      utmTerm,
    });

    /* subscribe logic on version change */
    this._versionCheckService.newVersionAvailable$$.pipe(
      take(1),
      switchMap(() => this._snackbarService.openInfoTimer()),
      delay(10 * 1000),
      tap(() => location.reload()),
      untilDestroyed(this)
    ).subscribe();

    /** load application-wide data */

    this._versionService.loadVersions().pipe(
      catchError(() => {
        return EMPTY;
      }),
      untilDestroyed(this)
    ).subscribe();

    this._deviceInfoService.getDeviceInfo$().subscribe();

    this._router.events.pipe(
      untilDestroyed(this)
    ).subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this._snackbarService.dismissAll();
      window.scrollTo(0, 0);
    });

    this._persistQuery.accessToken$$.pipe(
      switchMap((t) => {
        if (t == null || t === '') {
          this._userSessionsSignalRService.stopConnection();
          return EMPTY;
        }
        return this._userSessionsSignalRService.startConnection$$(t);
      })
    ).subscribe();

    this._loadClarityScript();
    this._loadGoogleCaptchaScript();
    this._loadGoogleTagManager();
    this._subscribeLanguageChange();
    this._subscribeOnPersistSelectedLanguage();

    if (!environment.production) {
      // @ts-ignore
      window.setWizardState = (state: boolean) => {
        this._profileStore.setWizard$(state).subscribe();
      };
      // @ts-ignore
      window.wizardChecksEnabled = (state: boolean) => {
        this._persistService.updatePersistenceStore({ isWizardChecksEnabled: state });
      };
      // @ts-ignore
      window.fillPd = () => {
        const randomString = uuidv4()
          .split('')
          .filter((e) => !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-'].includes(e))
          .join('');
        this._personalDataFormService.setField$$('name', 'mockName ' + randomString.slice(0, 5)).subscribe();
        this._personalDataFormService.setField$$('surname', 'mockSurname ' + randomString.slice(0, 5)).subscribe();
        this._personalDataFormService.setField$$('middleName', 'mockMiddlename ' + randomString.slice(0, 5)).subscribe();
        this._personalDataFormService.setField$$('dateOfBirth', DateTime.now().minus({ year: 20 }).toISODate()).subscribe();
        this._personalDataFormService.setField$$('sex', '1').subscribe();
        this._personalDataFormService.setField$$('placeOfBirth', 'mock_place_of_birth').subscribe();
        this._personalDataFormService.setField$$('isReceivePaymentInfoByEmail', true).subscribe();

        this._citizenshipCountriesQuery.state$$.pipe(
          map((css: IIdIssuedCountriesModel[]) => css.find((cs) => cs.name.includes('Kingdom')) ?? css[0]),
          take(1),
          switchMap((cs: IIdIssuedCountriesModel | undefined) => cs != null ? this._personalDataFormService.setDraftPartial$({ nationality: cs }) : EMPTY),
          untilDestroyed(this)
        ).subscribe();

        this._residenceCountriesQuery
          .state$$
          .pipe(
            map((rcs: IIdIssuedCountriesModel[]) => rcs.find((rs) => rs.name.includes('United')) ?? rcs[0]),
            take(1),
            switchMap((rc: IIdIssuedCountriesModel | undefined) => rc != null ? this._personalDataFormService.setDraftPartial$({ country: rc }) : EMPTY),
            untilDestroyed(this)
          )
          .subscribe();

        this._personalDataFormService.setField$$('city', 'mock_city').subscribe();
        this._personalDataFormService.setField$$('street', 'mock_street').subscribe();
        this._personalDataFormService.setField$$('building', 'mock_building').subscribe();
        this._personalDataFormService.setField$$('apartment', 'mock_apartment').subscribe();
        this._personalDataFormService.setField$$('postcode', 'mock_postcode').subscribe();
      };

      // Mock data and functions for required documents flags
      // @ts-ignore
      window.setIsMockDataUsed = (isMockDataUsed: boolean) => {
        this._requiredDocumentsService.setIsMockDataUsed(isMockDataUsed);
      };
      // @ts-ignore
      window.updateRequiredDocumentsFlags = (state: Partial<IRequiredDocumentsResponse>) => {
        this._requiredDocumentsService.updateRequiredDocumentsWithMockData(state);
      };
      // @ts-ignore
      window.PDAndIDAndKYCRequired = PDAndIDAndKYCRequired;
      // @ts-ignore
      window.IDVRequired = IDVRequired;
      // @ts-ignore
      window.POARequired = POARequired;
      // @ts-ignore
      window.SOIRequired = SOIRequired;
      // @ts-ignore
      window.EDRequired = EDRequired;
      // @ts-ignore
      window.EmailRequired = EmailRequired;
    }
    Castle.configure({ pk: environment.thirdPartyServices.castlePublicKey });
    this._persistService.updatePersistenceStore({ isCheckoutJSLoaded: false });
  }

  // noinspection JSMethodCanBeStatic
  private _loadGoogleCaptchaScript(): void {
    if (environment.google.captcha.gcaptchaEnabled === false) {
      return;
    } // disable script loading if no captcha enabled
    const node = document.createElement('script');
    node.type = 'text/javascript';
    node.async = true;
    node.src = 'https://www.google.com/recaptcha/api.js?render=' + environment.google.captcha.gcaptchaSitekey;
    document.getElementsByTagName('head')[0]?.appendChild(node);
  }

  // noinspection JSMethodCanBeStatic
  private _loadClarityScript(): void {
    if (environment.production) {
      return;
    }
    // Using only for test now
    // const node = document.createElement('script'); // creates the script tag
    // node.type = 'text/javascript'; // set the script type
    // node.async = true; // makes script run asynchronously
    // node.innerText =
    //   '(function(c,l,a,r,i,t,y){\n' +
    //   '        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};\n' +
    //   '        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;\n' +
    //   '        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);\n' +
    //   '    })(window, document, "clarity", "script", "417be6r80o");';
    // // append to head of document
    // document.getElementsByTagName('head')[0]?.appendChild(node);
  }

  private _loadGoogleTagManager(): void {
    if (environment.google.googleTagManagerId == null || environment.google.googleTagManagerId.trim() === '') {
      return;
    }

    fromPromise(this._gtmService.addGtmToDom())
      .pipe(
        take(1),
        catchError((err) => {
          console.warn(err);
          return EMPTY;
        })
      )
      .subscribe((result) => {
        if (!environment.production) {
          // eslint-disable-next-line no-console
          console.log('google tag manager attached to dom', result);
        }
      });
  }

  private _subscribeOnPersistSelectedLanguage(): void {
    this._persistQuery.selectedLanguageCode$$.pipe(
      take(1),
      switchMap((id) => this._locService.changeLanguage(id))
    ).subscribe();
  }

  private _subscribeLanguageChange(): void {

    this._languagesQuery.selectActive().pipe(
      take(1), // берем первый, на случай если нет в persistStore уже выбранного языка
      withLatestFrom(this._persistQuery.selectedLanguageCode$$),
      tap(([active, selectedLanguageCode]) => {
        if (selectedLanguageCode.length === 0) {
          this._persistService.updatePersistenceStore({ selectedLanguageCode: active?.id });
        }
      }),
      mapTo(void 0)
    ).subscribe();

    this._languagesQuery.selectActive().pipe(
      skip(1), // при инициализации пропускаем первый результат т.к он перезатирает уже выбранный язык до рефреша
      tap(lang => this._persistService.updatePersistenceStore({ selectedLanguageCode: lang?.id })),
      mapTo(void 0)
    ).subscribe();
  }
}
