import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class RecaptchaInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {
    if (!environment.google.captcha.gcaptchaEnabled) {
      req = req.clone({
        setHeaders: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'recaptcha-key': 'a3a0826a-5c94-11eb-ae93-0242ac130002'
        }
      });
    }
    return next.handle(req);
  }
}
