import { Query, Store, StoreConfig } from '@datorama/akita';
import { IReceiverDetailDto } from './receivers.store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface IReceiverEditState {
  receiver: IReceiverDetailDto | null,
  originalReceiver: IReceiverDetailDto | null
}

function createInitialReceiverEditState(): IReceiverEditState {
  return {
    receiver: null,
    originalReceiver: null
  };
}

@Injectable()
@StoreConfig({ name: 'receiver-edit', resettable: true })
export class ReceiverEditStore extends Store<IReceiverEditState> {
  constructor() {
    super(createInitialReceiverEditState());
  }
}

@Injectable()
export class ReceiverEditQuery extends Query<IReceiverEditState> {

  receiver$$: Observable <IReceiverDetailDto | null> = this.select(x => x.receiver);
  originalReceiver$$: Observable <IReceiverDetailDto | null> = this.select(x => x.originalReceiver);
  isLoading$$: Observable<boolean> = this.selectLoading();

  constructor(protected override store: ReceiverEditStore) {
    super(store);
  }
}
