import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DashboardReceiversStore, IRecipientResultDto } from './dashboard-receivers.store';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';

@Injectable()
export class DashboardReceiversService extends BaseService {
  constructor(
    private _store: DashboardReceiversStore,
    http: HttpClient
  ) {
    super(http);
  }

  loadReceivers$(): Observable<void> {
    const pageSize = 4;
    return super.loadGETCollectionToStore<IRecipientResultDto[], IRecipientResultDto>('history/recipients', this._store,
      resp => resp, { pageSize: pageSize.toString() });
  }
}
