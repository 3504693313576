import { IEnvironment } from './environment.model';
import { defaultEnvironment } from './environment.default';

export const environment: IEnvironment = {
  production: false,
  baseUrl: 'https://ff-online-2.ffsw.dev/srv/api/v1.0/',
  webSocketHostOrigin: 'https://ff-online-2.ffsw.dev/srv',
  onlineLocationOrigin: 'https://ff-online-2.ffsw.dev',
  stNotificationsUrl: 'https://devapi.ffsw.dev/',

  thirdPartyServices: {
    ...defaultEnvironment.thirdPartyServices,
    freshchat: {
      ...defaultEnvironment.thirdPartyServices.freshchat,
      fillColor: '#cc1215'
    }
  },

  appSettings: {
    ...defaultEnvironment.appSettings,

    mobileApp: {
      androidUrl: 'https://play.google.com/store/apps/details?id=com.everestremit.sendmoney',
      iosUrl: 'https://apps.apple.com/us/app/everestremit-money-remittances/id6461774509',
      galleryUrl: null,
    },
    showEmailNotificationCheckbox: false
  },

  google: {
    ...defaultEnvironment.google
  },

  vendor: {
    company: {
      name: 'EVERESTREMIT',
      siteAddress: 'https://everestremit.ffsw.dev/{{lang}}/contact-us',
      contactUsExternalUrl: 'https://everestremit.ffsw.dev/{{lang}}/contact-us',
      siteLocales: [ ]
    },
    theme: {
      name: 'CHILI_PEPPER',
      devMode: false,
      autoDarkMode: true,
      isUseCustomLogo: true
    }
  }
};
