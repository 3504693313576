import { NgModule } from '@angular/core';
import { AuthenticationCountriesQuery, AuthenticationCountriesStore } from './authentication-countries.store';
import { AuthenticationCountriesService } from './authentication-countries.service';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AuthenticationCountriesService,
    AuthenticationCountriesStore,
    AuthenticationCountriesQuery
  ],
})
export class AuthenticationCountriesModule {}
