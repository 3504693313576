import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, mapTo, switchMap, take } from 'rxjs/operators';
import { IBankResponseModel } from './banks.model';
import { BanksStore } from './banks.store';
import { withTransaction } from '@datorama/akita';

@Injectable()
export class BanksService {

  constructor(
    private _http: HttpClient,
    private _banksStore: BanksStore
  ) { }

  getBanks$(directionId: string): Observable<void> {
    return of(void 0)
      .pipe(
        withTransaction(() => {
          this._banksStore.remove();
          this._banksStore.setLoading(true);
        }),
        switchMap(() => this._http.get<IBankResponseModel[]>('reference/bank', { params: { directionId } })),
        catchError(() => {
          this._banksStore.setLoading(false);
          return EMPTY;
        }),
        take(1),
        withTransaction(bankAccountTypes => {
          this._banksStore.setLoading(false);
          this._banksStore.upsertMany(bankAccountTypes);
        }),
        mapTo(void 0)
      );
  }
}
