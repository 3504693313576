import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { StaticResourceDialogService } from './static-resource-dialog.service';
import { environment } from '../../../environments/environment';
import { SiteTemplateType } from './models/site-template.type';

@Component({
  selector: 'app-static-resource-dialog',
  templateUrl: 'static-resource-dialog.component.html',
  styleUrls: ['static-resource-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [StaticResourceDialogService]
})

export class StaticResourceDialogComponent implements OnInit {
  private _companyName: typeof environment.vendor.company.name = environment.vendor.company.name;
  @ViewChild('frame') iframe: ElementRef;

  isLoading$$: Observable<boolean> = this._privacyPolicyService.isLoading$$;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private _data: { confirmText: string, template: SiteTemplateType },
    private _dialogRef: MatDialogRef<StaticResourceDialogComponent>,
    private _privacyPolicyService: StaticResourceDialogService
  ) {
  }

  ngOnInit(): void {
    this._privacyPolicyService.getTemplate$(this._companyName, this._data.template).pipe(
      tap(response => {
        if (response != null) {
          this.iframe.nativeElement.srcdoc = response;
          return;
        } else {
          this.iframe.nativeElement.srcdoc = response;
          this._dialogRef.close();
        }
      }),
      catchError(() => {
        this._dialogRef.close();
        return EMPTY;
      })
    ).subscribe();
  }

  onNoClick(): void {
    this._dialogRef.close();
  }
}
