<button mat-icon-button class="custom-btn-mat-close" (click)="onCloseClick()">
  <i class="ff-icon ff-close icon-size-2"></i>
</button>
<mat-dialog-content class="dialog-content">
  <ng-container *ngIf="sanitizedImage$$ | async as picData">
    <div class="row">
      <div class="col-12">
        <img class="dialog-content__photo" [src]="picData.img" (load)="onLoad()" alt="" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isLoading$$ | async">
    <app-spinner [isFullScreen]="true"></app-spinner>
  </ng-container>
</mat-dialog-content>
