// https://stackoverflow.com/a/16245768
export function convertBase64ToBlob(b64Data: string, contentType: string = '', sliceSize: number = 512): Blob {
  const byteCharacters = atob(b64Data);
  const byteArrays: number[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    // @ts-ignore
    byteArrays.push(byteArray);
  }
  // @ts-ignore
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}
