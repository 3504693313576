import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, from, Observable } from 'rxjs';
import { distinctUntilChanged, map, mapTo, mergeMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ILanguage, LocalizationQuery } from '@ff/localization';
import { PersistenceQuery, PersistenceStore } from '../persist/persist.store';
import { PersistenceService } from '../persist/persistence.service';
import { SnackbarService } from '../../functionality-modules/snackbars/snackbar.service';

export const THEME_DEV_URI_HOST = 'http://localhost:4202';

export type FFThemeVendor =
  'FINFLOW'
  | 'BESTPAY'
  | 'CHILI_PEPPER'
  | 'LAPIS_LAZULI';

export type FFThemeCompanyName = 'FINFLOW'
  | 'BESTPAY' | 'EVERESTREMIT' | 'REMITFINCH' | 'WINMALL';

export type FFThemeComponent = 'root' | 'base' | 'extensions' | 'material' | 'kendo';
export type FFThemeAssets = 'iconFont' | 'iconFlags';
export type FFThemeMode = 'light' | 'dark';

export type FFLogoType = 'horizontal' | 'vertical';
export type FlagIconSize = 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large' | 'extra-extra-large';

@Injectable()
export class ThemeService {
  private _activeThemeName$$: BehaviorSubject<FFThemeVendor> = new BehaviorSubject<FFThemeVendor>(environment.vendor.theme.name);
  private _isMenuVisible$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private readonly _head: HTMLElement;
  private _renderer: Renderer2;
  private _defaultActiveThemeMode: FFThemeMode = 'light';

  /**
   *  Возвращает флаг, управляющий видимостью меню в интерфейсе
   */
  isMenuVisible$$: Observable<boolean> = this._isMenuVisible$$.asObservable();

  /**
   * Возвращает активный режим интерфейса (dark, light)
   */
  activeThemeMode$$: Observable<FFThemeMode | null> = this._persistenceQuery.activeThemeMode$$;

  /**
   *  Возвращает активного вендора
   */
  activeThemeName$$: Observable<FFThemeVendor> = this._activeThemeName$$.asObservable();

  /**
   *  Вовзращает ссылку на логотип finflow с учетом активного режима интерфейса (dark, light)
   */
  ownerLogoUrl$$: Observable<string> = this.activeThemeMode$$.pipe(
    map(() => this.getOwnerLogoUrl()));

  constructor(rendererFactory: RendererFactory2,
              private _snackbarService: SnackbarService,
              private _localizationQuery: LocalizationQuery,
              private _persistenceQuery: PersistenceQuery,
              private _persistenceStore: PersistenceStore,
              private _persistenceService: PersistenceService,
              @Inject(DOCUMENT) private _document: Document) {
    this._head = this._document.head;
    this._renderer = rendererFactory.createRenderer(null, null);

    this._subscribeOnInit();
    this._initDevelopmentThemeSwitcher();
  }

  /**
   *  Вовзращает ссылку на логотип вендора с учетом активного режима интерфейса (dark, light)
   */
  getVendorLogoUrl$(logoType?: FFLogoType): Observable<string> {
    return combineLatest(
      [this._activeThemeName$$, this.activeThemeMode$$]).pipe(
      map(() => this._getVendorLogoUrl(logoType)));
  }

  /**
   *  Возвращает имя текущего вендора
   */
  activeThemeName(): string {
    return this._activeThemeName$$.getValue();
  }

  /**
   *  Упрвляет выбранным вендором
   */
  setTheme(name: FFThemeVendor): void {
    this._activeThemeName$$.next(name);
  }

  /**
   *  Управляет выбранным вендором
   */
  toggleThemeMode(): void {
    this.activeThemeMode$$
      .pipe(
        take(1),
        tap((activeThemeMode) => {
          this._persistenceService.updatePersistenceStore({ activeThemeMode: activeThemeMode === 'dark' ? 'light' : 'dark' });
        })
      )
      .subscribe();
  }

  /**
   *  Управляет видимостью меню
   */
  toggleMenuVisibility(visibility?: boolean): void {
    this._isMenuVisible$$.next(visibility != null ? visibility : !this._isMenuVisible$$.getValue());
  }

  /**
   *  Возвращает ссылку на картинку, которая находится в папке с вендором дизайн-системы
   */
  getUniqueImageUrl(imgAddress: string): string {
    const currentVendor = this._activeThemeName$$.getValue().toUpperCase();
    return this.getSharedImageUrl(`vendors/${currentVendor}/${imgAddress}`);
  }

  /**
   *  Возвращает ссылку на картинку, которая находится в дизайн системе и не зависит от вендора
   *  Как правило, такие картинки мы раскрашиваем через CSS-переменные
   */
  getSharedImageUrl(imgAddress: string): string {
    // returns image from design system
    return this._createDesignSystemResourceLink(imgAddress);
  }

  /**
   *  Возвращает ссылку на картинку, которая находится в дизайн системе, в зависимости от выбранной темы
   */
  getSharedImageUrlWithDarkMode(imgAddress: string): string {
    const [name, extension] = imgAddress.split('.');
    const activeThemeMode = this._persistenceStore.getValue().activeThemeMode;
    const fileName = `${name}-${activeThemeMode}.${extension}`;
    return this._createDesignSystemResourceLink(fileName);
  }

  /**
   * Возвращает ссылку на картинку, которая находится в проекте, в зависимости от выбранной темы
   * */
  getInternalImageUrlWithDarkMode(imgAddress: string): string {
    const [name, extension] = imgAddress.split('.');
    const activeThemeMode: FFThemeMode = this._persistenceStore.getValue().activeThemeMode === 'dark' ? 'light' : 'dark';
    const fileName = `${name}-${activeThemeMode}.${extension}`;
    return this._createInternalResourceLink(fileName);
  }

  /**
   *  Возвращает ссылку на картинку, находящуюся в проекте приложения и не зависящую от дизайн-системы
   */
  getInternalImageUrl(imgAddress: string): string {
    // returns image from application assets
    return this._createInternalResourceLink(imgAddress);
  }

  /**
   *  Возвращает ссылку на логотип finflow
   */
  getOwnerLogoUrl(): string {
    // always returns finflow logotype
    const activeThemeMode = this._persistenceStore.getValue().activeThemeMode === 'dark' ? 'light' : 'dark';
    const fileName = `/img/logo-footer-${activeThemeMode}.svg`;
    return this._createDesignSystemResourceLink(fileName);
  }

  /**
   *  Возвращает корректный класс для флагов стран
   */
  getFlagClass(iso2Code: ILanguage | string, size: FlagIconSize): string {
    const flagClases = 'flag-icon-circle flag-icon';
    let flagSizeClass = '';

    switch (size) {
      case 'extra-small':
        flagSizeClass = 'flag-icon-circle-xs';
        break;
      case 'small':
        flagSizeClass = 'flag-icon-circle-sm';
        break;
      case 'medium':
        flagSizeClass = 'flag-icon-circle-md';
        break;
      case 'large':
        flagSizeClass = 'flag-icon-circle-lg';
        break;
      case 'extra-large':
        flagSizeClass = 'flag-icon-circle-xl';
        break;
      case 'extra-extra-large':
        flagSizeClass = 'flag-icon-circle-xxl';
        break;
      default:
        flagSizeClass = '';
    }

    if (iso2Code == null) {
      return `${flagClases} flag-icon-unknown`;
    }

    if (typeof iso2Code !== 'string') {
      return `${flagClases} flag-icon-${iso2Code.id.slice(3, 5).toString()} ${flagSizeClass}`;
    }

    return `${flagClases} flag-icon-${iso2Code.toLowerCase()} ${flagSizeClass}`;
  }

  /**
   *  Возвращает ссылку на кастомные стили для айфрейма самсаба с учетом активного режима (dark / light)
   */
  getSumsubCustomStylesLink$(): Observable<string> {
    return combineLatest([this.activeThemeMode$$, this.activeThemeName$$]).pipe(
      take(1),
      map(([themeMode, themeName]) => `${document.baseURI}/assets/ui/vendors/${themeName}/sumsub/root-${themeMode}.css`)
    );
  }

  /**
   *  В зависимости от настройки в environment включаем или отключаем автоматическую активацию темной темы, при включенной настройке автоматически включаем темную тему в зависимости от системной темы
   */
  initDarkModeAutoSwitch(): void {
    // Check if browser supports prefers-color-scheme media
    const currentSystemTheme: FFThemeMode = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    if (environment.vendor.theme.autoDarkMode && this._persistenceStore.getValue().activeThemeMode == null) {
      this._persistenceService.updatePersistenceStore({ activeThemeMode: currentSystemTheme, systemThemeMode: currentSystemTheme });
      if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {

        if (currentSystemTheme === this._defaultActiveThemeMode) {
          this._setActiveThemeMode(this._defaultActiveThemeMode);
        }

        if (currentSystemTheme !== this._defaultActiveThemeMode) {
          this._setActiveThemeMode(currentSystemTheme);
          this._snackbarService
            .openDarkModeInfo(
              this._localizationQuery.transform('%[snackbar.text.switch-to-system-theme]%'), { duration: environment.appSettings.snackbar.shortDuration })
            .pipe(tap(() => this.toggleThemeMode()))
            .subscribe();
        }

      }
    }

    if (this._persistenceStore.getValue().activeThemeMode != null) {
      if (currentSystemTheme !== this._persistenceStore.getValue().systemThemeMode && this._persistenceStore.getValue().activeThemeMode !== currentSystemTheme) {
        this._persistenceService.updatePersistenceStore({ systemThemeMode: currentSystemTheme });
        this._snackbarService
          .openDarkModeInfo(
            this._localizationQuery.transform('%[snackbar.text.system-theme-has-changed]%'), { duration: environment.appSettings.snackbar.shortDuration })
          .pipe(tap(() => this.toggleThemeMode()))
          .subscribe();
      } else {
        this._persistenceService.updatePersistenceStore({ systemThemeMode: currentSystemTheme });
      }
    }
  }

  /**
   *  Возвращает ссылку на логотип вендора с учетом активного режима (dark / light)
   */
  private _getVendorLogoUrl(logoType: FFLogoType = 'horizontal'): string {
    const activeThemeMode = this._persistenceStore.getValue().activeThemeMode === 'dark' ? 'light' : 'dark';
    const isUseCustomLogo = environment.vendor.theme.isUseCustomLogo;
    const companyName = environment.vendor.company.name.toUpperCase();
    const fileName = `${logoType}-${activeThemeMode}.svg`;

    if (isUseCustomLogo) {
      return this._createInternalResourceLink(`/vendors/${companyName}/logotype/${fileName}`);
    } else {
      return this.getUniqueImageUrl(`/logotype/${fileName}`);
    }
  }

  /**
   *  Создает ссылку для подключения шрифтов и подключает ее в <head>
   */
  private _loadFont(): Observable<void> {
    const currentVendor = this._activeThemeName$$.getValue().toUpperCase();
    const fontFileName = this._createDesignSystemResourceLink(`vendors/${currentVendor}/fonts/fonts.css`);

    const promise = new Promise((resolve) => {
      const linkEl: HTMLElement = this._createCssLinkHTML(fontFileName);
      this._renderer.setProperty(linkEl, 'onload', resolve);
      this._renderer.appendChild(this._head, linkEl);
    });
    return from(promise).pipe(mapTo(void 0));
  }

  /**
   *  Создает ссылку для подключения иконочного шрифта и подключает ее в <head>
   */
  private _loadThemeAssets(assetName: FFThemeAssets): Observable<void> {
    let assetFileName = '';
    if (assetName === 'iconFont') {
      assetFileName = this._createDesignSystemResourceLink(`${assetName}/ff-icon.css`);
    } else if (assetName === 'iconFlags') {
      assetFileName = this._createExternalPackageResourceLink(`${assetName}/flags.min.css`);
    }

    const promise = new Promise((resolve) => {
      const linkEl: HTMLElement = this._createCssLinkHTML(assetFileName);
      this._renderer.setProperty(linkEl, 'onload', resolve);
      this._renderer.appendChild(this._head, linkEl);
    });
    return from(promise).pipe(mapTo(void 0));
  }

  /**
   *  Создает набор favicon для <head>
   */
  private _loadFavicon(): Observable<void> {
    const isUseCustomLogo = environment.vendor.theme.isUseCustomLogo;
    const companyName = environment.vendor.company.name.toUpperCase();

    const currentVendor = this._activeThemeName$$.getValue()?.toUpperCase();
    const favIconDirectory = isUseCustomLogo ? this._createInternalResourceLink(`/vendors/${companyName}/favicon`)
      : this._createDesignSystemResourceLink(`vendors/${currentVendor}/favicon`, true);

    const promise = new Promise((resolve, reject) => {
      const favicon16x16ico: HTMLElement = this._createFavIconHTML(`${favIconDirectory}/favicon.ico`); // favicon 16x16 icon
      const favicon16x16: HTMLElement = this._createFavIconHTML(`${favIconDirectory}/16x16.png`); // favicon 16x16
      const favicon32x32: HTMLElement = this._createFavIconHTML(`${favIconDirectory}/32x32.png`); // favicon 32x32 shortcut
      const favicon180x180: HTMLElement = this._createFavIconHTML(`${favIconDirectory}/apple-touch-icon.png`); // favicon 180x180
      const favicon192x192: HTMLElement = this._createFavIconHTML(`${favIconDirectory}/android-chrome-192x192.png`); // favicon 192x192

      this._renderer.appendChild(this._head, favicon16x16ico);
      this._renderer.appendChild(this._head, favicon16x16);
      this._renderer.appendChild(this._head, favicon32x32);
      this._renderer.appendChild(this._head, favicon180x180);
      this._renderer.appendChild(this._head, favicon192x192);

      Promise.all([
        new Promise((resolved) => this._renderer.setProperty(favicon16x16ico, 'onload', resolved)),
        new Promise((resolved) => this._renderer.setProperty(favicon16x16, 'onload', resolved)),
        new Promise((resolved) => this._renderer.setProperty(favicon32x32, 'onload', resolved)),
        new Promise((resolved) => this._renderer.setProperty(favicon180x180, 'onload', resolved)),
        new Promise((resolved) => this._renderer.setProperty(favicon192x192, 'onload', resolved))
      ]).then(
        () => resolve('ok'),
        () => reject('failure')
      );
    });

    return from(promise).pipe(mapTo(void 0));
  }

  /**
   *  Создает компонент и добавляет его в <head>
   */
  private _loadCssModule(moduleName: FFThemeComponent): Observable<void> {
    const currentVendor = this._activeThemeName$$.getValue().toUpperCase();
    let cssModuleUrl = '';

    if (moduleName === 'root') {
      cssModuleUrl = this._createDesignSystemResourceLink(`vendors/${currentVendor}/style/${moduleName}.min.css`);
    } else {
      cssModuleUrl = this._createDesignSystemResourceLink(`components/${moduleName}.min.css`);
    }

    const promise = new Promise<string>((resolve) => {
      const linkEl: HTMLElement = this._createCssLinkHTML(cssModuleUrl);
      this._renderer.setProperty(linkEl, 'onload', resolve);
      this._renderer.appendChild(this._head, linkEl);
    });
    return from(promise).pipe(mapTo(void 0));
  }

  /**
   *  Создает HTML элемент для FavIcon
   */
  private _createFavIconHTML(iconUrl: string): HTMLElement {
    const htmlElement: HTMLElement = this._renderer.createElement('link');
    this._renderer.setAttribute(htmlElement, 'rel', 'icon');
    this._renderer.setAttribute(htmlElement, 'type', 'image/x-icon');
    this._renderer.setAttribute(htmlElement, 'href', iconUrl);
    this._renderer.setAttribute(htmlElement, 'ff-style', '');
    return htmlElement;
  }

  /**
   *  Создает HTML элемент с ссылкой
   */
  private _createCssLinkHTML(cssUrl: string): HTMLElement {
    const htmlElement: HTMLElement = this._renderer.createElement('link');
    this._renderer.setAttribute(htmlElement, 'rel', 'stylesheet');
    this._renderer.setAttribute(htmlElement, 'type', 'text/css');
    this._renderer.setAttribute(htmlElement, 'href', cssUrl);
    this._renderer.setAttribute(htmlElement, 'ff-style', '');
    return htmlElement;
  }

  /**
   *  Создает ссылку на ресурс, который лежит в дизайн-системе
   */
  private _createDesignSystemResourceLink(link: string, relative: boolean = true): string {
    const isDevelopment = environment.vendor.theme.devMode;
    if (link == null || link === '') return '';
    link = link.replace(/^\//, '');
    if (!isDevelopment) {
      return `${relative ? '.' : ''}/assets/ui/${link}`;
    } else {
      return `${THEME_DEV_URI_HOST}/dist/${link}`;
    }
  }

  /**
   *  Создает ссылку на ресурс из стороннего пакета
   */
  private _createExternalPackageResourceLink(link: string, relative: boolean = true): string {
    if (link == null || link === '') return '';
    link = link.replace(/^\//, '');
    return `${relative ? '.' : ''}/assets/ui/${link}`;
  }

  /**
   *  Создает ссылку на ресурс, который лежит в папке приложения
   */
  private _createInternalResourceLink(link: string): string {
    if (link == null || link === '') return '';
    link = link.replace(/^\//, '');
    return `./assets/${link}`;
  }

  /**
   *  Удаляет все созданные стили из <head>
   */
  private _cleanStyleNodesInHead(): void {
    // удаляем все ранее добавленные стили из head
    const ffNodes = Array.from(this._head.querySelectorAll('[ff-style]'));
    ffNodes.map((currentNode) => this._renderer.removeChild(this._head, currentNode));
  }

  /**
   *  Активизирует функции управления темой и активным режимом через консоль
   */
  private _initDevelopmentThemeSwitcher(): void {
    if (!environment.production) {
      // this is only for dev environment for changing themes instantly
      // @ts-ignore
      window.themeFinFlow = () => this.setTheme('FINFLOW');
      // @ts-ignore
      window.themeBestPay = () => this.setTheme('BESTPAY');
      // @ts-ignore
      window.themeRed = () => this.setTheme('CHILI_PEPPER');
      // @ts-ignore
      window.toggleThemeMode = () => this.toggleThemeMode();
    }
  }

  /**
   *  Переключает активный режим темы (dark / light)
   */
  private _setThemeMode(activeThemeMode: FFThemeMode | null): void {
    this._renderer.setAttribute(document.firstElementChild, 'data-theme', activeThemeMode ?? 'light');
  }

  private _setActiveThemeMode(ThemeMode: FFThemeMode): void {
    this.activeThemeMode$$
      .pipe(
        take(1),
        tap((ThemeMode) => {
          this._persistenceStore.update({ activeThemeMode: ThemeMode });
        })
      )
      .subscribe();
  }

  /**
   *  Подписки, которые сработают при инициализации сервиса
   */
  private _subscribeOnInit(): void {
    const isDevelopment = environment.vendor.theme.devMode;

    // Инициализируем все модули и загружаем их
    this._activeThemeName$$
      .pipe(
        distinctUntilChanged(),
        tap(() => this._cleanStyleNodesInHead()),
        mergeMap(() => {

            const commonTasks = [
              this._loadFont(),
              this._loadThemeAssets('iconFont'),
              this._loadThemeAssets('iconFlags'),
              this._loadCssModule('root'),
              this._loadFavicon()
            ];

            if (!isDevelopment) {
              return forkJoin([...commonTasks]);
            }

            return forkJoin([
              this._loadCssModule('base'),
              this._loadCssModule('extensions'),
              this._loadCssModule('material'),
              ...commonTasks
            ]);
          }
        )
      )
      .subscribe();

    // Подписываемся на изменения переключателя темной темы
    this.activeThemeMode$$
      .pipe(
        distinctUntilChanged(),
        tap((activeThemeMode) => this._setThemeMode(activeThemeMode))
      )
      .subscribe();
  }
}
