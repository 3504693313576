import { NgModule } from '@angular/core';
import { DocTypesSourceIncomeService } from './doctypes-source-income.service';
import { DocTypesSourceIncomeQuery, DocTypesSourceIncomeStore } from './doctypes-source-income.store';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    DocTypesSourceIncomeService,
    DocTypesSourceIncomeStore,
    DocTypesSourceIncomeQuery,
  ],
})
export class DocTypesSourceOfIncomeModule {}
