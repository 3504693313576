import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-slide-toggle',
  templateUrl: 'ff-slide-toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FfSlideToggleComponent {
  @Input() formControlRef: AbstractControl | FormControl;
  @Input() label: string;
  @Input() required: boolean = false;
  @Input() disabled: boolean;
  @Input() color: string = 'primary';

}
