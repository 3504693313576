import { EntityState, EntityStore, EntityUIQuery, EntityUIStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ITemplate, ITemplateUI } from '../../models/template.model';
import { Observable } from 'rxjs';

export interface ITemplatesListState extends EntityState<ITemplate, string> {
  filter: string
}

export interface ITemplatesListUIState extends EntityState<ITemplateUI, string> {
}

@StoreConfig({ name: 'templates-list' })
export class TemplatesListStore extends EntityStore<ITemplatesListState> {
  override ui: EntityUIStore<ITemplatesListUIState>;

  constructor() {
    super({ filter: 'ALL' });
    const defaults: ITemplateUI = { isEdit: false };
    this.createUIStore().setInitialEntityState(defaults);
  }
}

@Injectable({ providedIn: 'root' })
export class TemplatesListQuery extends QueryEntity<ITemplatesListState> {
  override ui: EntityUIQuery<ITemplatesListUIState>;

  isLoading$$: Observable<boolean> = this.selectLoading();
  templates$$: Observable<ITemplate[]> = this.selectAll({
    sortBy: (a, b) => {
      if (a.templateName.toLowerCase() < b.templateName.toLowerCase()) { return -1; }
      if (a.templateName.toLowerCase() > b.templateName.toLowerCase()) { return 1; }
      return 0;
    }
  });

  constructor(protected override store: TemplatesListStore) {
    super(store);
    this.createUIQuery();
  }
}
