import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mapTo, switchMap } from 'rxjs/operators';
import {
  IIdentifyIframeRequest,
  IFrameResponseMessage,
  IGetIFrameTokenResponse,
  IPostScanVerifySaveResultRequest
} from './identify-iframe.model';
import { IdentifyIframeComponent } from './identify-iframe.component';
import { MatDialog } from '@angular/material/dialog';
import { IdentifyResultDialogComponent } from './identify-result-dialog/identify-result-dialog.component';

@Injectable()
export class IdentifyIframeService {
  constructor(private _http: HttpClient, private _dialogController: MatDialog) {}

  getToken$(params: IIdentifyIframeRequest): Observable<string> {
    return of(void 0).pipe(
      switchMap(() => this._http.get<IGetIFrameTokenResponse>('scan-verify/token', { params: { ...params } })),
      map((response: IGetIFrameTokenResponse): string => response.url)
    );
  }

  saveResult$(payload: IFrameResponseMessage, identityDocumentId: string): Observable<void> {
    const remappedData: IPostScanVerifySaveResultRequest = {
      resultJson: JSON.stringify(payload),
      identityDocumentId: Number(identityDocumentId)
    };

    return of(void 0).pipe(
      switchMap(() => this._http.post<IPostScanVerifySaveResultRequest>('scan-verify/save-result', { ...remappedData })),
      mapTo(void 0)
    );
  }

  openIdenfyIframe$(url: string): Observable<IFrameResponseMessage | null> {
    return this._dialogController
      .open(IdentifyIframeComponent, {
        data: { url },
        autoFocus: false,
        disableClose: true,
        width: '100%',
        maxWidth: 752,
        maxHeight: '85vh'
      })
      .afterClosed();
  }

  openIdenfyResults$(message: string): Observable<unknown> {
    return this._dialogController
      .open(IdentifyResultDialogComponent, {
        data: { message },
        autoFocus: false,
        disableClose: true,
        maxWidth: 420,
        width: '100%',
        maxHeight: '85vh',
      })
      .afterClosed()
      .pipe();
  }

}
