import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { map, mapTo, takeWhile, tap } from 'rxjs/operators';
import { ThemeService } from '../../../data-modules/theme/theme.service';
import { ProfileAdditionalAttributesService } from '../../../root/private/private-store/profile-additional-attributes.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-prepare-for-payment',
  styleUrls: ['./prepare-for-payment.component.scss'],
  templateUrl: 'prepare-for-payment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrepareForPaymentComponent implements OnInit {
  readonly countdown: number = 60;
  secondsLeft$$: BehaviorSubject<number> = new BehaviorSubject(this.countdown);
  interval$$: Observable<number> = interval(1000);
  route$$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: unknown,
    public dialogRef: MatDialogRef<PrepareForPaymentComponent>,
    public themeService: ThemeService,
    private profileAdditionalAttributesService: ProfileAdditionalAttributesService
  ) {}

  ngOnInit(): void {
      this.interval$$.pipe(
          map((timeElapsed: number): number => this.countdown - timeElapsed),
          takeWhile((timeLeft: number): boolean => timeLeft >= 0),
          tap((timeLeft: number) => {
            this.secondsLeft$$.next(timeLeft);

            if (timeLeft === 0) {
              this.route$$.next();
            }
          }),
          mapTo(void 0),
          untilDestroyed((this))
        ).subscribe();

      this.route$$.pipe(
          tap(() => this.dialogRef.close(true)),
          untilDestroyed((this))
        ).subscribe();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onTextClick(event: MouseEvent): void {
    event.preventDefault();
  }

  onCheckboxChange(event: MatCheckboxChange): void {
    this.profileAdditionalAttributesService.setAdditionalAttribute$({ dontNotifyBeReady: event.checked }).subscribe();
  }
}
