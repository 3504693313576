import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mapTo, switchMap, withLatestFrom } from 'rxjs/operators';
import { DirectionCitiesQuery, DirectionCitiesStore, IDirectionCitiesState } from './direction-cities.store';
import { IDirectionCitiesResponse, IDirectionCity } from './direction-cities.model';
import { withTransaction } from '@datorama/akita';
import { IGateway } from '../gateways/gateways.model';
import { IRemittanceCountry } from '../remittance-countries/remittance-countries.model';
import { SnackbarService } from '../../functionality-modules/snackbars/snackbar.service';
import { ILookupStr } from '../../root/private/private-models/lookup-str';
import { CalculatorQuery } from '../calculator/calculator.store';
import { GatewaysQuery } from '../gateways/gateways.store';
import { environment } from '../../../environments/environment';

@Injectable()
export class DirectionCitiesService {

  private readonly _pageSize: number = environment.appSettings.payoutLocations.pageCitiesSize;
  private _offset$$: Observable<number> = this._directionCitiesQuery.offset$$;

  constructor(
    private _http: HttpClient,
    private _directionCitiesStore: DirectionCitiesStore,
    private _snackbarService: SnackbarService,
    private _calculatorQuery: CalculatorQuery,
    private _gatewaysQuery: GatewaysQuery,
    private _directionCitiesQuery: DirectionCitiesQuery
  ) { }

  getDirectionCities$(country: IRemittanceCountry, gateway: IGateway | null | undefined): Observable<void> {
    return of(void 0).pipe(
      withTransaction(() => {
        this._directionCitiesStore.reset();
        this._directionCitiesStore.setLoading(true);
      }),
      switchMap(() => {
        const params: { [key: string]: string | number } = {
          countryId: country.id,
          offset: 0,
          pageSize: this._pageSize
        };
        if (gateway?.id != null) { params.directionId = gateway.directionId; }
        return this._getDirectionCities$(params);
      }),
      catchError(() => {
        this._snackbarService.openFailure$('%[location.get-direction.failed]%').subscribe();
        this._directionCitiesStore.setLoading(false);
        return EMPTY;
      }),
      map((response: IDirectionCitiesResponse): IDirectionCity[] => response.cities),
      map((cities: IDirectionCity[]): ILookupStr[] => cities.map(((c, i) => ({ title: c.name, id: i.toString(10) })))),
      withTransaction((cities: ILookupStr[]) => {
        this._directionCitiesStore.setLoading(false);
        this._directionCitiesStore.upsertMany(cities);
        this._directionCitiesStore.update((st) => ({
          ...st,
          offset: st.offset + cities.length
        }));
      }),
      mapTo(void 0)
    );
  }

  updateMoreCities$(): Observable<void> {
    return of(0).pipe(
      withTransaction(() => this._directionCitiesStore.setLoading(true)),
      withLatestFrom(
        this._calculatorQuery.select(x => x.destinationCountry),
        this._gatewaysQuery.selectActive(),
        this._offset$$
      ),
      switchMap(([_, destinationCountry, gateway, offset]) => {
        const params: { [key: string]: string | number } = {
          countryId: destinationCountry?.id ?? '',
          offset: offset + this._pageSize,
          pageSize: this._pageSize
        };
        if (gateway?.id != null) { params.directionId = gateway.directionId; }
        return this._getDirectionCities$(params);
      }),
      catchError(() => {
        this._snackbarService.openFailure$('%[location.get-direction.failed]%').subscribe();
        this._directionCitiesStore.setLoading(false);
        return EMPTY;
      }),
      withTransaction((response: IDirectionCitiesResponse) => {
        const cities = response.cities.map(((c, i) => ({ title: c.name, id: i.toString(10) })));

        this._directionCitiesStore.upsertMany(cities);
        this._directionCitiesStore.update((state: IDirectionCitiesState) => ({
          ...state,
          offset: state.offset + cities.length,
          totalCount: response.totalCount,
        }));
        this._directionCitiesStore.setLoading(false);
      }),
      mapTo(void 0)
    );
  }

  private _getDirectionCities$(params: { [key: string]: string | number }): Observable<IDirectionCitiesResponse> {
    return this._http.get<IDirectionCitiesResponse>('reference/direction-cities', { params });
  }

}
