import { Injectable } from '@angular/core';
import { combineLatest, interval, Observable, of, ReplaySubject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  shareReplay,
  startWith,
  switchMap
} from 'rxjs/operators';
import { randomString } from '../utils/functions/random-string';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { isArray } from '@datorama/akita';
// @ts-ignore
import pkg from '../../../package.json';
import { urlJoin } from 'url-join-ts';

@Injectable()
export class VersionCheckService {

  private _interval: Observable<number> = interval(60 * 1000).pipe(startWith(-1)); // check every minute
  private _http: HttpClient;
  private _compiledVersion: string = pkg.version;
  private _serverVersion: ReplaySubject<string> = new ReplaySubject<string>(1);

  newVersionAvailable$$: Observable<void> = combineLatest([
    of(this._compiledVersion),
    this._serverVersion.pipe(distinctUntilChanged())
  ]).pipe(
    filter(([compiled, server]) => compiled !== server),
    mapTo(void 0),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(
    backend: HttpBackend
  ) {
    this._http = new HttpClient(backend);

    this._interval.pipe(
      switchMap((): Observable<string | null> => {
        const random = randomString();
        const urlJoined = urlJoin(document.baseURI, '/assets/_version.json?' + random);
        return this._http.get<[string]>(urlJoined).pipe(
          map(resp => isArray(resp) && resp.length > 0 ? resp[0] : null),
          catchError(err => {
            console.error('could not get version', err);
            return of(null);
          })
        );
      })
    ).subscribe(version => {
      if (version == null) { return; }
      this._serverVersion.next(version);
    });

    // this.newVersionAvailable$.subscribe();
  }
}
