import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, DoCheck,
  forwardRef,
  Inject,
  Injector,
  INJECTOR, Input, OnInit
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { deepEquals, FFValidators, updateValueAndValidityRecursively } from '@ff/utils';
import { Observable } from 'rxjs';
import { ICountry } from '../../../../../../../../data-modules/countries/countries.model';
import { filter, map, take, tap } from 'rxjs/operators';
import { CountriesQuery } from '../../../../../../../../data-modules/countries/countries.store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IAmlAddressModel } from '../aml-questionnaire-modal/store/aml-questionnaire-modal.model';
import {
  BeneficiaryAddressQuery,
  IBeneficiaryAddressForm
} from '../../../beneficiary/beneficiary-address/beneficiary-address.store';

@UntilDestroy()
@Component({
  selector: 'app-address-form-control',
  templateUrl: './address-form-control.component.html',
  styleUrls: ['./address-form-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressFormControlComponent),
      multi: true
    }
  ]
})
export class AddressFormControlComponent implements AfterViewInit, ControlValueAccessor, DoCheck, OnInit {
  private _ngControl: NgControl;

  @Input() elementWidth: string;

  form: FormGroup = new FormGroup({
    country: new FormControl(null, [Validators.required]),
    city: new FormControl('', [FFValidators.requiredNoWhitespaces]),
    street: new FormControl('', [FFValidators.requiredNoWhitespaces]),
    building: new FormControl('', [FFValidators.requiredNoWhitespaces, Validators.maxLength(15)]),
    apartment: new FormControl('', [Validators.maxLength(15)]),
    postcode: new FormControl('', [Validators.maxLength(15)])
  });

  countries$$: Observable<ICountry[]> = this._countriesQuery.countries$$.pipe(
    map((countries: ICountry[]): ICountry[] => countries.map(country => ({
      id: country.id,
      title: country.title,
      phoneCode: country.phoneCode,
      iso2Code: country.iso2Code
    })))
  );

  constructor(
    private _countriesQuery: CountriesQuery,
    private _beneficiaryAddressQuery: BeneficiaryAddressQuery,
    @Inject(INJECTOR) private _injector: Injector,
  ) { }

  ngDoCheck (): void {
    if (this._ngControl != null) {
      if (this._ngControl.control?.touched === true) {
        this.form.markAllAsTouched();
        updateValueAndValidityRecursively(this.form);
      }
    }
  }

  ngOnInit(): void {
    this._subscribeOnStore();
  }

  ngAfterViewInit(): void {
    this._ngControl = this._injector.get(NgControl);

    this.form.valueChanges.pipe(
      filter(() => this.form.touched),
      map(formValue => this.form.valid ? this._mapFormValueToString(formValue) : null),
      tap((fullAddress: IAmlAddressModel | null) => {
        this.onChange(fullAddress);
        if (fullAddress != null) {
          this.onTouched();
        }
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  registerOnChange(fn: (fn: IAmlAddressModel | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = (): void => {
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (v: IAmlAddressModel | null) => void = (): void => {
  };

  setDisabledState(isDisabled: boolean): void {
    // isDisabled ? this.control.disable() : this.control.enable();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  writeValue(val: IAmlAddressModel): void {
    if (!deepEquals(this.form.value, val)) {
      this.form.patchValue(val, { emitEvent: false });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _mapFormValueToString(formValue: any): IAmlAddressModel {
    return {
      country: {
        id: formValue.country.id,
        title: formValue.country.title,
        iso2Code: formValue.country.iso2Code,
        phoneCode: formValue.country.phoneCode
      },
      city: formValue.city,
      street: formValue.street,
      building: formValue.building,
      apartment: formValue.apartment,
      postcode: formValue.postcode
    };
  }

  private _subscribeOnStore(): void {
    this._beneficiaryAddressQuery.state$$.pipe(
      take(1),
      tap((value: IBeneficiaryAddressForm) => {
        if (value != null) {
          this.form.patchValue(value);
        }
      }),
      untilDestroyed(this)
    ).subscribe();
  }

}
