<div class="custom-modal-scan-document-finish" xmlns="http://www.w3.org/1999/html">
  <mat-dialog-content class="dialog-content mat-dialog-content-max-height-none">
    <div class="row">
      <div class="col-12 dialog-content__action">
        <div class="ff-icon ff-repeat cursor-pointer dialog-content__action-btn" (click)="rotateLeftClick()"></div>
        <div class="ff-icon ff-repeat cursor-pointer dialog-content__action-btn" (click)="rotateRightClick()"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 custom-modal-scan-frame">
        <ng-container *ngIf="imageModalService.bufferedImage$ | async as picData">
          <!--<img [src]="sanitize(picData.dataBase64)" [alt]="picData.name">-->
          <image-cropper
            [imageBase64]="sanitize2(picData.dataBase64)"
            [maintainAspectRatio]="true"
            [aspectRatio]="4 / 3"
            format="png"
            [canvasRotation]="canvasRotation"
            [transform]="transform"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
          ></image-cropper>
        </ng-container>
      </div>
    </div>
    <!--<img [src]="croppedImage" />-->
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="row flex-grow-1 gy-5">
      <div class="col-1 d-none d-sm-block"></div>
      <div class="col-12 col-sm-10">
        <div class="row gy-5">
          <div class="col-12 col-sm-6 order-2 order-sm-1">
            <button mat-stroked-button class="w-100" (click)="closeModal.emit()">
              {{ '%[edit-capture.button.cancel]%' | tr }}
            </button>
          </div>
          <div class="col-12 col-sm-6 order-1 order-sm-2">
            <button mat-flat-button color="primary" class="w-100" (click)="onSaveClick()">
              {{ '%[edit-capture.button.save]%' | tr }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-1 d-none d-sm-block"></div>
    </div>
  </mat-dialog-actions>
</div>
