import { Injectable } from '@angular/core';
import { Query, Store, StoreConfig } from '@datorama/akita';
import { Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { IMemberStatus, IProfileState } from '../private-models/profile-state.model';
import { ProfileRiskRating } from '../private-models/risk-rating';

export function createInitialState(): IProfileState {
  return {
    customerProfileId: 0,
    customerProfileStatus: '',
    memberId: 0,
    memberStatus: '',
    stamp: '',
    activeTab: 0,
    isWizardMode: false,
    isIdenfyTokenLoading: false,
    riskRating: {
      id: 0,
      rating: null
    },
    animationDoneStamp: null
  };
}

@StoreConfig({ name: 'profile' })
export class ProfileStore extends Store<IProfileState> {
  constructor() {
    super(createInitialState());
  }

  setActiveTab$(index: number): Observable<void> {
    return of(void 0)
      .pipe(
        tap(() => {
          this.update(state => ({ ...state, activeTab: index }));
        })
      );
  }

  setWizard$(state: boolean): Observable<void> {
    return of(void 0)
      .pipe(
        take(1),
        tap(() => {
          this.update(x => ({ ...x, isWizardMode: state }));
        })
      );
  }

  setIdenfyTokenLoadingStatus(status: boolean): void {
    this.update(x => ({ ...x, isIdenfyTokenLoading: status }));
  }

}

@Injectable()
export class ProfileQuery extends Query<IProfileState> {
  isLoading$$: Observable<boolean> = this.selectLoading();
  isIdenfyTokenLoading$$: Observable<boolean> = this.select(x => x.isIdenfyTokenLoading);
  activeTabIndex$$: Observable<number> = this.select(x => x.activeTab);
  isWizardMode$$: Observable<boolean> = this.select(x => x.isWizardMode);
  stamp$$: Observable<string> = this.select(x => x.stamp);
  memberId$$: Observable<number | null> = this.select(x => x.memberId);
  freshchatTrackById$$: Observable<number> = this.select(x => x.customerProfileId);
  animationDoneStamp$$: Observable<string | null> = this.select(state => state.animationDoneStamp);

  constructor(protected override store: ProfileStore) {
    super(store);
  }

  profileHasRiskRating$(ratings: ProfileRiskRating[]): Observable<boolean> {
    return this.select(x => ratings.includes(x.riskRating.rating));
  }

  hasMemberStatus$(states: IMemberStatus[]): Observable<boolean> {
    return this.select(x => states.includes(x.memberStatus));
  }
}
