import { NgModule } from '@angular/core';
import { CountriesService } from './countries.service';
import { CountriesQuery, CountriesStore } from './countries.store';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    CountriesService,
    CountriesStore,
    CountriesQuery,
  ],
})
export class CountriesModule {}
