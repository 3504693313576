import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IPoint } from './points.model';
import { Observable } from 'rxjs';

export interface IPointsState extends EntityState<IPoint, number> {
  total: number,
  offset: number
}

const idKey: keyof IPoint = 'pointId';

@Injectable()
@StoreConfig({ name: 'points', idKey })
export class PointsStore extends EntityStore<IPointsState> {
  constructor() {
    super({ loading: false, offset: 0, total: 0 });
  }
}

@Injectable()
export class PointsQuery extends QueryEntity<IPointsState> {
  state$$: Observable<IPoint[]> = this.selectAll();
  constructor(protected override store: PointsStore) {
    super(store);
  }
}
