import { NgModule } from '@angular/core';

import { WorkflowsService } from './workflows.service';
import { WorkflowsQuery, WorkflowsStore } from './workflows.store';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    WorkflowsService,
    WorkflowsStore,
    WorkflowsQuery,
  ],
})
export class WorkflowsModule {}
