import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SumsubService } from './sumsub.service';
import { SumsubWebsdkComponent } from './sumsub-websdk.component';
import { SumsumWebsdkContainerComponent } from './sumsum-websdk-container/sumsum-websdk-container.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SpinnerModule } from '../../../root/components/spinner/spinner.module';
import { LocalizationModule } from '@ff/localization';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [
    SumsubWebsdkComponent,
    SumsumWebsdkContainerComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    SpinnerModule,
    LocalizationModule,
    MatButtonModule
  ],
  providers: [
    SumsubService
  ]
})
export class SumsubModule { }
