import { ActiveState, EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IReceiverDetailDto {
  id: number,
  surname: string | null,
  name: string | null,
  middleName: string | null,
  sex: '1' | '2' | null,
  phoneNumber: string | null,
  dateOfBirth: string | null,
  isCanEdit: boolean
}

export interface IReceiverDetailResults {
  results: IReceiverDetailDto[],
  totalCount: number
}

export interface IReceiversUI {
  showAll: boolean
}

export interface IReceiversState extends EntityState<IReceiverDetailDto>, ActiveState, IReceiversUI {
  loading: boolean,
  showAll: boolean,
  isEdit: boolean
}

const idKey: keyof IReceiverDetailDto = 'id';

@StoreConfig({ name: 'receivers-receivers', idKey })
@Injectable()
export class ReceiversStore extends EntityStore<IReceiversState> {
  constructor() {
    super({ loading: false, showAll: true, isEdit: false });
  }
}

@Injectable()
export class ReceiversQuery extends QueryEntity<IReceiversState> {

  active$$: Observable<IReceiverDetailDto | undefined> = this.selectActive();
  isEdit$$: Observable<boolean> = this.select(x => x.isEdit);

  receiversToShow$: Observable<IReceiverDetailDto[]> = combineLatest([
    this.selectAll(),
    this.select(x => x.showAll)
  ]).pipe(
    map(([all, isShowAll]) => isShowAll ? all : all.slice(0, 5))
  );

  constructor(protected override store: ReceiversStore) {
    super(store);
  }

}
