import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ImageModalService } from '../image-modal.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { take, tap } from 'rxjs/operators';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { IAttachment } from '../../../../../../private-models/attachment.model';

@Component({
  selector: 'app-edit-capture',
  styleUrls: ['edit-capture.component.scss'],
  templateUrl: 'edit-capture.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class EditCaptureComponent {
  @Output() fileFinished: EventEmitter<IAttachment> = new EventEmitter();
  @Output() closeModal: EventEmitter<void> = new EventEmitter();

  croppedImage: string | null | undefined = '';

  transform: ImageTransform = {};
  canvasRotation: number = 0;

  constructor(
    public imageModalService: ImageModalService,
    private _sanitizer: DomSanitizer,
  ) { }

  sanitize(url: string | ArrayBuffer): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + url);
  }

  sanitize2(url: string): string {
    return 'data:image/jpg;base64,' + url;
  }

  onSaveClick(): void {
    this.imageModalService.bufferedImage$
      .pipe(
        take(1),
        tap((editedImage: IAttachment | null) => {

          if (editedImage == null) {
            return;
          }

          if (this.croppedImage != null && this.croppedImage !== '') {
            this.fileFinished.emit({ ...editedImage, dataBase64: this.croppedImage });
          } else {
            this.fileFinished.emit(editedImage);
          }

        })
      )
      .subscribe();
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64?.split(',')[1];
  }

  imageLoaded(): void {
    // show cropper
  }

  cropperReady(): void {
    // cropper ready
  }

  loadImageFailed(): void {
    // show message
  }

  rotateLeftClick(): void {
    this.canvasRotation = this.canvasRotation - 1;
    this._flipAfterRotate();
  }

  rotateRightClick(): void {
    this.canvasRotation = this.canvasRotation + 1;
    this._flipAfterRotate();
  }

  private _flipAfterRotate(): void {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }
}


