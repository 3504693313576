import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IBankBranch } from './bank-branches.model';

export interface IBankBranchesState extends EntityState<IBankBranch, string> { }
@Injectable()
@StoreConfig({ name: 'bank-branches' })
export class BankBranchesStore extends EntityStore<IBankBranchesState> {
  constructor() {
    super();
  }
}

@Injectable()
export class BankBranchesQuery extends QueryEntity<IBankBranchesState> {

  banksBranches$: Observable<IBankBranch[]> = this.selectAll();

  constructor(protected override store: BankBranchesStore) {
    super(store);
  }
}
