import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, mapTo, switchMap, tap } from 'rxjs/operators';
import { IIdIssuedCountriesResponseModel } from './id-issued-countries.model';
import { IdIssuedCountriesStore } from './id-issued-countries.store';


@Injectable()
export class IdIssuedCountriesService {

  constructor(
    private _http: HttpClient,
    private _idIssuedCountriesStore: IdIssuedCountriesStore
  ) { }

  getIdIssuedCountries$(): Observable<void> {
    return of(void 0)
      .pipe(
        tap(() => this._idIssuedCountriesStore.setLoading(true)),
        switchMap(() => this._http.get<IIdIssuedCountriesResponseModel[]>('reference/id-issued-countries')),
        catchError(() => {
          this._idIssuedCountriesStore.setLoading(false);
          return EMPTY;
        }),
        tap(countries => {
          this._idIssuedCountriesStore.set(countries
            // .sort((a, b) => a.title.localeCompare(b.title))
            // .map(c => ({ ...c, phoneCode: c.phoneCode.replace('+', '') }))
            // .map(c => ({...c, weight: c.id === 'RUS' ? 1 : 0}))
          );
          tap(() => this._idIssuedCountriesStore.setLoading(false));
        }),
        mapTo(void 0)
      );
  }
}
