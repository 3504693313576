import { FormGroup } from '@angular/forms';
import { BehaviorSubject, EMPTY, merge, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { deepEquals, FFObject } from '@ff/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';


export interface IFormChangeOptions {
  debounceTime: number,
  isNumberControl: boolean,
  isDistinct: boolean,
  /**
   * If new value arrives from a control during an async execution, cancel the execution
   */
  cancelExecutionOnValueChanges: boolean
}

/**
 * Helper class that helps subscribing to form changes and update the root-store and vice versa. Also provides some handy functions
 * to work with reactive forms
 */
@UntilDestroy()
export class FormStoreMapper {


  private readonly _untilDestroyed$: Subject<void>;
  private _disabledControls: Set<string> = new Set<string>();

  private _isWatchingForChanges$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private form: FormGroup, untilDestroyed$: Observable<unknown> | null = null) {
    this._untilDestroyed$ = new Subject<void>();
    if (untilDestroyed$ != null) {
      untilDestroyed$.pipe(
        take(1),
        untilDestroyed(this)
      ).subscribe();
    }
  }

  formChange<T>(formControl: string | string[], actionFunction: (value: T) => Observable<void>, options: Partial<IFormChangeOptions> = {}): void {
    const theOptions: IFormChangeOptions = {
      debounceTime: 0,
      isNumberControl: false,
      isDistinct: false,
      cancelExecutionOnValueChanges: false,
      ...options
    };

    const formControls: string[] = typeof formControl === 'string' ? [formControl] : formControl;
    formControls.forEach(fc => {
      const innerFormControl = this.form.controls[fc];
      if (innerFormControl == null) {
        throw new Error(fc + ' is not found in the formgroup');
      }
      let obs = innerFormControl.valueChanges.pipe(
        startWith(innerFormControl.value as object), // https://github.com/ReactiveX/rxjs/issues/4772
        pairwise(),
        filter(([prev, next]) => prev !== next && this._isWatchingForChanges$$.getValue()),
        map(([, next]) => next)
      );

      if (theOptions.debounceTime > 0) {
        obs = obs.pipe(debounceTime(theOptions.debounceTime));
      }

      obs = obs.pipe(
        map((value): T => options.isNumberControl === true && typeof value === 'string' ? parseFloat(value) : value),
        takeUntil(this._untilDestroyed$),
      );
      if (theOptions.isDistinct) {
        obs = obs.pipe(distinctUntilChanged((x, y) => deepEquals(x, y)));
      }

      obs.pipe(
        switchMap((value: T) => actionFunction(value).pipe(
          take(1),
          // eslint-disable-next-line rxjs/no-unsafe-takeuntil
          takeUntil(merge(
            innerFormControl.valueChanges.pipe(
              filter(() => theOptions.cancelExecutionOnValueChanges)
            ),
            this._untilDestroyed$
          )),
          catchError(() => EMPTY)
        )),
        takeUntil(this._untilDestroyed$),
      ).subscribe();

    });
  }

  /**
   * This is a workaround. Disables formChange subscriptions -> updates form -> enables subscriptions.
   * For some reason { emitEvent: false } doesn't work and a form still emits valueChanges
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patchValue(formValue: { [p: string]: unknown }): void {
    of(0).pipe(
      tap(() => this._isWatchingForChanges$$.next(true)),
      tap(() => {
        const formValueKeys: (string | number)[] = FFObject.keys(formValue);
        formValueKeys.forEach(key => {
          if (this.form.controls[key] == null) {
            console.error(`${key} is not found in form`);
            return;
          }
          const formControlValue: unknown = this.form.controls[key]?.value;
          const fromParamValue: unknown = formValue[key];
          if (formControlValue !== fromParamValue) {
            const formControl = this.form.controls[key];
            if (formControl == null) {
              throw new Error(key + ' not found in the form group');
            }
            formControl.patchValue(fromParamValue, { emitEvent: false });
          }
        });
      }),
      delay(0),
      tap(() => this._isWatchingForChanges$$.next(true))
    ).subscribe();
  }

  /**
   * Subscribes 'disabled' state to FromGroup or particular mat-customized depending on Observable
   * @param controlName name of the control in the FormGroup. If equals null, then the whole FormGroup state will be set.
   * @param disabledFunc Observable which disabled state depends on
   * @param emitEvent emit event on control state change
   * @example this.formMapper.setDisabled(['firstName', 'lastName'], this.isLoading$);
   */
  public setDisabled(controlName: string | string[] | null, disabledFunc: Observable<boolean>, emitEvent: boolean = false): void {
    // check if control has a rule to be disabled
    if (controlName != null) {
      const controlNames: string[] = typeof controlName === 'string' ? [controlName] : [...controlName];
      const controlsExist: string[] = controlNames.filter(name => this._disabledControls.has(name));
      if (controlsExist.length > 0) {
        throw new Error(`Controls [${controlsExist.join(', ')}] already have disabled rules`);
      }

      controlNames.forEach(name => this._disabledControls.add(name));
    }

    // main logic
    disabledFunc.pipe(
      distinctUntilChanged(),
      tap(disabled => {
        if (controlName == null) {
          disabled ? this.form.disable({ emitEvent }) : this.form.enable({ emitEvent });
          return;
        }
        const strSet: Set<string> = new Set<string>();
        typeof controlName === 'string' ? strSet.add(controlName) : controlName.forEach(x => strSet.add(x));
        for (const name of strSet.keys()) {
          if ((name in this.form.controls) === false) {
            throw new Error(name + ' not found in form group');
          }
          disabled ? this.form.controls[name]?.disable({ emitEvent }) : this.form.controls[name]?.enable({ emitEvent });
        }
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  /**
   * Marks all form properties as touched and dirty to show validation errors
   */
  public markAsTouchedAndDirty(): void {
    FormStoreMapper.markAsTouchedAndDirty(this.form);
  }

  public destroy(): void {
    this._untilDestroyed$.next(void 0);
    this._untilDestroyed$.complete();
  }

  /**
   * Marks all form properties as touched and dirty to show validation errors
   * @param group any not nested form group
   */
  public static markAsTouchedAndDirty(group: FormGroup): void {
    group.markAsTouched();
    group.markAsDirty();
    FFObject.keys(group.controls)
      .forEach(controlName => {
        const control = group.get(controlName?.toString());

        if (control == null) {
          throw new Error(`control not found! controlName: ${controlName}`);
        }

        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });
      });
  }
}
