import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { SnackbarService } from '../../snackbars/snackbar.service';
import { LocalizationQuery } from '@ff/localization';

@Injectable()
export class ErrorsSnackbarInterceptor implements HttpInterceptor {

  constructor(
    private _snackbarService: SnackbarService,
    private _localizationQuery: LocalizationQuery
  ) { }

  intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {
    return next.handle(req).pipe(
      catchError((errorMessage: HttpErrorResponse) => {
        return this.showErrorSnackbar$$(errorMessage);
      })
    );
  }

  showErrorSnackbar$$(errorMessage: HttpErrorResponse): Observable<HttpEvent<string>> {
    return this._snackbarService.openFailure$(this.parseServerError(errorMessage),
      { duration: environment.appSettings.snackbar.duration }).pipe(
      concatMap(() => throwError(errorMessage))
    );
  }

  parseServerError(errorMessage: HttpErrorResponse): string {
    if (errorMessage != null) {
      if (errorMessage?.error != null && errorMessage.error?.errors != null && errorMessage.error?.errors.length > 0) {
        return errorMessage.error.errors[0].message;
      }
      return errorMessage.message;
    } else {
      const message = this._localizationQuery.transform('%[stored-procedure.error.unknown-error]%');
      return message;
    }
  }
}
