import { NgModule } from '@angular/core';
import { DialogService } from './dialog.service';
import { ChangeCountryComponent } from './change-country/change-country.component';
import { PrepareForPaymentComponent } from './prepare-for-payment/prepare-for-payment.component';
import { DocumentViewerComponent } from './document-viewer/document-viewer.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { SendCheckComponent } from './send-check/send-check.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OverlayModule } from '@angular/cdk/overlay';
import { TextMaskModule } from 'angular2-text-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocationsComponent } from './locations/locations.component';
import { LocationsGridComponent } from './locations/locations-grid/locations-grid.component';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { ClockSpinnerModule } from '../../root/private/private-components/clock-spinner/clock-spinner.module';
import { ImageUploadModule } from '../../root/private/private-components/image-upload/image-upload.module';
import { ProveYourIdentityComponent } from './flag-related/prove-your-identity/prove-your-identity.component';
import { FFDateModule } from '../pipes/ff-date/ff-date.module';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ServiceDeniedComponent } from './flag-related/service-denied/service-denied.component';
import { ServiceDeniedStrictComponent } from './flag-related/service-denied-strict/service-denied-strict.component';
import { ReadonlyOnlineComponent } from './flag-related/readonly-online/readonly-online.component';
import { RateHasChangedComponent } from './rate-has-changed/rate-has-changed.component';
import { LocalizationModule } from '@ff/localization';
import {
  ImageModalService
} from '../../root/private/private-pages/profile/additional-documents/dialogs/image-modal/image-modal.service';
import { LogoutDialogComponent } from './logout-dialog/logout-dialog.component';
import { SvgModule } from '../../root/components/svg/svg.module';
import { FfSlideToggleModule } from '../form-fields/ff-slide-toggle/ff-slide-toggle.module';
import { FfButtonToggleGroupModule } from '../form-fields/ff-button-toggle-group/ff-button-toggle-group.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { SpinnerModule } from '../../root/components/spinner/spinner.module';
import {
  ProvideKycQuestionnaireComponent
} from './flag-related/provide-kyc-questionnaire/provide-kyc-questionnaire.component';
import {
  IdentityOnVerificationComponent
} from './flag-related/identity-on-verification/identity-on-verification.component';
import { EmailVerificationComponent } from './flag-related/email-verification/email-verification.component';
import { WizardAwaitingComponent } from './flag-related/wizard-awaiting/wizard-awaiting.component';
import { IdenfyFrameModule } from './identify-iframe/idenfy-frame.module';
import {
  FFSelectInputModule,
  FFLocalizedMatErrorModule,
} from '@ff/material-controls';
import { FFTextInputModule } from '@ff/material-controls';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LocalizationModule,
    MatButtonModule,
    MatCheckboxModule,
    FFTextInputModule,
    MatDialogModule,
    MatTooltipModule,
    CommonModule,
    OverlayModule,
    TextMaskModule,
    ClockSpinnerModule,
    SpinnerModule,
    ImageUploadModule,
    FFDateModule,
    MatRadioModule,
    MatFormFieldModule,
    FFLocalizedMatErrorModule,
    MatInputModule,
    SvgModule,
    FfSlideToggleModule,
    FfButtonToggleGroupModule,
    MatExpansionModule,
    FFSelectInputModule,

    IdenfyFrameModule
  ],
  exports: [
    IdenfyFrameModule
  ],
  declarations: [
    ChangeCountryComponent,
    PrepareForPaymentComponent,
    DocumentViewerComponent,
    InfoDialogComponent,
    SendCheckComponent,
    ConfirmDialogComponent,

    LocationsComponent,
    LocationsGridComponent,
    LogoutDialogComponent,

    QrCodeComponent,
    ProveYourIdentityComponent,
    ServiceDeniedComponent,
    ServiceDeniedStrictComponent,
    ReadonlyOnlineComponent,
    RateHasChangedComponent,
    ProvideKycQuestionnaireComponent,
    IdentityOnVerificationComponent,
    EmailVerificationComponent,
    WizardAwaitingComponent
  ],
  providers: [
    DialogService,
    ImageModalService
  ]
})
export class DialogModule {}
