import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LanguageCode, LanguagesQuery } from '@ff/localization';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
  constructor(private _languagesQuery: LanguagesQuery) {}

  intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {
    const languageCode: LanguageCode | null = this._languagesQuery.getValue().active;

    if (languageCode != null && languageCode.trim() !== '') {
      req = req.clone({
        setHeaders: {
          lang: languageCode,
        }
      });
    }

    return next.handle(req);
  }
}
