import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ILookupStr } from '../../root/private/private-models/lookup-str';
import { Observable } from 'rxjs';

export interface IDirectionCitiesState extends EntityState<ILookupStr, string> {
  filter: string,
  offset: number,
  totalCount: number
}

const idKey: keyof ILookupStr = 'title';

@Injectable()
@StoreConfig({ idKey, name: 'direction-cities' })
export class DirectionCitiesStore extends EntityStore<IDirectionCitiesState> {
  constructor() {
    super({ filter: 'ALL', offset: 0, totalCount: 0 });
  }
}

@Injectable()
export class DirectionCitiesQuery extends QueryEntity<IDirectionCitiesState> {

  state$$: Observable<ILookupStr[]> = this.selectAll();
  offset$$: Observable<number> = this.select(x => x.offset);
  totalResult$$: Observable<number> = this.select(x => x.totalCount);
  isLoading$$: Observable<boolean> = this.selectLoading();

  constructor(protected override store: DirectionCitiesStore) {
    super(store);
  }
}
