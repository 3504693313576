import { Injectable } from '@angular/core';
import { Query, Store, StoreConfig } from '@datorama/akita';

export interface IAuthState {
  loginOrPhone: string,
  password: string,
  approvalCodeAwaiting: boolean,
  changePassApprovalCodeAwaiting: boolean,
  loading: boolean,
  isShowCompleteTransferScreen: boolean,
  isForgotPassword: boolean
}

function createInitialState(): IAuthState {
  return {
    loginOrPhone: '',
    loading: false,
    password: '',
    approvalCodeAwaiting: false,
    changePassApprovalCodeAwaiting: false,
    isShowCompleteTransferScreen: false,
    isForgotPassword: false
  };
}
@Injectable()
@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<IAuthState> {
  constructor() {
    super(createInitialState());
  }

}

@Injectable()
export class AuthQuery extends Query<IAuthState> {

  constructor(protected override store: AuthStore) {
    super(store);
  }
}
