import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {
  IGetSumsubTokenResponse,
  ISumsubTokenRequest
} from './sumsub.model';
import { SumsubWebsdkComponent } from './sumsub-websdk.component';
import { MatDialog } from '@angular/material/dialog';
import { LanguageCode, LanguagesQuery } from '@ff/localization';
import { DocDefinitions } from '@sumsub/websdk/types/types';

@Injectable()
export class SumsubService {
  private _isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _isIdWasScanned$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _selectedLanguage$$: Observable<string> = this._languagesQuery.selectActive().pipe(
    take(1),
    map(active => active?.id),
    map((localeCode: LanguageCode | undefined) => {
      if (localeCode == null) { return ''; }
      const [one, two] = localeCode.split('-');
      return [one, two?.toUpperCase() ?? ''].join('-');
    })
  );

  constructor(
    private _http: HttpClient,
    private _dialogController: MatDialog,
    private _languagesQuery: LanguagesQuery
  ) { }

  updateLoading(isLoading: boolean): void {
    this._isLoading$$.next(isLoading);
  }

  getLoading$(): Observable<boolean> {
    return this._isLoading$$.asObservable();
  }

  updateIsIdWasScanned(isIdWasScanned: boolean): void {
    this._isIdWasScanned$.next(isIdWasScanned);
  }

  getIsIdWasScanned$(): Observable<boolean> {
    return this._isIdWasScanned$.asObservable();
  }

  getSumsubToken$(params: ISumsubTokenRequest): Observable<string> {
    return this._http.get<IGetSumsubTokenResponse>('scan-verify/sumsub-token/' + params.documentId).pipe(
      map((response: IGetSumsubTokenResponse): string => response.token)
    );
  }

  openSumsubWebsdk$(token: string, documentDefinitions: DocDefinitions): Observable<void> {
    return this._selectedLanguage$$.pipe(
      mergeMap(lang => {
        const language = lang.split('-')[0] ?? '';
        return this._dialogController
          .open(SumsubWebsdkComponent, {
            data: { token, language, documentDefinitions },
            autoFocus: false,
            disableClose: true,
            width: '100%',
            maxWidth: 752
          })
          .afterClosed();
      })
    );
  }
}
