import { NgModule } from '@angular/core';

import { TransferPurposesService } from './transfer-purposes.service';
import { TransferPurposesQuery, TransferPurposesStore } from './transfer-purposes.store';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    TransferPurposesService,
    TransferPurposesStore,
    TransferPurposesQuery,
  ],
})
export class TransferPurposesModule {}
