import { Injectable } from '@angular/core';
import { ReceiverEditQuery, ReceiverEditStore } from './receiver-edit.store';
import { EMPTY, Observable, of } from 'rxjs';
import { IReceiverDetailDto } from './receivers.store';
import { HttpClient } from '@angular/common/http';
import { catchError, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { logAction } from '@datorama/akita';
import { ReceiversService } from './receivers.service';

@Injectable()
export class ReceiverEditService {
  constructor(
    private _receiverEditStore: ReceiverEditStore,
    private _receiverEditQuery: ReceiverEditQuery,
    private _storeReceivers: ReceiversService,
    private _http: HttpClient,
  ) {}

  setOriginalReceiver$$(receiver: IReceiverDetailDto | null): Observable<void> {
    return of(void 0)
      .pipe(
        tap(() => this._receiverEditStore.update(() => ({ receiver, originalReceiver: receiver }))),
        mapTo(void 0)
      );
  }

  updateReceiver(state: Partial<IReceiverDetailDto>, logActionText?: string): void {
    const currentState = this._receiverEditStore.getValue();
    if (currentState.receiver == null) { return; }
    let receiver: IReceiverDetailDto = currentState.receiver;
    receiver = { ...receiver, ...state };
    if (logActionText != null) { logAction(logActionText); }
    this._receiverEditStore.update(st => ({ ...st, receiver }));
  }

  saveReceiver$(): Observable<void> {
    return this._receiverEditQuery.receiver$$.pipe(
      take(1),
      tap(() => this._receiverEditStore.setLoading(true)),
      switchMap(receiver => this._http.post<IReceiverDetailDto>('receivers/update', receiver)),
      catchError(() => {
        this._receiverEditStore.setLoading(false);
        return EMPTY;
      }),
      tap((receiver) => {
        this._storeReceivers.updateActiveReceiver(receiver);
        this._receiverEditStore.setLoading(false);
      }),
      mapTo(void 0)
    );
  }

  resetStore(): void {
    this._receiverEditStore.reset();
  }
}
