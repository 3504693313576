<mat-dialog-content class="mat-dialog-content-max-height-none position-relative">
  <div *ngIf="hasSafeUrl$$ | async" style="height: 60vh; width: 100%">
    <iframe [src]="safeUrl$$ | async" id="iframe" allowfullscreen class="w-100 h-100 d-block" allow="camera"></iframe>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <ng-container *ngIf="hasIdenfyResult$$ | async; else closeBtn">
    <button mat-flat-button color="primary" cdkFocusInitial (click)="onCloseClick()">
      {{ '%[info-dialog.button.continue]%' | tr }}
    </button>
  </ng-container>
  <ng-template #closeBtn>
    <button mat-flat-button color="primary" (click)="onCloseClick()">
      {{ '%[info-dialog.button.close]%' | tr }}
    </button>
  </ng-template>
</mat-dialog-actions>
