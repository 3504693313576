import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-change-country',
  styleUrls: ['change-country.component.scss'],
  templateUrl: 'change-country.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ChangeCountryComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { },
    private _dialogRef: MatDialogRef<ChangeCountryComponent>,
  ) { }

  onCloseClick(userDecision: boolean): void {
    this._dialogRef.close(userDecision);
  }
}
