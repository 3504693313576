import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withTransaction } from '@datorama/akita';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, mapTo, switchMap, take } from 'rxjs/operators';
import { CurrenciesDestinationStore } from './currencies-destination.store';
import { CalculatorStore } from '../calculator/calculator.store';
import { ICurrenciesDestinationResponseModel } from './currencies-destination.model';

@Injectable()
export class CurrenciesDestinationService {

  constructor(
    private _currencyDestinationStore: CurrenciesDestinationStore,
    private _http: HttpClient,
    private _calculatorStore: CalculatorStore
  ) { }

  getCurrenciesDestination$(sourceCurrencyId: number, directionId: string | null): Observable<void> {

    if (directionId == null) {
      return EMPTY;
    }

    return of(void 0).pipe(
      withTransaction(() => {
        this._currencyDestinationStore.reset();
        this._currencyDestinationStore.setLoading(true);
      }),
      switchMap(() => this._http.get<ICurrenciesDestinationResponseModel[]>('reference/currencies-destination',
        { params: { directionId, sourceCurrency: sourceCurrencyId?.toString(10) } })),
      catchError(() => {
        this._currencyDestinationStore.setLoading(false);
        return EMPTY;
      }),
      take(1),
      withTransaction(currencies => {
        this._currencyDestinationStore.setLoading(false);
        this._currencyDestinationStore.upsertMany(currencies);
      }),
      withTransaction(currencies => this._calculatorStore.update(x => ({
        ...x,
        destinationCurrency: currencies[0]
      }))),
      mapTo(void 0)
    );
  }
}
