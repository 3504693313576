import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map, mapTo, switchMap, take, tap } from 'rxjs/operators';

import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { combineQueries, withTransaction } from '@datorama/akita';
import { ProfileStore } from '../../../private-store/profile.store';
import { PersonalDataFormQuery, PersonalDataFormStore } from './personal-data.store';
import { DialogService } from '../../../../../functionality-modules/dialog-windows/dialog.service';
import { IPersonalFormData } from './personal-data.model';
import { IPersonalDataFormRequest, ISuccessfulSavingResponse } from '../../../private-models/document.model';
import { ILookupStr } from '../../../private-models/lookup-str';
import { ProfileService } from '../../../private-store/profile.service';
import { IdentityDocumentsStore } from '../identity-documents/store/identity-documents.store';
import {
  ResidenceConfirmationsQuery,
  ResidenceConfirmationsStore
} from '../additional-documents/store/residence-confirmations.store';
import { SourceOfIncomeStore } from '../additional-documents/store/source-of-income.store';
import { AdditionalDocumentsService } from '../additional-documents/additional-documents.service';
import { FreshchatService } from '../../../../../functionality-modules/freshchat/freshchat.service';

@Injectable()
export class PersonalDataFormService {

  constructor(
    private _http: HttpClient,
    private _profileStore: ProfileStore,
    private _personalDataFormStore: PersonalDataFormStore,
    private _freshChatService: FreshchatService,
    private _personalDataFormQuery: PersonalDataFormQuery,
    private _identityDocumentsStore: IdentityDocumentsStore,
    private _profileService: ProfileService,
    private _residenceConfirmationsQuery: ResidenceConfirmationsQuery,
    private _dialogService: DialogService,
    private _sourceOfIncomeStore: SourceOfIncomeStore,
    private _residenceConfirmationsStore: ResidenceConfirmationsStore,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _additionalDocumentService: AdditionalDocumentsService
  ) { }

  save$(body: IPersonalFormData): Observable<void> {
    return of(void 0)
      .pipe(
        tap(() => {
          this._googleAnalyticsService.event('save-personal-data-click', '', '');
        }),
        switchMap(() => this.warnOnCountryChange$$(body)),
        tap(() => this._profileStore.setLoading(true)),
        switchMap(() => this._http.post<ISuccessfulSavingResponse>('profile/personal', <IPersonalDataFormRequest>body)),
        catchError(() => {
          this._profileStore.setLoading(false);
          return EMPTY;
        }),
      )
      .pipe(
        map((response: ISuccessfulSavingResponse): IPersonalFormData => ({ ...body, stamp: response.stamp })),
        withTransaction(() => {
          this._identityDocumentsStore.reset();
          this._sourceOfIncomeStore.reset();
          this._residenceConfirmationsStore.reset();
        }),
        switchMap(() => this._profileService.updateProfile$()),
        // recreate chat with names
        switchMap(() => this._freshChatService.createChat$()),
        tap(() => {
          this._googleAnalyticsService.event('save-personal-data-success', '', '');
        }),
      )
      .pipe(
        switchMap(() => {
          return this._additionalDocumentService.wizardAfterEffects$(false);
        }),
      )
      .pipe(
        mapTo(void 0)
      );
  }

  setField$$(fieldName: keyof IPersonalFormData, value: string | ILookupStr | boolean | Date | null): Observable<void> {
    return of(void 0).pipe(
      tap(() => {
        this._personalDataFormStore.update(x => {
          const newDraft: IPersonalFormData = { ...x.draft };
          // @ts-ignore todo: fix this logic
          newDraft[fieldName] = value;
          return { ...x, draft: newDraft };
        });
      })
    );
  }

  setDraftPartial$(partial: Partial<IPersonalFormData>): Observable<void> {
    return of(void 0).pipe(
      tap(() => this._personalDataFormStore.update(x => ({ ...x, draft: { ...x.draft, ...partial } })))
    );
  }

  warnOnCountryChange$$(body: IPersonalFormData): Observable<void> {
    return of(void 0)
      .pipe(
        switchMap(() => combineQueries([
          this._personalDataFormQuery.select(x => x.current.country?.id)
            .pipe(map((id: string | undefined) => id == null ? false : id !== body.country?.id)),
          this._residenceConfirmationsQuery.selectAll().pipe(map(rcs => rcs.length > 0)),
        ])),
        take(1),
        switchMap(([
                     isCountryChanging,
                     hasRC,
                   ]) => {
          if (isCountryChanging && hasRC) {
            return this._dialogService.openCountryChange$()
              .pipe(
                switchMap((result: boolean) => result ? of(void 0) : EMPTY)
              );
          }
          return of(void 0);
        }),
        mapTo(void 0)
      );
  }
}
