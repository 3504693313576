import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { SvgService } from './svg.service';
import { nextOnChange } from '@ff/utils';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-img-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgComponent implements OnInit, OnChanges {
  private _url$$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  @Input() url: string = '';
  svg$: Observable<SafeHtml>;

  constructor(private svgService: SvgService) { }

  ngOnChanges(changes: SimpleChanges): void {
    nextOnChange(changes.url, this._url$$);
  }

  ngOnInit(): void {
    this.svg$ = this._url$$.pipe(
      mergeMap((url: string) => this.svgService.getSvgImage$(url))
    );
  }

}
