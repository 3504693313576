<mat-dialog-content>
  <div class="row">
    <div class="col-12">
      <p mat-dialog-title>{{ '%[payment-summary.send-to-email-dialog.title]%' | tr }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ff-text-input
        class="w-100"
        preventInputType="email"
        [label]="'%[payment-summary.send-to-email-dialog.placeholder]%' | tr"
        [formControl]="control"
      ></ff-text-input>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="mat-dialog-actions-pt-1">
  <div class="row flex-grow-1">
    <div class="col-6">
      <button class="btn-block" mat-stroked-button (click)="onNoClick()">
        {{ '%[payment-summary.send-to-email-dialog.button.cancel]%' | tr }}
      </button>
    </div>
    <div class="col-6">
      <button
        class="btn-block"
        mat-flat-button
        color="primary"
        (click)="onSaveClick()"
        cdkFocusInitial
        [disabled]="control.invalid"
      >
        {{ '%[payment-summary.send-to-email-dialog.button.send]%' | tr }}
      </button>
    </div>
  </div>
</mat-dialog-actions>
