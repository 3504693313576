import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizationQuery } from '@ff/localization';
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { from, Observable, of, throwError } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { catchError, mapTo, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../../../../../environments/environment';
import { PersistenceQuery } from '../../../../../../../data-modules/persist/persist.store';
import { SnackbarService } from '../../../../../../../functionality-modules/snackbars/snackbar.service';
import { IApiErrorLine } from '../../../../../private-models/api-error';
import { ProfileService } from '../../../../../private-store/profile.service';
import { SendStore } from '../../../store/send.store';
import { FfpgErrorHelper } from './ffpg-error.helper';

@Injectable()
export class FFPGSignalRService {
  private readonly _hubConnection: HubConnection;

  constructor(
    private _sendStore: SendStore,
    private _router: Router,
    private _snackbarService: SnackbarService,
    private _localizationQuery: LocalizationQuery,
    private _profileService: ProfileService,
    private _persistQuery: PersistenceQuery,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _ffpgErrorHelper: FfpgErrorHelper
  ) {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(`${environment.webSocketHostOrigin}/paymenthub`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .configureLogging(LogLevel.Information)
      .build();
  }

  startConnection$(transactionId: string | null): Observable<void> {
    if (transactionId != null) {
      return from(this._hubConnection.start().catch((e) => throwError(e))).pipe(
        switchMap(() => {
          if (this._hubConnection.state === 'Connected') {
            return this._hubConnection.invoke(
              'createconnection',
              transactionId,
              this._persistQuery.getValue().accessToken
            );
          }
          return throwError('FFPG Hub is disconnected');
        }),
        tap(() => {
          this._addPaymentCompleteListener();
          this._addFailPaymentListener();
        }),
        catchError(() => {
          return of(0);
        }),
        mapTo(void 0)
      );
    }
    return of(void 0);
  }

  stopConnection(): void {
    if (this._hubConnection != null) {
      this._hubConnection.stop().then();
    }
  }

  private _addPaymentCompleteListener(): void {
    this._hubConnection.on('paymentcomplete', (data) => {
      this._sendStore.setLoading(false);
      fromPromise(this._router.navigate(['private', 'receipt', data.transactionId]))
        .pipe(
          tap(() => {
            this._googleAnalyticsService.event('transfer_success', '', '');
            this.stopConnection();
          }),
          switchMap(() => this._profileService.updateProfile$(false))
        )
        .subscribe();
    });
  }

  private _addFailPaymentListener(): void {
    this._hubConnection.on('failpayment', (data) => {
      this._googleAnalyticsService.event('transfer_fail', '', '');
      this._sendStore.setLoading(false);
      let errorMessage = this._localizationQuery.transform('%[send.snackbar.error.transaction-commit]%');
      if (data?.errors != null) {
        const errors = data.errors as IApiErrorLine[];
        errorMessage = errors?.map((er) => this._getLocalizedErrorMessageByCode(er)).join('\r\n');
      }
      if (data?.error?.code != null) {
        errorMessage = this._ffpgErrorHelper.getLocalizedError(data.error.code);
      }
      if (data?.transactionId != null) {
        this._router
          .navigate(['private', 'receipt', data.transactionId])
          .then(() => this._showError(errorMessage));
      } else {
        // eslint-disable-next-line no-restricted-globals
        setTimeout(() => {
          this._router.navigate(['private', 'history']).then(() => this._showError(errorMessage));
        }, 2000);
      }
    });
  }

  private _showError(errorMessage: string): void {
    this._snackbarService
      .openFailure$(errorMessage, {
        title: this._localizationQuery.transform('%[send.snackbar.start-failed]%'),
        duration: environment.appSettings.snackbar.duration,
      })
      .subscribe();
    this.stopConnection();
  }

  private _getLocalizedErrorMessageByCode(error: IApiErrorLine): string {
    if (error.code != null) {
      let locKey: string;
      switch (error.code) {
        case 2: {
          locKey = '%[send.secure-trading.error.card-holder-mismatch]%';
          break;
        }
        default:
          locKey = '%[send.snackbar.error.transaction-commit]%';
      }
      return this._localizationQuery.transform(locKey);
    }

    return error.message;
  }
}
