import { Injectable } from '@angular/core';
import { IUtmMarksModel } from './utm-marks.model';
import { UtmMarksStore } from './utm-marks.store';

@Injectable()
export class UtmMarksService {

  constructor(
    private _utmMarkStore: UtmMarksStore
  ) { }

  updateUtmMarks(value: IUtmMarksModel): void {
    this._utmMarkStore.update(st => ({ ...st, data: { ...value } }));
  }
}
