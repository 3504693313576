import { Injectable } from '@angular/core';
import { Query, Store, StoreConfig } from '@datorama/akita';
import { ISaveTemplatePanelForm } from './save-template-panel.model';
import { Observable } from 'rxjs';

export function createInitialState(): ISaveTemplatePanelForm {
  return {
    saveAsTemplate: false,
    templateName: ''
  };
}

@StoreConfig({ name: 'save-template-panel-form' })
export class SaveTemplatePanelStore extends Store<ISaveTemplatePanelForm> {
  constructor() {
    super(createInitialState());
  }
}

@Injectable()
export class SaveTemplatePanelQuery extends Query<ISaveTemplatePanelForm> {

  isSaveAsTemplate$$: Observable<boolean> = this.select(x => x.saveAsTemplate);
  templateName$$: Observable<string> = this.select(x => x.templateName);

  constructor(protected override store: SaveTemplatePanelStore) {
    super(store);
  }
}
