import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mapTo, switchMap } from 'rxjs/operators';
import { IBankBranchResponseModel } from './bank-branches.model';
import { BankBranchesStore } from './bank-branches.store';
import { withTransaction } from '@datorama/akita';

@Injectable()
export class BankBranchesService {

  constructor(
    private _http: HttpClient,
    private _bankBranchesStore: BankBranchesStore,
  ) { }

  getBankBranches$(directionId: string, bankCode: string): Observable<void> {
    return of(0).pipe(
      withTransaction(() => {
        this._bankBranchesStore.reset();
        this._bankBranchesStore.setLoading(true);
      }),
      switchMap(() => this._http.get<IBankBranchResponseModel[]>('reference/bank-branch', { params: { directionId, bankCode } })),
      withTransaction((bankBranches: IBankBranchResponseModel[]) => {
        this._bankBranchesStore.setLoading(false);
        if (bankBranches.length > 0) {
          this._bankBranchesStore.upsertMany(bankBranches);
        } else {
          this._bankBranchesStore.upsertMany([]);
        }
      }),
      catchError(err => {
        this._bankBranchesStore.setLoading(false);
        return throwError(err);
      }),
      mapTo(void 0)
    );
  }
}
