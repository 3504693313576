import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface IMessage { idenfyAlias: string, localizationKey: string, isGoodStatus: boolean }

@Component({
  selector: 'app-identify-result-dialog',
  styleUrls: ['identify-result-dialog.component.scss'],
  templateUrl: 'identify-result-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdentifyResultDialogComponent {

  // public message$: Observable<string> = combineLatest([
  //   this.idenfyResultsQuery.MESSAGES$,
  //   of(this.data.message)
  // ])
  //   .pipe(
  //     map(([msgs, result]): IMessage | undefined => msgs.find(x => x.idenfyAlias === result)),
  //     map((msg: IMessage) => msg == null ? 'EMPTY' : msg.localizationKey),
  //     map((s: string) => this.localizationQuery.transform(s))
  //   );

  constructor(

    // private idenfyResultsQuery: IdenfyResultsQuery,
    @Inject(MAT_DIALOG_DATA)
    public data: { message: string },
    public dialogRef: MatDialogRef<IdentifyResultDialogComponent>,
  ) { }
}
