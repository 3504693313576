import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mapTo, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ResidenceCountriesStore } from './residence-countries.store';
import { IIdIssuedCountriesResponseModel } from '../id-issued-countries/id-issued-countries.model';

@Injectable()
export class ResidenceCountriesService {

  constructor(
    private _residenceCountriesStore: ResidenceCountriesStore,
    private _http: HttpClient
  ) { }

  getResidenceCountries$(): Observable<void> {
    return of(void 0)
      .pipe(
        switchMap(() => this._http.get<IIdIssuedCountriesResponseModel[]>('reference/residence-countries')),
        tap(countries => {
          this._residenceCountriesStore.set(countries
            // .sort((a, b) => a.title.localeCompare(b.title))
            // .map(c => ({ ...c, phoneCode: c.phoneCode.replace('+', '') }))
            // .map(c => ({...c, weight: c.id === 'RUS' ? 1 : 0}))
          );
        }),
        mapTo(void 0)
      );
  }
}
