import { NgModule } from '@angular/core';
import { RequiredDocumentsService } from './required-documents.service';
import { RequiredDocumentsQuery, RequiredDocumentsStore } from './required-documents.store';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [RequiredDocumentsService, RequiredDocumentsStore, RequiredDocumentsQuery],
})
export class RequiredDocumentsModule {}
