import { Query, Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IDeviceInfo } from './device-info.model';
import { Observable } from 'rxjs';

export function createInitialState(): IDeviceInfo {
  return {
    browser: '',
    browserVersion: '',
    device: '',
    os: '',
    osVersion: '',
    userAgent: '',
    deviceId: '',
    fingerprint: ''
  };
}

@Injectable()
@StoreConfig({ name: 'device-info' })
export class DeviceInfoStore extends Store<IDeviceInfo> {

  constructor() {
    super(createInitialState());
  }

  setDeviceInfo(info: IDeviceInfo): void {
    this.update(state => ({ ...state, ...info }));
  }
}

@Injectable()
export class DeviceInfoQuery extends Query<IDeviceInfo> {

  deviceId$$: Observable<string> = this.select(x => x.deviceId);

  constructor(protected override store: DeviceInfoStore) {
    super(store);
  }
}
