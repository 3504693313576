import { EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { combineLatest, isObservable, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { IAuthenticationCountries2State, IAuthenticationCountry } from './auth-countries.models';
import { removeNonDigits } from '../../utils/functions/remove-non-digits';

const idKey: keyof IAuthenticationCountry = 'id';
@Injectable()
@StoreConfig({ name: 'authentication-countries', idKey })
export class AuthenticationCountriesStore extends EntityStore<IAuthenticationCountries2State> {
  constructor() {
    super();
  }
}

@Injectable()
export class AuthenticationCountriesQuery extends QueryEntity<IAuthenticationCountries2State> {

  /**
   * Collection { phoneCode: string, country: IAuthCountryStore}[] ordered by phoneCode length desc for easier country search
   * @example
   *  { phoneCode: 68974, country: { ... name: 'Some country with longest code' }}
   *  { phoneCode: 77, country: { ... name: 'Kazakhstan' }}
   *  { phoneCode: 76, country: { ... name: 'Kazakhstan' }}
   *  { phoneCode: 7, country: { ... name: 'Russia' }}
   */
  private _phoneCodeCounties$$: Observable<{ phoneCode: string, country: IAuthenticationCountry}[]> = this.selectAll().pipe(
    filter(arr => arr.length > 0),
    // select collection (unordered items)
    map(countries => {
      const result: { phoneCode: string, country: IAuthenticationCountry}[] = [];
      for (const country of countries) {
        for (const phoneCode of country.phoneCodes) {
          result.push({ phoneCode, country });
        }
      }
      return result;
    }),
    // order collection by phoneCode length
    map(countryArr => [...countryArr].sort((a, b) => b.phoneCode.length - a.phoneCode.length))
  );

  /**
   * all countries ordered by name
   */
  countries$$: Observable<IAuthenticationCountry[]> = this.selectAll({ sortBy: 'title' });

  constructor(protected override store: AuthenticationCountriesStore) {
    super(store);
  }

  /**
   * Calculates current auth country depending on the phone value
   * @param phone phone string or observable with phone value
   */
  getAuthCountryByPhone$$(phone: string | Observable<string>): Observable<IAuthenticationCountry | null> {
    const phone$$ = isObservable(phone) ? phone : of(phone);
    return combineLatest([phone$$, this._phoneCodeCounties$$]).pipe(
      map(([ph, codesWithCountry]) => {
        const cleanPhone = removeNonDigits(ph);
        const countryData = codesWithCountry.find(cwc => cleanPhone.startsWith(cwc.phoneCode)) ?? null;
        return countryData?.country ?? null;
      })
    );
  }
}
