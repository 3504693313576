import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageModalService } from './image-modal.service';
import { IAttachment } from '../../../../../private-models/attachment.model';

@Component({
  selector: 'app-image-modal',
  templateUrl: 'image-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ImageModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { attachment: IAttachment },
    public imageModalService: ImageModalService,
    public dialogRef: MatDialogRef<ImageModalComponent>
  ) { }

  ngOnInit(): void {
    if (this.data.attachment != null) {
      this.imageModalService.isModalEdit$.next(true);
      this.imageModalService.bufferedImage$.next(this.data.attachment);
      this.imageModalService.flowType$.next('file');
    } else {
      this.imageModalService.isModalEdit$.next(false);
      this.imageModalService.flowType$.next('camera');
    }
  }

  onConfirmEvent(att: IAttachment): void {
    this.imageModalService.isModalEdit$.next(false);
    this.imageModalService.bufferedImage$.next(null);
    this.dialogRef.close(att);
  }

  onCancelEvent(): void {
    this.imageModalService.isModalEdit$.next(false);
    this.imageModalService.bufferedImage$.next(null);
    this.dialogRef.close();
  }
}
