import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeviceInfoService } from '../../../data-modules/device-info/device-info.service';

@Injectable()
export class DeviceIdInterceptor implements HttpInterceptor {
  constructor(private _deviceInfoService: DeviceInfoService) {}

  intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {
    const deviceId = this._deviceInfoService.deviceInfoQuery.getValue().deviceId;
    const fingerprint = 'fingerprint_placeholder';

    if (deviceId != null && deviceId.trim() !== '') {
      req = req.clone({
        setHeaders: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'finflow-device-id': deviceId,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'finflow-fingerprint': fingerprint,
        },
      });
    }

    return next.handle(req);
  }
}
