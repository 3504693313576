import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ILookupInt } from '../../root/private/private-models/lookup-str';

export interface ICurrenciesSourceState extends EntityState<ILookupInt, string> {
  filter: string
}

const idKey: keyof ILookupInt = 'id';

@Injectable()
@StoreConfig({ name: 'currencies-source', idKey })
export class CurrenciesSourceStore extends EntityStore<ICurrenciesSourceState> {
  constructor() {
    super({ filter: 'ALL', loading: false });
  }
}

@Injectable()
export class CurrenciesSourceQuery extends QueryEntity<ICurrenciesSourceState> {
  currenciesSource$$: Observable<ILookupInt[]> = this.selectAll();
  loading$$: Observable<boolean> = this.selectLoading();

  constructor(protected override store: CurrenciesSourceStore) {
    super(store);
  }
}
