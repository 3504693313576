import { NgModule } from '@angular/core';
import { IdIssuedCountriesQuery, IdIssuedCountriesStore } from './id-issued-countries.store';
import { IdIssuedCountriesService } from './id-issued-countries.service';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    IdIssuedCountriesStore,
    IdIssuedCountriesService,
    IdIssuedCountriesQuery,
  ],
})
export class IdIssuedCountriesModule {}
