import { ILookupStr } from '../../root/private/private-models/lookup-str';
import { IRemittanceCountry } from '../remittance-countries/remittance-countries.model';

export enum EFeesAmountKey {
  Source = 0,
  Destination = 1
}

export interface IFeesBody {
  directionId: string,
  sourceCurrencyIso4217: string,
  destinationCurrencyIso4217: string,
  amount: string,
  amountKey: string
}

export interface IFeesResponseModel {
  source: {
    currency: ILookupStr,
    amount: number,
    amountMin: number,
    amountMax: number,
    totalAmount: number,
    commission: number
  },
  destination: {
    currency: ILookupStr,
    amount: number,
    amountMin: number,
    amountMax: number,
    rate: number
  },
  isPayoutMode: boolean
}

export interface IFeesResponse {
  source: {
    /**
     * Валюта отправления
     */
    currency: ILookupStr,
    /**
     * сумма отправления
     */
    amount: number | null,
    /**
     * минимальная сумма, которую можно отправить
     */
    amountMin: number | null,
    /**
     * максимальное значение суммы, которую можно отправить
     */
    amountMax: number | null,
    /**
     * сумма с учетом всех комиссий (сколько денег взять с клиента)
     */
    totalAmount: number,
    /**
     * взимаемая комиссия
     */
    commission: number
  },
  destination: {
    /**
     *  валюта, в которой получит выплату конечный получатель
     */
    currency: ILookupStr,
    /**
     * сумма для получения
     */
    amount: number | null,
    /**
     * минимально возможное значение, может приходить null, тогда нужно валидировать только по сумме отправки
     */
    amountMin: number | null,
    /**
     * максимально допустимое значение суммы получения. в случае null, валидировать по сумме отправления
     */
    amountMax: number | null,
    /**
     * курс: 1 SourceCurrency = rate DestinationCurrency (1 EUR = 1.0987 USD). Может быть до 6 знаков после запятой
     */
    rate: number
  },
  /**
   * В интерфейсе нигде не используется, но это флаг, показывающий, что в выбранном направлении используется валюта выдачи, возможно где-то будет нужен
   */
  isPayoutMode: boolean
}

export const EMPTY_CALC_RESULT: IFeesResponse = {
  source: {
    currency: { id: '', title: '' },
    commission: 0,
    amount: 0,
    totalAmount: 0,
    amountMax: 0,
    amountMin: 0
  },
  destination: {
    currency: { id: '', title: '' },
    rate: 0,
    amount: 0,
    amountMax: 0,
    amountMin: 0
  },
  isPayoutMode: false
};

export interface ICalculatorForm {
  destinationCountry: IRemittanceCountry | null,
  sourceAmount: number | null,
  sourceCurrency: ILookupStr | null,
  destinationAmount: number | null,
  destinationCurrency: ILookupStr | null
}
