<mat-dialog-content class="mat-dialog-content-pb-3">
  <div class="mx-n6">
    <iframe allowfullscreen #frame frameBorder="0" class="iframe"></iframe>
    <ng-container *ngIf="isLoading$$ | async">
      <app-spinner [isFullScreen]="true"></app-spinner>
    </ng-container>
  </div>
  <ng-container *ngIf="isLoading$$ | async">
    <app-spinner [isFullScreen]="true"></app-spinner>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button color="primary" cdkFocusInitial [mat-dialog-close]="true">
    {{ '%[confirm-dialog.button.close]%' | tr }}
  </button>
</mat-dialog-actions>
