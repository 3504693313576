/**
 * generates xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx guid
 */
export function uuidv4(): string {
  return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    // downloaded from https://www.codegrepper.com/code-examples/javascript/typescript+how+to+create+uuid
    // eslint-disable-next-line no-bitwise
    const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
