import { NgModule } from '@angular/core';
import { CurrenciesDestinationService } from './currencies-destination.service';
import { CurrenciesDestinationQuery, CurrenciesDestinationStore } from './currencies-destination.store';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    CurrenciesDestinationService,
    CurrenciesDestinationStore,
    CurrenciesDestinationQuery,
  ],
})
export class CurrenciesDestinationModule {}
