import { Query, Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IVersionInfo } from './version.model';
import { Observable } from 'rxjs';

export function createInitialState(): IVersionInfo {
  return {
    backend: '',
    frontend: '',
    materialUi: ''
  };
}
@Injectable()
@StoreConfig({ name: 'version' })
export class VersionStore extends Store<IVersionInfo> {
  constructor() {
    super(createInitialState());
  }
}

@Injectable()
export class VersionQuery extends Query<IVersionInfo> {

  versions$: Observable<string | null> = this.select(x => {
    if (x.frontend === '' && x.backend === '') {
      return null;
    }
    return 'f: ' + x.frontend + '; b: ' + x.backend + '; u: ' + x.materialUi;
  });

  constructor(protected override store: VersionStore) {
    super(store);
  }
}
