import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { catchError, delay, finalize, map, mapTo, retryWhen, switchMap, take, tap } from 'rxjs/operators';
import { IGateway } from '../gateways/gateways.model';
import { GatewaysQuery } from '../gateways/gateways.store';
import { EMPTY_CALC_RESULT, EFeesAmountKey, IFeesBody, IFeesResponse, IFeesResponseModel } from './calculator.models';
import { CalculatorQuery, CalculatorStore } from './calculator.store';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ILookupInt } from '../../root/private/private-models/lookup-str';
import { SendStore } from '../../root/private/private-pages/send/store/send.store';
import { logAction, withTransaction } from '@datorama/akita';
import { WorkflowsStore } from '../workflows/workflows.store';
import { CurrenciesDestinationStore } from '../currencies-destination/currencies-destination.store';


@Injectable()
export class CalculatorService {

  public partnerIsNotSelected$$: Observable<boolean> = this._gatewaysQuery.selectActive()
    .pipe(
      map((val: IGateway | undefined) => val == null)
    );

  constructor(
    private _calculatorQuery: CalculatorQuery,
    private _gatewaysQuery: GatewaysQuery,
    private _sendStore: SendStore,
    private _http: HttpClient,
    private _calculatorStore: CalculatorStore,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _workflowsStore: WorkflowsStore,
    private _currenciesDestinationStore: CurrenciesDestinationStore,
  ) {}

  setSourceAmount$(sourceAmount: number): Observable<void> {
    return of(sourceAmount).pipe(
      map((value: number) => Math.trunc(value)),
      tap((value: number) => {
        this._calculatorStore.update(x => ({
          ...x,
          sourceAmount: value,
          amount: value,
          amountKey: EFeesAmountKey.Source
        }));
      }),
      mapTo(void 0)
    );
  }

  setSourceCurrency$(sourceCurrency: ILookupInt | null | undefined): Observable<void> {
    return of(void 0).pipe(
      tap(() => this._calculatorStore.update({ sourceCurrency })),
      mapTo(void 0)
    );
  }

  setDestinationAmount$(destinationAmount: number): Observable<void> {
    return of(destinationAmount).pipe(
      map((value: number) => Math.trunc(value)),
      tap((value: number) => {
        this._calculatorStore.update(x => ({
          ...x,
          destinationAmount: value,
          amount: value,
          amountKey: EFeesAmountKey.Destination
        }));
      }),
      mapTo(void 0),
    );
  }

  setDestinationCurrency(state: ILookupInt): void {
    this._calculatorStore.update({ ...state, destinationCurrency: state });
  }

  executeCalculationAndUpdateStore$(): Observable<void> {
    return of(void 0)
      .pipe(
        switchMap(() => combineLatest([
          this._gatewaysQuery.selectActive(),
          this._calculatorQuery.select()
        ])),
        take(1),
        map(([activeGateway, calcQuery]): IFeesBody | null => {
          const isSourceAmountBad: boolean = calcQuery.sourceAmount == null || calcQuery.sourceAmount === 0;
          const isDestinationAmountBad: boolean = calcQuery.destinationAmount == null || calcQuery.destinationAmount === 0;
          if (activeGateway == null ||
            (isSourceAmountBad && isDestinationAmountBad) || calcQuery.amount === 0 ||
            (calcQuery.sourceCurrency == null) || (calcQuery.destinationCurrency == null)) {
            console.warn('calculation did not execute because one of the parameters is null');
            return null;
          }

          return {
            directionId: activeGateway.directionId,
            sourceCurrencyIso4217: calcQuery.sourceCurrency?.id.toString() ?? '',
            destinationCurrencyIso4217: calcQuery.destinationCurrency?.id.toString() ?? '',
            amount: calcQuery.amount.toString(10),
            amountKey: calcQuery.amountKey.toString(10)
          };
        }),
        switchMap((body: IFeesBody | null) => {
          this._googleAnalyticsService.event('view_fees_attempt', '', '');
          const RETRY_COUNT = 2;

          if (body == null) {
            return of(null);
          } else {
            this._calculatorStore.update({ isFeeLoading: true });
            return this._http.get<IFeesResponseModel>('reference/fee', { params: { ...body } }).pipe(
              withTransaction(() => this._calculatorStore.update({ isFeeLoading: false })),
              retryWhen(err => {
                let retries = 0;
                return err
                  .pipe(
                    delay(1000),
                    map(error => {
                      retries += 1;
                      if (retries === RETRY_COUNT) {
                        throw error;
                      }
                      return error;
                    })
                  );
              }),
              catchError(() => {
                this._googleAnalyticsService.event('view_fees_error', '', '');
                return of(void 0)
                  .pipe(
                    tap(() => this._calculatorStore.update({ isFeeLoading: false })),
                    withTransaction(() => {
                      this._calculatorStore.update(x => ({
                        ...x,
                        calculationResult: EMPTY_CALC_RESULT,
                        sourceAmount: null,
                        destinationAmount: null,
                        amountKey: 0,
                        amount: 0,
                        isShowSummary: false,
                        isCalculateOnOpen: false,
                      }));
                    }),
                    mapTo(void 0),
                    switchMap(() => EMPTY)
                  );
              }),
              finalize(() => this._calculatorStore.update({ isFeeLoading: false })));
          }
        }),
        withTransaction((feeResponse: IFeesResponse | null) => {
          if (feeResponse != null) {
            this._googleAnalyticsService.event('view_fees_success', '', '');
            this._calculatorStore.update({ isShowSummary: true });

            logAction('calculator.service update _sendStore');
            this._sendStore.update((state) => ({
              ...state,
              paymentGatewayParams: {
                ...state.paymentGatewayParams,
                currency: feeResponse.source.currency.title,
                amount: feeResponse.source.totalAmount.toFixed(2),
              },
            }));

            logAction('calculator.service update _calculatorStore');
            this._calculatorStore.update(x => ({
              ...x,
              previousCalculationResult: x.calculationResult,
              sourceAmount: feeResponse.source.amount,
              destinationAmount: feeResponse.destination.amount,
              calculationResult: feeResponse
            }));
          }
        }),
        mapTo(void 0)
      );
  }

  resetStoresWorkflowsEmptyOrMoreThanOne(): Observable<void> {
    return of(void 0).pipe(
      tap(() => {
        this._workflowsStore.setActive(null);
        this._currenciesDestinationStore.reset();
        this._calculatorStore.update({
          calculationResult: EMPTY_CALC_RESULT,
          amountKey: 0,
          amount: 0,
          sourceAmount: null,
          destinationAmount: null,
          destinationCurrency: null,
          isShowSummary: false,
          isCalculateOnOpen: false,
          previousCalculationResult: EMPTY_CALC_RESULT,
        });
      })
    );
  }

}
