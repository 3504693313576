<button mat-icon-button class="btn-mat-close" (click)="onCloseClick()">
  <i class="ff-icon ff-close icon-size-2"></i>
</button>
<mat-dialog-content class="dialog-content">
  <p mat-dialog-title>{{ '%[send.error.title.readonly-access]%' | tr }}</p>
  <div class="row">
    <div class="col-12">
      <app-img-svg class="dialog-content__img" [url]="themeService.getSharedImageUrl('img/girl.svg')"></app-img-svg>
      <p class="dialog-content__text">{{ '%[send.error.message.readonly-online]%' | tr }}</p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="row flex-grow-1">
    <div class="col-3 d-none d-sm-block"></div>
    <div class="col-12 col-sm-6">
      <button (click)="onContactUsClick()" mat-flat-button color="primary">
        {{ '%[send.error.button.support]%' | tr }}
      </button>
    </div>
    <div class="col-3 d-none d-sm-block"></div>
  </div>
</mat-dialog-actions>
