import { Query, Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ILookupStr } from '../../../root/private/private-models/lookup-str';
import { Observable } from 'rxjs';

export interface ILocationsState {
  city: ILookupStr | null,
  search: string,
  allLocations: boolean,
  allPartners: boolean,
  isPdfDownloading: boolean
}

export function createInitialState(): ILocationsState {
  return {
    city: null,
    search: '',
    allLocations: false,
    allPartners: false,
    isPdfDownloading: false
  };
}

@Injectable()
@StoreConfig({ name: 'locations-modal' })
export class LocationsStore extends Store<ILocationsState> {

  constructor() {
    super(createInitialState());
  }
}

@Injectable()
export class LocationsQuery extends Query<ILocationsState> {
  state$$: Observable<ILocationsState> = this.select(x => x);
  city$$: Observable<ILookupStr | null> = this.select(x => x.city);
  isPdfDownloading$$: Observable<boolean> = this.select(x => x.isPdfDownloading);

  constructor(protected override store: LocationsStore) {
    super(store);
  }
}
