import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ThemeService } from '../../../../data-modules/theme/theme.service';
import { map, take } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { LanguageCode, LanguagesQuery } from '@ff/localization';

@Component({
  selector: 'app-refused-online',
  styleUrls: ['service-denied.component.scss'],
  templateUrl: 'service-denied.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ServiceDeniedComponent {
  private _externalUrl$$: Observable<string> = combineLatest([
    this.languagesQuery.selectActive().pipe(
      map(active => active?.id),
      map((localeCode: LanguageCode | undefined) => {
        if (localeCode == null) { return ''; }
        const [one, two] = localeCode.split('-');
        return [one, two?.toUpperCase() ?? ''].join('-');
      })
    ),
  ]).pipe(
    map(([lang]): string => {
      let url = environment.vendor.company.contactUsExternalUrl;
      url = url.replace('{{lang}}', lang);
      return url;
    })
  );

  constructor(
    public dialogRef: MatDialogRef<ServiceDeniedComponent>,
    public themeService: ThemeService,
    private languagesQuery: LanguagesQuery,
  ) { }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  onContactUsClick(): void {
    this._externalUrl$$.pipe(take(1)).subscribe((url: string) => {
      window.open(url);
    });
  }
}
