import { Query, Store, StoreConfig, withTransaction } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IBeneficiaryGeneralForm } from './beneficiary-general.model';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ILookupStr } from '../../../../../private-models/lookup-str';

export function createInitialState(): IBeneficiaryGeneralForm {
  return {
    id: null,
    name: '',
    middleName: '',
    surname: '',
    sex: '',
    phoneNumber: '',
    dateOfBirth: null,
    remittancePurpose: null,
    senderSourceOfIncomeType: null
  };
}

@StoreConfig({ name: 'beneficiary-general-form' })
export class BeneficiaryGeneralFormStore extends Store<IBeneficiaryGeneralForm> {
  constructor() {
    super(createInitialState());
  }

  setBeneficiaryFormField$(partial: Partial<IBeneficiaryGeneralForm>): Observable<void> {
    return of(void 0).pipe(
      tap(() => this.update(x => ({ ...x, ...partial })))
    );
  }

  setRemittancePurpose$(value: ILookupStr | null): Observable<void> {
    return of(void 0).pipe(
      tap(() => this.update(x => ({ ...x, remittancePurpose: value })))
    );
  }

  setComplexRemittancePurpose$(value: ILookupStr | string): Observable<void> {
    return of(void 0).pipe(
      withTransaction(() => {
        if (typeof value === 'string') {
          this.update(x => ({ ...x, remittancePurpose: { id: value.toUpperCase(), title: value.toUpperCase() } }));
          return;
        }

        this.update(x => ({ ...x, remittancePurpose: value }));
      })
    );
  }
}

@Injectable()
export class BeneficiaryGeneralFormQuery extends Query<IBeneficiaryGeneralForm> {
  state$$: Observable<IBeneficiaryGeneralForm> = this.select(x => x);
  name$$: Observable<string> = this.select(x => x.name);
  surname$$: Observable<string> = this.select(x => x.surname);
  middlename$$: Observable<string> = this.select(x => x.middleName);
  dateOfBirth$$: Observable<Date | null | string> = this.select(x => x.dateOfBirth);

  constructor(protected override store: BeneficiaryGeneralFormStore) {
    super(store);
  }
}
