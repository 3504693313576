import { NgModule } from '@angular/core';
import { BankAccountTypesService } from './bank-account-types.service';
import { BankAccountTypesQuery, BankAccountTypesStore } from './bank-account-types.store';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    BankAccountTypesService,
    BankAccountTypesStore,
    BankAccountTypesQuery,
  ],
})
export class BankAccountModule {}
