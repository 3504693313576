import { Query, Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IIdenfyResultsModel } from '../private-models/idenfy-results.model';
import {
  IMessage
} from '../../../functionality-modules/dialog-windows/identify-iframe/identify-result-dialog/identify-result-dialog.component';


export function createInitialState(): IIdenfyResultsModel {
  return {
    idenfyStatusManualDocument: '',
    idenfyStatusManualFace: '',
    onBoardingScreeningResponseLogId: ''
  };
}

@StoreConfig({ name: 'idenfy-results' })
export class IdenfyResultsStore extends Store<IIdenfyResultsModel> {
  constructor() {
    super(createInitialState());
  }

}

@Injectable()
export class IdenfyResultsQuery extends Query<IIdenfyResultsModel> {
  public messages$$: Observable<IMessage[]> = of([
    { isGoodStatus: true, idenfyAlias: 'APPROVED', localizationKey: '%[idenfy-result.approved]%' },
    { isGoodStatus: false, idenfyAlias: 'DENIED', localizationKey: '%[idenfy-result.denied]%' },
    { isGoodStatus: false, idenfyAlias: 'SUSPECTED', localizationKey: '%[idenfy-result.suspected]%' },
    { isGoodStatus: false, idenfyAlias: 'REVIEWING', localizationKey: '%[idenfy-result.reviewing]%' },
    { isGoodStatus: false, idenfyAlias: 'EXPIRED', localizationKey: '%[idenfy-result.expired]%' },
    { isGoodStatus: false, idenfyAlias: 'ACTIVE', localizationKey: '%[idenfy-result.active]%' },
    { isGoodStatus: true, idenfyAlias: 'FACE_MATCH', localizationKey: '%[idenfy-result.face-match]%' },
    { isGoodStatus: false, idenfyAlias: 'FACE_MISMATCH', localizationKey: '%[idenfy-result.face-mismatch]%' },
    { isGoodStatus: false, idenfyAlias: 'NO_FACE_FOUND', localizationKey: '%[idenfy-result.no-face-found]%' },
    { isGoodStatus: false, idenfyAlias: 'TOO_MANY_FACES', localizationKey: '%[idenfy-result.too-many-faces]%' },
    { isGoodStatus: false, idenfyAlias: 'FACE_TOO_BLURRY', localizationKey: '%[idenfy-result.face-too-blurry]%' },
    { isGoodStatus: false, idenfyAlias: 'FACE_ERROR', localizationKey: '%[idenfy-result.face-error]%' },
    { isGoodStatus: false, idenfyAlias: 'FACE_NOT_ANALYSED', localizationKey: '%[idenfy-result.face-not-analysed]%' },
    { isGoodStatus: false, idenfyAlias: 'FAKE_FACE', localizationKey: '%[idenfy-result.fake-face]%' },
    { isGoodStatus: true, idenfyAlias: 'DOC_VALIDATED', localizationKey: '%[idenfy-result.doc-validated]%' },
    { isGoodStatus: false, idenfyAlias: 'DOC_INFO_MISMATCH', localizationKey: '%[idenfy-result.doc-info-mismatch]%' },
    { isGoodStatus: false, idenfyAlias: 'DOC_NOT_FOUND', localizationKey: '%[idenfy-result.doc-not-found]%' },
    { isGoodStatus: false, idenfyAlias: 'DOC_NOT_SUPPORTED', localizationKey: '%[idenfy-result.doc-not-supported]%' },
    { isGoodStatus: false, idenfyAlias: 'DOC_FACE_NOT_FOUND', localizationKey: '%[idenfy-result.doc-face-not-found]%' },
    { isGoodStatus: false, idenfyAlias: 'DOC_NAME_ERROR', localizationKey: '%[idenfy-result.doc-name-error]%' },
    { isGoodStatus: false, idenfyAlias: 'DOC_SURNAME_ERROR', localizationKey: '%[idenfy-result.doc-surname-error]%' },
    { isGoodStatus: false, idenfyAlias: 'DOC_EXPIRY_ERROR', localizationKey: '%[idenfy-result.doc-expiry-error]%' },
    { isGoodStatus: false, idenfyAlias: 'DOC_DOB_ERROR', localizationKey: '%[idenfy-result.doc-dob-error]%' },
    { isGoodStatus: false, idenfyAlias: 'DOC_PERSONAL_NUMBER_ERROR', localizationKey: '%[idenfy-result.doc-personal-number-error]%' },
    { isGoodStatus: false, idenfyAlias: 'DOC_NUMBER_ERROR', localizationKey: '%[idenfy-result.doc-number-error]%' },
    { isGoodStatus: false, idenfyAlias: 'DOC_TOO_BLURRY', localizationKey: '%[idenfy-result.doc-too-blurry]%' },
    { isGoodStatus: false, idenfyAlias: 'MRZ_NOT_FOUND', localizationKey: '%[idenfy-result.mrz-not-found]%' },
    { isGoodStatus: false, idenfyAlias: 'MRZ_OCR_READING_ERROR', localizationKey: '%[idenfy-result.mrz-ocr-reading-error]%' },
    { isGoodStatus: false, idenfyAlias: 'DOC_EXPIRED', localizationKey: '%[idenfy-result.doc-expired]%' },
    { isGoodStatus: false, idenfyAlias: 'COUNTRY_MISMATCH', localizationKey: '%[idenfy-result.country-mismatch]%' },
    { isGoodStatus: false, idenfyAlias: 'DOC_ERROR', localizationKey: '%[idenfy-result.doc-error]%' },
    { isGoodStatus: false, idenfyAlias: 'DOC_NOT_ANALYSED', localizationKey: '%[idenfy-result.doc-not-analysed]%' },
    { isGoodStatus: false, idenfyAlias: 'DOC_DAMAGED', localizationKey: '%[idenfy-result.doc-damaged]%' },
    { isGoodStatus: false, idenfyAlias: 'DOC_FAKE', localizationKey: '%[idenfy-result.doc-fake]%' },
    { isGoodStatus: false, idenfyAlias: 'AUTO_UNVERIFIABLE', localizationKey: '%[idenfy-result.auto-unverifiable]%' },
    { isGoodStatus: false, idenfyAlias: 'FACE_SUSPECTED', localizationKey: '%[idenfy-result.face-suspected]%' },
    { isGoodStatus: false, idenfyAlias: 'DOC_MOBILE_PHOTO', localizationKey: '%[idenfy-result.doc-mobile-photo]%' },
    { isGoodStatus: false, idenfyAlias: 'DEV_TOOLS_OPENED', localizationKey: '%[idenfy-result.dev-tools-opened]%' },
    { isGoodStatus: false, idenfyAlias: 'DOC_PRINT_SPOOFED', localizationKey: '%[idenfy-result.doc-print-spoofed]%' },
    { isGoodStatus: false, idenfyAlias: 'FAKE_PHOTO', localizationKey: '%[idenfy-result.fake-photo]%' },
    { isGoodStatus: false, idenfyAlias: 'AML_SUSPECTION', localizationKey: '%[idenfy-result.aml-suspection]%' },
    { isGoodStatus: false, idenfyAlias: 'AML_FAILED', localizationKey: '%[idenfy-result.aml-failed]%' },
    { isGoodStatus: false, idenfyAlias: 'LID_SUSPECTION', localizationKey: '%[idenfy-result.lid-suspection]%' },
    { isGoodStatus: false, idenfyAlias: 'LID_FAILED', localizationKey: '%[idenfy-result.lid-failed]%' },
    { isGoodStatus: false, idenfyAlias: 'UNDER_AGE', localizationKey: '%[idenfy-result.under-age]%' },
    { isGoodStatus: false, idenfyAlias: 'UNKNOWN_AGE', localizationKey: '%[idenfy-result.unknown-age]%' },
    { isGoodStatus: false, idenfyAlias: 'NAME', localizationKey: '%[idenfy-result.name]%' },
    { isGoodStatus: false, idenfyAlias: 'SURNAME', localizationKey: '%[idenfy-result.surname]%' },
    { isGoodStatus: false, idenfyAlias: 'DOCUMENT_NUMBER', localizationKey: '%[idenfy-result.document-number]%' },
    { isGoodStatus: false, idenfyAlias: 'PERSONAL_CODE', localizationKey: '%[idenfy-result.personal-code]%' },
    { isGoodStatus: false, idenfyAlias: 'EXPIRY_DATE', localizationKey: '%[idenfy-result.expiry-date]%' },
    { isGoodStatus: false, idenfyAlias: 'DATE_OF_BIRTH', localizationKey: '%[idenfy-result.date-of-birth]%' },
    { isGoodStatus: false, idenfyAlias: 'DATE_OF_ISSUE', localizationKey: '%[idenfy-result.date-of-issue]%' },
    { isGoodStatus: false, idenfyAlias: 'FULL_NAME', localizationKey: '%[idenfy-result.full-name]%' },
  ]);

  enrichedIdenfyStatusManualDocument$$: Observable<IMessage | null> = combineLatest([
    this.messages$$,
    this.select(x => x.idenfyStatusManualDocument)
  ])
    .pipe(
      map(([msgs, result]): IMessage | null => {
        return result != null ? msgs.find(x => x.idenfyAlias === result) ?? null : null;
      }),
    );

  enrichedIdenfyStatusManualFace$$: Observable<IMessage | null> = combineLatest([
    this.messages$$,
    this.select(x => x.idenfyStatusManualFace)
  ])
    .pipe(
      map(([msgs, result]): IMessage | null => {
        return result != null ? msgs.find(x => x.idenfyAlias === result) ?? null : null;
      }),
    );

  constructor(
    protected override store: IdenfyResultsStore,
  ) {
    super(store);
  }
}
