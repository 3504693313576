import { Injectable } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { EMPTY, from, Observable, throwError } from 'rxjs';
import { catchError, mapTo, switchMap, tap } from 'rxjs/operators';
import { LocalizationQuery } from '@ff/localization';
import { environment } from '../../environments/environment';
import { LogoutService } from './logout.service';

@Injectable()
export class UserSessionsSignalRService {

  private readonly _hubConnection: HubConnection;

  constructor(
    private _localizationQ: LocalizationQuery,
    private _logoutService: LogoutService
  ) {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(`${environment.webSocketHostOrigin}/user-sessions-hub`, { skipNegotiation: true, transport: HttpTransportType.WebSockets })
      .configureLogging(LogLevel.Information)
      .build();
  }

  startConnection$$(token: string): Observable<void> {
    return from(this._hubConnection.start().catch(e => throwError(e)))
      .pipe(
        switchMap(() => {
          if (this._hubConnection.state === 'Connected') {
            return this._hubConnection.invoke('createconnection', token);
          }
          return throwError('User Sessions Hub is disconnected');
        }),
        tap(() => this._addUserSessionLogoutListener()),
        catchError(() => EMPTY),
        mapTo(void 0)
      );
  }

  stopConnection(): void {
    if (this._hubConnection != null) {
      this._hubConnection.stop().then();
    }
  }

  private _addUserSessionLogoutListener(): void {
    this._hubConnection.on('session-logout', () => {
      this.stopConnection();
      console.warn('logged out due to session-logout message');
      this._logoutService.forcedLogout$$(
        this._localizationQ.transform('%[snackbar.title.session-logout]%'),
        this._localizationQ.transform('%[snackbar.message.session-logout]%')
      ).subscribe();
    });
  }
}
