import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ILookupStr } from '../../root/private/private-models/lookup-str';

export interface ITransferPurposesState extends EntityState<ILookupStr, string> {
  filter: string
}

@Injectable()
@StoreConfig({ name: 'transfer-purposes' })
export class TransferPurposesStore extends EntityStore<ITransferPurposesState> {
  constructor() {
    super({ filter: 'ALL' });
  }
}

@Injectable()
export class TransferPurposesQuery extends QueryEntity<ITransferPurposesState> {

  transferPurposes$$: Observable<ILookupStr[]> = this.selectAll();

  constructor(protected override store: TransferPurposesStore) {
    super(store);
  }
}
