import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtmMarksQuery } from '../../../data-modules/utm-marks/utm-marks.store';
import { IUtmMarksModel } from '../../../data-modules/utm-marks/utm-marks.model';

@Injectable()
export class UtmMarksInterceptor implements HttpInterceptor {
  constructor(private _utmMarksQuery: UtmMarksQuery) { }

  intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {
    const marks: IUtmMarksModel | null = this._utmMarksQuery.getValue().data;

    if (marks != null) {
      if (marks.utmSource != null && marks.utmSource.trim() !== '') {
        req = req.clone({
          setHeaders: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            utm_source: marks.utmSource
          }
        });
      }
      if (marks.utmMedium != null && marks.utmMedium.trim() !== '') {
        req = req.clone({
          setHeaders: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            utm_medium: marks.utmMedium
          }
        });
      }
      if (marks.utmCampaign != null && marks.utmCampaign.trim() !== '') {
        req = req.clone({
          setHeaders: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            utm_campaign: marks.utmCampaign
          }
        });
      }
      if (marks.utmTerm != null && marks.utmTerm.trim() !== '') {
        req = req.clone({
          setHeaders: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            utm_term: marks.utmTerm
          }
        });
      }
      if (marks.utmContent != null && marks.utmContent.trim() !== '') {
        req = req.clone({
          setHeaders: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            utm_content: marks.utmContent
          }
        });
      }
    }

    return next.handle(req);
  }
}
