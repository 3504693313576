import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type RequiredFieldName = 'BeneficiaryAddress' | 'BeneficiaryPan' | 'BeneficiaryPhone' | 'BeneficiaryDateOfBirth' | 'BeneficiaryIDType'
  | 'BeneficiaryIDNumber' | 'BeneficiaryAccountNumber' |
  'BeneficiaryCitizenshipAbcCode' | 'BeneficiaryBankCode' | 'BeneficiaryBankBranchCode' | 'BeneficiaryAccountType' | 'BeneficiaryBankBic';

export interface IRequiredFieldResponseModel {
  id?: string,
  name: RequiredFieldName,
  sourceMethodName: string,
  sourceType: string,
  regExp: null | RegExp
}

export interface IRequiredField {
  id?: string,
  name: RequiredFieldName,
  sourceMethodName: string,
  sourceType: string,
  regExp: null | RegExp
}

export interface IRequiredFieldsState extends EntityState<IRequiredField, string> {
  loading: boolean
}

@Injectable()
@StoreConfig({ name: 'required-fields' })
export class RequiredFieldsStore extends EntityStore<IRequiredFieldsState> {
  constructor() {
    super({ loading: false });
  }
}

@Injectable()
export class RequiredFieldsQuery extends QueryEntity<IRequiredFieldsState> {
  state$$: Observable<IRequiredField[]> = this.selectAll();
  requiredFields$$: Observable<IRequiredField[]> = this.selectAll();
  hasBeneficiaryAddress$$: Observable<boolean> = this.requiredFields$$
    .pipe(map((requiredFields: IRequiredField[]) => requiredFields.findIndex(requiredField => requiredField.name === 'BeneficiaryAddress') > -1));

  hasBeneficiaryPhoneNumber$$: Observable<boolean> = this.requiredFields$$
    .pipe(map((requiredFields: IRequiredField[]) => requiredFields.findIndex(requiredField => requiredField.name === 'BeneficiaryPhone') > -1));

  loading$$: Observable<boolean> = this.selectLoading();

  constructor(protected override store: RequiredFieldsStore) {
    super(store);
  }
}
