import { EntityState, EntityStore, EntityUIQuery, EntityUIStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { defer, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IDocumentBody, IDocumentBodyUI } from '../../../../private-models/document.model';
import { AttachmentStatus, IAttachment } from '../../../../private-models/attachment.model';

export interface IResidenceConfirmationsState extends EntityState<IDocumentBody, string> {}

export interface IRCUIState extends EntityState<IDocumentBodyUI, string> {
  newDocFormIsOpen: boolean
}

@StoreConfig({ name: 'residence-confirmations' })
export class ResidenceConfirmationsStore extends EntityStore<IResidenceConfirmationsState> {
  override ui: EntityUIStore<IRCUIState>;

  constructor() {
    super();
    this.createUIStore();
  }
}

@Injectable()
export class ResidenceConfirmationsQuery extends QueryEntity<IResidenceConfirmationsState> {
  override ui: EntityUIQuery<IRCUIState>;
  state$$: Observable<IResidenceConfirmationsState> = this.selectAll();
  stateUI$$: Observable<IResidenceConfirmationsState> = defer(() => this.ui.selectAll());
  isLoading$$: Observable<boolean> = this.selectLoading();
  isHasCustomerProofOfAddressBody$$: Observable<boolean> = this.selectAll()
    .pipe(
      map((proofsOfAddress: IDocumentBody[]) => proofsOfAddress.length > 0)
    );

  isCustomerProofOfAddressExpired$$: Observable<boolean> = this.selectAll()
    .pipe(
      map((proofsOfAddress: IDocumentBody[]) => proofsOfAddress.every(poa => poa.document.isExpired)),
    );

  isHasCustomerProofOfAddressCopy$$: Observable<boolean> = this.selectAll()
    .pipe(
      map((proofsOfAddress: IDocumentBody[]) => proofsOfAddress.find(poa => !poa.document.isExpired)),
      map((poa: IDocumentBody | undefined) => {
        if (poa == null) {
          return false;
        }
        return poa.scans.length > 0;
      })
    );

  isCustomerProofOfAddressCopyBad$$: Observable<boolean> = this.selectAll()
    .pipe(
      map((proofsOfAddress: IDocumentBody[]) => proofsOfAddress.find(poa => !poa.document.isExpired)),
      switchMap((poa: IDocumentBody | undefined) => {
        if (poa == null) {
          return of(false);
        }
        return of(poa.scans[0])
          .pipe(
            map((scan: IAttachment | undefined) => {
              if (scan == null || scan.attachmentFileStatus == null) {
                return false;
              }
              return ['DenyBot', 'DenyManualInner', 'DenyManualOuter'].includes(scan.attachmentFileStatus.alias);
            })
          );
      }),
    );

  isNewPoaOpen$$: Observable<boolean> = defer(() => this.ui.select(x => x.newDocFormIsOpen));

  constructor(protected override store: ResidenceConfirmationsStore) {
    super(store);
    this.createUIQuery();
  }

  isCustomerProofOfAddressCopyHasStatuses(statuses: AttachmentStatus[]): Observable<boolean> {
    return this.selectAll().pipe(
      map((proofsOfAddress: IDocumentBody[]) => proofsOfAddress.find(poa => !poa.document.isExpired)),
      switchMap((poa: IDocumentBody | undefined) => {
        if (poa == null) {
          return of(false);
        }
        return of(poa.scans[0])
          .pipe(
            map((scan: IAttachment | undefined) => {
              if (scan == null || scan.attachmentFileStatus == null) {
                return false;
              }
              return statuses.includes(scan.attachmentFileStatus.alias);
            })
          );
      }),
    );
  }
}
