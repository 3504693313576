<mat-dialog-content class="dialog-content-max-height-none">
  <div class="row">
    <div class="col-12">
      <p class="dialog-content__text">{{ data.confirmText }}</p>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="row flex-grow-1">
    <div class="col-6">
      <button mat-stroked-button class="btn-block" (click)="onNoClick()">
        {{ data.no }}
      </button>
    </div>
    <div class="col-6">
      <button mat-flat-button class="btn-block" color="accent" cdkFocusInitial [mat-dialog-close]="true">
        {{ data.yes }}
      </button>
    </div>
  </div>
</mat-dialog-actions>
