import { IIdIssuedCountriesModel } from '../../../../../data-modules/id-issued-countries/id-issued-countries.model';

export type IEmailStatus = null | 'Approved' | 'Pending';

export interface IPersonalFormData {
  isReceivePaymentInfoByEmail: boolean,
  surname: string | null,
  name: string | null,
  middleName: string | null,
  dateOfBirth: string | null,

  nationality: IIdIssuedCountriesModel | null,
  placeOfBirth: string | null,
  sex: string | null,
  country: IIdIssuedCountriesModel | null,
  city: string | null,

  street: string | null,
  building: string | null,
  apartment: string | null,
  postcode: string | null,

  email: string | null,
  emailStatus: IEmailStatus,
  stamp: string | null
}

export const DEFAULT_PERSONAL_FORM_DATA: IPersonalFormData = {
  isReceivePaymentInfoByEmail: false,
  surname: '',
  name: '',
  middleName: '',
  dateOfBirth: '',

  nationality: null,
  placeOfBirth: '',
  sex: '',
  country: null,
  city: '',

  street: '',
  building: '',
  apartment: '',
  postcode: '',

  email: '',
  emailStatus: null,
  stamp: '',
};

export interface IPersonalDataForm {
  current: IPersonalFormData,
  draft: IPersonalFormData,
  isEdit: boolean
}
