import { Injectable } from '@angular/core';
import { IDatePickerRange } from '../../../private-models/lookup-str';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { ITransferGridDateRange } from '../containers/history.component';
import { createInitialFilterDataState, HistoryQuery, HistoryStore } from './history.store';
import { IFilterData } from './history.models';

@Injectable()
export class HistoryFilterService {

  constructor(
    private _historyStore: HistoryStore,
    private _historyQuery: HistoryQuery
  ) { }

  updateFilterData(data: Partial<IFilterData>): void {
    this._historyStore.update(state => ({ ...state, filterData: { ...state.filterData, ...data } }));
  }

  clearFilter(): void {
    const ui = createInitialFilterDataState().filterData;
    this._historyStore.update(state => ({ ...state, filterData: { ...state.filterData, ...ui } }));
  }

  remappedFormDateRange$(controlDate?: IDatePickerRange | null, type?: ITransferGridDateRange): Observable<void> {
    return of(void 0).pipe(
      tap(() => {
        const currDate: DateTime = DateTime.now();
        const fromDate: DateTime | null = controlDate?.dateFrom ?? null;
        const toDate: DateTime | null = controlDate?.dateTo ?? null;
        const selectedPeriod = this._historyQuery.getValue().filterData.periodDays;
        const remappedFormData = {
          period: selectedPeriod,
          fromDate,
          toDate
        };

        if (controlDate != null && controlDate?.dateFrom != null || controlDate?.dateTo != null) {
          remappedFormData.period = selectedPeriod;
        } else {
          if (selectedPeriod !== 0) {
            remappedFormData.fromDate = currDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .minus({ days: selectedPeriod });
            remappedFormData.toDate = currDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .minus({ days: selectedPeriod });
          } else if (controlDate?.dateTo != null) {
            const currentDayWeekAgo = currDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .minus({ days: 7 }).startOf('day');

            const isDayWeekAgoSame: boolean = currentDayWeekAgo.equals(controlDate.dateTo);
            remappedFormData.period = !isDayWeekAgoSame ? selectedPeriod : remappedFormData.period;
          }
        }

        if (type === 'PERIOD' && selectedPeriod !== 0) { // если период выбран не others, то меняем все поля
          this.updateFilterData({
            dateFrom: remappedFormData.fromDate,
            dateTo: currDate,
            periodDays: remappedFormData.period
          });
        }

        if (type === 'RANGE' && controlDate != null && remappedFormData.fromDate instanceof DateTime) { // если выбран диапазон дат, тогда меняем период, и диапазон дат на выбранный
          this.updateFilterData({
            dateFrom: remappedFormData.fromDate?.set({ hour: 23, minute: 59, second: 59, millisecond: 0 }),
            dateTo: remappedFormData.toDate
          });
        }

      }),
    );
  }

}
