import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { concatMap, switchMap, take, tap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';
import { withTransaction } from '@datorama/akita';
import { HttpClient } from '@angular/common/http';
import { PersistenceStore } from './persist/persist.store';
import { Router } from '@angular/router';
import { SnackbarService } from '../functionality-modules/snackbars/snackbar.service';
import { ProfileStore } from '../root/private/private-store/profile.store';
import { LocationsStore } from '../functionality-modules/dialog-windows/locations/locations.store';
import { PointsStore } from './points/points.store';
import { FreshchatService } from '../functionality-modules/freshchat/freshchat.service';
import { AuthStore } from './auth/auth.store';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import {
  SourceOfIncomeStore
} from '../root/private/private-pages/profile/additional-documents/store/source-of-income.store';
import {
  ResidenceConfirmationsStore
} from '../root/private/private-pages/profile/additional-documents/store/residence-confirmations.store';
import {
  IdentityDocumentsStore
} from '../root/private/private-pages/profile/identity-documents/store/identity-documents.store';
import {
  BeneficiaryGeneralFormStore
} from '../root/private/private-pages/send/containers/beneficiary/beneficiary-general/beneficiary-general.store';
import {
  TemplatesListStore
} from '../root/private/private-pages/templates/containers/templates-list/templates-list.store';
import { ProfileAdditionalAttributesStore } from '../root/private/private-store/profile-additional-attributes.store';
import { HistoryStore } from '../root/private/private-pages/history/store/history.store';
import { MyBeneficiaryStore } from '../root/private/private-pages/send/containers/beneficiary/beneficiary.store';
import { RequiredFieldsStore } from './required-fields/required-fields.store';
import { WorkflowsStore } from './workflows/workflows.store';
import { GatewaysStore } from './gateways/gateways.store';
import { CalculatorStore } from './calculator/calculator.store';
import { SecuritySettingsStore } from '../root/private/private-pages/security/store/security.store';
import {
  PasswordStore
} from '../root/private/private-pages/security/containers/authentication/password/password.store';
import {
  TwoFactorStore
} from '../root/private/private-pages/security/containers/authentication/two-factor/two-factor.store';
import {
  BeneficiaryAdditionalFieldsFormStore
} from '../root/private/private-pages/send/containers/beneficiary/beneficiary-additional-fields/beneficiary-additional-fields.store';
import {
  BeneficiaryAddressService
} from '../root/private/private-pages/send/containers/beneficiary/beneficiary-address/beneficiary-address.service';
import { CurrenciesDestinationStore } from './currencies-destination/currencies-destination.store';
import {
  SaveTemplatePanelStore
} from '../root/private/private-pages/send/containers/save-template-panel/save-template-panel.store';
import { LocalizationQuery } from '@ff/localization';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { RequiredDocumentsStore } from './required-documents/required-documents.store';
import { DraftService } from '../root/private/private-pages/send/containers/draft/draft.service';

@Injectable()
export class LogoutService {

  constructor(
    private _http: HttpClient,
    private _persistenceStore: PersistenceStore,
    private _router: Router,
    private _snackbarService: SnackbarService,
    private _localizationQ: LocalizationQuery,
    private _profileStore: ProfileStore,
    private _locationsStore: LocationsStore,
    private _pointsStore: PointsStore,
    private _freshChatService: FreshchatService,
    private _authStore: AuthStore,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _sourcesOfIncomeStore: SourceOfIncomeStore,
    private _residenceConfirmations: ResidenceConfirmationsStore,
    private _identityDocumentsStore: IdentityDocumentsStore,
    private _beneficiaryGeneralFormStore: BeneficiaryGeneralFormStore,
    private _templatesListStore: TemplatesListStore,
    private _profileAdditionalAttributesStore: ProfileAdditionalAttributesStore,
    private _historyStore: HistoryStore,
    private _myBeneficiaryStore: MyBeneficiaryStore,
    private _requiredFieldsStore: RequiredFieldsStore,
    private _workflowsStore: WorkflowsStore,
    private _gatewaysStore: GatewaysStore,
    private _calculatorStore: CalculatorStore,
    private _securitySettingsStore: SecuritySettingsStore,
    private _passwordStore: PasswordStore,
    private _twoFactorStore: TwoFactorStore,
    private _beneficiaryAdditionalFieldsFormStore: BeneficiaryAdditionalFieldsFormStore,
    private _beneficiaryAddressService: BeneficiaryAddressService,
    private _currenciesDestinationStore: CurrenciesDestinationStore,
    private _saveAsTemplatePanelFormStore: SaveTemplatePanelStore,
    private _dialogRef: MatDialog,
    private _requiredDocumentsStore: RequiredDocumentsStore,
    private _remittanceDraftService: DraftService
  ) { }

  voluntaryLogout(): void {
    of(void 0).pipe(
      // execute immediately
      concatMap(() => this._remittanceDraftService.deleteDraft$()),
      switchMap(() => this._http.post('login/logout', {})),
      switchMap(() => this.cleanupStores$$()),
      tap(() => this.cleanPersistenceStore()),
      switchMap(() => fromPromise(this._router.navigate(['public', 'authentication', 'sign-in']))),
      switchMap(() => this._snackbarService.openSuccess(this._localizationQ.transform('%[snackbar.text.voluntary-logout]%'))),
      switchMap(() => this.wipeSendPage$()),
      switchMap(() => this._profileStore.setActiveTab$(0)),
      switchMap(() => this._freshChatService.destruct$()),
      tap(() => this._authStore.setLoading(false)),
      tap(() => this._googleAnalyticsService.set('userId', '')),
      take(1)
    ).subscribe();
  }

  forcedLogout$$(title: string, message: string): Observable<void> {
    return of(void 0).pipe(
      tap(() => {
        this._persistenceStore.update(x => ({ ...x, accessToken: '', previousPhone: x.phone }));
        this._snackbarService.openFailure$(message, { duration: environment.appSettings.snackbar.duration, title }).subscribe();
        this._dialogRef.closeAll();
      }),
      switchMap(() => fromPromise(this._router.navigate(['public', 'authentication', 'sign-in']))),
      switchMap(() => this.cleanupStores$$()),
      switchMap(() => this._profileStore.setActiveTab$(0)),
      switchMap(() => this._freshChatService.destruct$()),
      tap(() => this._googleAnalyticsService.set('userId', '')),
    );
  }

  cleanupStores$$(): Observable<void> {
    return of(void 0).pipe(
      withTransaction(() => {
        this._sourcesOfIncomeStore.reset();
        this._sourcesOfIncomeStore.ui.reset();
        this._residenceConfirmations.reset();
        this._residenceConfirmations.ui.reset();
        this._identityDocumentsStore.reset();
        this._identityDocumentsStore.ui.reset();

        this._locationsStore.reset();
        this._pointsStore.reset();
        this._beneficiaryGeneralFormStore.reset();
        this._templatesListStore.reset();
        this._profileAdditionalAttributesStore.reset();
        this._historyStore.reset();
        this._persistenceStore.update({ currentLocationCountry: null });
        this._myBeneficiaryStore.reset();
        this._securitySettingsStore.reset();
        this._passwordStore.reset();
        this._twoFactorStore.reset();
        this._requiredDocumentsStore.reset();
      }),
    );
  }

  wipeSendPage$(): Observable<void> {
    return of(void 0)
      .pipe(
        take(1),
        withTransaction(() => {
          this._beneficiaryGeneralFormStore.reset();
          this._beneficiaryAdditionalFieldsFormStore.reset();
          this._beneficiaryAddressService.beneficiaryAddressForm.reset();
          this._myBeneficiaryStore.reset();

          this._requiredFieldsStore.reset();
          this._workflowsStore.setDefault();
          this._gatewaysStore.reset();
          this._calculatorStore.reset();
          this._currenciesDestinationStore.reset();
          this._beneficiaryGeneralFormStore.reset();
          this._saveAsTemplatePanelFormStore.reset();
        }),
      );
  }

  cleanPersistenceStore(): void {
    this._persistenceStore.update(x => ({
      ...x,
      accessToken: '',
      refreshToken: '',
      previousPhone: x.phone
    }));
  }
}
