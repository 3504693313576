import { NgModule } from '@angular/core';
import { CalculatorService } from './calculator.service';
import { CalculatorFormService } from './calculator.form';
import { CalculatorQuery, CalculatorStore } from './calculator.store';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    CalculatorService,
    CalculatorFormService,
    CalculatorStore,
    CalculatorQuery,
  ],
})
export class CalculatorModule {}
