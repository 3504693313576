import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { IFrameMessageData } from './identify-iframe.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-idenfy-iframe',
  templateUrl: 'identify-iframe.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdentifyIframeComponent implements OnInit {
  safeUrl$$: BehaviorSubject<SafeResourceUrl | null> = new BehaviorSubject<SafeResourceUrl | null>(null);
  hasSafeUrl$$: Observable<boolean> = this.safeUrl$$.pipe(
    map(safeUrl => safeUrl != null)
  );

  idenfyResult$$: BehaviorSubject<IFrameMessageData | null> = new BehaviorSubject<IFrameMessageData | null>(null);
  hasIdenfyResult$$: Observable<boolean> = this.idenfyResult$$.pipe(
    map(idenfyResult => idenfyResult != null)
  );

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private _data: { url: string },
    private _dialogRef: MatDialogRef<IdentifyIframeComponent>,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this._initSafeUrl();
    this._subscribeOnMessage();
  }

  onCloseClick(): void {
    this.idenfyResult$$.pipe(
      take(1),
      tap((iFrameMessageData: IFrameMessageData | null) => {
        this._dialogRef.close(iFrameMessageData?.data);
      })
    ).subscribe();
  }

  private _initSafeUrl(): void {
    this.safeUrl$$.next(this._sanitizer.bypassSecurityTrustResourceUrl(this._data.url));
  }

  private _subscribeOnMessage(): void {
    fromEvent<IFrameMessageData>(window, 'message').pipe(
      filter((e: IFrameMessageData) => e.data.status != null),
      tap((iFrameMessageData: IFrameMessageData) => this.idenfyResult$$.next(iFrameMessageData)),
      untilDestroyed(this)
    ).subscribe();
  }

}
