<ng-container *ngIf="(isLoading$$ | async) === false; else loadingContainer">
  <ng-container *ngIf="form$$ | async as form">
    <h2>{{ '%[private.aml-questionnaire-form.title]%' | tr }}</h2>
    <form [formGroup]="form">
      <mat-dialog-content class="dialog-content">
        <ng-container *ngFor="let row of questionnaireFields$$ | async; trackBy: trackByFn">
          <div class="row form__row">
            <div class="col-12 form__col" [ngSwitch]="row.type">
              <ng-template ngSwitchCase="Select">
                <p class="label label__select">
                  <label *ngIf="row.field != null" [ngClass]="{ required: hasRequiredAsterisk$(row.id) | async }">
                    {{ row.description }}
                  </label>
                </p>
                <ng-container *ngIf="getCustomControlData(row) as controlData">
                  <ng-container *ngIf="controlData.isShow">
                    <p class="label label__text">
                      {{ controlData.controlLabel }}
                    </p>
                    <mat-form-field [appearance]="appearance" class="input input__text">
                      <mat-label> {{ controlData.controlLabel }}</mat-label>
                      <input matInput [formControl]="form.controls[controlData.controlName]" />
                      <mat-error>
                        <div
                          *ngIf="form.controls[controlData.controlName].errors?.['required'] != null ||
                form.controls[controlData.controlName].errors?.['minlength'] != null"
                        >
                          {{ '%[global.error-messages.field-required]%' | tr }}
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </ng-container>
                </ng-container>
              </ng-template>

              <ng-template ngSwitchCase="Text">
                <p class="label label__text">
                  <label *ngIf="row.field != null" [ngClass]="{ required: hasRequiredAsterisk$(row.id) | async }">
                    {{ row.description }}
                  </label>
                </p>
                <mat-form-field [appearance]="appearance" class="input input__text">
                  <mat-label> {{ row.field }}</mat-label>
                  <input
                    matInput
                    ffRegexFilter
                    ffForceUppercase
                    [formControl]="form.controls[getControlName(row.id)]"
                    [formControlName]="getControlName(row.id)"
                    [filterRegex]="getFilter('alphaNumericSymbols')"
                  />

                  <mat-error>
                    <div
                      *ngIf="form.controls[getControlName(row.id)].errors?.['required'] != null ||
                form.controls[getControlName(row.id)].errors?.['minlength'] != null"
                    >
                      {{ '%[global.error-messages.field-required]%' | tr }}
                    </div>
                  </mat-error>
                </mat-form-field>
              </ng-template>

              <ng-template ngSwitchCase="Radio">
                <p class="label label__radio">
                  <label *ngIf="row.field != null" [ngClass]="{ required: hasRequiredAsterisk$(row.id) | async }">
                    {{ row.description }}
                  </label>
                </p>

                <div class="input__radio">
                  <mat-radio-group [formControlName]="getControlName(row.id)" class="input row gy-5">
                    <ng-container *ngFor="let answer of row.answers">
                      <div class="col-12 col-md-auto">
                        <mat-radio-button
                          [value]="answer.id"
                          [ngClass]="{ 'validation-radio': form.controls[getControlName(row.id)].touched &&
                             form.controls[getControlName(row.id)].errors?.['required'] != null}"
                        >
                          <span class="input__radio-label">
                            {{ answer.title }}
                          </span>
                        </mat-radio-button>
                      </div>
                    </ng-container>
                  </mat-radio-group>

                  <mat-error *ngIf="form.controls[getControlName(row.id)].touched">
                    <div *ngIf="form.controls[getControlName(row.id)].errors?.['required'] != null">
                      <small class="radio-error">
                        {{ '%[global.error-messages.field-required]%' | tr }}
                      </small>
                    </div>
                  </mat-error>
                </div>
              </ng-template>

              <ng-template ngSwitchCase="DateOfBirth">
                <p class="label label__date-of-birth">
                  <label *ngIf="row.field != null" [ngClass]="{ required: hasRequiredAsterisk$(row.id) | async }">
                    {{ row.description }}
                  </label>
                </p>

                <div class="input__date-picker">
                  <ff-date-picker
                    class="w-100"
                    [singleLabel]="row.field"
                    [dataType]="'dateOfBirth'"
                    [minAge]="minAge$$ | async"
                    [formControlName]="getControlName(row.id)"
                  ></ff-date-picker>
                </div>
              </ng-template>

              <ng-template ngSwitchCase="Address">
                <div class="input__address">
                  <p class="label label__address">
                    <label *ngIf="row.field != null" [ngClass]="{ required: hasRequiredAsterisk$(row.id) | async }">
                      {{ row.description }}
                    </label>
                  </p>
                  <app-address-form-control
                    class="input input__address-control"
                    [formControlName]="getControlName(row.id)"
                    [elementWidth]="'48%'"
                  ></app-address-form-control>
                </div>
              </ng-template>

              <ng-template ngSwitchCase="Checkbox">
                <p class="label label__text label__text-only">
                  <label *ngIf="row.field != null"> {{ row.description }} {{ row.answers[0]?.title }} </label>
                  <ng-container *ngIf="row.annotationLink != null">
                    <i
                      class="ff-icon ff-info-circle icon-size-3"
                      matTooltip="{{ '%[tooltip.label.info]%' | tr }}"
                      (click)="onShowMoreInfoClick(row.annotationLink)"
                    ></i>
                  </ng-container>
                </p>
              </ng-template>
            </div>
          </div>
        </ng-container>
      </mat-dialog-content>
      <mat-dialog-actions>
        <div class="row flex-grow-1 d-flex justify-content-end gx-3">
          <div class="col-6 col-sm-auto">
            <button mat-stroked-button class="btn-block" (click)="onCloseClick()">
              {{ '%[private.aml-questionnaire-form.button-label.cancel]%' | tr }}
            </button>
          </div>
          <div class="col-6 col-sm-auto">
            <button mat-flat-button color="accent" class="btn-block" (click)="onSubmitEvent()">
              {{ '%[private.aml-questionnaire-form.button-label.confirm]%' | tr }}
            </button>
          </div>
        </div>
      </mat-dialog-actions>
    </form>
  </ng-container>
</ng-container>

<ng-template #loadingContainer>
  <div class="form__preloader">
    <app-spinner></app-spinner>
  </div>
</ng-template>
