import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { IMyReceiver, IMyReceiverResponseModel } from './my-receivers.models';
import { MyReceiversStore } from './my-receivers.store';
import { catchError, map, mapTo, switchMap } from 'rxjs/operators';
import { withTransaction } from '@datorama/akita';

@Injectable()
export class MyReceiversService {

  constructor(
    private _http: HttpClient,
    private _myReceiversStore: MyReceiversStore
  ) { }

  getMyReceivers$(): Observable<void> {
    return of(void 0).pipe(
      withTransaction(() => {
        this._myReceiversStore.reset();
        this._myReceiversStore.setLoading(true);
      }),
      switchMap(() => this._http.get<IMyReceiverResponseModel>('profile/recipients')),
      catchError(() => {
        this._myReceiversStore.setLoading(false);
        return EMPTY;
      }),
      map((response: IMyReceiverResponseModel): IMyReceiver[] => {
        return response.recipients.map((receiver: IMyReceiver) => {
          return { ...receiver, title: `${receiver.name} ${receiver.surname} ${receiver.middleName ?? ''}` };
        });
      }),
      withTransaction(myReceivers => {
        this._myReceiversStore.upsertMany(myReceivers);
        this._myReceiversStore.setLoading(false);
      }),
      mapTo(void 0)
    );
  }
}
