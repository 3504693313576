import { NgModule } from '@angular/core';
import { IdentifyIframeService } from './identify-iframe.service';
import { IdentifyIframeComponent } from './identify-iframe.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocalizationModule } from '@ff/localization';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OverlayModule } from '@angular/cdk/overlay';
import { TextMaskModule } from 'angular2-text-mask';
import { ClockSpinnerModule } from '../../../root/private/private-components/clock-spinner/clock-spinner.module';
import { SpinnerModule } from '../../../root/components/spinner/spinner.module';
import { ImageUploadModule } from '../../../root/private/private-components/image-upload/image-upload.module';
import { FFDateModule } from '../../pipes/ff-date/ff-date.module';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SvgModule } from '../../../root/components/svg/svg.module';
import { FfSlideToggleModule } from '../../form-fields/ff-slide-toggle/ff-slide-toggle.module';
import { FfButtonToggleGroupModule } from '../../form-fields/ff-button-toggle-group/ff-button-toggle-group.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { IdentifyResultDialogComponent } from './identify-result-dialog/identify-result-dialog.component';
import { FFLocalizedMatErrorModule } from '@ff/material-controls';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LocalizationModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTooltipModule,
    CommonModule,
    OverlayModule,
    TextMaskModule,
    ClockSpinnerModule,
    SpinnerModule,
    ImageUploadModule,
    FFDateModule,
    MatRadioModule,
    MatFormFieldModule,
    FFLocalizedMatErrorModule,
    MatInputModule,
    SvgModule,
    FfSlideToggleModule,
    FfButtonToggleGroupModule,
    MatExpansionModule,
  ],
  exports: [
    IdentifyIframeComponent,
    IdentifyResultDialogComponent
  ],
  declarations: [
    IdentifyIframeComponent,
    IdentifyResultDialogComponent
  ],
  providers: [
    IdentifyIframeService,
  ]
})
export class IdenfyFrameModule {

}
