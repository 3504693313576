import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ILookupStr } from '../../../private-models/lookup-str';

export interface IHistoryCountriesState extends EntityState<ILookupStr> {
  loading: boolean
}

const idKey: keyof ILookupStr = 'id';

@StoreConfig({ name: 'history-countries', idKey })
@Injectable()
export class HistoryCountriesStore extends EntityStore<IHistoryCountriesState> {
  constructor() {
    super({ loading: false });
  }
}

@Injectable()
export class HistoryCountriesQuery extends QueryEntity<IHistoryCountriesState> {
  constructor(protected override store: HistoryCountriesStore) {
    super(store);
  }
}
