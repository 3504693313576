import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { SaveTemplatePanelQuery, SaveTemplatePanelStore } from './save-template-panel.store';
import { map, tap } from 'rxjs/operators';
import { FormStoreMapper } from '../../../../../../utils/helpers/form-store-mapper';

@Injectable()
export class SaveTemplatePanelService {

  saveTemplatePanelFormGroup: FormGroup = new FormGroup({
    saveAsTemplate: new FormControl(null),
    templateName: new FormControl(null),
  });

  formMapper: FormStoreMapper = new FormStoreMapper(this.saveTemplatePanelFormGroup);
  isValidatorsOn$$: Observable<boolean> = this.saveTemplatePanelQuery.isSaveAsTemplate$$;

  constructor(
    private saveTemplatePanelStore: SaveTemplatePanelStore,
    private saveTemplatePanelQuery: SaveTemplatePanelQuery
  ) {
    this._saveTemplateFormUpdate();
    this._subscribeOnStoreChange();
    this.formMapper.setDisabled('templateName', this.saveTemplatePanelQuery.isSaveAsTemplate$$.pipe(map(isSave => !isSave)));
  }

  private _saveTemplateFormUpdate(): void {
    this.formMapper.formChange<boolean>('saveAsTemplate', value => this._setSaveAsTemplate$(value));
    this.formMapper.formChange<string>('templateName', value => this._setTemplateName$(value));
  }

  private _setSaveAsTemplate$(value: boolean): Observable<void> {
    return of(void 0).pipe(
      tap(() => {
        if (value) {
          this.saveTemplatePanelFormGroup.controls.templateName?.setValidators([Validators.required, Validators.minLength(3)]);
        } else {
          this.saveTemplatePanelStore.update(x => ({ ...x, templateName: '' }));
          this.saveTemplatePanelFormGroup.controls.templateName?.clearValidators();
        }
        // this.saveTemplatePanelFormGroup.controls.templateName.markAllAsTouched();
        this.saveTemplatePanelFormGroup.controls.templateName?.updateValueAndValidity();

        this.saveTemplatePanelStore.update(x => ({ ...x, saveAsTemplate: value }));
      })
    );
  }

  private _setTemplateName$(value: string): Observable<void> {
    return of(void 0).pipe(
      tap(() => this.saveTemplatePanelStore.update(x => ({ ...x, templateName: value })))
    );
  }

  private _subscribeOnStoreChange(): void {
    this.saveTemplatePanelQuery.isSaveAsTemplate$$.pipe(
      tap(saveAsTemplate => this.saveTemplatePanelFormGroup.patchValue({ saveAsTemplate }, { emitEvent: false }))
    ).subscribe();

    this.saveTemplatePanelQuery.templateName$$.pipe(
      tap(templateName => this.saveTemplatePanelFormGroup.patchValue({ templateName }, { emitEvent: false }))
    ).subscribe();
  }
}
