import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDocTypeValue } from './doctypes-identity.model';

export interface IDocTypesIdentityState extends EntityState<IDocTypeValue, string> {
  filter: string
}
@Injectable()
@StoreConfig({ name: 'doctypes-identity' })
export class DocTypesIdentityStore extends EntityStore<IDocTypesIdentityState> {
  constructor() {
    super({ filter: 'ALL' });
  }
}

@Injectable()
export class DocTypesIdentityQuery extends QueryEntity<IDocTypesIdentityState> {
  docTypesIdentity$$: Observable<IDocTypeValue[]> = this.selectAll();

  constructor(protected override store: DocTypesIdentityStore) {
    super(store);
  }
}
