<ng-container>
  <ff-select-input
    [label]="'%[profile.personal-data.label.country]%' | tr"
    [options]="countries$$ | async"
    [countries]="countries$$ | async"
    [searchable]="true"
    [formControl]="form.controls.country"
    [ngStyle]="{ width: elementWidth }"
    emptyOptionText="{{ '%[global.select-input.label.no-options]%' | tr }}"
  ></ff-select-input>
  <ff-text-input
    [label]="'%[profile.personal-data.label.city]%' | tr"
    name="city"
    type="text"
    preventInputType="cityStreetBuilding"
    [formControl]="form.controls.city"
    [ngStyle]="{ width: elementWidth }"
  ></ff-text-input>
  <ff-text-input
    [label]="'%[profile.personal-data.label.street]%' | tr"
    name="street"
    type="text"
    preventInputType="cityStreetBuilding"
    [formControl]="form.controls.street"
    [ngStyle]="{ width: elementWidth }"
  ></ff-text-input>
  <ff-text-input
    [label]="'%[profile.personal-data.label.building]%' | tr"
    name="building"
    type="text"
    preventInputType="cityStreetBuilding"
    [formControl]="form.controls.building"
    [ngStyle]="{ width: elementWidth }"
  ></ff-text-input>
  <ff-text-input
    [label]="'%[profile.personal-data.label.apartment]%' | tr"
    name="apartment"
    type="text"
    preventInputType="cityStreetBuilding"
    [formControl]="form.controls.apartment"
    [ngStyle]="{ width: elementWidth }"
  ></ff-text-input>
  <ff-text-input
    [label]="'%[profile.personal-data.label.post-code]%' | tr"
    name="zip"
    type="text"
    preventInputType="alphaNumeric"
    [formControl]="form.controls.postcode"
    [ngStyle]="{ width: elementWidth }"
  ></ff-text-input>
</ng-container>
