import { NgModule } from '@angular/core';
import { PersistenceQuery, PersistenceStore } from './persist.store';
import { PersistenceService } from './persistence.service';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    PersistenceStore,
    PersistenceQuery,
    PersistenceService
  ],
})
export class PersistModule {}
