import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface IRecipientResultDto {
  id: number,
  fullName: string
}

export interface IDashboardReceiversState extends EntityState<IRecipientResultDto> {
  loading: boolean
}

const idKey: keyof IRecipientResultDto = 'id';

@StoreConfig({ name: 'dashboard-receivers', idKey })
@Injectable()
export class DashboardReceiversStore extends EntityStore<IDashboardReceiversState> {
  constructor() {
    super({ loading: false });
  }
}

@Injectable()
export class DashboardReceiversQuery extends QueryEntity<IDashboardReceiversState> {
  constructor(protected override store: DashboardReceiversStore) {
    super(store);
  }
}
