import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { combineQueries, withTransaction } from '@datorama/akita';
import { EMPTY, from, Observable, of, ReplaySubject, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  map,
  mapTo,
  mergeMap,
  switchMap,
  take,
  tap,
  filter, concatMap, withLatestFrom
} from 'rxjs/operators';

import { TemplatesListService } from '../../../templates/containers/templates-list/templates-list.service';
import { SendStore } from '../../store/send.store';
import {
  BeneficiaryAdditionalFieldsFormQuery,
  BeneficiaryAdditionalFieldsFormStore
} from '../beneficiary/beneficiary-additional-fields/beneficiary-additional-fields.store';
import {
  BeneficiaryGeneralFormQuery,
  BeneficiaryGeneralFormStore
} from '../beneficiary/beneficiary-general/beneficiary-general.store';
import { BeneficiaryService } from '../beneficiary/beneficiary.service';
import { SaveTemplatePanelService } from '../save-template-panel/save-template-panel.service';
import { SaveTemplatePanelQuery, SaveTemplatePanelStore } from '../save-template-panel/save-template-panel.store';
import { DraftService } from '../draft/draft.service';
import {
  IPaymentCheckRemittanceResponseModel,
  IPaymentGatewayInfoDto, IPaymentGatewayInfoDtoWithPayoutInstruments,
  IRemittanceReceiver,
  IStartRemittanceBody
} from './send.models';
import { MyBeneficiaryStore } from '../beneficiary/beneficiary.store';
import { BeneficiaryAddressService } from '../beneficiary/beneficiary-address/beneficiary-address.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { GatewaysQuery, GatewaysStore } from '../../../../../../data-modules/gateways/gateways.store';
import { WorkflowsQuery, WorkflowsStore } from '../../../../../../data-modules/workflows/workflows.store';
import {
  AmountKeyType,
  CalculatorQuery,
  CalculatorStore
} from '../../../../../../data-modules/calculator/calculator.store';
import { SnackbarService } from '../../../../../../functionality-modules/snackbars/snackbar.service';
import { DialogService } from '../../../../../../functionality-modules/dialog-windows/dialog.service';
import {
  RequiredFieldsQuery,
  RequiredFieldsStore
} from '../../../../../../data-modules/required-fields/required-fields.store';
import { CalculatorService } from '../../../../../../data-modules/calculator/calculator.service';
import { ProfileService } from '../../../../private-store/profile.service';
import { CalculatorFormService } from '../../../../../../data-modules/calculator/calculator.form';
import { PointsQuery } from '../../../../../../data-modules/points/points.store';
import { ProfileAdditionalAttributesQuery } from '../../../../private-store/profile-additional-attributes.store';
import { ProfileStore } from '../../../../private-store/profile.store';
import { PersistenceQuery } from '../../../../../../data-modules/persist/persist.store';
import {
  CurrenciesDestinationStore
} from '../../../../../../data-modules/currencies-destination/currencies-destination.store';
import { IPoint } from '../../../../../../data-modules/points/points.model';
import {
  IStoredProcedureError
} from '../../../../../../data-modules/stored-procedure-error/stored-procedure-error.model';
import { environment } from '../../../../../../../environments/environment';
import { IApiError } from '../../../../private-models/api-error';
import { RequiredDocumentsService } from '../../../../../../data-modules/required-documents/required-documents.service';
import {
  IdentityDocumentsQuery,
  IdentityDocumentsStore
} from '../../../profile/identity-documents/store/identity-documents.store';
import { IDocumentBody } from '../../../../private-models/document.model';
import {
  ResidenceConfirmationsQuery,
  ResidenceConfirmationsStore
} from '../../../profile/additional-documents/store/residence-confirmations.store';
import {
  SourceOfIncomeStore,
  SourcesOfIncomeQuery
} from '../../../profile/additional-documents/store/source-of-income.store';
import { LocalizationQuery } from '@ff/localization';
import { IGateway } from '../../../../../../data-modules/gateways/gateways.model';
import { IWorkflow } from '../../../../../../data-modules/workflows/workflows.models';
import { IFeesResponse } from '../../../../../../data-modules/calculator/calculator.models';
import { IRemittanceCountry } from '../../../../../../data-modules/remittance-countries/remittance-countries.model';
import { IBeneficiaryGeneralForm } from '../beneficiary/beneficiary-general/beneficiary-general.model';
import {
  IBeneficiaryAdditionalFieldsForm
} from '../beneficiary/beneficiary-additional-fields/beneficiary-additional-fields.model';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { FFObject } from '@ff/utils';
import { AmlQuestionnaireModalComponent } from './containers/aml-questionnaire-modal/aml-questionnaire-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { ILookupStr } from '../../../../private-models/lookup-str';
import { IFFCheckoutRetrievePaymentMethodsModel } from 'ff-checkout/models/payment-methods.model';
import { CheckoutPaymentInstrumentsService } from '../../../../../../data-modules/checkout-payment-instruments.service';
import { RequiredDocumentsQuery } from '../../../../../../data-modules/required-documents/required-documents.store';
import { EProfileTabs } from '../../../../private-models/profile-tabs.model';
import {
  AdditionalDocsFlags,
  IAdditionalDocsFlagsType,
  EIdentityDocumentVerificationFlags,
  IIdentityDocumentsVerificationFlagsType
} from '../../../../../../data-modules/required-documents/required-documents.model';
import { SecuritySettingsStore } from '../../../security/store/security.store';
import { PersonalDataFormQuery } from '../../../profile/personal-data/personal-data.store';

@Injectable()
export class SendService {
  private _debounceScrollToEmptyField: number = environment.appSettings.controls.inputDebounceTime;
  private _isCheckForFlags$$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  sourceAmountFieldFocusTrigger$: Subject<void> = new Subject<void>();
  destinationAmountFieldFocusTrigger$: Subject<void> = new Subject<void>();
  isCheckForFlags$$: Observable<boolean> = this._isCheckForFlags$$.asObservable();

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _sendStore: SendStore,
    private _gatewaysQuery: GatewaysQuery,
    private _gatewaysStore: GatewaysStore,
    private _workflowsQuery: WorkflowsQuery,
    private _workflowsStore: WorkflowsStore,
    private _calculatorStore: CalculatorStore,
    private _snackbarService: SnackbarService,
    private _beneficiaryService: BeneficiaryService,
    private _dialogService: DialogService,
    private _requiredFieldsStore: RequiredFieldsStore,
    private _requiredFieldsQuery: RequiredFieldsQuery,
    private _calculatorService: CalculatorService,
    private _localizationQ: LocalizationQuery,
    private _profileService: ProfileService,
    private _calculatorFormService: CalculatorFormService,
    private _pointsQuery: PointsQuery,
    private _remittanceDraftService: DraftService,
    private _templatesListService: TemplatesListService,
    private _beneficiaryGeneralFormStore: BeneficiaryGeneralFormStore,
    private _beneficiaryGeneralFormQuery: BeneficiaryGeneralFormQuery,
    private _beneficiaryAdditionalFieldsFormQuery: BeneficiaryAdditionalFieldsFormQuery,
    private _saveTemplatePanelQuery: SaveTemplatePanelQuery,
    private _saveTemplatePanelService: SaveTemplatePanelService,
    private _profileAdditionalAttributesQuery: ProfileAdditionalAttributesQuery,
    private _scrollToService: PageScrollService,
    private _persistenceQuery: PersistenceQuery,
    private _myBeneficiaryStore: MyBeneficiaryStore,
    private _beneficiaryAdditionalFieldsFormStore: BeneficiaryAdditionalFieldsFormStore,
    private _beneficiaryAddressService: BeneficiaryAddressService,
    private _currenciesDestinationStore: CurrenciesDestinationStore,
    private _saveAsTemplatePanelFormStore: SaveTemplatePanelStore,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _requiredDocumentsService: RequiredDocumentsService,
    private _profileStore: ProfileStore,
    private _identityDocumentsStore: IdentityDocumentsStore,
    private _identityDocumentsQuery: IdentityDocumentsQuery,
    private _residenceConfirmationsStore: ResidenceConfirmationsStore,
    private _residenceConfirmationsQ: ResidenceConfirmationsQuery,
    private _sourcesOfIncomeStore: SourceOfIncomeStore,
    private _sourcesOfIncomeQuery: SourcesOfIncomeQuery,
    private _dialogController: MatDialog,
    private _calculatorQuery: CalculatorQuery,
    private _paymentInstrumentsService: CheckoutPaymentInstrumentsService,
    private _requiredDocumentsQuery: RequiredDocumentsQuery,
    private _securitySettingsStore: SecuritySettingsStore,
    private _personalDataQuery: PersonalDataFormQuery,
    @Inject(DOCUMENT) private document: Document
  ) {}

  checkForFlags$(): Observable<void> {
    return this._requiredDocumentsService.updateRequiredDocuments$().pipe(
      tap(() => this._isCheckForFlags$$.next(true)),
      concatMap(() => this._profileService.updateProfile$()),
      concatMap(() => this._checkPaymentAwaiting$()),
      concatMap(() => this._requiredDocumentsQuery.isPersonalDataRequired$$),
      take(1),
      concatMap((isPersonalDataRequired: boolean) => this._checkPersonalData$(isPersonalDataRequired))
    ).pipe(
      concatMap(() => this._requiredDocumentsQuery.isIdentityDocumentRequired$$),
      take(1),
      concatMap((isIdentityDocumentRequired: boolean) => this._checkIdentityDocument$(isIdentityDocumentRequired))
    ).pipe(
      concatMap(() => this._requiredDocumentsQuery.isIDVerificationRequired$$),
      take(1),
      concatMap(isIDVerificationRequired => this._checkIDVerification$(isIDVerificationRequired))
    ).pipe(
      concatMap(() => this._requiredDocumentsQuery.isKYCQuestionnaireRequiredOrExpired$$),
      take(1),
      concatMap(isKYCQuestionnaireRequired => this._checkKYCQuestionnaire$(isKYCQuestionnaireRequired))
    ).pipe(
      concatMap(() => this._requiredDocumentsQuery.isProofOfAddressRequired$$),
      take(1),
      concatMap(isProofOfAddressRequired => this._checkPOA$(isProofOfAddressRequired))
    ).pipe(
      concatMap(() => this._requiredDocumentsQuery.isSourceOfIncomeRequired$$),
      take(1),
      concatMap(isSourceOfIncomeRequired => this._checkSOI$(isSourceOfIncomeRequired))
    ).pipe(
      concatMap(() => this._requiredDocumentsQuery.isExtraDocumentsRequired$$),
      take(1),
      concatMap(isExtraDocumentsRequired => this._checkED$(isExtraDocumentsRequired))
    ).pipe(
      concatMap(() => this._requiredDocumentsQuery.isEmailValidatePossessionRequired$$),
      take(1),
      concatMap(isEmailValidatePossessionRequired => this._checkEmailValidatePossession$(isEmailValidatePossessionRequired))
    ).pipe(
      concatMap(() => this._requiredDocumentsQuery.isSendingRefused$$),
      take(1),
      concatMap((isRefusedToSend: boolean | null) => this._checkRefusedToSend(isRefusedToSend))
    ).pipe(
      concatMap(() => this._requiredDocumentsQuery.isReadonlyAccess$$),
      take(1),
      concatMap((isReadonlyAccess: boolean | null) => this._checkReadonlyAccess(isReadonlyAccess))
    ).pipe(
      concatMap(() => this._requiredDocumentsQuery.isServiceRefused$$),
      take(1),
      concatMap((isServiceRefused: boolean | null) => this._checkServiceRefused(isServiceRefused)),
      tap(() => this._isCheckForFlags$$.next(false)),
      mapTo(void 0)
    );
  }

  onPayLater$(transactionId: string): Observable<void> {
    return from(this._router.navigate(['private', 'home'])).pipe(
      switchMap(() => this._http.post(`remittance/pay-later-notify/${transactionId}`, null)),
      catchError(() => EMPTY),
      mapTo(void 0)
    );
  }

  // start sending money
  onComplicatedStart$$(): Observable<void> {
    return this._requiredFieldsQuery.hasBeneficiaryAddress$$
      .pipe(
        take(1),
        map((hasBeneficiaryAddress: boolean): FormGroup[] => {
          const arrOfForms: (FormGroup | null)[] = [
            this._calculatorFormService.calculatorFormGroup,
            this._beneficiaryService.beneficiaryGeneralService.beneficiaryGeneralForm,
            hasBeneficiaryAddress ? this._beneficiaryService.beneficiaryAddressService.beneficiaryAddressForm : null,
            this._saveTemplatePanelService.saveTemplatePanelFormGroup,
          ];
          return arrOfForms.filter((form) => form != null) as FormGroup[];
        })
      )
      .pipe(
        switchMap((formGroups: FormGroup[]): Observable<void | never> => {
            let scrolled = false;
            if (formGroups.some((formGroup) => formGroup.invalid)) {
              for (const formGroup of formGroups) {
                const controlNames = FFObject.keys(formGroup.controls) as string[];

                for (const controlName of controlNames) {
                  const ctrl = formGroup.controls[controlName];
                  if (ctrl == null) {
                    throw new Error(controlName + ' is not found in the form');
                  }

                  if (
                    ctrl.invalid &&
                    !scrolled &&
                    !['phoneNumber', 'remittancePurpose', 'senderSourceOfIncomeType'].includes(controlName)
                  ) {
                    this._scrollToService.scroll({
                      document: this.document,
                      scrollTarget: controlName,
                      scrollOffset: -200,
                    });
                    scrolled = true;
                  }
                  ctrl.markAllAsTouched();
                  ctrl.updateValueAndValidity();
                }
              }
              return EMPTY;
            }
            return of(void 0);
          }
        ),
        tap(() => this._sendStore.setLoading(true)),
        switchMap(() => this._persistenceQuery.select((x) => x.isWizardChecksEnabled)),
        take(1),
        switchMap((isWizardChecksEnabled: boolean) => {
          if (isWizardChecksEnabled) {
            return this.checkForFlags$();
          }
          return of(void 0);
        }),
        switchMap(() => this._completeInitiateRemittance$$())
      );
  }

  apiCancel$$(): Observable<void> {
    return of(0).pipe(
      tap(() => this._sendStore.setLoading(true)),
      map(() => this._sendStore.getValue().paymentGatewayParams.orderreference),
      map((ref) => {
        if (ref != null) {
          return ref;
        }
        throw new Error('ref is null');
      }),
      // TODO: add catcherror with root-store procedures error service
      switchMap((transactionId: string) =>
        this._http.post(`remittance/cancel/${transactionId}`, null).pipe(
          catchError((error: IApiError) => {
            return this._snackbarService
              .openFailure$(error.error.errors.map((e) => e.message).join('\n'), {
                title: this._localizationQ.transform('%[send.snackbar.cancel-failed]%')
              })
              .pipe(
                mergeMap(() => {
                  this._sendStore.setLoading(false);
                  return EMPTY;
                })
              );
          })
        )
      ),
      tap(() => this._sendStore.setLoading(false)),
      mapTo(void 0)
    );
  }

  onCancelButton$$(): Observable<void> {
    return this._dialogService.openConfirmDialog$(this._localizationQ.transform('%[send.dialog.confirm-prompt]%')).pipe(
      switchMap((result: boolean) => {
        if (result) {
          return this._vanishSendForm$().pipe(
            switchMap(() => this._remittanceDraftService.deleteDraft$()),
            concatMap(() => from(this._router.navigate(['private', 'home']))),
            mapTo(void 0)
          );
        }
        return of(void 0);
      }),
      concatMap(() => this._requiredDocumentsService.updateRequiredDocuments$())
    );
  }

  scrollToFirstEmptyField$(): Observable<void> {
    return this._requiredFieldsQuery.hasBeneficiaryAddress$$.pipe(
      take(1),
      map((hasBeneficiaryAddress: boolean): FormGroup[] => {
        const arrOfForms: (FormGroup | null)[] = [
          this._calculatorFormService.calculatorFormGroup,
          this._beneficiaryService.beneficiaryGeneralService.beneficiaryGeneralForm,
          hasBeneficiaryAddress ? this._beneficiaryService.beneficiaryAddressService.beneficiaryAddressForm : null,
          this._beneficiaryService.beneficiaryAdditionalFieldsService.additionalFieldsFormGroup,
          this._saveTemplatePanelService.saveTemplatePanelFormGroup
        ];
        return arrOfForms.filter((form) => form != null) as FormGroup[];
      }),
      switchMap(
        (formGroups: FormGroup[]): Observable<void> => {
          if (formGroups.some((e) => e.invalid)) {
            for (const formGroup of formGroups) {
              const controlNames = FFObject.keys(formGroup.controls);

              for (const controlName of controlNames) {
                const ctrl = formGroup.controls[controlName];
                if (ctrl != null && ctrl.invalid) {
                  ctrl.markAllAsTouched();
                  ctrl.updateValueAndValidity();
                  return of(void 0).pipe(
                    tap(() => {
                      this._scrollToService.scroll({
                        document: this.document,
                        scrollTarget: `#controls.${controlName}`,
                        scrollOffset: -200,
                      });
                    })
                  );
                }
              }
            }
          }
          return of(void 0).pipe(
            tap(() => {
              this._scrollToService.scroll({
                document: this.document,
                scrollTarget: '#start-remittance'
              });
            })
          );
        }
      ),
      debounceTime(this._debounceScrollToEmptyField),
      take(1)
    );
  }

  private _checkPersonalData$(isPersonalDataRequired: boolean): Observable<void> {
    if (isPersonalDataRequired) {
      return this._dialogService.openProveYourIdentity$().pipe(
        concatMap(() => from(this._router.navigate(['private', 'profile']))),
        concatMap(() => this._profileStore.setActiveTab$(EProfileTabs.PersonalInfo)),
        concatMap(() => this._profileStore.setWizard$(true)),
        concatMap(() =>
          this._snackbarService.openWarning(
            this._localizationQ.transform('%[flags.snackbar.message.pd-required]%'),
            {
              title: this._localizationQ.transform('%[flags.snackbar.title.pd-required]%'),
              duration: environment.appSettings.snackbar.longDuration,
            }
          )
        ),
        tap(() => this._sendStore.setLoading(false)),
        concatMap(() => EMPTY),
      );
    }
    return of(void 0);
  }

  private _checkIdentityDocument$(isIdentityDocumentRequired: boolean): Observable<void> {
    if (isIdentityDocumentRequired) {
      return this._dialogService.openProveYourIdentity$().pipe(
        concatMap(() => from(this._router.navigate(['private', 'profile']))),
        concatMap(() => this._profileStore.setActiveTab$(EProfileTabs.IdentityDocuments)),
        concatMap(() => this._profileStore.setWizard$(true)),
        concatMap(() =>
          this._snackbarService.openWarning(
            this._localizationQ.transform('%[flags.snackbar.message.id-required-no-doc]%'),
            {
              title: this._localizationQ.transform('%[flags.snackbar.title.id-required-no-doc]%'),
              duration: environment.appSettings.snackbar.longDuration,
            }
          )
        ),
        withLatestFrom(this._identityDocumentsQuery.selectAll(), this._requiredDocumentsQuery.isIdentityDocumentExpired$$),
        tap(([_, idDocs, isDocExpired]) => {
          this._identityDocumentsStore.ui.update((x) => ({ ...x, newDocFormIsOpen: true }));
          if (!isDocExpired) {
            const firstDoc = idDocs[0];
            if (firstDoc == null) { return; }
            this._identityDocumentsStore.ui.upsert(firstDoc.id, { isExpanded: true, isEdit: true });
          }
        }),
        tap(() => this._sendStore.setLoading(false)),
        concatMap(() => EMPTY)
      );
    }
    return of(void 0);
  }

  private _checkKYCQuestionnaire$(isKYCQuestionnaireRequired: boolean): Observable<void> {
    if (isKYCQuestionnaireRequired) {
      return this._dialogService.openProveKYCQuestionnaire$().pipe(
        concatMap(() => from(this._router.navigate(['private', 'profile']))),
        concatMap(() => this._profileStore.setActiveTab$(EProfileTabs.KYC)),
        concatMap(() => this._profileStore.setWizard$(true)),
        concatMap(() =>
          this._snackbarService.openWarning(
            this._localizationQ.transform('%[flags.snackbar.message.kyc-required]%'),
            {
              title: this._localizationQ.transform('%[flags.snackbar.title.kyc-required]%'),
              duration: environment.appSettings.snackbar.longDuration,
            }
          )
        ),
        tap(() => this._sendStore.setLoading(false)),
        concatMap(() => EMPTY),
      );
    }
    return of(void 0);
  }

  private _checkIDVerification$(isIDVerificationRequired: boolean): Observable<void> {
    let identityDocumentVerificationFlag: string | null;
    if (isIDVerificationRequired) {
      return this._requiredDocumentsQuery.identityDocumentVerification$$.pipe(
        take(1),
        concatMap((identityDocumentVerification: IIdentityDocumentsVerificationFlagsType | null) => {
          identityDocumentVerificationFlag = identityDocumentVerification?.toLowerCase() ?? null;
          return this._dialogService.openProveYourIdentity$();
        }),
        concatMap(() => from(this._router.navigate(['private', 'profile']))),
        concatMap(() => this._profileStore.setActiveTab$(EProfileTabs.IdentityDocuments)),
        concatMap(() => this._profileStore.setWizard$(true)),
        concatMap(() => {
          let message: string;
          let title: string;
          switch (identityDocumentVerificationFlag) {
            case EIdentityDocumentVerificationFlags.Required:
              message = this._localizationQ.transform('%[flags.snackbar.message.id-required-no-attach]%');
              title = this._localizationQ.transform('%[flags.snackbar.title.id-required-no-attach]%');
              break;
            case EIdentityDocumentVerificationFlags.Wait:
              message = this._localizationQ.transform('%[flags.snackbar.message.id-required-wait]%');
              title = this._localizationQ.transform('%[flags.snackbar.title.id-required-wait]%');
              break;
            default:
              message = '';
              title = '';
              break;
          }
          return this._snackbarService.openWarning(message, { title, duration: environment.appSettings.snackbar.longDuration });
        })
      ).pipe(
        concatMap(() => this._identityDocumentsQuery.selectAll()),
        take(1),
        tap((idDocs: IDocumentBody[]) => {
          this._identityDocumentsStore.ui.update(({ newDocFormIsOpen: false }));
          const primary: IDocumentBody | null = idDocs.find((x) => x.document.isPrimary) ?? null;
          if (primary != null) {
            this._identityDocumentsStore.ui.upsert(primary.id, (x) => ({
              ...x,
              isExpanded: true,
              isEdit: true,
            }));
          }
        }),
        tap(() => this._sendStore.setLoading(false)),
        concatMap(() => EMPTY)
      );
    }

    return combineQueries([this._requiredDocumentsQuery.isIDOnVerification$$, this._requiredDocumentsQuery.isIDVerificationWait$$]).pipe(
      take(1),
      concatMap(([isIDOnVerification, isIDVerificationWait]) => {
        if (isIDOnVerification) {
          return this._openIdOnVerificationDialog$();
        }
        if (isIDVerificationWait) {
          return this._openIdVerificationWaitingDialog$();
        }
        return of(void 0);
      }),
      mapTo(void 0)
    );
  }

  private _openIdOnVerificationDialog$(): Observable<void> {
    return this._dialogService.openIdentityOnVerification$().pipe(
      tap(() => this._sendStore.setLoading(false)),
      concatMap(() => EMPTY)
    );
  }

  private _openIdVerificationWaitingDialog$(): Observable<void> {
    const message = this._localizationQ.transform('%[send.error.message.check-identity-document]%');
    return this._dialogService.openWizardAwaiting$(message).pipe(
      tap(() => this._sendStore.setLoading(false)),
      concatMap(() => EMPTY)
    );
  }

  private _checkPOA$(isPOAScansRequired: boolean): Observable<void> {
    let proofOfAddressFlag: string | null;
    if (isPOAScansRequired) {
      return this._requiredDocumentsQuery.proofOfAddress$$.pipe(
        take(1),
        concatMap((proofOfAddress: IAdditionalDocsFlagsType | null) => {
          proofOfAddressFlag = proofOfAddress?.toLowerCase() ?? null;
          return this._dialogService.openProveYourIdentity$();
        }),
        concatMap(() => from(this._router.navigate(['private', 'profile']))),
        concatMap(() => this._profileStore.setActiveTab$(EProfileTabs.AdditionalDocuments)),
        concatMap(() => this._profileStore.setWizard$(true)),
        concatMap(() => {
          let message: string;
          let title: string;
          switch (proofOfAddressFlag) {
            case AdditionalDocsFlags.Required:
              message = this._localizationQ.transform('%[flags.snackbar.message.poa-required-no-attach]%');
              title = this._localizationQ.transform('%[flags.snackbar.title.poa-required-no-attach]%');
              break;
            case AdditionalDocsFlags.NoAttach:
              message = this._localizationQ.transform('%[flags.snackbar.message.poa-required-no-attach]%');
              title = this._localizationQ.transform('%[flags.snackbar.title.poa-required-no-attach]%');
              break;
            case AdditionalDocsFlags.Wait:
              message = this._localizationQ.transform('%[flags.snackbar.message.poa-required-wait]%');
              title = this._localizationQ.transform('%[flags.snackbar.title.poa-required-wait]%');
              break;
            case AdditionalDocsFlags.BadAttach:
              message = this._localizationQ.transform('%[flags.snackbar.message.poa-required-bad-attach]%');
              title = this._localizationQ.transform('%[flags.snackbar.title.poa-required-bad-attach]%');
              break;
            case AdditionalDocsFlags.Expired:
              message = this._localizationQ.transform('%[flags.snackbar.message.poa-expired]%');
              title = this._localizationQ.transform('%[flags.snackbar.title.poa-expired]%');
              break;
            default:
              message = '';
              title = '';
              break;
          }
          return this._snackbarService.openWarning(message, { title, duration: environment.appSettings.snackbar.longDuration });
        })
      ).pipe(
        concatMap(() => this._residenceConfirmationsQ.selectAll()),
        take(1),
        withTransaction((rcs: IDocumentBody[]) => {
          if (proofOfAddressFlag === AdditionalDocsFlags.Required) {
            this._residenceConfirmationsStore.ui.update(x => ({ ...x, newDocFormIsOpen: true }));
          } else if (proofOfAddressFlag === AdditionalDocsFlags.NoAttach) {
            if (rcs.length > 0) {
              rcs.forEach((rc: IDocumentBody) => {
                this._residenceConfirmationsStore.ui.upsert(rc.id, { isExpanded: true, isEdit: true });
              });
            } else {
              this._residenceConfirmationsStore.ui.update(x => ({ ...x, newDocFormIsOpen: true }));
            }
          } else if (proofOfAddressFlag === AdditionalDocsFlags.Expired) {
            this._residenceConfirmationsStore.ui.update({ newDocFormIsOpen: true });
          } else {
            const firstDoc = rcs[0];
            if (firstDoc == null) { return; }
            this._residenceConfirmationsStore.ui.upsert(firstDoc.id, { isExpanded: false, isEdit: false });
          }
          this._sendStore.setLoading(false);
        }),
        concatMap(() => EMPTY)
      );
    }
    return of(void 0);
  }

  private _checkSOI$(isSOIScansRequired: boolean): Observable<void> {
    let sourceOfIncomeFlag: string | null;
    if (isSOIScansRequired) {
      return this._requiredDocumentsQuery.sourceOfIncome$$.pipe(
        take(1),
        concatMap((sourceOfIncome: IAdditionalDocsFlagsType | null) => {
          sourceOfIncomeFlag = sourceOfIncome?.toLowerCase() ?? null;
          return this._dialogService.openProveYourIdentity$();
        }),
        concatMap(() => from(this._router.navigate(['private', 'profile']))),
        concatMap(() => this._profileStore.setActiveTab$(EProfileTabs.AdditionalDocuments)),
        concatMap(() => this._profileStore.setWizard$(true)),
        concatMap(() => {
          let message: string;
          let title: string;
          switch (sourceOfIncomeFlag) {
            case AdditionalDocsFlags.Required:
              message = this._localizationQ.transform('%[flags.snackbar.message.soi-required-no-attach]%');
              title = this._localizationQ.transform('%[flags.snackbar.title.soi-required-no-attach]%');
              break;
            case AdditionalDocsFlags.NoAttach:
              message = this._localizationQ.transform('%[flags.snackbar.message.soi-required-no-attach]%');
              title = this._localizationQ.transform('%[flags.snackbar.title.soi-required-no-attach]%');
              break;
            case AdditionalDocsFlags.Wait:
              message = this._localizationQ.transform('%[flags.snackbar.message.soi-required-wait]%');
              title = this._localizationQ.transform('%[flags.snackbar.title.soi-required-wait]%');
              break;
            case AdditionalDocsFlags.BadAttach:
              message = this._localizationQ.transform('%[flags.snackbar.message.soi-required-bad-attach]%');
              title = this._localizationQ.transform('%[flags.snackbar.title.soi-required-bad-attach]%');
              break;
            case AdditionalDocsFlags.Expired:
              message = this._localizationQ.transform('%[flags.snackbar.message.soi-expired]%');
              title = this._localizationQ.transform('%[flags.snackbar.title.soi-expired]%');
              break;
            default:
              message = '';
              title = '';
              break;
          }
          return this._snackbarService.openWarning(message, { title, duration: environment.appSettings.snackbar.longDuration });
        })
      ).pipe(
        concatMap(() => this._sourcesOfIncomeQuery.selectAll()),
        take(1),
        withTransaction((rcs: IDocumentBody[]) => {
          if (sourceOfIncomeFlag === AdditionalDocsFlags.Required) {
            this._sourcesOfIncomeStore.ui.update(x => ({ ...x, newDocFormIsOpen: true }));
          } else if (sourceOfIncomeFlag === AdditionalDocsFlags.NoAttach) {
            if (rcs.length > 0) {
              rcs.forEach((rc: IDocumentBody) => {
                this._sourcesOfIncomeStore.ui.upsert(rc.id, { isExpanded: true, isEdit: true });
              });
            } else {
              this._sourcesOfIncomeStore.ui.update(x => ({ ...x, newDocFormIsOpen: true }));
            }
          } else if (sourceOfIncomeFlag === AdditionalDocsFlags.Expired) {
            this._sourcesOfIncomeStore.ui.update({ newDocFormIsOpen: true });
          } else {
            const firstDoc = rcs[0];
            if (firstDoc == null) { return; }
            this._sourcesOfIncomeStore.ui.upsert(firstDoc.id, { isExpanded: false, isEdit: false });
          }
          this._sendStore.setLoading(false);
        }),
        concatMap(() => EMPTY)
      );
    }
    return of(void 0);
  }

  private _checkED$(isEDScansRequired: boolean): Observable<void> {
    let extraDocumentsFlag: string | null;
    if (isEDScansRequired) {
      return this._requiredDocumentsQuery.extraDocuments$$.pipe(
        take(1),
        concatMap((extraDocuments: IAdditionalDocsFlagsType | null) => {
          extraDocumentsFlag = extraDocuments?.toLowerCase() ?? null;
          return this._dialogService.openProveYourIdentity$();
        }),
        concatMap(() => from(this._router.navigate(['private', 'profile']))),
        concatMap(() => this._profileStore.setActiveTab$(EProfileTabs.AdditionalDocuments)),
        concatMap(() => this._profileStore.setWizard$(true)),
        concatMap(() => {
          let message: string;
          let title: string;
          switch (extraDocumentsFlag) {
            case AdditionalDocsFlags.Required:
              message = this._localizationQ.transform('%[flags.snackbar.message.ed-required-no-attach]%');
              title = this._localizationQ.transform('%[flags.snackbar.title.ed-required-no-attach]%');
              break;
            case AdditionalDocsFlags.Expired:
              message = this._localizationQ.transform('%[flags.snackbar.message.ed-expired-no-attach]%');
              title = this._localizationQ.transform('%[flags.snackbar.title.ed-expired-no-attach]%');
              break;
            case AdditionalDocsFlags.NoAttach:
              message = this._localizationQ.transform('%[flags.snackbar.message.ed-required-no-attach]%');
              title = this._localizationQ.transform('%[flags.snackbar.title.ed-required-no-attach]%');
              break;
            case AdditionalDocsFlags.Wait:
              message = this._localizationQ.transform('%[flags.snackbar.message.ed-required-wait]%');
              title = this._localizationQ.transform('%[flags.snackbar.title.ed-required-wait]%');
              break;
            case AdditionalDocsFlags.BadAttach:
              message = this._localizationQ.transform('%[flags.snackbar.message.ed-required-bad-attach]%');
              title = this._localizationQ.transform('%[flags.snackbar.title.ed-required-bad-attach]%');
              break;
            default:
              message = '';
              title = '';
              break;
          }
          return this._snackbarService.openWarning(message, { title, duration: environment.appSettings.snackbar.longDuration });
        })
      ).pipe(
        tap(() => {
          this._sendStore.setLoading(false);
        }),
        concatMap(() => EMPTY)
      );
    }
    return of(void 0);
  }

  private _checkEmailValidatePossession$(isEmailValidatePossessionRequired: boolean): Observable<void> {
    if (isEmailValidatePossessionRequired) {
      return this._dialogService.openEmailVerification$().pipe(
        take(1),
        concatMap(() => from(this._router.navigate(['private', 'security']))),
        concatMap(() => this._securitySettingsStore.setWizard$(true)),
        concatMap(() => this._snackbarService.openWarning(
          this._localizationQ.transform('%[flags.snackbar.message.email-required]%'),
          { title: this._localizationQ.transform('%[flags.snackbar.title.email-required]%'), duration: environment.appSettings.snackbar.longDuration })
        ),
        tap(() => this._sendStore.setLoading(false)),
        concatMap(() => EMPTY)
      );
    }
    return of(void 0);
  }

  private _checkRefusedToSend(isRefusedToSend: boolean | null): Observable<void> {
    if (isRefusedToSend === true) {
      return this._dialogService.openServiceDenied$().pipe(
        tap(() => this._sendStore.setLoading(false)),
        switchMap(() => EMPTY)
      );
    }
    return of(void 0);
  }

  private _checkReadonlyAccess(isReadonlyAccess: boolean | null): Observable<void> {
    if (isReadonlyAccess === true) {
      return this._dialogService.openReadonlyOnline$().pipe(
        tap(() => {
          this._sendStore.setLoading(false);
        }),
        switchMap(() => EMPTY)
      );
    }
    return of(void 0);
  }

  private _checkServiceRefused(isServiceRefused: boolean | null): Observable<void> {
    if (isServiceRefused === true) {
      return this._dialogService.openServiceDeniedStrict$().pipe(
        tap(() => this._sendStore.setLoading(false)),
        switchMap(() => EMPTY)
      );
    }
    return of(void 0);
  }

  private _openAmlQuestionnaireModal$(transactionId: string): Observable<boolean> {
    return this._dialogController
      .open(AmlQuestionnaireModalComponent, {
        data: { transactionId },
        maxWidth: 800,
        width: '100%',
        disableClose: true
      })
      .afterClosed();
  }

  private _gatherDataAndStartRemittance$(): Observable<IPaymentGatewayInfoDto> {
    return combineQueries([
      this._gatewaysQuery.activeGateway$$,
      this._workflowsQuery.activeWorkflow$$,
      this._calculatorQuery.amountKey$$,
      this._calculatorQuery.calculationResult$$,
      this._calculatorQuery.destinationCountry$$,
      this._pointsQuery.state$$.pipe(
        map((points: IPoint[]) => points.filter((p) => p.selected)),
        map((points: IPoint[]): number[] => points.map((ponit) => ponit.pointId))
      ),
      this._beneficiaryGeneralFormQuery.state$$,
      this._beneficiaryAdditionalFieldsFormQuery.select()
    ]).pipe(
      take(1),
      switchMap(
        ([
          activeGateway,
          activeWorkflow,
          amountKey,
          calculationResult,
          destinationCountry,
          selectedPoints,
          beneficiaryGeneralForm,
          beneficiaryAdditionalFieldsForm,
        ]): Observable<[
            IGateway,
            IWorkflow | undefined,
            0 | 1,
            IFeesResponse,
            IRemittanceCountry | null,
            number[],
            IBeneficiaryGeneralForm,
            IBeneficiaryAdditionalFieldsForm
          ]
        > => {
          if (activeGateway == null) {
            console.error('active gateway is not provided');
            return EMPTY;
          }

          return of([
            activeGateway,
            activeWorkflow,
            amountKey,
            calculationResult,
            destinationCountry,
            selectedPoints,
            beneficiaryGeneralForm,
            beneficiaryAdditionalFieldsForm
          ]);
        }
      ),
      map(
        ([
          activeGateway,
          activeWorkflow,
          amountKey,
          calculationResult,
          destinationCountry,
          selectedPoints,
          beneficiaryGeneralForm,
          beneficiaryAdditionalFieldsForm
        ]): IStartRemittanceBody => {
          const sourceCurrency: ILookupStr = this._calculatorFormService.calculatorFormGroup.controls?.sourceCurrency?.value;
          const destinationCurrency: ILookupStr = this._calculatorFormService.calculatorFormGroup.controls?.destinationCurrency?.value;

          const receiver = {
            ...{
              ...beneficiaryGeneralForm,
              dateOfBirth: beneficiaryGeneralForm.dateOfBirth === '' ? null : beneficiaryGeneralForm.dateOfBirth
            },
            ...this._beneficiaryService.beneficiaryAddressService.beneficiaryAddressForm.value
          };

          return this._remappedStartRemittanceBody (
            activeGateway,
            activeWorkflow,
            amountKey,
            calculationResult,
            destinationCountry,
            selectedPoints,
            beneficiaryGeneralForm,
            beneficiaryAdditionalFieldsForm,
            sourceCurrency,
            destinationCurrency,
            receiver
          );

        }
      ),
      switchMap((startRemittanceBody: IStartRemittanceBody) =>
        // сначала проверяем уровень риска, если риск Medium/High - то юзер заполняет анкету, и при успешной отправке AML анкеты создается платеж
        forkJoin(this._startCheckRemittanceForRisk$(startRemittanceBody), of(startRemittanceBody))
      ),
      switchMap(([checkRemittanceForRiskResult, startRemittanceBody]) => {
        const remappedData: IStartRemittanceBody = {
          ...startRemittanceBody,
          transactionId: checkRemittanceForRiskResult.transactionId,
          confirmationCode: checkRemittanceForRiskResult.confirmationCode
        };

        if (checkRemittanceForRiskResult.isAmlRequired) {
          // вызывать модалку с формой для заполнения, если успешно заполнена - вызывать apiStartRemittance$
          return this._openAmlQuestionnaireModal$(checkRemittanceForRiskResult.transactionId).pipe(
            tap(() => this._sendStore.setLoading(false)),
            filter((result: boolean) => result),
            switchMap(() => this._apiCreateRemittance$(remappedData))
          );
        }
        return this._apiCreateRemittance$(remappedData);
      })
    );
  }

  private _mapStartRemittanceResponseToPayoutInstrumentsRequest(response: IPaymentGatewayInfoDto): IFFCheckoutRetrievePaymentMethodsModel {
    return {
      order: {
        orderId: response.transactionId ?? '',
        currency: response.currency ?? '',
        amount: response.amountInfo?.totalAmount ?? 0
      },
      customer: {
        country: response.remitterInfo?.address?.country ?? ''
      }
    };
  }

  private _remappedStartRemittanceBody (
    activeGateway: IGateway,
    activeWorkflow: IWorkflow | undefined,
    amountKey: AmountKeyType,
    calculationResult: IFeesResponse,
    destinationCountry: IRemittanceCountry | null,
    selectedPoints: number[],
    beneficiaryGeneralForm: IBeneficiaryGeneralForm,
    beneficiaryAdditionalFieldsForm: IBeneficiaryAdditionalFieldsForm,
    sourceCurrency: ILookupStr,
    destinationCurrency: ILookupStr,
    receiver: IRemittanceReceiver): IStartRemittanceBody {

    return {
      country: destinationCountry,
      gateway: activeGateway,
      workflow: activeWorkflow?.id,
      directionId: activeGateway.directionId,
      sourceCurrency,
      destinationCurrency,
      sourceAmount: calculationResult?.source.amount,
      destinationAmount: calculationResult?.destination.amount,
      commission: calculationResult?.source.commission,
      receiver,
      transferPurpose: receiver.remittancePurpose,
      sourceOfIncome: beneficiaryGeneralForm.senderSourceOfIncomeType ?? null,
      additionalFields: {
        beneficiaryPan: beneficiaryAdditionalFieldsForm.beneficiaryPan,
        beneficiaryIDType:
          typeof beneficiaryAdditionalFieldsForm.beneficiaryIDType === 'string'
            ? beneficiaryAdditionalFieldsForm.beneficiaryIDType
            : beneficiaryAdditionalFieldsForm.beneficiaryIDType?.id,
        beneficiaryIDNumber: beneficiaryAdditionalFieldsForm.beneficiaryIDNumber,
        beneficiaryAccountNumber: beneficiaryAdditionalFieldsForm.beneficiaryAccountNumber,
        beneficiaryCitizenship: beneficiaryAdditionalFieldsForm.beneficiaryCitizenship?.id,
        beneficiaryBankCode: beneficiaryAdditionalFieldsForm.beneficiaryBankCode?.code,
        beneficiaryBankBranchCode: beneficiaryAdditionalFieldsForm.beneficiaryBankBranchCode?.code,
        beneficiaryAccountType: beneficiaryAdditionalFieldsForm.beneficiaryAccountType?.id,
        beneficiaryBankBic: beneficiaryAdditionalFieldsForm.beneficiaryBankBic,
        beneficiaryBankBranchBic: beneficiaryAdditionalFieldsForm.beneficiaryBankBranchBic
      },
      amountKey,
      hash: [],
      pointIds: [...selectedPoints],
      exchangeRate: calculationResult.destination.rate,
      payoutCurrency: calculationResult.source.currency,
      payoutAmount: calculationResult.source.totalAmount
    };
  }

  private _completeInitiateRemittance$$(): Observable<void> {
    return of(void 0)
      .pipe(
        // locations window
        switchMap(() => {
          if (
            this._workflowsQuery.getActiveId() === 'ToCash' &&
            this._pointsQuery.getAll().filter((p) => p.selected === true).length === 0
          ) {
              return this._dialogService.openLocations$({ mode: 'before-start' });
            }
            return of(void 0);
          }
        ),
        switchMap(() => this._gatherDataAndStartRemittance$()),
        // TODO отрефачить, разбить на методы
        catchError(
          (error: IStoredProcedureError): Observable<never | IPaymentGatewayInfoDto> => {
            const theError = error?.error?.errors[0];
            this._sendStore.setLoading(false);

            if (theError?.code === environment.appSettings.errorCodes.ratesHasChangedErrorCode) {
              return this._calculatorService.executeCalculationAndUpdateStore$().pipe(
                switchMap(() => this._dialogService.openRateHasChanged$()),
                switchMap((result: boolean) => {
                  if (!result) {
                    return this._calculatorQuery.amountKey$$.pipe(
                      take(1),
                      switchMap((amountKey: 0 | 1) => {
                        if (amountKey === 0) {
                          this.sourceAmountFieldFocusTrigger$.next(void 0);
                          this._scrollToService.scroll({
                            document: this.document,
                            scrollTarget: '#sourceAmount',
                            scrollOffset: -400,
                          });
                          return EMPTY;
                        } else {
                          this.destinationAmountFieldFocusTrigger$.next(void 0);
                          this._scrollToService.scroll({
                            document: this.document,
                            scrollTarget: '#destinationAmount',
                            scrollOffset: -500,
                          });
                          return EMPTY;
                        }
                      })
                    );
                  }
                  return this._gatherDataAndStartRemittance$().pipe(
                    catchError(() => {
                      this._sendStore.setLoading(false);
                      return EMPTY;
                    })
                  );
                })
              );
            }
            return EMPTY;
          }
        ),
        withLatestFrom(this._personalDataQuery.email$$),
        withTransaction(([response, profileEmail]) => {
          if (response.remitterInfo != null) {
            response.remitterInfo.email = profileEmail;
          }
          this._sendStore.setPaymentGatewayDetails(response);
          this._sendStore.setPaymentGatewayType(response.paymentGatewayType);
          this._sendStore.setRedirectsWithToken(response.transactionId);
        }),
        switchMap(() => this._profileAdditionalAttributesQuery.isDontNotifyBeReady$$),
        take(1),
        switchMap((dontNotifyBeReady: boolean) =>
          dontNotifyBeReady ? of(void 0) : this._dialogService.openPrepareForPayment$()
        )
      )
      .pipe(
        switchMap(() => this._saveTemplatePanelQuery.isSaveAsTemplate$$),
        take(1),
        switchMap((saveAsTemplate: boolean) => {
          if (saveAsTemplate) {
            return this._templatesListService.createAndSaveTemplate$();
          }
          return of(void 0);
        })
      )
      .pipe(
        switchMap(() => from(this._router.navigate(['private', 'send', 'secure']))),
        take(1),
        tap(() => this._sendStore.setLoading(false)),
        switchMap(() => this._vanishSendForm$()),
        mergeMap(() => this._remittanceDraftService.deleteDraft$()),
        tap(() => this._googleAnalyticsService.event('transfer_attempt', '', '')),
        mapTo(void 0)
      );
  }

  private _apiCreateRemittance$(startRemittanceBody: IStartRemittanceBody): Observable<IPaymentGatewayInfoDtoWithPayoutInstruments> {
    return of(void 0)
      .pipe(
        tap(() => this._sendStore.setLoading(true)),
        switchMap(() => this._http.post<IPaymentGatewayInfoDto>('remittance/create', startRemittanceBody)),
        switchMap(response => {
          const payoutInstrumentsRequest: IFFCheckoutRetrievePaymentMethodsModel = this._mapStartRemittanceResponseToPayoutInstrumentsRequest(response);
          return forkJoin([of(response), this._paymentInstrumentsService.getPayoutInstruments$(payoutInstrumentsRequest, response.baseApiUrl, response.innerIp)]);
        }),
        map(([createRemittanceResponse, payoutInstruments]): IPaymentGatewayInfoDtoWithPayoutInstruments => ({
          ...createRemittanceResponse,
          // @ts-ignore we keep instruments with non-nullable id
          paymentInstruments: payoutInstruments.paymentInstruments?.filter(instrument => instrument.id != null).map(instrument => instrument.id) ?? []
        })),
        catchError(() => {
          this._sendStore.setLoading(false);
          return EMPTY;
        }),
        tap(() => this._sendStore.setLoading(false)),
        take(1)
      );
  }

  private _startCheckRemittanceForRisk$(
    startRemittanceBody: IStartRemittanceBody
  ): Observable<IPaymentCheckRemittanceResponseModel> {
    return of(void 0).pipe(
      tap(() => this._sendStore.setLoading(true)),
      switchMap(() => this._http.post<IPaymentCheckRemittanceResponseModel>('remittance/check', startRemittanceBody)),
      catchError(() => {
        this._sendStore.setLoading(false);
        return EMPTY;
      }),
      tap(() => this._sendStore.setLoading(false))
    );
  }

  private _vanishSendForm$(): Observable<void> {
    return of(void 0).pipe(
      take(1),
      tap(() => {
        this._beneficiaryGeneralFormStore.reset();
        this._beneficiaryAdditionalFieldsFormStore.reset();
        this._beneficiaryAddressService.beneficiaryAddressForm.reset();
        this._myBeneficiaryStore.reset();

        this._requiredFieldsStore.reset();
        this._workflowsStore.setDefault();
        this._gatewaysStore.reset();
        this._calculatorStore.reset();

        this._currenciesDestinationStore.reset();
        this._beneficiaryGeneralFormStore.reset();
        this._saveAsTemplatePanelFormStore.reset();
      })
    );
  }

  private _checkPaymentAwaiting$(): Observable<void> {
    return this._requiredDocumentsQuery.isPaymentAwaiting$$.pipe(
      take(1),
      concatMap(isPaymentAwaiting => {
        let awaitingMessage: string;
        if (isPaymentAwaiting) {
          this._sendStore.setLoading(false);
          awaitingMessage = this._localizationQ.transform('%[send.error.message.wait-transfer-confirmation]%');
          return this._openAwaitingModal$(awaitingMessage);
        }
        return of(void 0);
      }),
      mapTo(void 0)
    );
  }

  private _openAwaitingModal$(message: string): Observable<void> {
    return this._dialogService.openWizardAwaiting$(message).pipe(
      concatMap(() => EMPTY)
    );
  }
}
