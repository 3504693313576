import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { withTransaction } from '@datorama/akita';
import { EMPTY, forkJoin, from, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  mapTo,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { HistoryStore } from './history.store';
import { ICurrentSearchParameters, IHistoryResults } from './history.models';
import { DialogService } from '../../../../../functionality-modules/dialog-windows/dialog.service';
import { IRemittanceDetailResponseDto } from '../../../private-models/remittance-refund.model';
import { IPaymentGatewayInfoDto } from '../../../private-models/send.models';
import { SendStore } from '../../send/store/send.store';
import { LanguagesQuery, LocalizationQuery } from '@ff/localization';
import { IFFCheckoutRetrievePaymentMethodsModel } from 'ff-checkout/models/payment-methods.model';
import {
  IPaymentGatewayInfoDtoWithPayoutInstruments,
} from '../../send/containers/send/send.models';
import { CheckoutPaymentInstrumentsService } from '../../../../../data-modules/checkout-payment-instruments.service';
import { RequiredDocumentsService } from '../../../../../data-modules/required-documents/required-documents.service';
import { PersonalDataFormQuery } from '../../profile/personal-data/personal-data.store';

@Injectable()
export class HistoryService {

  constructor(
    private _historyStore: HistoryStore,
    private _langQ: LanguagesQuery,
    private _sendStore: SendStore,
    private _http: HttpClient,
    private _router: Router,
    private _dialogService: DialogService,
    private _localizationQ: LocalizationQuery,
    private _paymentInstrumentsService: CheckoutPaymentInstrumentsService,
    private _requiredDocumentsService: RequiredDocumentsService,
    private _personalDataQuery: PersonalDataFormQuery
  ) { }

  remittanceRefund$(transactionId: string, reference: string | null): Observable<void> {
    return of(void 0).pipe(
      tap(() => this._historyStore.setLoading(true)),
      switchMap(() => this._http.post<IRemittanceDetailResponseDto>('remittance/refund', { transactionId })),
      catchError(() => {
        this._historyStore.setLoading(false);
        return EMPTY;
      }),
      switchMap(resp => {
        if (resp.id != null) {
          const param: { [key: string]: string | string[] } = this._getHistorySearchParameters({ refNumber: reference } as ICurrentSearchParameters);
          return this._http.get<IHistoryResults>('history', { params: param });
        }
        return throwError('Failure refund!');
      }),
      catchError(() => {
        this._historyStore.setLoading(false);
        return EMPTY;
      }),
      withTransaction((res) => {
        if (res.results != null && res.results.length === 1 && res.results[0] != null) {
          this._historyStore.update(res.results[0].id, { ...res.results[0] });
        }
        this._historyStore.setLoading(false);
      }),
      mapTo(void 0)
    );
  }

  remittanceSendContinue$(transactionId: string): Observable<void> {
    return of(void 0).pipe(
      withLatestFrom(this._langQ.select(s => s.active)),
      tap(() => this._historyStore.setLoading(true)),
      switchMap(([_, lang]) => this._http.get<IPaymentGatewayInfoDto>('remittance/continue/' + transactionId + '/' + lang)),
      switchMap((response: IPaymentGatewayInfoDto) => {
        const payoutInstrumentsRequest: IFFCheckoutRetrievePaymentMethodsModel = this._mapContinueRemittanceResponseToPayoutInstrumentsRequest(response);
        return forkJoin([of(response), this._paymentInstrumentsService.getPayoutInstruments$(payoutInstrumentsRequest, response.baseApiUrl, response.innerIp)]);
      }),
      map(([createRemittanceResponse, payoutInstruments]): IPaymentGatewayInfoDtoWithPayoutInstruments => ({
        ...createRemittanceResponse,
        // @ts-ignore we keep instruments with non-nullable id
        paymentInstruments: payoutInstruments.paymentInstruments?.filter(instrument => instrument.id != null).map(instrument => instrument.id) ?? []
      })),
      withLatestFrom(this._personalDataQuery.email$$),
      withTransaction(([resp, profileEmail]) => {
        if (resp.remitterInfo != null) {
          resp.remitterInfo.email = profileEmail;
        }
        this._sendStore.setPaymentGatewayDetails(resp);
        this._sendStore.setPaymentGatewayType(resp.paymentGatewayType);
        this._sendStore.setRedirectsWithToken(resp.transactionId);
      })
    ).pipe(
      mergeMap(() => {
        this._historyStore.setLoading(false);
        return from(this._router.navigate(['private', 'send', 'secure']));
      }),
      catchError(() => {
        this._historyStore.setLoading(false);
        return EMPTY;
      }),
      mapTo(void 0)
    );
  }

  loadHistory$(): Observable<void> {
    return of(void 0).pipe(
      withTransaction(() => {
        this._historyStore.set([]);
        this._historyStore.setLoading(true);
        // copy filter data to searchParameters
        const filterData = this._historyStore.getValue().filterData;
        this._historyStore.update(st => ({
          ...st, currentSearchParameters: {
            country: filterData.country,
            currency: filterData.currency,
            dateFrom: filterData.dateFrom,
            dateTo: filterData.dateTo,
            receiver: filterData.receiver,
            refNumber: filterData.refNumber,
            totalCount: null
          }
        }));
      }),
      switchMap(() => {
        const searchParameters = this._historyStore.getValue().currentSearchParameters;
        const param: { [key: string]: string | string[] } = this._getHistorySearchParameters(searchParameters);
        return this._http.get<IHistoryResults>('history', { params: param });
      }),
      withTransaction(resp => {
        this._historyStore.setLoading(false);
        this._historyStore.upsertMany(resp.results);
        this._historyStore.update(st => ({
          ...st,
          currentSearchParameters: { ...st.currentSearchParameters, totalCount: resp.totalCount }
        }));
        this._historyStore.update(st => ({ ...st, filterState: { isFilterCollapsed: true } }));
      }),
      catchError(() => {
        this._historyStore.setLoading(false);
        return EMPTY;
      }),
      mapTo(void 0)
    );
  }

  loadMoreHistory$(): Observable<void> {
    return of(0).pipe(
      withTransaction(() => this._historyStore.setLoading(true)),
      switchMap(() => {
        const offset: number = this._historyStore.getValue().ids?.length ?? 0;
        const searchParameters = this._historyStore.getValue().currentSearchParameters;
        const param: { [key: string]: string | string[] } = this._getHistorySearchParameters(searchParameters, offset);
        return this._http.get<IHistoryResults>('history', { params: param });
      }),
      withTransaction(resp => {
        this._historyStore.setLoading(false);
        this._historyStore.upsertMany(resp.results);
        this._historyStore.update(st => ({
          ...st,
          currentSearchParameters: { ...st.currentSearchParameters, totalCount: resp.totalCount }
        }));
      }),
      catchError(err => {
        this._historyStore.setLoading(false);
        return throwError(err);
      }),
      mapTo(void 0)
    );
  }

  toggleFilter$(forcedValue?: boolean): Observable<void> {
    return this._historyStore._select(x => x.filterState.isFilterCollapsed).pipe(
      take(1),
      tap(is => this._historyStore.update(st => ({
        ...st,
        filterState: { isFilterCollapsed: forcedValue != null ? forcedValue : !is }
      }))),
      mapTo(void 0)
    );
  }

  cancelCreatedTx$(txId: string): Observable<void> {
    return this._dialogService.openConfirmDialog$(this._localizationQ.transform('%[history.dialog.cancel]%')).pipe(
        filter((result: boolean) => result),
        tap(() => this._historyStore.setLoading(true)),
        concatMap(() => this._http.post(`remittance/cancel/${txId}`, {})),
        concatMap(() => this._requiredDocumentsService.updateRequiredDocuments$()),
        concatMap(() => this.loadHistory$()),
        catchError(() => {
          this._historyStore.setLoading(false);
          return EMPTY;
        }),
      );
  }

  private _getHistorySearchParameters(searchParameters: ICurrentSearchParameters | null, offset: number = 0): { [k: string]: string | string[] } {
    if (searchParameters == null) { throw new Error('could not find search parameters'); }
    const result: { [key: string]: string | string[] } = {};
    const paramsDateFrom = searchParameters.dateFrom?.toISODate() ?? null;
    const paramsDateToPlus30 = searchParameters.dateTo?.plus({ day: 30 }).toISODate() ?? null;
    // using properties from FFOnline.Contract.History.Connector.GetHistoryParamDto
    if (paramsDateFrom != null) {result.dateFrom = paramsDateFrom; }
    if (paramsDateToPlus30 != null) { result.dateTo = paramsDateToPlus30; }
    if (searchParameters.refNumber != null && searchParameters.refNumber.trim() !== '') { result.refNumber = searchParameters.refNumber; }
    if (searchParameters.currency != null) { result.sourceCurrencies = [searchParameters.currency.id]; }
    if (searchParameters.country != null) { result.countries = [searchParameters.country.id]; }
    if (searchParameters.receiver != null) { result.destinationMembers = [searchParameters.receiver.id.toString(10)]; }
    if (searchParameters.statuses != null && searchParameters.statuses.length != null) { result.documentStatuses = searchParameters.statuses; }
    if (offset != null) { result.offset = offset.toString(10); }

    return result;
  }

  private _mapContinueRemittanceResponseToPayoutInstrumentsRequest(response: IPaymentGatewayInfoDto): IFFCheckoutRetrievePaymentMethodsModel {
    return {
      order: {
        orderId: response.transactionId ?? '',
        currency: response.currency ?? '',
        amount: response.amountInfo?.totalAmount ?? 0
      },
      customer: {
        country: response.remitterInfo?.address?.country ?? ''
      }
    };
  }

}
