import { NgModule } from '@angular/core';
import { BanksService } from './banks.service';
import { BanksQuery, BanksStore } from './banks.store';


@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    BanksService,
    BanksStore,
    BanksQuery,
  ],
})
export class BanksModule {}
